import React from 'react'
import { authRoles } from '../../auth/authRoles'

const FinanceRoutes = [

    {
        path: '/students/payments',
        component: React.lazy(() => import('./AllStudentspayments')),
        auth: authRoles.FinanceView,
    },

]

export default FinanceRoutes
