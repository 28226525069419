/*
Loons Lab File picker component
Developed By Shammika
Loons Lab
*/
import React, { Fragment, useState, Component } from "react";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import { Breadcrumb, SimpleCard, MatxProgressBar } from 'app/components'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Fab, Icon, Card, Grid, Divider, IconButton, Tooltip, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import OutlinedDiv from "../OutlinedDiv";
import clsx from 'clsx'
import PropTypes from "prop-types";
import {
    Button,
    LoonsTable,
    LoonsSnackbar,
    MainContainer,
    LoonsCard,
    CardTitle,
    SubTitle,
    FilePicker,
    CustomGrid
} from 'app/components/LoonsLabComponents'
import AptitudeTestServices from "app/services/AptitudeTestServices";

class LoonsAptitudeFilePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            uploadBtnProgress: false,
            CurrentUpload: {
                title: "",
                files: { fileList: [] },
            },


            addedFiles: [],
            //snack bar
            alert: false,
            severity: '',
            message: '',

        }
    }

    static propTypes = {

    };

    static defaultProps = {



    };
    setDocumentTitle(e) {

        let CurrentUpload = this.state.CurrentUpload

        CurrentUpload.title = e.target.value

        this.setState({
            CurrentUpload: CurrentUpload
        })
    }

    //file upload
    selectedFiles(files) {

        console.log("FIles", files)

        let CurrentUpload = this.state.CurrentUpload
        CurrentUpload.files = files
        this.setState({
            CurrentUpload: CurrentUpload,
        })
    }

    addFiles() {
        if (this.state.CurrentUpload.title === "" || this.state.CurrentUpload.files.fileList.length == 0) {
            this.setState(
                {
                    alert: true,
                    severity: 'error',
                    message: 'Please fill the all fields!!',
                    uploadBtnProgress: false
                }
            )
        } else {
            let addedFiles = this.state.addedFiles
            let obj =
            {
                title: this.state.CurrentUpload.title,
                files: this.state.CurrentUpload.files
            }

            addedFiles.push(obj)


            let CurrentUpload = {
                title: "",
                files: { fileList: [] },
            }
            this.setState({
                addedFiles: addedFiles,
                CurrentUpload: CurrentUpload
            })
        }

    }

    removeItem(title) {
        let addedFiles = this.state.addedFiles.filter(function (el) {
            return el.title != title
        })
        this.setState({
            addedFiles: addedFiles
        })
    }

    async uploadAllFiles(id) {
        var bodyFormData = new FormData();
        this.setState({
            uploadBtnProgress: true
        })

        let file = []
        let titles = []

        let addedFiles = this.state.addedFiles
        addedFiles.forEach((element, index) => {
            console.log("Title", element.title)
            bodyFormData.append("file_titles[" + index + "]", element.title);
            bodyFormData.append('file', element.files.fileList[0].file);

        })

        bodyFormData.append(`aptitude_test_exam_id`, id);

        console.log("file arr", file)
        console.log("file titles", titles)

        await AptitudeTestServices.UploadPaperFiles(bodyFormData)
            .then(res => {



                if (res.status == 201) {

                    console.log(res)

                    this.setState({
                        alert: true,
                        severity: 'success',
                        message: 'Files uploaded successfully',
                        uploadBtnProgress: false,
                        addedFiles: []
                    })

                    setTimeout(() => {
                        this.props.handlerInsert(this.props.mode, res.data.posted.result)
                    }, 2000);


                } else {
                    this.setState({
                        alert: true,
                        severity: 'error',
                        message: res.data.error,
                        uploadBtnProgress: false
                    })
                }

            }).catch(error => {
                this.setState({
                    alert: true,
                    severity: 'error',
                    message: 'Cannot Upload Documents',
                    uploadBtnProgress: false
                })
            })
    }

    componentDidMount() {

    }

    render() {
        const {
            singleFileEnable,
            multipleFileEnable,
            dragAndDropEnable,
            tableEnable,
            label,
            singleFileButtonText,
            multipleFileButtonText,
            className,
            validators,
            errorMessages,
            accept,
            documentName,
            documentNameValidation,
            documenterrorMessages,
            selectedFileList,
            id
        } = this.props;


        return (
            <Fragment>
                <ValidatorForm
                    // ref={ref => this.setRef1(ref)}
                    // id="Main"
                    onSubmit={() => this.uploadAllFiles(this.props.aptitude_test_exam_id)}
                    onError={() => null}
                >
                    <Grid container spacing={2}>
                        <Grid item lg={12} md={12} sm={12} xs={12} className="my-4">
                            <small className="text-primary font-medium text-16 mb--2 p-2 bg-light-primary border-radius-4">
                                Upload files for the paper</small>

                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>

                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} >
                                            {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
                                            <TextValidator

                                                type="text"
                                                size="small"
                                                label="Title of the Document"
                                                variant="outlined"
                                                name="title"
                                                onChange={(e) => {
                                                    this.setDocumentTitle(e);
                                                }}
                                                value={this.state.CurrentUpload.title}
                                                // validators={["required"]}
                                                // errorMessages={[
                                                //     "This field is required!",

                                                // ]}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FilePicker
                                                className="mb-4 w-full"
                                                singleFileEnable={true}
                                                id={`supporting_doc ${this.props.filePicker_id}`}
                                                multipleFileEnable={false}
                                                dragAndDropEnable={true}
                                                //tableEnable={false}
                                                documentName={false}
                                                //documentNameValidation={['required']}
                                                //documenterrorMessages={['This field is required']}
                                                //accept="image/png, image/gif, image/jpeg"
                                                maxFileSize={4000000000}
                                                maxTotalFileSize={4000000000}
                                                maxFilesCount={1}
                                                validators={['maxSize', 'maxTotalFileSize', 'maxFileCount']}
                                                errorMessages={["file size too large", "Total file size is too large", "Too many files added"]}
                                                label="Select Attachment"
                                                singleFileButtonText="Select File"
                                                multipleFileButtonText="Select Files"
                                                selectedFiles={(files) => this.selectedFiles(files)}
                                                selectedFileList={this.state.CurrentUpload.files.fileList}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                className="full-width"
                                                // progress={this.state.requestBtnProgress}
                                                startIcon="add"
                                                type="button"
                                                color="primary"
                                                size="small"
                                                scrollToTop={false}
                                                onClick={() => {
                                                    this.addFiles()
                                                }}

                                            >
                                                <span className="capitalize">Add this File</span>
                                            </Button>
                                        </Grid>
                                    </Grid>

                                </Grid>

                                {this.state.addedFiles.length > 0 ? (
                                    <Grid item md={6} xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <CustomGrid className="bg-light-primary text-primary " title="Selected File List" />
                                            </Grid>
                                            {this.state.addedFiles.map((element, index) => (
                                                <Grid key={index} item xs={12} className="mt--6 pl-5">
                                                    <Grid container spacing={2}>
                                                        <Grid container item xs={2} className="pl-2 items-center">
                                                            <Typography className="text-15 font-medium ">File {(index + 1).toLocaleString('en-US', {
                                                                minimumIntegerDigits: 2,
                                                                useGrouping: false
                                                            })}.</Typography>
                                                        </Grid>

                                                        <Grid container item xs={8} className="pl-2 items-center justify-start">
                                                            <Typography className="text-15 font-normal ">{element.title}</Typography>
                                                        </Grid>
                                                        <Grid container item xs={2} className="pl-2 justify-end">
                                                            <IconButton
                                                                onClick={() => {

                                                                    this.removeItem(element.title)

                                                                    // setShouldOpenEditorDialog(true)
                                                                }}
                                                            >
                                                                <Icon color="error">delete</Icon>
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid >
                                            ))}

                                        </Grid>
                                        <Grid item xs={12} className="mt-5 flex  justify-end items-end">
                                            <Button
                                                className="full-width"
                                                progress={this.state.uploadBtnProgress}
                                                startIcon="cloud_upload"
                                                type="submit"
                                                color="primary"
                                                scrollToTop={false}
                                                // onClick={() => {
                                                //     this.uploadAllFiles(this.props.aptitude_test_exam_id)
                                                // }}
                                                disabled={this.state.addedFiles.length > 0 ? false : true}

                                            >
                                                <span className="capitalize">Upload All Files</span>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ) : <Fragment></Fragment>}
                                <div>
                                    <Divider className="bg-primary" />
                                </div>
                            </Grid>

                        </Grid>
                    </Grid>
                </ValidatorForm>
                <LoonsSnackbar
                    open={this.state.alert}
                    onClose={() => {
                        this.setState({ alert: false })
                    }}
                    message={this.state.message}
                    autoHideDuration={3000}
                    severity={this.state.severity}
                    elevation={2}
                    variant="filled"
                ></LoonsSnackbar>
            </Fragment>
        );
    }
}

export default LoonsAptitudeFilePicker;