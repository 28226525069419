import React from 'react'
import { authRoles } from 'app/auth/authRoles'

const LectureDayRoutes = [

    {
        path: '/QRBaseAttendance/LectureDay/:id',
        component: React.lazy(() => import('./LectureDay')),
        auth: authRoles.LectureDay,
    },

    {
        path: '/QRBaseAttendance/MarkMyAttendance',
        component: React.lazy(() => import('./MarkMyAttendance')),
        auth: authRoles.LectureDay,
    },

    {
        path: '/QRBaseAttendance/EditAttendance/:id',
        component: React.lazy(() => import('./EditAttendance')),
        auth: authRoles.LectureDay,
    },

    {
        path: '/QRBaseAttendance/StudentAttendance',
        component: React.lazy(() => import('./StudentAttendance')),
        auth: authRoles.LectureDay,
    },

    {
        path: '/QRBaseAttendance/Manual',
        component: React.lazy(() => import('./Manual')),
        auth: authRoles.LectureDay,
    },

    {
        path: '/QRBaseAttendance/Cancellation',
        component: React.lazy(() => import('./Cancellation')),
        auth: authRoles.LectureDay,
    },

    {
        path: '/QRBaseAttendance/Cancelled',
        component: React.lazy(() => import('./Cancelled')),
        auth: authRoles.LectureDay,
    },

    {
        path: '/QRBaseAttendance/AllAttendance',
        component: React.lazy(() => import('./AllAttendance')),
        auth: authRoles.LectureDay,
    },

    {
        path: '/QRBaseAttendance/MyAttendance',
        component: React.lazy(() => import('./MyAttendance')),
        auth: authRoles.LectureDay,
    },

    {
        path: '/QRBaseAttendance/individual',
        component: React.lazy(() => import('./individual')),
        auth: authRoles.LectureDay,
    },

    {
        path: '/QRBaseAttendance/individualyear',
        component: React.lazy(() => import('./IndividualYear')),
        auth: authRoles.LectureDay,
    },
    
    {
        path: '/QRBaseAttendance/MyAttendance',
        component: React.lazy(() => import('./MyAttendance')),
        auth: authRoles.LectureDay,
    },
    


]

export default LectureDayRoutes
