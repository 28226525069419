import React from 'react'
import { authRoles } from '../../auth/authRoles'

const LeaveRoutes = [
    {
        path: '/Leave/LeaveRequest',
        component: React.lazy(() => import('./LeaveRequest')),
        auth: authRoles.EmployeeProfileView,
    },
    {
        path: '/Leave/MyLeaves',
        component: React.lazy(() => import('./MyLeaves')),
        auth: authRoles.EmployeeProfileView,
    },
    {
        path: '/Leave/EmployeeLeaveRequests',
        component: React.lazy(() => import('./EmployeeLeaveRequests')),
        auth: authRoles.EmployeeProfileView,
    },
    {
        path: '/Leave/leave-approval/:id',
        component: React.lazy(() => import('./LeaveApproval')),
        auth: authRoles.EmployeeProfileView,
    },
    {
        path: '/Leave/leave-cancellations/',
        component: React.lazy(() => import('./LeaveCancellationRequests')),
        auth: authRoles.EmployeeProfileView,
    },
    {
        path: '/Leave/leave-substitutes/',
        component: React.lazy(() => import('./SubstituteRequests')),
        auth: authRoles.EmployeeProfileView,
    },
    {
        path: '/Leave/leave-approval-requests/',
        component: React.lazy(() => import('./LeaveApprovalsRequests')),
        auth: authRoles.EmployeeProfileView,
    },
    {
        path: '/Leave/my-leaves/',
        component: React.lazy(() => import('./DisplayMyLeaves')),
        auth: authRoles.EmployeeProfileView,
    },
    {
        path: '/Leave/overtime-details/',
        component: React.lazy(() => import('./OvertimeDetails')),
        auth: authRoles.FinanceView,
    },
    {
        path: '/Leave/attendance-details/',
        component: React.lazy(() => import('./AttendanceDetails')),
        auth: authRoles.HR,
    },
    {
        path: '/Leave/leave-summary/',
        component: React.lazy(() => import('./LeaveSummary')),
        auth: authRoles.HR,
    },
    {
        path: '/Leave/other-leave-setup/',
        component: React.lazy(() => import('./OtherLeaveSetup')),
        auth: authRoles.HR,
    },
    {
        path: '/Leave/add-calender-events/',
        component: React.lazy(() => import('./AddCalenderEvents')),
        auth: authRoles.HR,
    },
]

export default LeaveRoutes
