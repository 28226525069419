import React from 'react'
import {authRoles} from '../../../auth/authRoles'

const ConfigurationsGradesRoutes = [

    {
        path: '/examinations/configurations_grades',
        component: React.lazy (() => import('./ConfigurationsGrades')),
        authRoles: authRoles.ExaminationView,
    },
    {
        path: '/examinations/configurations_class',
        component: React.lazy(() => import('./ConfigurationsClasses')),
        authRoles: authRoles.ExaminationView,
    },
]

export default ConfigurationsGradesRoutes