import React from 'react'
import { authRoles } from '../../auth/authRoles'

const CourseManagementRoutes = [
    {
        path: '/CourseManagement/InstrumentCategory',
        component: React.lazy(() => import('./Instrument/InstrumentCategory')),
        auth: authRoles.aptitudeTestView,
    },
    {
        path: '/CourseManagement/AddInstrument',
        component: React.lazy(() => import('./Instrument/Instruments')),
        auth: authRoles.aptitudeTestView,
    },
    {
        path: '/CourseManagement/AllInstrument',
        component: React.lazy(() => import('./Instrument/All_Instruments')),
        auth: authRoles.aptitudeTestView,
    },
    {
        path: '/CourseManagement/InstrumentTabHand',
        component: React.lazy(() => import('./Instrument/InstrumentTabHandling')),
        auth: authRoles.aptitudeTestView,
    },
    {
        path: '/CourseManagement/IssueInstrument',
        component: React.lazy(() => import('./Instrument/IssueInstrument')),
        auth: authRoles.aptitudeTestView,
    },
    {
        path: '/CourseManagement/BorrowedInstrument',
        component: React.lazy(() => import('./Instrument/BorrowedInstruments')),
        auth: authRoles.aptitudeTestView,
    },
    // {
    //     path: '/CourseManagement/InstrumentHandling',
    //     component: React.lazy(() => import('./Instrument/InstrumentHandling')),
    //     auth: authRoles.aptitudeTestView,
    // },
    {
        path: '/CourseManagement/InstrumentHandling/SingleView/:id',
        component: React.lazy(() => import('./Instrument/InstrumentHandling')),
        auth: authRoles.batchView,
    },
]

export default CourseManagementRoutes;