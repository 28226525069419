//For Application constant and other default values
// export const keycloak_client_secret = '4bavtmo9oTW3kpiUvWUeEfiMsCLU8W2l'
// export const keycloak_client_secret = 'W6IxP6jq953Ra2Dd6yL70KZP9msqtCMz'

// Azure
// export const keycloak_client_secret = '4bavtmo9oTW3kpiUvWUeEfiMsCLU8W2l'

// Test New
//export const keycloak_client_secret = 'fiNbO5Swmk95YaWgMxCmMSfRwzStXKtG'

//Live server
 export const keycloak_client_secret = '4bavtmo9oTW3kpiUvWUeEfiMsCLU8W2l'

//local
// export const keycloak_client_secret = 'YhVkSQlwVTwRsj6W2hhJKfA6jAyZmQh3'
// Local Shammika
// export const keycloak_client_secret = 'd0215dc6-426d-40e6-b442-314bf713f7d1'
// Local Shiny
// export const keycloak_client_secret = 'oTogzhDwoNtfH1RoYUzcuILDtJ4f8TOm'

export const keycloak_client_id = 'VPAClient'
export const refresh_befor = 86400 //for 24 hours

export const languages = [
    { index: 0, label: 'English', value: 'English' },
    { index: 1, label: 'Sinhala', value: 'Sinhala' },
    { index: 2, label: 'Tamil', value: 'Tamil' },
]

export const facultyList = [
    { label: 'All', value: 'all' },
    { index: 1, label: 'Dance and Drama', value: 'dance_and_drama', id: 1 },
    { index: 2, label: 'Music', value: 'music', id: 2 },
    {
        index: 3,
        label: 'Faculty of Visual Arts',
        value: 'Faculty_of_visual_arts',
        id: 3,
    },
]

export const result = [
    { label: 'Pass', value: 'pass' },
    { label: 'Fail', value: 'fail' },
    { label: 'Pending', value: 'pending' },
]

export const degreeList = [
    { label: 'All', value: 'all' },
    { label: 'Dance', value: 'dance' },
    { label: 'Drama', value: 'drama' },
    { label: 'Music', value: 'music' },
]

export const appeal_types = [
    { label: 'Payment Issues', value: 'Payment Issues' },
    { label: 'Application Issues', value: 'Application Issues' },
    { label: 'Other', value: 'Other' },
]

export const decisions = [
    { label: 'Approved', value: 'Approved' },
    { label: 'Rejected', value: 'Rejected' },
    { label: 'Pending', value: 'Pending' },
]

export const genderList = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
]

export const paymentStatus = [
    { label: 'All', value: 'all' },
    { label: 'Done', value: 'done' },
    { label: 'Pending', value: 'pending' },
]
export const applicationStatus = [
    { label: 'All', value: 'all' },
    { label: 'Done', value: 'done' },
    { label: 'Pending', value: 'pending' },
]
export const documentUploadStatus = [
    { label: 'All', value: 'all' },
    { label: 'Done', value: 'done' },
    { label: 'Pending', value: 'pending' },
]
export const academicYears = [
    { label: 'All', value: 'all' },
    { label: 'Year 01', value: 'Year 01' },
    { label: 'Year 02', value: 'Year 02' },
    { label: 'Year 03', value: 'Year 03' },
    { label: 'Year 04', value: 'Year 04' },
]
export const appealTypes = [
    { label: 'All', value: 'all' },
    {
        label: 'Year Renewal',
        value: 'Year Renewal',
    },
    {
        label: 'Late Registration',
        value: 'Late Registration',
    },
    {
        label: 'Deferment',
        value: 'Deferment',
    },
    {
        label: 'Subject Change',
        value: 'Subject Change',
    },
    {
        label: 'Department Change',
        value: 'Department Change',
    },
    {
        label: 'Other',
        value: 'Other',
    },
]
export const appealStatus = [
    { label: 'All', value: '' },
    { label: 'Appealed', value: 'Appealed' },
    { label: 'Rejected', value: 'Rejected' },
    { label: 'Approved', value: 'Approved' },
]

export const districtList = [
    { value: 'Ampara', label: 'Ampara', province: 'Eastern' },
    { value: 'Anuradhapura', label: 'Anuradhapura', province: 'North Central' },
    { value: 'Badulla', label: 'Badulla', province: 'Uva' },
    { value: 'Batticaloa', label: 'Batticaloa', province: 'Eastern' },
    { value: 'Colombo', label: 'Colombo', province: 'Western' },
    { value: 'Galle', label: 'Galle', province: 'Southern' },
    { value: 'Gampaha', label: 'Gampaha', province: 'Western' },
    { value: 'Hambantota', label: 'Hambantota', province: 'Southern' },
    { value: 'Jaffna', label: 'Jaffna', province: 'Northern' },
    { value: 'Kalutara', label: 'Kalutara', province: 'Western' },
    { value: 'Kandy', label: 'Kandy', province: 'Central' },
    { value: 'Kegalle', label: 'Kegalle', province: 'Sabaragamuwa' },
    { value: 'Kilinochchi', label: 'Kilinochchi', province: 'Northern' },
    { value: 'Kurunegala', label: 'Kurunegala', province: 'North Western' },
    { value: 'Mannar', label: 'Mannar', province: 'Northern' },
    { value: 'Matale', label: 'Matale', province: 'Central' },
    { value: 'Matara', label: 'Matara', province: 'Southern' },
    { value: 'Monaragala', label: 'Monaragala', province: 'Uva' },
    { value: 'Mullaitivu', label: 'Mullaitivu', province: 'Northern' },
    { value: 'Nuwara Eliya', label: 'Nuwara Eliya', province: 'Central' },
    { value: 'Polonnaruwa', label: 'Polonnaruwa', province: 'North Central' },
    { value: 'Puttalam', label: 'Puttalam', province: 'North Western' },
    { value: 'Ratnapura', label: 'Ratnapura', province: 'Sabaragamuwa' },
    { value: 'Trincomalee', label: 'Trincomalee', province: 'Eastern' },
    { value: 'Vavuniya', label: 'Vavuniya', province: 'Northern' },
]

export const provinceList = [
    // { label: 'All', value: 'all' },
    { value: 'Southern', label: 'Southern' },
    { value: 'Western', label: 'Western' },
    { value: 'Central', label: 'Central' },
    { value: 'Uva', label: 'Uva' },
    { value: 'Sabaragamuwa', label: 'Sabaragamuwa' },
    { value: 'North Western', label: 'North Western' },
    { value: 'North Central', label: 'North Central' },
    { value: 'Northern', label: 'Northern' },
    { value: 'Eastern', label: 'Eastern' },
]
export const civil_status_types = [
    {
        label: 'Married',
        value: 'Married',
    },
    {
        label: 'Unmarried',
        value: 'Unmarried',
    },
]

export const citizenship_get_types = [
    {
        label: 'Registration',
        value: 'Registration',
    },
    {
        label: 'Descent',
        value: 'Descent',
    },
]

export const emp_documents = [
    {
        label: 'An Affidavit',
        value: 'An Affidavit',
    },
    {
        label: 'Declaration of Assets',
        value: 'Declaration of Assets',
    },
    {
        label: 'Agreements',
        value: 'Agreements',
    },
    {
        label: 'Oaths',
        value: 'Oaths',
    },
    {
        label: 'Certificate of Grama Niladhari',
        value: 'Certificate of Grama Niladhari',
    },
    {
        label: 'Police Report',
        value: 'Police Report',
    },
    {
        label: 'Nominations for UPF',
        value: 'Nominations for UPF',
    },
    {
        label: 'Pension',
        value: 'Pension',
    },
    {
        label: 'Funds',
        value: 'Funds',
    },
    {
        label: 'The Medical Report',
        value: 'The Medical Report',
    },
]

// Student Document Submission Types

export const StudentDocumentSubmissionTypes = [
    { label: 'Download', value: 'Download' },
    { label: 'Upload', value: 'Upload' },
    { label: 'Download and Upload', value: 'Download and Upload' },
    /*   { label: 'Not Applicable', value: 'Not Applicable' }, */
]
// All Students View Constants
export const registrationStatus = [
    // { label: 'All', value: 'all' },
    { label: 'Active', value: 'Active' },
    { label: 'Deactive', value: 'Deactive' },
]

export const nationality_arr = [
    {
        label: 'Sinhalese',
    },
    {
        label: 'Tamil',
    },
    {
        label: 'Other',
    },
]

export const citizenship_arr = [
    {
        label: 'Citizen of Sri Lanka by Descent',
    },
    {
        label: 'Citizen of Sri Lanka by Registration',
    },
]

export const religion_Arr = [
    {
        label: 'Buddhism',
    },
    {
        label: 'Hinduism',
    },
    {
        label: 'Islam',
    },
    {
        label: 'Christianity',
    },
    {
        label: 'Other',
    },
]

//student profile side nav bar buttons
export const shortcutList = [
    {
        title: 'Dashboard',
        icon: 'dashboard',
    },
    {
        title: 'Uploads',
        icon: 'brightness_7',
    },

    {
        title: 'Cancel',
        icon: 'cancel_schedule_sendIcon',
    },
    {
        title: 'Renewal',
        icon: 'group',
    },
    {
        title: 'Student ID',
        icon: 'credit_card',
    },

    {
        title: 'Appeal',
        icon: 'touch_app_icon',
    },
    {
        title: 'Certificates',
        icon: 'description',
    },
    {
        title: 'Disciplinary Action',
        icon: 'people',
    },
    /* {
        title: 'ID',
        icon: 'payment_icon',
    }, */
    // {
    //     title: 'Exam Results',
    //     icon: 'layers',
    // },
]

//student profile admin view side nav buttons
export const shortcutList_AdminView = [
    {
        title: 'Student Info',
        icon: 'assignment_icon',
    },
    {
        title: 'Disciplinary',
        icon: 'group',
    },
    /* {
        title: 'Medical Info',
        icon: 'touch_app_icon',
    },
    {
        title: 'Academic',
        icon: 'cancel_schedule_sendIcon',
    },
    {
        title: 'Welfare',
        icon: 'payment_icon',
    },
    {
        title: 'Examination',
        icon: 'brightness_7',
    },
    {
        title: 'Disciplinary',
        icon: 'group',
    },
    {
        title: 'Appeals',
        icon: 'group',
    },
    {
        title: 'Achievements',
        icon: 'group',
    },
    {
        title: 'Cancellation',
        icon: 'group',
    }, */
]

//user profile id status
export const studentIDStatus = [
    {
        label: 'Requested',
    },
    {
        label: 'Ready to Collect',
    },
    {
        label: 'Collected',
    },
]

//Student Year renewal offline Payment
export const paymentOptions = [
    {
        paidTo: 'Bank Counter',
        icon: 'account_balance',
    },
    {
        paidTo: 'Online Transfer',
        icon: 'account_balance_wallet',
    },
]
export const yearRenewalRequestStatus = [
    {
        label: 'Requested',
    },
    {
        label: 'Approved',
    },
    {
        label: 'Rejected',
    },
]

//Duplicate ID Cards
export const DuplicateIDCardReasons = [
    {
        label: 'Lose ID',
    },
    {
        label: 'Damaged ID',
    },
]

export const DuplicateIDCardStatus = [
    {
        label: 'Duplicate Card Requested',
    },
    {
        label: 'Duplicate Card Ready to Collect',
    },
    {
        label: 'Duplicate Card Collected',
    },
    {
        label: 'Approved',
    },
    {
        label: 'Rejected',
    },
]

export const yearRenewalStatusTypes = [
    {
        label: 'All',
    },
    {
        label: 'Requested',
    },
    {
        label: 'Approved',
    },
    {
        label: 'Rejected',
    },
    {
        label: 'Year Updated',
    },
]

export const duplicateIDReasonTypes = [
    {
        label: 'Lose ID',
    },
    {
        label: 'Damaged ID',
    },
    {
        label: 'Other',
    },
]

export const offlinePaymentMethods = [
    {
        label: 'Bank Counter',
    },
    {
        label: 'University Shop Counter',
    },
]
export const cancellationRequestStatus = [
    { label: 'All', value: '' },
    { label: 'Requested', value: 'Requested' },
    { label: 'Rejected', value: 'Rejected' },
    { label: 'Approved', value: 'Approved' },
]

export const studentIdCardSubmitedStatus = [
    { label: 'Pending', value: 'pending' },
    { label: 'Submitted', value: 'submitted' },
]

export const batch_filters_degree_and_course_types = [
    {
        label: 'All',
    },
    {
        label: 'Taught Masters',
    },
    {
        label: 'Short Course',
    },
]

export const batch_filters_faculty_and_department_types = [
    {
        label: 'All',
    },
    {
        label: 'Dance and Drama',
    },
    {
        label: 'Visual Art',
    },
    {
        label: 'Music',
    },
    // {
    //     label: 'Multi-Disciplinary',
    // },
    // {
    //     label: 'Discipline of the Departments',
    // },
]

export const batch_filters_status_types = [
    {
        label: 'All',
    },
    {
        label: 'Active',
    },
    {
        label: 'Inactive',
    },
]

export const curriculum_filters_status_types = [
    {
        label: 'All',
    },
    {
        label: 'Active',
    },
    {
        label: 'Inactive',
    },
]

export const applicant_filters_course_types = [
    {
        label: 'Short Course',
    },
    {
        label: 'Taught Masters',
    },
    {
        label: 'MPhil',
    },
    {
        label: 'Phd',
    },
    {
        label: 'Masters',
    },
]

export const student_application_citizenship_types = [
    {
        label: 'Sri Lankan',
    },
    {
        label: 'Dual Citizen',
    },
    {
        label: 'Foreign',
    },
]

export const student_application_course_medium_types = [
    {
        label: 'English',
    },
    {
        label: 'Sinhala',
    },
    {
        label: 'Both',
    },
]

export const student_application_types = [
    {
        label: 'All',
    },
    {
        label: 'Normal',
    },
    {
        label: 'Special Circumstance',
    },
]

export const student_application_supervisor_types = [
    {
        label: 'I have both Supervisors',
    },
    {
        label: 'I have Internal Supervisor',
    },
    {
        label: 'I have External Supervisor',
    },
    {
        label: 'I dont have Supervisors',
    },
]

export const all_applications_course_types = [
    {
        label: 'All',
    },
    {
        label: 'Phd',
    },
    {
        label: 'MPhil',
    },
]

export const all_applications_status_types = [
    {
        label: 'All',
    },
    {
        label: 'Applied',
    },
    {
        label: 'Under Review',
    },
    {
        label: 'Approve',
    },
    {
        label: 'Reject',
    },
]

export const all_applications_sub_status_types = [
    {
        label: 'All',
    },
    {
        label: 'Under Review - BOS Approval',
    },
    {
        label: 'Under Review - BOGS Approval',
    },
    {
        label: 'Under Review - Senate',
    },
    {
        label: 'Reject - FGS',
    },
    {
        label: 'Reject - BOS',
    },
    {
        label: 'Reject - BOGS',
    },
    {
        label: 'Reject - Senate',
    },
]

export const create_curriculum_semester_types = [
    {
        label: '1 st semester',
    },
    {
        label: '2 nd semester',
    },
    {
        label: '3 rd semester',
    },
    {
        label: '4 th semester',
    },
]

export const create_curriculum_year_types = [
    {
        label: 'Year 1',
    },
    {
        label: 'Year 2',
    },
]

export const student_status_all_view_sign_up_status_types = [
    {
        label: 'All',
    },
    {
        label: 'Yes',
    },
    {
        label: 'No',
    },
]

export const student_status_all_view_payment_status_types = [
    {
        label: 'All',
    },
    {
        label: 'Pending',
    },
    {
        label: 'Approved',
    },
    {
        label: 'Rejected approved',
    },
]

export const student_status_all_view_application_status_types = [
    {
        label: 'All',
    },
    {
        label: 'Completed',
    },
    {
        label: 'Pending',
    },
]

export const student_status_all_view_document_upload_status_types = [
    {
        label: 'All',
    },
    {
        label: 'Complete',
    },
    {
        label: 'Pending',
    },
]

export const student_status_all_view_hard_copy_submission_status_types = [
    {
        label: 'All',
        value: '',
    },
    {
        label: 'Complete',
        value: true,
    },
    {
        label: 'Pending',
        value: false,
    },
]

//student aptitude test
export const Aptitude_Test_Examination_Modes = [
    'Online Practical Test(Pre - Recorded)',
    'Online Written Test',
    'Online Practical Test',
    'Online Viva',
    'E-Portfolio',
    'On Site Viva',
    'On Site Practical',
    'On Site Written Test',
]
export const examiners_titles = [
    { index: 0, label: 'All', value: '' },
    { index: 1, label: 'Dr.', value: 'dr' },
    { index: 2, label: 'Prof.', value: 'prof' },
]
export const examiners_types = [
    { index: 0, label: 'All', value: '' },
    { index: 1, label: 'Internal', value: 'internal' },
    { index: 2, label: 'External', value: 'external' },
]
export const aptituteExamAnsersStatus = [
    { index: 0, label: 'All', value: '' },
    { index: 1, label: 'Waiting to be marked', value: 'Waiting to be marked' },
    { index: 2, label: 'Marked', value: 'Marked' },
    { index: 2, label: 'Appealed', value: 'Appealed' },
]

export const facultyUserRole = [
    'SA',
    'Faculty AR',
    'Faculty Assistant Librarian',
    'Faculty System Analyst',
    'Faculty Network Manager',
    'Faculty MA',
    'Faculty Instructor',
    'Faculty Instructor in Physical Education',
    'Faculty Librarian',
    'Faculty Temporary Instructor',
    'Faculty Career Guidance Councelor',
    'Faculty Accomapanist',
    'Faculty Temporary Accomapanist',
    'HOD',
]
export const examDivitionUserRole = [
    'Exam AR',
    'Exam MA',
    'Exam SAR',
    'Exam DR',
    'SA',
    'ADMIN',
    'Lecturer',
]
export const studentServicesUserRole = ['SSW MA', 'SSW AR', 'SA', 'ADMIN']
export const financeDivisionRoles = [
    'Bursar',
    'Senior Assistant Bursar',
    'Assistant Bursar',
    'Shroff Counter Officer',
]
export const physicalUnitUserRole = ['Physical Unit AR', 'Physical Unit MA']
export const division = [{ label: 'Student Affairs', value: 'Student Affairs' }]

export const divisions = [
    { index: 0, label: 'Student Affairs', value: 'student_affairs' },
    { index: 1, label: 'Student Affairs', value: 'student_affairs' },
]

export const attendanceCalculationTypes = [
    'Work Norm Basis',
    'Daily Attendance Basis',
]

export const qualifications = [
    { lable: 'SDC', value: 'SDC' },
    { lable: 'Masters', value: 'Masters' },
]

export const designations = [
    { lable: 'SDC', value: 'SDC' },
    { lable: 'Masters', value: 'Masters' },
]
export const serviceLevel = [
    { label: 'Senior', value: 'Senior' },
    { label: 'Primary', value: 'Primary' },
    { label: 'Secondary', value: 'Secondary' },
    { label: 'Tertiary', value: 'Tertiary' },
]

// Medical unit const
export const prescriptionStatus = [
    { label: 'All', value: null },
    { label: 'Issued', value: true },
    { label: 'Not Issued', value: false },
]

export const patientsTypes = [
    { label: 'All', value: 'All' },
    { label: 'Student', value: 'Student' },
    { label: 'Employee', value: 'Employee' },
]
export const treatmentMethods = [
    { label: 'Inpatient', value: 'Inpatient' },
    { label: 'Outpatient', value: 'Outpatient' },
]
export const patientsTypes2 = [
    { label: 'All', value: '' },
    { label: 'Student', value: false },
    { label: 'Employee', value: true },
]

export const medicalTypes = [
    { label: 'All', value: 'All' },
    { label: 'Hospital', value: false },
    { label: 'Medical Unit', value: true },
]

export const purposeofMedical = [
    { label: 'All', value: 'All' },
    { label: 'Examination', value: 'examination' },
    { label: 'Absence', value: 'absence' },
]
export const apptituteTestStudentRegistrationPhotoSpesifications = [
    'Photo width and height should be same.',
    'The photo must be in full color.',
    'Blurred pictures will be rejected.',
    'Glasses: acceptable only if they do not obscure the eyes and there is no glare in the photo.',

    'File Size less than 2 MB.',
]
export const StudentSignaturePhotoSpesifications = [
    'The signature will be used in all the documents to indicate what you digitally sign throughout the system.Hence please upload a clear image of your signature.',
    'Image width and height should be same.',
    'Background: background less or plain white only.',
    'Blurred pictures will be rejected.',
    ' Resolution(pixels): at least 200 dpi.',
    'File Size less than 5 Mb.',
    //'Picture should be in the PNG format'
]

export const bankSlipPaymentInstructions = [
    'Please enter the OUSL Account Number (Acc No: 1741 0015 1189 657) to transfer the money.',
    'Upon the successful transaction an SMS will be sent by the Bank.',
    'Upon the successful completion of the transaction a SMS will be sent by the OUSL',
]

export const medical_types = [
    { label: 'Other Hospital', value: false },
    { label: 'Medical Unit', value: true },
]

export const purpose_of_medical = [
    { label: 'Examination' },
    { label: 'Absence' },
    { label: 'Appeal' },
]

export const genders = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Other', value: 'Other' },
]

export const presciptionList = [
    { label: 'All', value: '' },
    { label: 'Issued', value: 'issued' },
    { label: 'Not Issued', value: 'not_issued' },
]

export const patientTypeList = [
    { label: 'All', value: '' },
    { label: 'Student', value: 'student' },
    { label: 'Staff', value: 'staff' },
    { label: 'Relation', value: 'relation' },
]

// Recruitment -----------------------------------
// Const added by shammika
export const advertisementStatus = [
    { label: 'All', value: '' },
    { label: 'Published', value: 'published' },
    { label: 'Processing', value: 'processing' },
]

export const requestDecisions = [
    { label: 'All', value: '' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Rejected', value: 'Rejected' },
]

export const writtenExaminationModes = [
    'Online Written Test',
    'Offline Written Test',
]

export const writtenExamlanguages = [
    { index: 0, label: 'English', value: 'English' },
    { index: 1, label: 'Sinhala', value: 'Sinhala' },
]

export const category = ['1st Category', '2nd Category']

export const recruitmentTypes = ['Recruitment', 'Promotion']
export const recruitmentUnits = [
    'Academic Estabilishment Division',
    'Non Academic',
]

// ----------------------------------------------
export const gradeList = [
    { label: 'First Class ', value: 'first_class' },
    {
        label: 'Second Class Upper',
        value: 'second_class_upper',
    },
    {
        label: 'Second Class Lower',
        value: 'second_class_lower',
    },
    { label: 'General', value: 'general' },
]

export const degreeYears = [
    { label: '3 Years', value: '3_years' },
    { label: '4 Years', value: '4_years' },
    { label: '5 Years', value: '5_years' },
]

export const post_graduate_degree_types = [
    {
        label: 'Phd',
    },
    {
        label: 'MPhil',
    },
    {
        label: 'MA',
    },
    {
        label: 'MFA',
    },
    {
        label: 'M.Music',
    },
    {
        label: 'M.Art',
    },
    {
        label: 'M.Dance',
    },
    {
        label: 'M.PA',
    },
    {
        label: 'PG Diploma',
    },
    {
        label: 'Other',
    },
]

export const subject_areas = [
    {
        label: 'Music',
    },
    {
        label: 'Drama',
    },
    {
        label: 'Dance',
    },
    {
        label: 'Visual Arts',
    },
    {
        label: 'Other',
    },
]

export const working_experience_service_type = [
    {
        label: 'Permanent',
    },
    {
        label: 'Contract',
    },
    {
        label: 'Part Time',
    },
]
export const employeement_type = [
    {
        label: 'Government',
    },
    {
        label: 'Department',
    },
    {
        label: 'Corporation',
    },
    {
        label: 'Statutory board',
    },
    {
        label: 'Private',
    },
]

export const publication_type = [
    {
        label: 'Journals (Index)',
    },
    {
        label: 'Journals',
    },
    {
        label: 'Books (Rec. Publisher)',
    },
    {
        label: 'Books (Other Publisher)',
    },
    {
        label: 'Abstract',
    },
    {
        label: 'Magazine',
    },
    {
        label: 'Other',
    },
]

export const awards_and_scholarship_types = [
    {
        label: 'National',
    },
    {
        label: 'International',
    },
    {
        label: 'University Level',
    },
]
export const studentDocumentStatus = [
    {
        value: 'Not Upload',
        label: 'Pending Upload',
    },
    {
        label: 'Approved',
        value: 'Approved',
    },
    {
        label: 'Rejected',
        value: 'Rejected',
    },
]
export const meetingTypes = [
    {
        label: 'All',
    },
    {
        label: 'Senate Meeting',
    },
    {
        label: 'Council Meeting',
    },
    {
        label: 'Faculty Appeal Board',
    },
]
export const meetingStatusTypes = [
    {
        label: 'All',
    },
    {
        label: 'Pending',
    },
    {
        label: 'Complete',
    },
    {
        label: 'Postpone',
    },
]
export const meetingLocations = [
    {
        label: 'All',
    },
    {
        label: 'Physical',
    },
    {
        label: 'Online',
    },
    {
        label: 'Both',
    },
]
export const attendanceStatuses = [
    {
        label: 'Attended',
    },
    {
        label: 'Excused',
    },
    {
        label: 'Absent',
    },
    {
        label: 'Coverup',
    },
]
export const documentManagementCouncilMeetingUserRoles1 = [
    'Exam AR',
    'SSW AR',
    'Faculty AR',
    'Physical Unit AR',
    'SA',
    'Registrar',
]
export const documentManagementCouncilMeetingUserRoles2 = ['Committee Member']
export const documentManagementCouncilMeetingUserRoles3 = ['Faculty MA']
export const documentManagementAgendaUserRoles1 = [
    'Exam AR',
    'SSW AR',
    'Faculty AR',
    'Physical Unit AR',
]
export const documentManagementAgendaUserRoles2 = ['VC']
export const documentManagementAllMeetingUserRoles1 = [
    'Exam AR',
    'SSW AR',
    'Faculty AR',
    'Physical Unit AR',
]
export const documentManagementMemoRecommendationUserRoles1 = [
    'Exam AR',
    'SSW AR',
    'Faculty AR',
    'Physical Unit AR',
    'Exam MA',
    'SSW MA',
    'Faculty MA',
    'Physical Unit MA',
]
export const documentManagementAttendanceUserRoles1 = ['RO', 'SA']
export const documentManagementAttendanceUserRoles2 = [
    'Exam AR',
    'SSW AR',
    'Faculty AR',
    'Physical Unit AR',
    'VC',
]
export const documentManagementCreateComiteeUserRoles1 = [
    'Exam AR',
    'SSW AR',
    'Faculty AR',
    'Physical Unit AR',
]
export const courceManagementTemporaryLecturesRecruitUserRoles1 = ['HOD']
export const courceManagementTemporaryLecturesRecruitUserRoles2 = ['Dean']
export const courceManagementTemporaryLecturesRecruitUserRoles3 = ['VC']
export const courceManagementTemporaryLecturesRecruitUserRoles4 = [
    'Faculty MA',
    'Faculty AR',
]
export const courceManagementSubjectRegistrationUserRoles1 = ['Student', 'SA']
export const courceManagementSubjectRegistrationUserRoles2 = ['HOD']
export const courceManagementSubjectRegistrationUserRoles3 = ['Dean']
export const courceManagementSubjectRegistrationUserRoles4 = [
    'Faculty MA',
    'Faculty AR',
]
export const assignmentList = [
    {
        label: 'Minute Confirmation',
    },
    {
        label: 'Attendance Approved By',
    },
    {
        label: 'Meeting Book Confirmation',
    },
]
export const meetingTypes2 = [
    {
        label: 'Special',
    },
    {
        label: 'Regular',
    },
]
export const councilDecisions = [
    {
        label: 'Pending',
    },
    {
        label: 'Approved',
    },
    {
        label: 'Rejected',
    },
    {
        label: 'Other',
    },
]
export const councilDecisions2 = [
    {
        label: 'Pending',
    },
    {
        label: 'Approved',
    },
    {
        label: 'Rejected',
    },
]
export const courceManagementDepartmentSelectionStudentInformationUserRoles1 = [
    'Student',
]
export const courceManagementDepartmentSelectionStudentInformationUserRoles2 = [
    'Faculty MA',
    'Faculty AR',
    'HOD',
    'SA',
]
export const courceManagementDepartmentSelectionMarksUserRoles1 = ['Department']
export const courceManagementDepartmentSelectionMarksUserRoles2 = ['Student']
export const courseMgtLevels = [
    {
        label: 'Level 1',
    },
    {
        label: 'Level 2',
    },
    {
        label: 'Level 3',
    },
    {
        label: 'Level 4',
    },
]
export const subjectTypes = [
    {
        label: 'Theory',
    },
    {
        label: 'Practical',
    },
    {
        label: 'Theory and Practical',
    },
]
export const subjectCredits = [
    {
        label: '1',
    },
    {
        label: '2',
    },
    {
        label: '3',
    },
    {
        label: '4',
    },
    {
        label: '5',
    },
    {
        label: '6',
    },
    {
        label: '7',
    },
    {
        label: '8',
    },
    {
        label: '9',
    },
    {
        label: '10',
    },
    {
        label: '11',
    },
    {
        label: '12',
    },
    {
        label: '13',
    },
    {
        label: '14',
    },
]
export const resourceTypes = [
    {
        label: 'Lecture Hall',
    },
    {
        label: 'Theater',
    },
    {
        label: 'Gallery',
    },
    {
        label: 'Meeting Room',
    },
    {
        label: 'Computer Room',
    },
    {
        label: 'Studio',
    },
]
export const priceType = [
    {
        label: 'Per Day',
    },
    {
        label: 'Per Hour',
    },
]
export const timePeriod = [
    {
        label: 'Beginning of 1st semester',
    },
    {
        label: 'During 1st semester',
    },
    {
        label: 'Middle of 1st semester',
    },
    {
        label: 'End of 1st semester',
    },
    {
        label: 'Beginning of 2nd semester',
    },
    {
        label: 'During 2nd semester',
    },
    {
        label: 'Middle of 2nd semester',
    },
    {
        label: 'End of 2nd semester',
    },
]
export const aligningLearningOutcomes = [
    {
        label: 'Soft Skills',
    },
    {
        label: 'Soft Skills2',
    },
]

export const CadreTypes = ['Combined Cadre', 'Single Cadre']

export const CadreCategory = [
    'Academic Cadres',
    'Academic Supportive Cadres',
    'Library Professional Cadres',
    'Administrative Staff & Financial Cadres',
    'Medical Officers',
    'Academic Supportive Cadres',
]

export const JobStatus = ['Not Selected', 'Selected', 'Reserved', 'Demoted']

export const ProbationPeriod = ['3 Months', '4 Months', '6 Months', '12 Months']

export const auditStatus = [
    { label: 'All', value: 'all' },
    { label: 'New', value: 'new' },
    { label: 'Awaiting Information', value: 'awaiting_info' },
    { label: 'Awaiting Verification', value: 'awaiting_veri' },
    { label: 'Awaiting Approval', value: 'awaiting_appro' },
    { label: 'Awaiting Corrections', value: 'awaiting_correct' },
    { label: 'Completed', value: 'complete' },
]

export const auditType = [
    { label: 'All', value: 'all' },
    { label: 'Audit Query', value: 'query' },
    { label: 'Audit Report', value: 'report' },
]

export const agenda_decision_status = [
    { label: 'Pending' },
    { label: 'Approved' },
    { label: 'Rejected' },
]
export const season_type = [
    { label: 'train', value: 'train' },
    { label: 'bus', value: 'bus' },
]
export const year = [
    { label: '2019', value: '2019' },
    { label: '2020', value: '2020' },
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
]
export const numbers = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
]

export const distance = [
    { label: '59 or less', value: '0' },
    { label: '60 - 75', value: '5' },
    { label: '76 - 100', value: '10' },
    { label: '101 - 125', value: '15' },
    { label: '126 - 150', value: '20' },
    { label: '151 - 175', value: '25' },
    { label: '176 - 200', value: '30' },
    { label: '201 - 225', value: '35' },
    { label: '226 - 250', value: '40' },
    { label: '251 - 275', value: '45' },
    { label: '276 - 300', value: '50' },
]

export const status = [
    { label: 'Checked', value: 'checked' },
    { label: 'Eligible', value: 'eligible' },
    { label: 'Eligible List', value: 'eligible_list' },
]

export const physical_diability = [
    { label: 'Have physical disability', value: 'yes' },
    { label: 'Does not have physical disability', value: 'no' },
]

export const intake = [
    { label: 'Intake No 01', value: 'intale01' },
    { label: 'Intake No 02', value: 'intale02' },
]

export const employee_type = [
    { label: 'All', value: '' },
    { label: 'Temporary', value: 'Temporary' },
    { label: 'Permanent', value: 'Permanent' },
    { label: 'Visiting', value: 'Visiting' },
]

export const employee_titles = [
    { label: 'Mr.', value: 'Mr.' },
    { label: 'Mrs.', value: 'Mrs.' },
    { label: 'Miss.', value: 'Miss.' },
    { label: 'Dr (Mr.)', value: 'Dr (Mr.)' },
    { label: 'Dr (Ms.)', value: 'Dr (Ms.)' },
    { label: 'Dr (Mrs.)', value: 'Dr (Mrs.)' },
    { label: 'Professor (Mr.)', value: 'Professor (Mr.)' },
    { label: 'Professor (Ms.)', value: 'Professor (Ms.)' },
    { label: 'Professor (Mrs.)', value: 'Professor (Mrs.)' },
    { label: 'Senior Professor (Mr.)', value: 'Senior Professor (Mr.)' },
    { label: 'Senior Professor (Ms.)', value: 'Senior Professor (Ms.)' },
    { label: 'Senior Professor (Mrs.)', value: 'Senior Professor (Mrs.)' },
    { label: 'Associate Professor (Mr.)', value: 'Associate Professor (Mr.)' },
    { label: 'Associate Professor (Ms.)', value: 'Associate Professor (Ms.)' },
    {
        label: 'Associate Professor (Mrs.)',
        value: 'Associate Professor (Mrs.)',
    },
    { label: 'Ven.', value: 'Ven.' },
    { label: 'Rev.', value: 'Rev.' },
]
//employee profile side nav bar buttons
export const employeeShortcutList = [
    {
        title: 'Profile Info',
        icon: 'person_outline',
    },
    {
        title: 'My Work History',
        icon: 'star_outline_sharp',
    },

    {
        title: 'My Education Qualifications',
        icon: 'school',
    },
    {
        title: 'Leaves',
        icon: 'touch_app_icon',
    },

    {
        title: 'Increments',
        icon: 'dashboard',
    },
    {
        title: 'Promotions',
        icon: 'stars',
    },
    {
        title: 'Terminations',
        icon: 'cancel_schedule_sendIcon',
    },

    {
        title: 'Resignations',
        icon: 'group',
    },
    {
        title: 'Appoinments',
        icon: 'credit_card',
    },

    {
        title: 'Training & Workshops',
        icon: 'brightness_7',
    },
]
//employee profile side nav bar buttons
export const viewEmployeeShortcutList = [
    {
        title: 'Personal Informations',
        icon: 'person_outline',
    },
    {
        title: 'Work History',
        icon: 'star_outline_sharp',
    },

    {
        title: 'Education Qualifications',
        icon: 'school',
    },
    {
        title: 'Leaves',
        icon: 'touch_app_icon',
    },
    {
        title: 'Increments',
        icon: 'dashboard',
    },
    // {
    //     title: 'Promotion',
    //     icon: 'star_outline_sharp',
    // },

    // {
    //     title: 'Work Norms',
    //     icon: 'exit_to_app',
    // },
    // {
    //     title: 'Appoinments',
    //     icon: 'credit_card',
    // },

    // {
    //     title: 'Designation',
    //     icon: 'account_balance',
    // },
    // {
    //     title: 'Benifits',
    //     icon: 'group',
    // },
    // {
    //     title: 'Training & Workshops',
    //     icon: 'brightness_7',
    // },

    // {
    //     title: 'Terminations',
    //     icon: 'cancel_schedule_sendIcon',
    // },

    // {
    //     title: 'Resignation',
    //     icon: 'dashboard',
    // },
]

export const AcademicEstabilishmentRoles = ['AE AR', 'AE MA']
export const NonAcademicEstabilishmentRoles = ['NAE AR', 'NAE MA']
export const StudentServiceRoles = ['SSW AR', 'SSW MA']
export const ExecutiveAcademicRoles = ['HOD', 'DEAN', 'SA']

export const patientSelection = [
    { label: 'In-patient', value: 'inpatient' },
    { label: 'Out-patient', value: 'Outpatient' },
]

export const MedicalItemFrequencies = [
    { label: 'bd', value: 2 },
    { label: 'mane', value: 1 },
    { label: 'nocte', value: 1 },
    { label: 'Daily', value: 1 },
    { label: 'stat', value: 1 },
    { label: '6 hrs', value: 4 },
    { label: '8 hrs', value: 3 },
    { label: 'EOD', value: 0.5 },
]

export const MedicalItemDose = [
    { label: '1/2', value: 0.5 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    // { label: '5 ml', value: 5 },
    // { label: '10 ml', value: 10 },
]

export const memo_decision_status = [
    { label: 'Approved as it is' },
    { label: 'Approved with the double reading' },
    { label: 'Approved subject to the comments' },
    { label: 'Postpone' },
    { label: 'Not Approved' },
    { label: 'Withdraw' },
    { label: 'Refer to the Next Meeting' },
    { label: 'Pending' },
]

export const genre = [
    { label: 'entertainment', value: 'entertainment' },
    { label: 'tv-new', value: 'tv_new' },
    { label: 'educational', value: 'educational' },
]
export const months = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'Novenmber', value: 11 },
    { label: 'December', value: 12 },
]

export const program = [
    { label: 'program1', value: 'program1' },
    { label: 'program2', value: 'program2' },
    { label: 'program3', value: 'program3' },
]

export const program_repeat = [
    { label: 'Repeats', value: true },
    { label: 'Does not repeat', value: false },
]

export const repeat = [
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
    { label: 'Year', value: 'year' },
]

export const matxIconsNameList = [
    'timelapse',
    'games',
    'grain',
    'casino',
    'camera',
    'filter_vintage',
    'album',
    'center_focus_strong',
    'casino',
    'camera',
    'album',
    'ac_unit',
    'brightness_7',
    'donut_small',
    'filter_tilt_shift',
    'healing',
    'pie_chart',
    'radio_button_checked',
    'wb_sunny',
]
export const entitledLeavesTypes = [
    { label: 'Per Calender Year', value: 'per_acedmic_year' },
    { label: 'Per Academic Year', value: 'per_year' },
    { label: 'Per Service Period', value: 'per_service_period' },
]

export const employeeFilters = [
    { label: 'Employee ID', value: 'employee_id' },
    { label: 'Employee Name', value: 'name_with_initials' },
    { label: 'Last Name', value: 'last_name' },
    { label: 'Last Update', value: 'updatedAt' },
]

export const filterOrders = [
    { label: 'Ascending Order', value: 'ASC' },
    { label: 'Descending Order', value: 'DESC' },
]

export const semesters_list = [{ name: 'Semester 1' }, { name: 'Semester 2' }]

export const syllabus_semesters = [
    { name: '1st Semester' },
    // { name: '1st Semester - common' },
    { name: '2nd Semester' },
    // { name: '2nd Semester - common' },
    { name: 'Both Semesters' },
]

export const subject_types = [
    { name: 'Core' },
    { name: 'Elective' },
    { name: 'Auxiliary' },
    { name: 'Non Credit Compulsory' },
]

export const lecturer_type = [
    { name: 'Temporary Lecturer', value: 'Temporary Lecturer' },
    { name: 'Permanent Lecturer', value: 'Permanent Lecturer' },
    { name: 'Visiting Lecturer', value: 'Visiting Lecturer' },
    { name: 'Accompanies', value: 'Accompanies' },
]

export const vehicle_type = [
    { label: 'Van', value: 'van' },
    { label: 'Car', value: 'car' },
    { label: 'Bus', value: 'bus' },
]

export const service_type = [
    { label: 'Full', value: 'full' },
    { label: 'Normal', value: 'normal' },
]

export const service_condition = [
    { label: '6 months', value: '6 months' },
    { label: '5000km', value: '5000km' },
]

export const road_type = [
    { label: 'Expressway', value: 'Expressway' },
    { label: 'Main road', value: 'Main road' },
    { label: 'Secondary road', value: 'Secondary road' },
    { label: 'Jeep/Car track', value: 'Jeep/Car track' },
]

export const weather_condition = [
    { label: 'Rainy', value: 'Rainy' },
    { label: 'Clear', value: 'Clear' },
    { label: 'Cloudy', value: 'Cloudy' },
]

export const accident_type = [
    { label: 'minor', value: 'minor' },
    { label: 'major', value: 'major' },
]

export const insurance_type = [
    { label: 'Third Party', value: 'third_party' },
    { label: 'Full Insurance', value: 'full_insurance' },
]

export const testing_center = [
    { label: 'Laugh Eco Sri', value: 'laugh_eco_sri' },
    { label: 'Klenco', value: 'klenco' },
]

export const overall_status = [
    { label: 'Pass', value: 'pass' },
    { label: 'Fail', value: 'fail' },
]

export const fuel_type = [
    { label: 'Petrol', value: 'petrol' },
    { label: 'Diesel', value: 'diesel' },
]

export const ownership = [
    { label: 'Own', value: 'own' },
    { label: 'Hired', value: 'hired' },
]

export const station_name = [
    { label: 'Lanka IOC', value: 'lanka_ioc' },
    { label: 'Ceypetco', value: 'ceypetco' },
    { label: 'Synopec', value: 'synopec' },
]

export const employee_type_ge = [
    { label: 'Permanent', value: 'permanent' },
    { label: 'Part Time', value: 'part_time' },
    { label: 'Contract', value: 'contract' },
    { label: 'Own Drivers', value: 'own_drivers' },
]

export const job_title = [
    { label: 'Driver I', value: 'driver_i' },
    { label: 'Driver II', value: 'driver_ii' },
    { label: 'Helper', value: 'helper' },
]

export const journey_type = [
    { label: 'Normal Journey', value: 'normal' },
    { label: 'Special Journey', value: 'special' },
]

export const reservation_status = [
    { label: 'Request Submitted', value: 'Requested' },
    { label: 'Recommended by HOD', value: 'recommended_by_hod' },
    {
        label: 'Waiting for Recommendation',
        value: 'Waiting_for_recommendation',
    },
    { label: 'Recommended by TMA', value: 'recommended_by_tma' },
    {
        label: 'Recommended by TMA-Special',
        value: 'special_recommended_by_tma',
    },
    { label: 'Recommended by Registrar', value: 'recommended_by_registrar' },
    { label: 'Approved by VC', value: 'approved_by_vc' },
    { label: 'Reserved Vehicle', value: 'reserved_vehicle' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Cancel', value: 'cancel' },
    { label: 'Requesting Cancelation', value: 'request_cancelation' },
    { label: 'Accepted Cancelation', value: 'accepted_cancelation' },
    { label: 'Rejected Cancelation', value: 'rejected_cancelation' },
]

export const warranty = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
]

export const hrRoles = [
    'Exam AR',
    'SSW AR',
    'Faculty AR',
    'Physical Unit AR',
    'Exam MA',
    'SSW MA',
    'Faculty MA',
    'Physical Unit MA',
]

export const registrarRoles = [
    'Registrar',
    'Registrar Office AR',
    'Registrar Office MA',
]

export const incrementRequestStatuses = [
    { label: 'Suspended', value: 'Suspended' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Stopped', value: 'Stopped' },
    { label: 'Defermented', value: 'Defermented' },
    { label: 'Reduced', value: 'Reduced' },
]

export const incrementRequestStatus = [
    { label: 'Suspended', value: 'Suspended' },
    { label: 'Not Eligible Yet', value: 'Not Eligible Yet' },
    { label: 'Increment Eligible', value: 'Increment Eligible' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Stopped', value: 'Stopped' },
    { label: 'Defermented', value: 'Defermented' },
    { label: 'Reduced', value: 'Reduced' },
]

export const open_to_public = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
]

export const ownership_type = [
    { label: 'University', value: 'university' },
    { label: 'Faculty of Dance and Drama', value: 'dance_and_drama' },
    { label: 'Faculty of Music', value: 'music' },
    { label: 'Faculty of Visual Arts', value: 'visual_arts' },
    { label: 'Administration', value: 'administration' },
]

export const booking_status = [
    { label: 'Tentative Booking', value: 'tentative_booking' },
    { label: 'Pending for Payment', value: 'pending_for_payment' },
    { label: 'Confirmed Booking', value: 'confirmed_booking' },
    { label: 'Event Completed', value: 'event_completed' },
    { label: 'Requesting Cancellation', value: 'requested_cancellation' },
    { label: 'Accepted Cancellation', value: 'accepted_cancellation' },
    { label: 'Rejected', value: 'rejected' },
]

export const booking_status_in = [
    { label: 'Tentative Booking', value: 'tentative_booking' },
    { label: 'Confirmed Booking', value: 'confirmed_booking' },
    { label: 'Event Completed', value: 'event_completed' },
    { label: 'Requesting Cancellation', value: 'requested_cancellation' },
    { label: 'Accepted Cancellation', value: 'accepted_cancellation' },
    { label: 'Rejected', value: 'rejected' },
]

// for booking_status = Tentative Booking - both uni & outsiders
export const payment_status_for_tentative_booking = [
    { label: 'Invoice Requested', value: 'invoice_requested' },
]

// for booking_status = Pending for Payment - uni only
export const uni_payment_status_for_pending_payment = [
    // { label: 'Invoice Sent', value: 'invoice_sent' },
    { label: 'Invoice Issued', value: 'invoice_issued' },
]

// for booking_status = Pending for Payment - outsiders only
export const payment_status_for_pending_payment = [
    // { label: 'Invoice Received', value: 'invoice_received' },
    { label: 'Invoice Issued', value: 'invoice_issued' },
]

// for booking_status = Confirmed Booking - both uni & outsiders
export const payment_status_for_confirmed_booking = [
    { label: 'Paid', value: 'paid' },
]

export const payment_status_for_pending_event = [
    { label: 'Paid', value: 'paid' },
]

// for booking_status = Event Completed - uni only
export const uni_payment_status_for_event_completed = [
    { label: 'Paid', value: 'paid' },
    // { label: 'Sent Post Report', value: 'sent_post_report' },
    { label: 'Issued Post Report', value: 'Issued_post_report' },
    { label: 'Payments Closed', value: 'payments_closed' },
]

// for booking_status = Event Completed - outsiders only
export const payment_status_for_event_completed = [
    { label: 'Paid', value: 'paid' },
    // { label: 'Received Post Report', value: 'received_post_report' },
    { label: 'Issued Post Report', value: 'Issued_post_report' },
    { label: 'Payments Closed', value: 'payments_closed' },
]

// for booking_status = Requesting Cancellation - uni only
export const uni_payment_status_for_cancellation_request = [
    { label: 'Invoice Requested', value: 'invoice_requested' },
    // { label: 'Invoice Sent', value: 'invoice_sent' },
    { label: 'Invoice Issued', value: 'invoice_issued' },
    { label: 'Paid', value: 'paid' },
]

// for booking_status = Requesting Cancellation - outsiders only
export const payment_status_for_cancellation_request = [
    { label: 'Invoice Requested', value: 'invoice_requested' },
    // { label: 'Invoice Received', value: 'invoice_received' },
    { label: 'Invoice Issued', value: 'invoice_issued' },
    { label: 'Paid', value: 'paid' },
]

// for booking_status = Accepted Cancellation - uni only
export const uni_payment_status_for_accepted_cancellation_request = [
    { label: 'Invoice Requested', value: 'invoice_requested' },
    // { label: 'Invoice Sent', value: 'invoice_sent' },
    { label: 'Invoice Issued', value: 'invoice_issued' },
    { label: 'Payments Closed', value: 'payments_closed' },
]

// for booking_status = Accepted Cancellation - outsiders only
export const payment_status_for_accepted_cancellation_request = [
    { label: 'Invoice Requested', value: 'invoice_requested' },
    // { label: 'Invoice Received', value: 'invoice_received' },
    { label: 'Invoice Issued', value: 'invoice_issued' },
    { label: 'Payments Closed', value: 'payments_closed' },
]

export const salutation = [
    { label: 'Mr.', value: 'Mr.' },
    { label: 'Mrs.', value: 'Mrs.' },
    { label: 'Ms.', value: 'Ms.' },
    { label: 'Rev.', value: 'Rev.' },
    { label: 'Thero.', value: 'Thero.' },
    { label: 'Others.', value: 'Others.' },
]

export const associated_as = [
    { label: 'Employee', value: 'Employee' },
    { label: 'Student', value: 'Student' },
    { label: 'Visiting Lecturer', value: 'Visiting Lecturer' },
]

export const courseType = [
    { index: 0, label: 'Short Course', value: 'short course' },
    { index: 1, label: 'Tought Master', value: 'tought master' },
    { index: 2, label: 'MPhil', value: 'mphil' },
    { index: 2, label: 'Phd', value: 'phd' },
    { index: 2, label: 'Masters', value: 'master' },
]

export const mahapola_status = [
    { label: 'Active', value: 'active' },
    { label: 'Suspended', value: 'suspended' },
    { label: 'Unregistered', value: 'unregistered' },
    { label: 'Deactivated', value: 'deactivated' },
]

export const mahapola_attendance_status = [
    { label: '80% fulfilled', value: '80% fulfilled' },
    { label: '80% not fulfilled', value: '80% not fulfilled' },
    { label: 'Medical Applied', value: 'Medical Applied' },
    { label: 'Suspended', value: 'Suspended' },
    { label: 'Not Applicable Month', value: 'Not Applicable Month' },
]

export const student_level = [
    { label: 'Ordinary', value: 'ordinary' },
    { label: 'Merits', value: 'merit' },
]

export const common_yes_no = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
]

export const monthly_installments_status = [
    { label: 'Created New Installment', value: 'new' },
    { label: 'AR Signed', value: 'ar' },
    { label: 'Bursar Signed', value: 'bursar' },
    { label: 'Registrar Signed', value: 'registrar' },
    { label: 'VC Signed', value: 'vc' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Sent & Closed', value: 'sent&closed' },
]

export const annexures = [
    { label: 'Annexure I', value: 'annx_I' },
    { label: 'Annexure II', value: 'annx_II' },
    { label: 'Annexure III', value: 'annx_III' },
    { label: 'Annexure IV', value: 'annx_IV' },
    { label: 'Annexure V', value: 'annx_V' },
    { label: 'Arrears', value: 'arrears' },
]

export const all_recommend_status = [
    { label: 'Pending', value: 'pending' },
    { label: 'Signed', value: 'signed' },
]

export const contribution_types = [
    { label: 'UGC Contribution', value: 'ugc' },
    { label: 'MTF Contribution', value: 'mtf' },
]
export const paymentTypes = [
    // 'Online Payment Gateway of the University',
    'Online Bank Transfer',
    'Bank Deposit',
    // 'Bank Deposit (Not recommended method of payment)',
]

// Aptitude Test Constants
export const APT_YT_Registration_Guide = 'https://www.youtube.com/watch?v=B2UNGvS6PuE'

export const offerType = [
    { index: 0, label: 'Full Time', value: 'Full Time' },
    { index: 1, label: 'Part Time', value: 'Part Time' },
    { index: 2, label: 'Both', value: 'Both' },
]
export const studentType = [
    { index: 1, label: 'Local', value: 'Local' },
    { index: 0, label: 'Foreign', value: 'Foreign' },
]

export const fgs_seminar1_results = [
    { label: 'Pass', value: 'Pass' },
    {
        label: 'Pass with Minor Corrections',
        value: 'Pass with Minor Corrections',
    },
    {
        label: 'Pass with Major Corrections',
        value: 'Pass with Major Corrections',
    },
    { label: 'Fail', value: 'Fail' },
]

export const associate_as = [
    { label: 'Faculty Member', value: 'Faculty Member' },
    { label: 'Student', value: 'Student' },
    { label: 'Visiting Lecturer', value: 'Visiting Lecturer' },
]

export const voucher_status = [
    { label: 'Prepared Voucher', value: 'Prepared Voucher' },
    { label: 'Confirmed Voucher', value: 'Confirmed Voucher' },
    { label: 'Recommended Voucher', value: 'Recommended Voucher' },
    { label: 'Approved Voucher', value: 'Approved Voucher' },
    { label: 'Checked by Finance MA', value: 'Checked by finance MA' },
    { label: 'Checked by Bookkeeper', value: 'Checked by Bookkeeper' },
    { label: 'Certified Voucher', value: 'Certified Voucher' },
    {
        label: 'Resubmission with corrections',
        value: 'Resubmission with corrections',
    },
    { label: 'Rejected', value: 'Rejected' },
    {
        label: 'Voucher with Attachment Issues',
        value: 'Voucher with Attachment Issues',
    },
    { label: 'Received Payment', value: 'Received Payment' },
    { label: 'Settled Payment', value: 'Settled Payment' },
]
export const finance_voucher_status = [
    { label: 'Prepared Voucher', value: 'Prepared Voucher' },
    { label: 'Confirmed Voucher', value: 'Confirmed Voucher' },
    { label: 'Recommended Voucher', value: 'Recommended Voucher' },
    { label: 'Approved Voucher', value: 'Approved Voucher' },
    { label: 'Checked by Finance MA', value: 'Checked by finance MA' },
    { label: 'Checked by Bookkeeper', value: 'Checked by Bookkeeper' },
    { label: 'Certified Voucher', value: 'Certified Voucher' },
    {
        label: 'Resubmission with corrections',
        value: 'Resubmission with corrections',
    },
    { label: 'Rejected', value: 'Rejected' },
    {
        label: 'Voucher with Attachment Issues',
        value: 'Voucher with Attachment Issues',
    },
    { label: 'Paid', value: 'Paid' },
]
export const fgs_attendance = [
    { label: 'Present', value: 'Present' },
    { label: 'Absent', value: 'Absent' },
    { label: 'Excuse', value: 'Excuse' },
]

//scholarship types in welafare
export const scholarship_approved_year = [
    { label: '2019', value: '2019' },
    { label: '2020', value: '2020' },
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
]
export const lecture_cancel_reason = [
    { label: 'Cancel with rescheduling now', value: 'reschedule_now' },
    { label: 'Cancel with rescheduling later', value: 'reschedule_later' },
    { label: 'Cancel without rescheduling', value: 'cancel' },
]
export const attendance_status = [
    { label: 'Present', value: 'Present' },
    { label: 'Absent', value: 'Absent' },
]

//scholarship types in welafare
export const scholarship_approval_type = [
    { label: 'New Scholarship', value: 'New Scholarship' },
    { label: 'Amended Scholarship', value: 'Amended Scholarship' },
]

//sccholarship award types in welfare
export const scholarship_awards = [
    { label: 'Certificates', value: 'Certificates' },
    { label: 'Medals', value: 'Medals' },
    { label: 'Cheques', value: 'Cheques' },
]

//scholarship donar types in welfare
export const scholarship_donar = [
    { label: 'Sri Sucharita Business', value: 'Sri Sucharita Business' },
    { label: 'Other', value: 'Other' },
]

//Module codes for the scholarship selection criteria
export const module_code = [
    { label: 'ALCC23016', value: 'ALCC23016' },
    { label: 'BLCC23016', value: 'BLCC23016' },
    { label: 'CLCC23016', value: 'CLCC23016' },
    { label: 'DLCC23016', value: 'DLCC23016' },
]

//Selecton method
export const selection_method = [
    { label: 'Average Of Marks', value: 'Average Of Marks' },
    { label: 'Hignest Mark', value: 'Hignest Mark' },
]

//Considered Attempt
export const considered_attempt = [
    { label: 'Not Considered', value: 'Not Considered' },
    { label: '1st Attempt only', value: '1st Attempt only' },
    { label: 'Both 1 st & 2nd Attempt', value: 'Both 1 st & 2nd Attempt' },
]

export const module_type = [
    { label: 'Theory', value: 'Theory' },
    { label: 'Practical', value: 'Practical' },
    { label: 'Both Theroy & Practical', value: 'Both Theroy & Practical' },
]

export const type_of_award = [
    { label: 'Certificates', value: 'Certificates' },
    { label: 'Medals', value: 'Medals' },
    { label: 'Cheques', value: 'Cheques' },
]

//applicable periods are Only in 4th year & During Entire Course Period
export const applicable_period = [
    { label: 'During The Entire Course', value: 'During The Entire Course' },
    { label: 'Only In 4th Year', value: 'Only In 4th Year' },
]

//SCHOLARSHIP STATUS
export const scholarship_status = [
    { label: 'Active', value: 'Active' },
    { label: 'Deactive', value: 'Deactive' },
]

export const bursary_application_status_colors = {
    submitted: '#6c757d', // grey
    'checked by ma': '#0f90e8', // blue
    incomplete: '#ffc107', // yellow
    'pending rejection': '#fc0303', // red
    rejected: '#dc3545', // red (same as pending rejection)
    'recommended by ar': '#28a745', // green
    'recommended by registrar': '#28a745', // green (same as recommended by ar)
    'approved by vc': '#28a745', // green (same as recommended by ar)
    'pending recommendation': '#0ea5e9',
}

export const academic_year = [
    { label: '2018/2019', value: '2018/2019' },
    { label: '2019/2020', value: '2019/2020' },
    { label: '2020/2021', value: '2020/2021' },
    { label: '2021/2022', value: '2021/2022' },
    { label: '2022/2023', value: '2022/2023' },
    { label: '2023/2024', value: '2023/2024' },
]
export const UnionMeetingType = [
    // { label: 'All', value: 'all' },
    { label: 'Regular', value: 'Regular' },
    { label: 'Special', value: 'Special' },
]

export const unionPositions = [
    // { label: 'All', value: 'all' },
    { label: 'President', value: 'President', id: 5 },
    { label: 'Vice President', value: 'Vice_President' },
    { label: 'Secretary', value: 'Secretary', id: 4 },
    { label: 'Treasurer', value: 'Treasurer' },
]

export const unionMeetingStatus = [
    // { label: 'All', value: 'all' },
    { label: 'Active', value: 'Active' },
    { label: 'Deactive', value: 'Deactive' },
]

export const UnionMeetingMode = [
    // { label: 'All', value: 'all' },
    { label: 'Physical', value: 'Physical' },
    { label: 'Online', value: 'Online' },
    { label: 'Both', value: 'Both' },
]

export const vapaPressStatus = [
    // { label: 'All', value: 'all' },
    { label: 'Active', value: 'Active' },
    { label: 'Deactive', value: 'Deactive' },
]

export const pressBookType = [
    // { label: 'All', value: 'all' },
    { label: 'Creative', value: 'Creative' },
    { label: 'Academic', value: 'Academic' },
]

export const pressCategory = [
    // { label: 'All', value: 'all' },
    { label: 'Own', value: 'Own' },
    { label: 'Translation', value: 'Translation' },
    { label: 'Edition', value: 'Edition' },
    { label: 'Booklet', value: 'Booklet' },
]

export const print_type = [
    { label: 'Print by press', value: 'Print by press' },
    { label: 'Print by author', value: 'Print by author' },
]
//temp
export const temp = [
    { label: '2021/22', value: '2021/22' },
    { label: '2022/23', value: '2022/23' },
]
export const scholarship_type_status = [
    {
        label: 'Pending for Student Selection',
        value: 'Pending for Student Selection',
    },
    { label: 'Pending for AR Approval', value: 'Pending for AR Approval' },
    { label: 'Done', value: 'Done' },
    { label: 'Not Completed', value: 'Not Completed' },
]

export const offered_by = [
    { label: 'Sri Sucharitha Buisness', value: 'Sri Sucharitha Buisness' },
    { label: 'Other', value: 'Other' },
]

export const award_type = [
    { label: 'Certificates', value: 'Certificates' },
    { label: 'Medals', value: 'Medals' },
    { label: 'Cheques', value: 'Cheques' },
]

export const cheque_amount = [
    { label: 'No cheque', value: 'No cheque' },
    { label: '1000', value: '1000' },
    { label: '1500', value: '1500' },
    { label: '2000', value: '2000' },
]

export const study_years = [
    { label: '2024', value: '2024' },
    { label: '2023', value: '2023' },
    { label: '2022', value: '2022' },
    { label: '2021', value: '2021' },
    { label: '2020', value: '2020' },
    { label: '2019', value: '2019' },
]
export const degree_type = [
    { label: 'Not Considered', value: 'Not Considered' },
    { label: 'Musicology', value: 'Musicology' },
]
export const moduel_code = [
    { label: 'DLCC2001', value: 'DLCC2001' },
    { label: 'DLCC2013', value: 'DlCC2013' },
]

export const department_type = [
    { label: 'Not Considered', value: 'Not Considered' },
    { label: 'Musicology', value: 'Musicology' },
]

export const approval_type = [
    { label: 'New Scholarship', value: 'New Scholarship' },
    { label: 'Amended Scholarship', value: 'Amended Scholarship' },
]
export const categories = [
    {
        label: 'Scholarship at the End of Academic Year',
        value: 'Scholarship at the End of Academic Year',
        id: 1,
    },
    { label: 'Prize at Convocation', value: 'Prize at Convocation', id: 2 },
    { label: 'Others', value: 'Others', id: 4 },
]

export const faculty = [
    { label: 'All faculties', value: 'All faculties' },
    { label: 'Music only ', value: 'Music only' },
]

//scholarship types in welafare
export const scholarship_type = [
    {
        label: 'Scholarships at the End of Academic Year',
        value: 'Scholarships at the End of Academic Year',
    },
    { label: 'Prize at Convocation', value: 'Prize at Convocation' },
    { label: 'Other', value: 'Other' },
]

export const study_year = [
    { label: '1st Year', value: '1st Year' },
    { label: '2nd Year', value: '2nd Year' },
    { label: '3rd Year', value: '3rd Year' },
    { label: '4th Year', value: '4th Year' },
]

export const unionStatus = [
    // { label: 'All', value: 'all' },
    { label: 'Active', value: 'Active' },
    { label: 'Deactive', value: 'Deactive' },
]

export const scholarship_name = [
    { label: 'Certificates', value: 'Certificates' },
    { label: 'Medals', value: 'Medals' },
    { label: 'Cheques', value: 'Cheques' },
]

export const approved_year = [
    { label: '2017', value: '2017' },
    { label: '2018', value: '2018' },
    { label: '2019', value: '2019' },
    { label: '2020', value: '2020' },
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
]
export const bursary_application_statuses = [
    'Submitted',
    'Checked by MA',
    'Incomplte',
    'Recommended by AR',
    'Approved by VC',
    'Recommended by Registrar',
    'Pending Recommendataion',
    'Rejected',
    'pending Rejection',
]

export const timetableLevels = {
    'Level 1': '#5d37e6', // Light blue
    'Level 2': '#dd22a5', // Green
    'Level 3': '#FCC328', // Purple
    'Level 4': '#b88351', // Red
}

export const milestones = [
    { label: 'Type Setting', value: 'Type Setting' },
    { label: 'Layout Design', value: 'Layout Design' },
    { label: '1st Proof', value: '1st Proof' },
    { label: '2nd Proof', value: '2nd Proof' },
    {
        label: 'Cover & Entertaining Quotation',
        value: 'Cover & Entertaining Quotation',
    },
    { label: 'Received book by CD', value: 'Received book by CD' },
]

export const staffType = [
    { label: 'Internal', value: 'Internal' },
    { label: 'External', value: 'External' },
]

export const bookType = [
    { label: 'Creative', value: 'Creative' },
    { label: 'Academic', value: 'Academic' },
]

export const bookcategory = [
    { label: 'Author', value: 'Author' },
    { label: 'Translations', value: 'Translations' },
    { label: 'Booklets', value: 'Booklets' },
]

export const publishedAt = [
    { label: 'University Bookshop', value: 'University Bookshop' },
    { label: 'Website', value: 'Website' },
]

export const manuscriptSentAs = [
    { label: 'Soft Copy', value: 'Soft Copy' },
    { label: 'Hard Copy', value: 'Hard Copy' },
]

export const selectedReviewers = [
    { label: 'Selected Reviewers', value: 'Selected Reviewers' },
    // { label: 'Hard Copy', value: 'Hard Copy' },
]

export const GOOGLE_CALENDAR_API_KEY = 'AIzaSyCMj0sXr-VILlTU3p9oc81ETysN-yDr4As'

//vapa press

export const book_status = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Completed', value: 'Completed' },
]

export const book_type = [
    { label: 'Creative', value: 'Creative' },
    { label: 'Academic', value: 'Academic' },
]

export const agreement_status = [
    { label: 'Expired', value: 'expired' },
    { label: 'Active', value: 'Active' },
    { label: 'Cancel', value: 'cancel' },
]
