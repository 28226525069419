import { makeStyles } from '@material-ui/core/styles'

export const commonStyles = makeStyles(({ palette, ...theme }) => ({
    '@global': {
        '.circular-image-small': {
            height: '48px',
            width: '48px',
            borderRadius: '50%',
            overflow: 'hidden',
        },
        '.card': { transition: 'all 0.3s ease' },
        '.card:hover': { boxShadow: theme.shadows[12] },
        '.card-title': {
            fontSize: '1rem',
            textTransform: 'capitalize',
            fontWeight: '500',
        },
        '.card-subtitle': { fontSize: '0.875rem', color: 'var(--text-muted)' },
        '.theme-dark .card-subtitle': { color: 'rgba(255, 255, 255, 0.54)' },
        '.hide-on-mobile': { display: 'inherit' },
        '@media screen and (max-width: 767px)': {
            '.hide-on-mobile': { display: 'none !important' },
            '.show-on-mobile': { display: 'inherit !important' },
            '.invisible-on-pc': { visibility: 'visible' },
        },
        '@media screen and (min-width: 1200px)': {
            '.hide-on-pc': { display: 'none !important' },
        },
        '@media screen and (max-width: 1200px)': {
            '.show-on-pc': { display: 'none !important' },
        },
        '.VictoryContainer svg': { height: '100% !important' },
        '.box-shadow-none': { boxShadow: 'none !important' },
        '.circle-44': { height: '44px !important', width: '44px !important' },
        '.circle-32': {
            height: '32px !important',
            minHeight: '32px !important',
            width: '32px !important',
        },
        '.circle-32 .MuiFab-root': { minHeight: '32px !important' },
        '.circle-32 .MuiIcon-root': { fontSize: '13px !important' },
        '.show-on-mobile': { display: 'none !important' },
        '.invisible-on-pc': { visibility: 'hidden' },
        '.highlight-js pre': { whiteSpace: 'pre-line' },
        '.cursor-pointer': {
            cursor: 'pointer',
        },
        '.cursor-move': {
            cursor: 'move',
        },
        '.avatar': {
            height: '32px !important',
            width: '32px !important',
        },
        '.face-group .avatar:not(:first-child)': {
            marginLeft: '-0.875rem !important',
        },
        '.opacity-1': {
            opacity: 1,
        },
        '.MuiTable-root': {
            width: 'unset !important',
            minWidth: '100%',
            //width: '1500px !important'
            //maxWidth: '98vw',
            // display: 'block !important',
        },

        '.MuiTableBody-root': {
            // display: 'block !important',
        },
        '.MuiTableHead-root': {
            // display: 'block !important',
        },
        '.MuiTableCell-head span': {
            justifyContent: 'center'
        },
        '.MuiTableRow-footer': {
            display: 'grid !important',
        },
        '.MuiTableFooter-root': {
            display: 'block !important',
            width: '100%',
        },
        '.MuiTableCell-head .MuiButtonBase-root': {
            fontWeight: '500',
            margin: '0px',
            width: 'max-content'
        },
        '.MuiToolbar-gutters': {
            paddingLeft: '0px !important',
            paddingRight: '0px !important'
        },
        '.MuiTableCell-head': {
            fontSize: '14px !important'
        },
        /*
                '.MuiIconButton-root': {
                    padding: '7px !important'
                },
                 '.MuiSvgIcon-root': {
        
                    width: '20px',
                    height: '20px'
                },
         */
        '.grabbable': {
            cursor: 'move', /* fallback if grab cursor is unsupported */
            cursor: 'grab',
            borderColor: '#e3e3e3',
            borderRadius: '8px',
            borderStyle: 'double',
        },
        '.MuiTableCell-root': {
            wordBreak: 'keep-all !important',
            paddingLeft: '-1px !important',
            textAlign: 'center !important',
        },
        '::-webkit-scrollbar': {
            width: 10,
            height: 5,
        },

        '::-webkit-scrollbar-track': {
            background: '#d6d6d6'
        },

        '::-webkit-scrollbar-thumb': {
            background: '#bfbdbd',
            borderRadius: '10px'
        },

        '::-webkit-scrollbar-thumb:hover': {
            background: '#afadad',
            borderRadius: '10px'
        },

        '::-webkit-scrollbar-track-piece:end': {
            marginBottom: '10px'
        },

        '::-webkit-scrollbar-track-piece:start': {
            marginTop: '10px'
        },

        '.ps__rail-y': {
            backgroundColor: 'transparent !important'
        },

        '.ps__rail-y:start': {
            marginBottom: '10px'
        },

        '.ps__rail-y:start': {
            marginTop: '10px'
        },
        '.image_hover:hover': {
            cursor: 'pointer',
            backgroundColor: '#dfe1e2',
            borderRadius: '16px',
            paddingLeft: '10px !important',
            paddingRight: '10px !important'
        },
        '.image_hover': {

            paddingLeft: '10px !important',
            paddingRight: '10px !important'
        },
        '.print-source': {
            'display': 'none',
        },

        'body > .print-source': {
            'display': 'block'
        },

        '@media print': {
            '.print-source': {
                'display': 'block'
            }
        },
        '.hover_point:hover': {
            cursor: 'pointer',

        },
        '#cadre_table .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root': {
            border: '1px solid rgba(112, 112, 112, 1)',
            borderBottom: '1px solid rgba(112, 112, 112, 1)',
        },
        '#cadre_table .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head': {
            border: '1px solid rgba(112, 112, 112, 1)',
            borderBottom: '1px solid rgba(112, 112, 112, 1)',
        },
        '.tab_text_underline': {

            borderStyle: "solid",
            borderBottomWidth: "2px",
        },






    }
}))
