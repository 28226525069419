import React from 'react'
import { authRoles } from '../../auth/authRoles'


const AuditRoutes = [
    {
        path: '/audit_view/allaudits',
        component: React.lazy(() => import('./AllAuidts')),
        auth: authRoles.AuditView,
    },
    {
        path: '/audit_view/newaudit',
        component: React.lazy(() => import('./AddNewAudit')),
        auth: authRoles.AuditView,
    },
    {
        path: '/audit_view/viewaudit/:id',
        component: React.lazy(() => import('./AuditSingleView')),
        auth: authRoles.AuditView,
    },
    {
        path: '/audit_view/checklist',
        component: React.lazy(() => import('./SetUpCheckList')),
        auth: authRoles.AuditView,
    },
    {
        path: '/audit_view/editaudit/:id',
        component: React.lazy(() => import('./EditAudit')),
        auth: authRoles.AuditView,
    },
]

export default AuditRoutes
