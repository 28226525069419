import React, { Component, Fragment } from 'react'

import { withStyles } from '@material-ui/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import AddIcon from '@material-ui/icons/Add';
import LinkIcon from '@material-ui/icons/Link';
import {
    Card,
    Icon,
    IconButton,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    Tooltip,
    Fab,
    Switch,
    Typography,
    InputAdornment,
    CircularProgress,
    Divider,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
} from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import 'date-fns'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { themeColors } from 'app/components/MatxTheme/themeColors'
import {
    LoonsTable,
    DatePicker,
    FilePicker,
    Button,
    ExcelToTable,
    LoonsSnackbar,
    LoonsSwitch,
    MainContainer,
    LoonsCard,
    CardTitle,
    SubTitle,
    CustomGrid,
    LoonsDialogBox,
} from 'app/components/LoonsLabComponents'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Alert from '@material-ui/lab/Alert';
import history from 'history.js'
import clsx from 'clsx'
import useAuth from 'app/hooks/useAuth'
import Avatar from '@material-ui/core/Avatar'
import EyeOn from '@material-ui/icons/Visibility';
import EyeOff from '@material-ui/icons/VisibilityOff';
import AssignmentIcon from '@material-ui/icons/Assignment';

const styleSheet = (theme) => ({

    appbar: {
        backgroundColor: "#B50000"
    },

    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
})



class ApplicantSignUp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dialogBox: true,
            message: '',
            textType: 'password',
            loading: false,
            userInfo: {
                email: '',
                password: ''
            }


        }
    }

    SignUp() {

        this.setState({
            dialogBox: true,
        })
    }
    async dialogClose() {
        this.setState({
            dialogBox: false,
        })
    }


    async componentDidMount() {
        // Sign
        console.log(
            '%c Human Resources  -- Applicant SignUp Created By: Shammika ',
            'background: #222; color: #bada55'
        )
        console.log('%c Issue No: DEV-592 ', 'background: #222; color: #bada55')

        // Load All Cadres

    }

    render() {

        const { classes } = this.props;
        return (
            <Fragment >
                <AppBar position="static" className="bg-primary ">
                    <Toolbar>

                        <Typography variant="h6" className="pl-4" >
                            Visual and Performing Arts University
                        </Typography>

                    </Toolbar>
                </AppBar>

                <div className="bg-paper flex-column justify-start items-center  py-12 h-full-screen p-3 ">

                    <Alert severity="info"> If you are Registered at Present for Degree/ Diploma at  University of Visual Performing Arts Please Log in to the student portal and Continue the application process
                    </Alert>
                    <div className="w-full text-center mt-8">
                        <img
                            className="mb-3 w-100"
                            alt="Logo"
                            src="/assets/images/logo_vpa.png"
                        />
                    </div>

                    <Grid container spacing={2} justify="center" className="my-4">
                        <Grid item md={3} xs={12}>
                            <ValidatorForm
                                onSubmit={() =>
                                    this.SignUp()
                                }
                                className="w-full"
                            >
                                <TextValidator
                                    className="mb-6 w-full"
                                    variant="outlined"
                                    size="small"
                                    label="Username"
                                    // onChange={handleChange}
                                    //type="email"
                                    name="email"
                                    // value={userInfo.email}
                                    validators={['required' /* , 'isEmail'*/]}
                                    errorMessages={[
                                        'This field is required',
                                        'email is not valid',
                                    ]}
                                />
                                <TextValidator
                                    className="mb-3 w-full"
                                    label="Password"
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => {
                                        // handleChange(e)
                                        // console.log("pass", e.target.value)
                                    }
                                    }
                                    name="password"
                                    // type={textType}
                                    // value={userInfo.password}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size="small"
                                                    disableRipple={true}
                                                    type="button"
                                                    onClick={() => {
                                                        // textType == "password" ? setTextType("text") : setTextType("password")
                                                    }}
                                                >
                                                    {/* {textType == "password"
                                                ? <EyeOff />
                                                : <EyeOn />
                                            } */}

                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                      

                                {/* {this.state.message && <p className="text-error">{message}</p>} */}

                                <div className="w-full mb-3">
                                    <Button
                                        className="w-full"
                                        variant="contained"
                                        color="primary"
                                        // disabled={loading}
                                        type="submit"
                                    >
                                        Sign Up
                                    </Button>
                                    {this.state.loading && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </div>
             
                            </ValidatorForm>
                        </Grid>
                    </Grid>


                </div>
                {/* Dialog box */}
                <Dialog
                    maxWidth="xs"
                    fullWidth
                    open={this.state.dialogBox}
                    onClose={this.dialogClose.bind(this)}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        <div className="flex w-full justify-center items-center my-3">
                            <Avatar className={[classes.large, "bg-light-primary "]} >
                                <Icon className="text-primary">email</Icon>
                            </Avatar>

                        </div>
                        <div className="flex w-full justify-center items-center my-2">
                            <h4 className="text-primary font-medium">Verify Your Email Address</h4>

                        </div>

                        <Divider className="my-2" />


                    </DialogTitle>
                    <DialogContent>
                        <div className="flex w-full justify-center items-center mt--2">
                            <h5 className="text-muted font-medium text-center">Please check your email for a link to verify your email address. </h5>

                        </div>
                        <h5 className="text-black font-medium text-center my-3">Once you verified you'll be able to continue!</h5>
                        <h5 className="text-muted font-normal text-center text-12 mt-5  ">Didn't Recieve an email? <a className="text-primary font-medium" href='https://www.google.lk'>Resend</a> </h5>
                    </DialogContent>

                </Dialog>

            </Fragment >
        )
    }
}
export default withStyles(styleSheet)(ApplicantSignUp)