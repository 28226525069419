import React from 'react'
import { authRoles } from '../../../auth/authRoles'

const SubjectDepartmentRegistrationRoutes = [
    {
        path: '/course_management/department_registration',
        component: React.lazy(() => import('./DepartmentRegistrationInclide')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/student_department_preference',
        component: React.lazy(() => import('./StudentDepartmentPreference/StudentDepartmentPreference')),
        auth: authRoles.dashboard,
    },
]

export default SubjectDepartmentRegistrationRoutes