import React from 'react'
import { authRoles } from '../../auth/authRoles'

const CourseManagementRoutes = [
    {
        path: '/course_management/assesment/setup',
        component: React.lazy(() => import('./Assesment/Setup')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/learning_outcome_alignment/setup',
        component: React.lazy(() => import('./LearningOutcomeAlignment/Setup')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/course/setup/:id',
        component: React.lazy(() => import('./Course/Setup')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/course/subject_setup',
        component: React.lazy(() => import('./Course/Setup/BasicInformation')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/resource_allocation/add_new_resource',
        component: React.lazy(() =>
            import('./ResourceAllocation/AddNewResource')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/academic_calendar/create_academic_calendar',
        component: React.lazy(() =>
            import('./AcademicCalendar/CreateAcademicCalendar')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/academic_calendar/view_academic_calendar',
        component: React.lazy(() =>
            import('./AcademicCalendar/ViewAcademicCalendar')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/temporary_lectures/recruit',
        component: React.lazy(() => import('./TemporaryLectures/Recruit')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/medical/application',
        component: React.lazy(() => import('./Medical/Application')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/medical/student_view/:id',
        component: React.lazy(() => import('./Medical/StudentView')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/syllabus/create',
        component: React.lazy(() => import('./Syllabus/CreateSyllabus')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/all_syllabus',
        component: React.lazy(() => import('./Syllabus/AllSyllabus')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/work_norm/work_norm',
        component: React.lazy(() => import('./WorkNorm/WorkNormMain')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/work_norm/faculty_ma',
        component: React.lazy(() => import('./WorkNorm/WorkNormMain/FacultyMA')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/work_norm/faculty_hod',
        component: React.lazy(() => import('./WorkNorm/WorkNormMain/FacultyHOD')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/work_norm/faculty_dean',
        component: React.lazy(() => import('./WorkNorm/WorkNormMain/FacultyDean')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/work_norm/audit_view',
        component: React.lazy(() => import('./WorkNorm/WorkNormMain/Audit')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/work_norm/single_view/:id',
        component: React.lazy(() => import('./WorkNorm/WorkNormMain/SingleView')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/work_norm/single_view_others/:id',
        component: React.lazy(() => import('./WorkNorm/WorkNormMain/SingleViewOthers')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/work_norm/attendance/lecturer',
        component: React.lazy(() => import('./WorkNorm/AttendanceLecturer')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/work_norm/attendance/students',
        component: React.lazy(() => import('./WorkNorm/AttendanceStudents')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/subject_registration',
        component: React.lazy(() =>
            import('./SubjectRegistration/SubjectRegistration')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/visiting_lectureres/approval_process',
        component: React.lazy(() =>
            import('./VisitingLectureres/ApprovalProcess')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/visiting_lectureres/registration',
        component: React.lazy(() =>
            import('./VisitingLectureres/Registration')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/visiting_lectureres/view',
        component: React.lazy(() =>
            import('./VisitingLectureres/ViewVisitingLectureres')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/department_selection/student_information',
        component: React.lazy(() =>
            import('./DepartmentSelection/StudentInformation')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/department_selection/department_details',
        component: React.lazy(() =>
            import('./DepartmentSelection/DepartmentDetails')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/department_selection/marks',
        component: React.lazy(() => import('./DepartmentSelection/Marks')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/work_details',
        component: React.lazy(() => import('./WorkDetails/WorkDetails')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/setup_timetable',
        component: React.lazy(() => import('./TimeTable/SetupTimeTable')),
        auth: authRoles.dashboard,
    },
    {
        path: '/course_management/view_timetable/:id',
        component: React.lazy(() => import('./TimeTable/ViewTimeTable')),
        auth: authRoles.dashboard,
    },
]

export default CourseManagementRoutes
