import React from 'react'
import { authRoles } from '../../auth/authRoles'

const VehicleRegisterRoutes = [
    // view vehicle
    {
        path: '/vehicle/view',
        component: React.lazy(() => import('./View/VehicleView')),
        auth: authRoles.vehicleView,
    },
    {
        path: '/vehicle/view_basic/:id',
        component: React.lazy(() => import('./View/ViewBasic')),
        auth: authRoles.vehicleView,
    },
    {
        path: '/vehicle/view_own',
        component: React.lazy(() => import('./View/ViewVehicleOwn')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vehicle/view_hired',
        component: React.lazy(() => import('./View/ViewVehicleHired')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vehicle/view_license_insurance',
        component: React.lazy(() => import('./View/LicenceInsuranceDetails')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vehicle/view_driver_helper',
        component: React.lazy(() =>
            import('./View/VehicleDriverHelperDetails')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vehicle/view_vehicle_running',
        component: React.lazy(() => import('./View/ViewVehicleRunningDetails')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vehicle/details/:id',
        component: React.lazy(() =>
            import('./View/VehicleViewTabHandlingComponent')
        ),
        auth: authRoles.dataSetup,
    },

    // register vehicle
    {
        path: '/vehicle/register_vehicle',
        component: React.lazy(() => import('./VehicleRegisterTabHandeling')),
        auth: authRoles.vehicleView,
    },
    {
        path: '/vehicle/renew_licence',
        component: React.lazy(() =>
            import('./RenewLicenceEcoInsuranceTabHandeling')
        ),
        auth: authRoles.vehicleView,
    },
    {
        path: '/vehicle/fueling',
        component: React.lazy(() => import('./Fueling')),
        auth: authRoles.vehicleView,
    },

    //
    {
        path: '/vehicle/service',
        component: React.lazy(() =>
            import('./Service_Maintenance_Accident/ServiceTabHandling.jsx')
        ),
        auth: authRoles.vehicleView,
    },
    // {
    //     path: '/vehicle/service/completed',
    //     component: React.lazy(() => import('./Service_Maintenance_Accident/ServiceCompleted')),
    //     auth: authRoles.dataSetup,
    // },
    {
        path: '/vehicle/maintenance',
        component: React.lazy(() =>
            import('./Service_Maintenance_Accident/Maintenance')
        ),
        auth: authRoles.vehicleView,
    },
    {
        path: '/vehicle/maintenance_setup',
        component: React.lazy(() =>
            import('./Service_Maintenance_Accident/MaintenanceSetup')
        ),
        auth: authRoles.vehicleView,
    },
    {
        path: '/vehicle/accident',
        component: React.lazy(() =>
            import('./Service_Maintenance_Accident/Accident')
        ),
        auth: authRoles.vehicleView,
    },
    {
        path: '/vehicle/add_accident',
        component: React.lazy(() =>
            import('./Service_Maintenance_Accident/AddAccident')
        ),
        auth: authRoles.vehicleView,
    },

    // Reservation
    {
        path: '/vehicle/my_reservations',
        component: React.lazy(() => import('./Reservation/MyReservation')),
        auth: authRoles.vehicle,
    },

    // hod | registrar | vc
    {
        path: '/vehicle/hod/view_reservations',
        component: React.lazy(() => import('./Reservation/ViewReservations')),
        auth: authRoles.reservationHODApproval,
    },
    {
        path: '/vehicle/ra/view_reservations',
        component: React.lazy(() =>
            import('./Reservation/RegistrarViewReservations')
        ),
        auth: authRoles.reservationRegistrarApprove,
    },
    {
        path: '/vehicle/vc/view_reservations',
        component: React.lazy(() => import('./Reservation/VCViewReservations')),
        auth: authRoles.reservationVCApprove,
    },
    {
        path: '/vehicle/ra/view_reservations',
        component: React.lazy(() => import('./Reservation/ViewReservations')),
        auth: authRoles.reservationRegistrarApprove,
    },
    {
        path: '/vehicle/vc/view_reservations',
        component: React.lazy(() => import('./Reservation/ViewReservations')),
        auth: authRoles.reservationVCApprove,
    },

    // transport head
    {
        path: '/vehicle/th/view_reservations',
        component: React.lazy(() => import('./Reservation/THViewReservations')),
        auth: authRoles.reservationMAApproval,
    },

    // GA
    {
        path: '/vehicle/ga/view_reservations',
        component: React.lazy(() => import('./Reservation/GAViewReservations')),
        auth: authRoles.reservationARApproval,
    },

    {
        path: '/vehicle/test',
        component: React.lazy(() => import('./Reservation/TransportReserved')),
        auth: authRoles.dataSetup,
    },

    //Agreement
    {
        path: '/all_agreements',
        component: React.lazy(() => import('./Agreement/AllAgreements')),
        auth: authRoles.vehicle,
    },
    {
        path: '/view_agreement/:id',
        component: React.lazy(() => import('./Agreement/ViewAgreement')),
        auth: authRoles.vehicle,
    },
    {
        path: '/renew_agreement/:id',
        component: React.lazy(() => import('./Agreement/RenewAgreement')),
        auth: authRoles.vehicle,
    },
    {
        path: '/add_agreements',
        component: React.lazy(() => import('./Agreement/AddAgreements')),
        auth: authRoles.vehicle,
    },
    {
        path: '/setup_agreement_type',
        component: React.lazy(() => import('./Agreement/SetUpAgreementTypes')),
        auth: authRoles.vehicle,
    },

    {
        path: '/vehicle/find_vehicle_for_reservation',
        component: React.lazy(() => import('./Reservation/FindVehicleForReservation')),
        auth: authRoles.vehicle,
    },
]

export default VehicleRegisterRoutes
