import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {
    Card,
    Icon,
    IconButton,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    Tooltip,
    Fab,
    Switch,
    Typography,
    InputAdornment,
    CircularProgress,
    Divider,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import {
    Alert,
    Autocomplete,
    AlertTitle,
    createFilterOptions,
} from '@material-ui/lab'
import { themeColors } from 'app/components/MatxTheme/themeColors'
import {
    LoonsTable,
    DatePicker,
    FilePicker,
    ExcelToTable,
    LoonsSnackbar,
    LoonsSwitch,
    MainContainer,
    LoonsCard,
    CardTitle,
    SubTitle,
    Button,
    LoonsDialogBox,
    DocumentLoader,
} from 'app/components/LoonsLabComponents'

import { MatxLayoutSettings } from 'app/components/MatxLayout/settings'
import portalGuide from 'app/views/PublicPages/Portal_guide.pdf'
import MailIcon from '@material-ui/icons/Mail'
import SearchIcon from '@material-ui/icons/Search'
import moment from "moment";
import * as appConst from '../../../appconst'
import LoonsDiaLogBox from 'app/components/LoonsLabComponents/Dialogbox'
import EmailIcon from '@material-ui/icons/Email'
import AptitudeTestServices from 'app/services/AptitudeTestServices'
import ApplicationServices from 'app/services/ApplicationServices'
import BadgeSelected from 'app/components/MatxCustomizer/BadgeSelected'
import EyeOn from '@material-ui/icons/Visibility';
import EyeOff from '@material-ui/icons/VisibilityOff';
//import background from "/assets/images/background/application_background2.jpg";
//get active theme
var activeTheme = MatxLayoutSettings.activeTheme

const styleSheet = (theme) => ({
    resendText: {
        color: themeColors[activeTheme].palette.primary.main,
        '&:hover': {
            cursor: 'pointer',
            color: 'green',
        },
    },

    centerDiv: {
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: "center",
        // float: "left",
        alignItems: 'center',
    },
    LeftDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        // // float: "left",
        alignItems: 'start',
    },
})

class ApplicantVerifyAccount extends Component {
    constructor(props) {
        super(props)

        this.state = {
            registrationClosed: false,
            academic_year : null,
            payment_confirmation_checkbox: false,
            expand: false,
            msgDialog: false,
            confirmation_checkbox: false,
            loading: true,
            textType: 'password',
            textType2: 'password',
            show: false,
            //timer
            timer_running: false,
            breakRemainingSeconds: 60,
            time_minutes: 1,
            time_seconds: 0,

            index_search_progress: false,

            show_other_data: true,

            data: {
                full_name: 'Amasha Saduni Perera',
                mobile_no: '2.30',
                nic: '981234561V',

                results: [
                    {
                        subject: 'Dance',
                        result: 'B',
                    },
                    {
                        subject: 'Art',
                        result: 'A',
                    },
                    {
                        subject: 'Music',
                        result: 'B',
                    },
                ],
            },

            all_degree_Programs: [],

            formData: {
                email: '',
                password: null,
                confirmPassword: '',
                degree_Programs: [],
                full_name: '',
                nic: '',
                index_no: '',
                mobile_no: '',
                english: '',
                subject_1: '',
                subject_2: '',
                subject_3: '',
                common_general_test: '',
                result_subject_1: '',
                result_subject_2: '',
                result_subject_3: '',
            },

            formData2: {},

            degreeProgramsSubject: [],

            musicType: '',

            otherSubjects: [
                {
                    subject: '',
                    grade: '',
                    active: true,
                },
            ],

            //dialog box
            dialogBox: false,

            //snackbar
            snackbar: false,
            snackbar_severity: '',
            snackbar_message: '',
        }
    }
    setTextType(type) {
        this.setState({
            textType: type,
        })
    }
    setTextType2(type) {
        this.setState({
            textType2: type,
        })
    }
    handleInputChange = (e) => {
        var name = e.target.name
        var val = e.target.value

        var data = this.state.formData

        data[name] = val

        this.setState({
            formData: data,
        })
    }

    handleInputChange = (e) => {
        var name = e.target.name
        var val = e.target.value

        var data = this.state.formData

        data[name] = val

        this.setState({
            formData: data,
        })
    }

    formSubmit1 = () => { }

    formSubmit2 = async () => {
        let seleceted = []

        this.setState({
            index_search_progress: true,
        })

        var data = this.state.formData
        let otherSubjects = this.state.otherSubjects

        let degreeProgramsSubject = this.state.degreeProgramsSubject

        data.degree_Programs.forEach((element) => {
            degreeProgramsSubject.forEach((e) => {
                if (element === e.degreeId) {
                    seleceted.push(e)
                }
            })
        })

        if (data.degree_Programs.length < 3) {
            seleceted = seleceted.concat(otherSubjects)
        }

        let filtered = seleceted.filter(function (el) {
            return el.subject !== ''
        })

        filtered.forEach((element, index) => {
            if (index < 3) {
                if (element.subject === 'DANCE') {
                    data[`subject_${index + 1}`] = 'Dance'
                } else if (element.subject === 'DRAMA & THEATRE') {
                    data[`subject_${index + 1}`] = 'Drama'
                } else if (element.subject === 'MUSIC') {
                    data[`subject_${index + 1}`] = this.state.musicType
                } else if (element.subject === 'VISUAL ARTS') {
                    data[`subject_${index + 1}`] = 'Art & Sculpture'
                } else {
                    data[`subject_${index + 1}`] = element.subject
                }
                data[`result_subject_${index + 1}`] = element.grade
            }
        })
        data['faculties'] = this.state.formData.degree_Programs

        if (filtered.length === 3) {
            await AptitudeTestServices.createApplicantAccountVerification(data)
                .then((res) => {
                    if (res.status === 201) {
                        this.setState(
                            {
                                // snackbar: true,
                                // snackbar_severity: 'success',
                                // snackbar_message: 'Verification email sent successfully!',
                                dialogBox: true,
                                index_search_progress: false,
                                CircularProgress: true,
                            },
                            () => {
                                setTimeout(() => {
                                    window.location.href =
                                        'https://ems.vpa.ac.lk/'
                                }, 5000)
                            }
                        )
                    } else {
                        this.setState({
                            snackbar: true,
                            snackbar_severity: 'error',
                            snackbar_message: 'Error!',
                            index_search_progress: false,
                            // dialogBox: true, //for test
                        })
                    }
                })
                .catch((error) => {
                    this.setState({
                        snackbar: true,
                        snackbar_severity: 'error',
                        snackbar_message: 'Error!',
                    })
                })
        } else {
            this.setState({
                snackbar: true,
                snackbar_severity: 'error',
                snackbar_message: 'Must enter 3 A/L subjects.',
                index_search_progress: false,
            })
        }
    }

    //re send email verification
    reSendEmail = () => {
        this.startStop()

        setInterval(() => {
            this.startStop()
        }, 60000)
    }

    async loadData() {
        var id = new URLSearchParams(this.props.location.search).get('id')
        console.log(id, 'id')

        await ApplicationServices.getStudentDegreePrograms(id)
            .then((res) => {
                if (res.data.view) {
                    var arr = res.data.view.TestsFaculties
                    this.setState({
                        academic_year : res.data.view?.academic_year
                    })
                    // var academic_year = res.data.view?.academic_year
                    var fields = []
                    var arr2 = this.state.degreeProgramsSubject
                    arr.forEach((element) => {
                        var itm = {
                            id: '',
                            checked: false,
                            name: '',
                        }

                        itm.id = element.id
                        itm.name = element.DegreeProgram.name
                        fields.push(itm)

                        // For Testing
                        var subject = {
                            degreeId: element.id,
                            subject: '',
                            grade: '',
                        }

                        arr2.push(subject)
                    })

                    this.setState(
                        {
                            all_degree_Programs: fields,
                            degreeProgramsSubject: arr2,
                            loading: false,
                        },
                        () => {
                            this.registrationClose(res.data.view)
                        }
                    )
                } else {
                    this.setState({
                        snackbar: true,
                        snackbar_severity: 'error',
                        snackbar_message: 'Error! No Data',
                    })
                }
            })
            .catch((error) => { })
    }
    registrationClose(data) {
        this.setState({
            registrationClose : false
        })
        let today = moment(new Date()).format('YYYY-MM-DD')
        let startDate = moment(data.start_date).subtract(1,'day').format('YYYY-MM-DD')
        let endDate = moment(data.end_date).add(1,'day').format('YYYY-MM-DD')
        
        console.log("SSSSS", 
            moment(new Date()).format('YYYY-MM-DD'),
            moment(today).isBetween(data.start_date,data.end_date),
            moment(today).isBetween(startDate,endDate)
        )
        if (!moment(today).isBetween(startDate,endDate)) {
            window.location.href = '/aptitude_test_registration'
        }else {
            this.setState({
                registrationClose : true
            })
        }
    }

    handleFieldOfStudy = (item) => {
        var arr = this.state.all_degree_Programs

        var fields_selected = this.state.formData.degree_Programs
        var data = this.state.formData

        if (item.checked) {
            arr.forEach((element) => {
                if (element.id == item.id) {
                    element.checked = false
                }
            })

            for (var i = 0; i < fields_selected.length; i++) {
                if (fields_selected[i] == item.id) {
                    fields_selected.splice(i, 1)
                }
            }
        } else {
            arr.forEach((element) => {
                if (element.id == item.id) {
                    element.checked = true
                }
            })

            fields_selected.push(item.id)
        }

        data['degree_Programs'] = fields_selected

        this.setState(
            {
                all_degree_Programs: arr,
                formData: data,
            },
            () => { }
        )

        if (
            this.state.formData.degree_Programs.length +
            this.state.otherSubjects.length >
            3
        ) {
            this.RemoveSubject(1)
        }
    }

    async componentDidMount() {

        // ! hardcoded to stop applying
        // let registrationClosed = this.state.registrationClosed

        // const endDate = moment('2023-10-27 23:59', 'YYYY-MM-DD HH:mm');
        // const today = moment();
        // if (today.isAfter(endDate)) {
        //     registrationClosed = true;
        // } else {
        //     registrationClosed = false;
        // }

        this.setState({
            msgDialog: false
        })

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.formData.password) {
                return false
            }
            return true
        })

        await this.loadData()
    }
    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('isPasswordMatch')
    }

    startStop = () => {
        const status = this.state.timer_running

        switch (status) {
            case false: {
                this.setState({
                    timer_running: true,
                })

                this.timer = setInterval(() => {
                    var sec = 0
                    var min = 0

                    var sec = this.state.breakRemainingSeconds % 60
                    var min =
                        Math.round(this.state.breakRemainingSeconds / 60) - 1

                    if (min < 0) {
                        min = 0
                    }

                    this.setState({
                        breakRemainingSeconds:
                            this.state.breakRemainingSeconds - 1,
                        time_seconds: sec,
                        time_minutes: min,
                    })
                    // console.log(this.state.breakRemainingSeconds);
                }, 1000)

                break
            }
            case true: {
                this.setState({
                    timer_running: false,
                })
                clearInterval(this.timer)
                break
            }

            default:
                break
        }
    }
    TempDetalisShow() {
        this.setState({
            show: true,
        })
    }
    async dialogClose() {
        this.setState({
            dialogBox: false,
        })
    }
    addNewSubject() {
        let otherSubjects = this.state.otherSubjects

        let newSubject = {
            subject: '',
            grade: '',
        }

        otherSubjects.push(newSubject)
        this.setState({
            otherSubjects: otherSubjects,
        })
    }
    RemoveSubject(index) {
        let otherSubjects = this.state.otherSubjects

        otherSubjects.splice(index, 1)

        this.setState({
            otherSubjects: otherSubjects,
        })
    }
    render() {
        let { theme } = this.props
        const { classes } = this.props

        return (
            <div>
                <Fragment>
                    {
                        this.state.loading === false ?
                            <Grid className={['w-full-screen']}
                                style={{
                                    backgroundImage: `url(/assets/images/background/application_background.jpg)`,
                                    backgroundSize: "cover",

                                }}
                            >

                                {this.state.registrationClosed ?
                                    <>
                                        <Grid className={classes.centerDiv}>
                                            <img width={300} height={105} src="/assets/images/background/UnivercityrHead.png" alt="company-logo" />

                                        </Grid>
                                        <Grid className={classes.centerDiv}>
                                            <Typography variant="h5" className={"text-black font-bold py-3"}>Aptitude Test Registration </Typography>

                                        </Grid>

                                        <Grid className={classes.centerDiv}>
                                            <Typography variant="h5" className={"text-muted font-bold py-1"}>Sorry!! Registration Closed</Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} className="py-5 text-center" justifyContent="center">
                                                <Grid item xs={12}>
                                                    <small className="text-primary text-18 font-bold mt-5"> Login to the EMS  👉👉 <a className="text-secondary" href={'https://ems.vpa.ac.lk/'}>Click here.</a></small>
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                    </>

                                    : <div style={{ display: "flex" }} >
                                        <Grid container spacing={2} className={["p-10", classes.centerDiv]}>
                                            {/* <Grid className={classes.centerDiv} md={7} xs={12}>
                                        <Alert severity='error' >
                                            <AlertTitle>Special Note</AlertTitle>
                                            <Typography className="">Please correctly enter your email. Once you click the "Verify to Continue" button, please check your email inbox or Spam folder.  You will receive two email with the  <span className="font-bold">INSTRUCTIONS.</span></Typography>
                                            <Typography className="text-scondary mb-1">Your application will be completed only when you<span className="font-bold"> CHECK YOUR EMAIL AND CREATE AN ACCOUNT FROM THE LINK RECEIVED TO YOUR MAIL</span> </Typography>
                                            <Typography className="text-scondary mb-1 mt-2">Use the given username in the mail and set a password and create an account in the University EMS system. Then you will be directed to continue the application process. Then you have to fill out the application, do the payment and wait for the examination admission to proceed with the test.</Typography>
                                            <Typography className="text-scondary mb-1 mt-2">Log into your portal frequently to get updates on the aptitude examination.</Typography>
                                            <Divider className="my-3" />
                                            <Typography className="text-scondary mt-2">
                                                ඔබගේ විද්‍යුත් තැපැල් ලිපිනය නිවැරදිව ඇතුලත් කරන්න.ඔබ <span className="font-bold">Verify to continue </span> බොත්තම click කළ පසු, ඔබගේ විද්‍යුත් තැපැල් ගිණුමෙහි Inbox හෝ Spam ෆෝල්ඩරය පරික්ෂා කරන්න. ඔබට EMS හි සබැදිය සහ උපදෙස් ඇතුලත්<span className="font-bold">  විද්‍යුත් පණිවිඩ දෙකක් </span>ලැබෙනු ඇත.
                                            </Typography>
                                            <Typography className="text-scondary mt-2">
                                                ඔබගේ අයදුම්පත සම්පුර්ණ වනු ඇත්තේ ඔබ ඔබගේ විද්‍යුත් තැපෑල පරික්ෂා කර එහි ඇති EMS හි සබැදියෙන් ගිණුමක් සෑදු විට පමණි.<br></br>
                                            </Typography>
                                            <Typography className="text-scondary mt-2">
                                                විද්‍යුත් තැපෑලෙන් ලබා දී ඇති පරිශීලක නාමය (Username) භාවිතා කර මුරපදයක් (Password) සකසා ගෙන EMS හි ගිණුමක් සාදන්න.එවිට ඔබට අයදුම්පත පුරවා,ගෙවීම සිදුකර විභාගයට ඇතුලත් වීම සඳහා හැකි වනු ඇත.<br></br>
                                            </Typography>
                                            <Typography className="text-scondary mt-2">
                                                යෝග්‍යතා විභාගය පිළිබද යාවත්කාලින තොරතුරු ලබා ගැනීමට ඔබගේ EMS ගිණුමට නිතර පිවිසෙන්න.

                                            </Typography>
                                        </Alert>

                                    </Grid> */}

                                            <Grid item md={7} xs={12}>
                                                <ValidatorForm
                                                    className="pt-1"
                                                    onSubmit={() => this.formSubmit2()}
                                                    onError={() => null}
                                                >
                                                    <Grid className='' container spacing={2}>
                                                        {/* <Grid item xs={12} className="bg-light-gray border-radius-8">
                                                        <SubTitle title="Aptitude Test Registration Guide" />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <small className="text-primary text-15 font-bold mb-5">Please refer following one of two guides before you filling this application.</small>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <small className="text-primary text-15 font-bold mb-5">ඔබ මෙම පෝරමය පිරවීමට පෙර කරුණාකර පහත මාර්ගෝපදේශ දෙකෙන් එකක් අනුගමනය කරන්න.</small>
                                                    </Grid>
                                                    <Grid item md={6} xs={12} className="w-full">
                                                        <a href={portalGuide} target='_blank' rel='noopener noreferrer' >
                                                            <Button
                                                                className='w-full'
                                                                size="large"
                                                                startIcon="download">
                                                                Download Guide As PDF
                                                            </Button>
                                                        </a>
                                                    </Grid>
                                                    <Grid item md={6} xs={12} className="w-full">
                                                        <a href={'https://youtu.be/hzINSZMXTf8'} target='_blank' rel='noopener noreferrer' >
                                                            <Button
                                                                color="secondary"
                                                                className='w-full'
                                                                size="large"
                                                                startIcon="video_library">
                                                                Watch On Youtube
                                                            </Button>
                                                        </a>
                                                    </Grid> */}

                                                        <Grid item xs={12} className="bg-light-primary border-radius-8 mt-5">
                                                            <Typography className=" text-primary font-semibold ">EMS Login</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2} className="py-5">
                                                                <Grid item xs={12}>
                                                                    <small className="text-primary text-15 font-bold mt-5">If you are already fill following application form, please  <a className="text-secondary" href={'https://ems.vpa.ac.lk/'}>Click Here.</a></small>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <small className="text-primary text-15 font-bold mb-5">ඔබ දැනටමත් පහත අයදුම්පත පුරවා ඇත්නම්, කරුණාකර මෙහි  <a className="text-secondary" href={'https://ems.vpa.ac.lk/'}>ක්ලික් කරන්න.</a></small>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <small className="text-primary text-15 font-bold mb-5">"ඔබ විසින් ලබා දෙනු ලබන විද්‍යුත් තැපැල් ලිපිනය හා ජංගම දුරකතන අංකය සමඟ මින් ඉදිරියට විශ්ව විද්‍යාලීය කටයුතු සිදුකරනු ලබන බව සලකන්න". </small>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>


                                                        <Grid item xs={12} className="bg-light-primary border-radius-8 mt-5">

                                                            <Typography className=" text-primary font-semibold ">Application</Typography>
                                                        </Grid>
                                                        {/* <Grid item xs={12}>
                                                    <Grid container spacing={2}> */}
                                                        <Grid item xs={12} md={6} lg={4}>
                                                            <TextValidator
                                                                className="mt-1 w-full"
                                                                type="number"
                                                                label="A/L Index Number"
                                                                variant="outlined"
                                                                size="small"
                                                                multiline={true}
                                                                name="index_no"
                                                                value={this.state.formData.index_no}
                                                                onChange={this.handleInputChange}
                                                                validators={[
                                                                    "matchRegexp:(^[0-9]{7})+$", "required",
                                                                ]}
                                                                errorMessages={[
                                                                    'Please enter valid Index Number', "This Field is required!",
                                                                ]}
                                                                inputProps={{ maxLength: 7 }}

                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        {/* </Grid>

                                                </Grid> */}

                                                        <Grid item xs={12} sm={6} md={6} lg={4}>
                                                            <TextValidator
                                                                className="mt-1 w-full"
                                                                type="text"
                                                                label="NIC Number"
                                                                variant="outlined"
                                                                size="small"
                                                                multiline={true}
                                                                name="nic"
                                                                value={this.state.formData.nic}
                                                                onChange={this.handleInputChange}
                                                                validators={['required', "matchRegexp:((^|, )([0-9]{9}(V|v)|(1|2)[0-9]{11}))+$"]}
                                                                errorMessages={[
                                                                    'This field is required', "Please enter a valid NIC Number(Eg:991234567V or 991234567v or 199981234567)"
                                                                ]}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={4}>
                                                            <TextValidator
                                                                className="mt-1 w-full"
                                                                type="number"
                                                                label="Mobile Number"
                                                                variant="outlined"
                                                                size="small"
                                                                multiline={true}
                                                                name="mobile_no"
                                                                value={this.state.formData.mobile_no}
                                                                onChange={this.handleInputChange}
                                                                // validators={['minFloat:0', 'maxFloat:3', "matchRegexp:(^[0-2][.][0-9]{4}){1}$", "required"]}
                                                                // errorMessages={[
                                                                //     "Range should be 0 to +3", "Range should be 0 to +3", "Please enter a valid Z-score ( Must have 4 decimal points. Eg:- 2.1234,1.2222)", "This Field is required!"
                                                                // ]}
                                                                validators={[
                                                                    "required",
                                                                    "matchRegexp:^[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$",
                                                                ]}
                                                                errorMessages={[
                                                                    'This field is required',
                                                                    'Please enter valid mobile number E.g.(0771231231)',
                                                                ]}
                                                                // inputProps={{ maxLength: 6 }}
                                                                fullWidth

                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <TextValidator
                                                                className="mt-1 w-full"
                                                                type="text"
                                                                label="Full Name"
                                                                variant="outlined"
                                                                size="small"
                                                                multiline={true}
                                                                name="full_name"
                                                                value={this.state.formData.full_name}
                                                                onChange={this.handleInputChange}
                                                                validators={["required", "matchRegexp:([a-zA-Z]([-']?[a-zA-Z]+ )*)$"]}
                                                                errorMessages={[
                                                                    "This Field is required!", "Please Enter a valid name"
                                                                ]}
                                                                fullWidth
                                                            />
                                                        </Grid>

                                                        <Grid className=' px-4 py-4' item xs={12} >

                                                            {this.state.show && (
                                                                <div>
                                                                    <Grid className='w-full ' container spacing={2}>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                            <SubTitle title="Full Name" />
                                                                            <Typography variant="h6" style={{ color: 'black' }}>
                                                                                {this.state.data.full_name}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid className='w-full flex justify-center items-center mt-1' container spacing={2}>
                                                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                                                            <SubTitle title="NIC " />
                                                                            <Typography variant="p" style={{ color: 'gray' }}>{this.state.data.nic}</Typography>
                                                                        </Grid>
                                                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                                                            <SubTitle title="Z-Score" />
                                                                            <Typography variant="p" style={{ color: 'gray' }}>{this.state.data.mobile_no}</Typography>
                                                                        </Grid>
                                                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                                                            <SubTitle title="Results" />
                                                                            <Typography variant="p" style={{ color: 'gray' }}>
                                                                                <Grid className='flex' container spacing={2}>
                                                                                    {
                                                                                        this.state.data.results.map((item, key) => (
                                                                                            < Grid item >
                                                                                                {item.subject} : {item.result}
                                                                                            </Grid>
                                                                                        ))
                                                                                    }
                                                                                </Grid>
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Divider className='my-3' />
                                                                </div>
                                                            )}
                                                            <Grid className='w-full mt-1' container spacing={2}>
                                                                {/* section 2*/}
                                                                {this.state.show_other_data &&
                                                                    <Fragment>
                                                                        <SubTitle title="Field of Study that the Applicant Expecting to Apply:" />
                                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                            <Grid item xs={12}>


                                                                                <Alert severity='info' variant="outlined" >
                                                                                    <AlertTitle>Note</AlertTitle>
                                                                                    <Typography className="">1st you can select the degree program that you wish to apply.</Typography>
                                                                                    <Typography className="text-scondary mb-1">(Select only the degrees that you are hoping to do the aptitude test)</Typography>
                                                                                    <Typography className=""> You will see the pre-requisite A/L subject once you select the degree program.</Typography>
                                                                                    <Typography className="mt-1"> Add other subjects you did under "Additional A/L Subjects"</Typography>


                                                                                </Alert>


                                                                            </Grid>

                                                                            <Typography variant="subtitle1" style={{ color: 'gray' }}>
                                                                                <Grid className='flex mt-1' container spacing={2}>
                                                                                    <Grid item xs={12} className="hide-on-mobile">
                                                                                        <Grid container spacing={2}>
                                                                                            <Grid container item xs={4} justify="center"
                                                                                            >
                                                                                                <Typography className="font-bold">Degree Program</Typography>
                                                                                            </Grid>
                                                                                            <Grid container item xs={4} justify="center" >
                                                                                                <Typography className="font-bold">Required A/L Subject</Typography>
                                                                                            </Grid>
                                                                                            <Grid container item xs={4} justify="center" >
                                                                                                <Typography className="font-bold">Grade</Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    {
                                                                                        this.state.all_degree_Programs.map((item, key) => (
                                                                                            <Grid key={key} item xs={12}>
                                                                                                <Grid container spacing={2}>
                                                                                                    <Grid item xs={12} md={4}>
                                                                                                        <FormControlLabel
                                                                                                            className='pl-5'
                                                                                                            control={
                                                                                                                <Checkbox
                                                                                                                    checked={item.checked}
                                                                                                                    name="checkedB"
                                                                                                                    color="primary"
                                                                                                                    disabled={((this.state.formData.degree_Programs.length) + (this.state.otherSubjects.length - 1)) >= 3 && !item.checked ? true : false}
                                                                                                                    required={this.state.formData.degree_Programs.length === 0 ? true : false}
                                                                                                                    onClick={() => this.handleFieldOfStudy(item, key)}
                                                                                                                />
                                                                                                            }
                                                                                                            label={item.name}
                                                                                                        />
                                                                                                    </Grid>
                                                                                                    <Grid item xs={12} md={4}>
                                                                                                        {item.name == "DANCE" ?
                                                                                                            <Grid container alignItems='center' item xs={12} md={12}>
                                                                                                                <Grid item lg={12} md={12} sm={12} xs={12} className="pt-2">
                                                                                                                    {item.checked ?
                                                                                                                        <Typography>Dance</Typography>
                                                                                                                        : ""}
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                            : item.name == "DRAMA & THEATRE" ?
                                                                                                                <Grid container alignItems='center' item xs={12} md={12}>
                                                                                                                    <Grid item lg={12} md={12} sm={12} xs={12} className="pt-2">
                                                                                                                        {item.checked ?
                                                                                                                            <Typography>Drama</Typography>
                                                                                                                            : ""}
                                                                                                                    </Grid>
                                                                                                                </Grid>
                                                                                                                : item.name == "MUSIC" ?
                                                                                                                    // <Grid container alignItems='center' item xs={12} md={12}>
                                                                                                                    <Grid item xs={12} md={12}>
                                                                                                                        {item.checked ?
                                                                                                                            // <Typography>Music</Typography>
                                                                                                                            <Autocomplete
                                                                                                                                className="w-full mt-2"
                                                                                                                                options={[
                                                                                                                                    "Carnatic Music",
                                                                                                                                    "Oriental Music",
                                                                                                                                    "Western Music",
                                                                                                                                ]}
                                                                                                                                getOptionLabel={(option) =>
                                                                                                                                    option
                                                                                                                                }
                                                                                                                                getOptionSelected={(option) => option}
                                                                                                                                onChange={(e, v) => {
                                                                                                                                    this.setState({
                                                                                                                                        musicType: v
                                                                                                                                    })
                                                                                                                                }}
                                                                                                                                // value={this.state.degreeProgramsSubject[key].grade}
                                                                                                                                renderInput={(params) => (
                                                                                                                                    <TextValidator
                                                                                                                                        {...params}
                                                                                                                                        label="Subject"
                                                                                                                                        //variant="outlined"
                                                                                                                                        value={this.state.musicType}
                                                                                                                                        fullWidth
                                                                                                                                        variant="outlined"
                                                                                                                                        size="small"
                                                                                                                                        validators={[
                                                                                                                                            "required",
                                                                                                                                        ]}
                                                                                                                                        errorMessages={[
                                                                                                                                            "This Field is required!",
                                                                                                                                        ]}
                                                                                                                                    />
                                                                                                                                )}
                                                                                                                            />
                                                                                                                            : ""}
                                                                                                                    </Grid>
                                                                                                                    // </Grid>
                                                                                                                    : item.name == "VISUAL ARTS" ?
                                                                                                                        <Grid container alignItems='center' item xs={12} md={12}>
                                                                                                                            <Grid item lg={12} md={12} sm={12} xs={12} className="pt-2">
                                                                                                                                {item.checked ?
                                                                                                                                    <Typography>Art & Sculpture</Typography>
                                                                                                                                    : ""}
                                                                                                                            </Grid>
                                                                                                                        </Grid>
                                                                                                                        : null}
                                                                                                    </Grid>
                                                                                                    <Grid item xs={6} md={4}>
                                                                                                        {item.checked ?
                                                                                                            <Autocomplete
                                                                                                                className="w-full mt-2"
                                                                                                                options={[
                                                                                                                    "A",
                                                                                                                    "B",
                                                                                                                    "C",
                                                                                                                    "S",
                                                                                                                    "W",
                                                                                                                ]}
                                                                                                                getOptionLabel={(option) =>
                                                                                                                    option
                                                                                                                }
                                                                                                                getOptionSelected={(option) => option}
                                                                                                                onChange={(e, v) => {
                                                                                                                    let subs = this.state.degreeProgramsSubject
                                                                                                                    subs[key].grade = v
                                                                                                                    subs[key].subject = item.name

                                                                                                                    this.setState({
                                                                                                                        degreeProgramsSubject: subs
                                                                                                                    })
                                                                                                                    // this.setOnlinePracticalTestQuestion(index, 'answer_type', v)
                                                                                                                }}
                                                                                                                // value={this.state.degreeProgramsSubject[key].grade}
                                                                                                                renderInput={(params) => (
                                                                                                                    <TextValidator
                                                                                                                        {...params}
                                                                                                                        label="Grade"
                                                                                                                        //variant="outlined"
                                                                                                                        value={this.state.degreeProgramsSubject[key].grade}
                                                                                                                        fullWidth
                                                                                                                        InputLabelProps={{
                                                                                                                            shrink: true,
                                                                                                                        }}
                                                                                                                        variant="outlined"
                                                                                                                        size="small"
                                                                                                                        // validators={[
                                                                                                                        //     "matchRegexp:^[A/B/C]$", "required",
                                                                                                                        // ]}
                                                                                                                        // errorMessages={[
                                                                                                                        //     'You should have minimum C pass to be eligible to apply.', "This Field is required!",
                                                                                                                        // ]}
                                                                                                                    />
                                                                                                                )}
                                                                                                            />
                                                                                                            : ""}
                                                                                                    </Grid>
                                                                                                    <Grid item xs={12} className="invisible-on-pc">
                                                                                                        <Divider />
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        ))
                                                                                    }
                                                                                </Grid>
                                                                            </Typography>
                                                                        </Grid>
                                                                        {this.state.formData.degree_Programs.length <= 2 ? <Fragment>
                                                                            {/* <h1>{this.state.formData.degree_Programs.length}</h1>
                                                                    <h1>{this.state.otherSubjects.length}</h1> */}
                                                                            <Grid item xs={12} className="my-2">
                                                                                <SubTitle title="Other A/L Subjects:" />
                                                                            </Grid>


                                                                            <Grid item xs={12}>
                                                                                <Grid container spacing={2}>
                                                                                    {this.state.otherSubjects.map((element, index) => (
                                                                                        <Grid key={index} item lg={12} md={12} sm={12} xs={12} >
                                                                                            <Grid container spacing={1} >
                                                                                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                                                    {index > 0 ?
                                                                                                        <Tooltip title="Remove Subject">
                                                                                                            <IconButton
                                                                                                                onClick={() => { this.RemoveSubject(index) }}>
                                                                                                                <Icon className="text-gray">delete</Icon>
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                        : null}
                                                                                                </Grid>
                                                                                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                                                    <TextValidator
                                                                                                        className="mt-2 w-full"
                                                                                                        type="text"
                                                                                                        label="Subject Name"
                                                                                                        variant="outlined"
                                                                                                        size="small"
                                                                                                        multiline={true}
                                                                                                        name="subject"
                                                                                                        value={this.state.otherSubjects[index].subject ? this.state.otherSubjects[index].subject : ""}
                                                                                                        onChange={(e, v) => {
                                                                                                            let other = this.state.otherSubjects
                                                                                                            other[index]["subject"] = e.target.value
                                                                                                            this.setState({
                                                                                                                otherSubjects: other
                                                                                                            })

                                                                                                        }}
                                                                                                        validators={["required"]}
                                                                                                        errorMessages={[
                                                                                                            "This Field is required!"
                                                                                                        ]}
                                                                                                        fullWidth
                                                                                                    />
                                                                                                </Grid>
                                                                                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                                                    <Autocomplete
                                                                                                        className="w-full mt-2"
                                                                                                        options={[
                                                                                                            "A",
                                                                                                            "B",
                                                                                                            "C",
                                                                                                            "S",
                                                                                                        ]}
                                                                                                        getOptionLabel={(option) =>
                                                                                                            option
                                                                                                        }
                                                                                                        getOptionSelected={(option) => option}
                                                                                                        onChange={(e, v) => {
                                                                                                            let subs = this.state.otherSubjects
                                                                                                            subs[index].grade = v
                                                                                                            this.setState({
                                                                                                                otherSubjects: subs
                                                                                                            })
                                                                                                            // this.setOnlinePracticalTestQuestion(index, 'answer_type', v)
                                                                                                        }}
                                                                                                        // value={this.state.otherSubjects[index].grade}
                                                                                                        renderInput={(params) => (
                                                                                                            <TextValidator
                                                                                                                {...params}
                                                                                                                label="Grade"
                                                                                                                value={this.state.otherSubjects[index].grade}
                                                                                                                fullWidth
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="outlined"
                                                                                                                size="small"
                                                                                                                validators={[
                                                                                                                    "matchRegexp:^[A/B/C/S]$", "required",
                                                                                                                ]}
                                                                                                                errorMessages={[
                                                                                                                    'You should have minimum S pass to be eligible to apply.', "This Field is required!",
                                                                                                                ]}
                                                                                                            />
                                                                                                        )}
                                                                                                    />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    ))}
                                                                                    <Grid item xs={12} container alignItems='center' justify='flex-end'>
                                                                                        <Button
                                                                                            className="full-width mt-1"
                                                                                            startIcon="add"
                                                                                            type="button"
                                                                                            scrollToTop={false}
                                                                                            onClick={() => { this.addNewSubject() }}
                                                                                            disabled={(this.state.formData.degree_Programs.length) === 0 || ((this.state.formData.degree_Programs.length) + (this.state.otherSubjects.length - 1)) >= 2}
                                                                                        >
                                                                                            Add New Subject
                                                                                        </Button>
                                                                                    </Grid>

                                                                                </Grid>
                                                                            </Grid>

                                                                        </Fragment> :
                                                                            // <h1>{this.state.formData.degree_Programs.length}</h1>
                                                                            <h1> </h1>}
                                                                        <Grid item xs={12}>
                                                                            <Grid item xs={12} className="my-2">
                                                                                <SubTitle title="Additional A/L Subjects:" />
                                                                            </Grid>
                                                                            <Grid container spacing={2}>
                                                                                <Grid item container lg={4} md={4} sm={12} xs={12} className="pt-2" alignItems='center'>
                                                                                    <Typography>General English</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                                                                    <Autocomplete
                                                                                        className="w-full mt-2"
                                                                                        options={[
                                                                                            "A",
                                                                                            "B",
                                                                                            "C",
                                                                                            "S",
                                                                                            "W"
                                                                                        ]}
                                                                                        getOptionLabel={(option) =>
                                                                                            option
                                                                                        }
                                                                                        getOptionSelected={(option) => option}
                                                                                        onChange={(e, v) => {
                                                                                            let formdata = this.state.formData
                                                                                            formdata.english = v

                                                                                            this.setState({
                                                                                                formData: formdata
                                                                                            })
                                                                                            // this.setOnlinePracticalTestQuestion(index, 'answer_type', v)
                                                                                        }}
                                                                                        // value={this.state.degreeProgramsSubject[key].grade}
                                                                                        renderInput={(params) => (
                                                                                            <TextValidator
                                                                                                {...params}
                                                                                                label="Grade"
                                                                                                //variant="outlined"
                                                                                                value={this.state.formData.english}
                                                                                                fullWidth
                                                                                                InputLabelProps={{
                                                                                                    shrink: true,
                                                                                                }}
                                                                                                variant="outlined"
                                                                                                size="small"
                                                                                                validators={[
                                                                                                    "required",
                                                                                                ]}
                                                                                                errorMessages={[
                                                                                                    "This Field is required!",
                                                                                                ]}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container spacing={2}>
                                                                                <Grid item container lg={4} md={4} sm={12} xs={12} className="pt-2" alignItems='center'>
                                                                                    <Typography>Common General Test</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                                                                    <TextValidator
                                                                                        className="mt-2 w-full"
                                                                                        type="text"
                                                                                        label="Test Marks"
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                        multiline={true}
                                                                                        name="common_general_test"
                                                                                        value={this.state.formData.common_general_test}
                                                                                        onChange={(e, v) => {
                                                                                            let cgt = this.state.formData
                                                                                            cgt.common_general_test = e.target.value
                                                                                            this.setState({
                                                                                                formData: cgt
                                                                                            })

                                                                                        }}
                                                                                        validators={['minNumber:0', 'maxNumber:100', "required"]}
                                                                                        errorMessages={[
                                                                                            "Please enter valid Number", "Maximum marks are 100", "This Field is required!"
                                                                                        ]}
                                                                                        fullWidth
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item xs={12} className="my-4">
                                                                            <Divider />
                                                                        </Grid>
                                                                        <Grid item xs={12} className="my-3" >
                                                                            <Alert severity='info' className='mt-3' icon={false} >
                                                                                <div>
                                                                                    <li>I sat for the GCE Advanced Level Examination in Art Stream in {this.state.academic_year.split("/")[0]|| ""}.</li>
                                                                                    <li>The information I provided about the subjects and the results in this form are true, complete and accurate.</li>
                                                                                    <li>I acknowledge that providing false information about my A/L examination will be resulted to a disqualification even after completing the Aptitude Test.</li>
                                                                                </div>
                                                                                <Typography className="text-error font-bold text-15 my-4">
                                                                                    ඔබ ඉහත ඇතුලත් කල තොරතුරු නිවැරදි බවට සනාත කිරීමට සහ  portal එකට log වීමට අවශ්‍ය username හා  password සාදා ගැනීමට පහත දැක්වෙන කොටුව මත click කරන්න.
                                                                                </Typography>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={this.state.confirmation_checkbox}
                                                                                            onChange={() => {
                                                                                                var checkbox = this.state.confirmation_checkbox
                                                                                                this.setState({
                                                                                                    confirmation_checkbox: !checkbox,
                                                                                                })
                                                                                            }}
                                                                                            name="chkbox_confirm"
                                                                                            color="primary"
                                                                                        />
                                                                                    }
                                                                                    label={<p>By ticking, I acknowledge that I have read, understand, confirm and agree to the above stated conditions.</p>}
                                                                                />
                                                                            </Alert>
                                                                        </Grid>
                                                                        {this.state.confirmation_checkbox && (
                                                                            <Grid item xs={12} container spacing={2} className="mt-3">
                                                                                <Grid item xs={12} className="bg-light-gray border-radius-8">
                                                                                    <SubTitle title="Create your username and password to log into Aptitude test Portal" />
                                                                                </Grid>
                                                                                <Grid item xs={12} >
                                                                                    <Alert severity='error'>
                                                                                        <AlertTitle>Dear Student,</AlertTitle>

                                                                                        <Typography className="mt-2" >
                                                                                            After you click <strong>Next : Login to Aptitude test portal </strong> button below, you will be directed to the Aptitude test Management System login Page. Please remember the email and password you entered below.
                                                                                        </Typography>
                                                                                        <Typography className="mt-2" >
                                                                                            Please note that your below entered email will be your username and entered password will be your password to the portal.
                                                                                        </Typography>
                                                                                        <Typography className="mt-2" >
                                                                                            Your application process will start only after you log into the portal and fill the application.
                                                                                        </Typography>
                                                                                        <Typography className="mt-2" >
                                                                                            You can find further instructions in the portal as soon as you logged in.

                                                                                        </Typography>

                                                                                        <Divider className="my-3"></Divider>
                                                                                        <Typography className="mt-2 font-bold" >
                                                                                            හිතවත් සිසුවනි,
                                                                                        </Typography>

                                                                                        <Typography className="mt-2" >
                                                                                            ඔබ  පහතින් ඇති <strong>Next : Login to Aptitude test portal </strong>  බොත්තම ක්ලික් කළ පසු, ඔබව Aptitude test Management System පිවිසුම් පිටුව වෙත යොමු කරනු ඇත. කරුණාකර ඔබ පහත ඇතුළත් කළ විද්‍යුත් තැපෑල සහ මුරපදය මතක තබා ගන්න.
                                                                                        </Typography>
                                                                                        <Typography className="mt-2" >
                                                                                            ඔබගේ පහත ඇතුලත් කල විද්‍යුත් තැපෑල ද්වාරයෙහි ඔබගේ පරිශීලක නාමය වන අතර ඇතුලත් කල මුරපදය ද්වාරයෙහි ඔබගේ මුරපදය වනු ඇති බව කරුණාවෙන් සලකන්න.
                                                                                        </Typography>
                                                                                        <Typography className="mt-2" >
                                                                                            ඔබගේ අයදුම් කිරීමේ ක්‍රියාවලිය ආරම්භ වන්නේ ඔබ ද්වාරයට පිවිසී අයදුම්පත පිරවීමෙන් පසුව පමණි.
                                                                                        </Typography>
                                                                                        <Typography className="mt-2" >
                                                                                            ඔබ පිවිසූ වහාම ඉදිරි උපදෙස් ද්වාරයෙහි සොයාගත හැකිය.

                                                                                        </Typography>


                                                                                    </Alert>

                                                                                </Grid>

                                                                                <Grid item xs={12} sm={12} md={12} lg={12} className="pt-4">

                                                                                    <Grid container

                                                                                        justify="flex-start"
                                                                                        alignItems="flex-start"
                                                                                        spacing={2}
                                                                                    >
                                                                                        <Grid item xs={12}>
                                                                                            <small className=" text-black text-15">Add your email address which will be used as your username to login to the portal</small>
                                                                                        </Grid>
                                                                                        <Grid item xs={12} >

                                                                                            <TextValidator
                                                                                                className=" w-full"
                                                                                                type="text"
                                                                                                label="Your Email Address"
                                                                                                variant="outlined"
                                                                                                size="small"
                                                                                                multiline={true}
                                                                                                name="email"
                                                                                                value={this.state.formData.email}
                                                                                                onChange={this.handleInputChange}
                                                                                                validators={["required", "isEmail"]}
                                                                                                errorMessages={[
                                                                                                    "This Field is required!",
                                                                                                    "Please enter valid email",
                                                                                                ]}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12}>
                                                                                            <small className=" text-black text-15">Create your password</small>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12} md={6} lg={6}>

                                                                                            <TextValidator
                                                                                                className="mb-3 w-full"
                                                                                                label="New Password"
                                                                                                variant="outlined"
                                                                                                size="small"
                                                                                                onChange={this.handleInputChange}

                                                                                                name="password"
                                                                                                type={this.state.textType}
                                                                                                value={this.state.formData.password}
                                                                                                validators={[`matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$`, 'required']}
                                                                                                errorMessages={["Please enter valid password ", 'This field is required']}
                                                                                                InputProps={{

                                                                                                    endAdornment: (
                                                                                                        <InputAdornment position="end">
                                                                                                            <IconButton
                                                                                                                size="small"
                                                                                                                disableRipple={true}
                                                                                                                type="button"
                                                                                                                onClick={() => {
                                                                                                                    this.state.textType == "password" ? this.setTextType("text") : this.setTextType("password")
                                                                                                                }}
                                                                                                            >
                                                                                                                {this.state.textType == "password"
                                                                                                                    ? <EyeOff />
                                                                                                                    : <EyeOn />
                                                                                                                }

                                                                                                            </IconButton>
                                                                                                        </InputAdornment>
                                                                                                    )
                                                                                                }} />
                                                                                            {/* <TextValidator
                                                                                        className="mt-1 w-full"
                                                                                        type="password"
                                                                                        label="Password"
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                        multiline={true}
                                                                                        name="password"
                                                                                        value={this.state.formData.password}
                                                                                        onChange={this.handleInputChange}
                                                                                        validators={["required"]}
                                                                                        errorMessages={[
                                                                                            "This Field is required!"
                                                                                        ]}
                                                                                    /> */}
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={12} md={6} lg={6}>

                                                                                            <TextValidator
                                                                                                className="mb-3 w-full"
                                                                                                label="Confirm Password"
                                                                                                variant="outlined"
                                                                                                size="small"
                                                                                                onChange={this.handleInputChange}

                                                                                                name="confirmPassword"
                                                                                                type={this.state.textType2}
                                                                                                value={this.state.formData.confirmPassword}
                                                                                                validators={['isPasswordMatch', 'required']}
                                                                                                errorMessages={['Password Mismatch', 'This field is required']}
                                                                                                InputProps={{

                                                                                                    endAdornment: (
                                                                                                        <InputAdornment position="end">
                                                                                                            <IconButton
                                                                                                                size="small"
                                                                                                                disableRipple={true}
                                                                                                                type="button"
                                                                                                                onClick={() => {
                                                                                                                    this.state.textType2 == "password" ? this.setTextType2("text") : this.setTextType2("password")
                                                                                                                }}
                                                                                                            >
                                                                                                                {this.state.textType2 == "password"
                                                                                                                    ? <EyeOff />
                                                                                                                    : <EyeOn />
                                                                                                                }

                                                                                                            </IconButton>
                                                                                                        </InputAdornment>
                                                                                                    )
                                                                                                }} />
                                                                                            {/* <TextValidator
                                                                                        className="mt-1 w-full"
                                                                                        type="password"
                                                                                        label="Confirm Password"
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                        multiline={true}
                                                                                        name="confirmPassword"
                                                                                        value={this.state.formData.confirmPassword}
                                                                                        onChange={this.handleInputChange}
                                                                                        validators={['isPasswordMatch', 'required']}
                                                                                        errorMessages={['password Mismatch', 'This field is required']}
                                                                                    /> */}
                                                                                        </Grid>
                                                                                        <Grid item xs={12}>
                                                                                            <Alert severity='info'>
                                                                                                <AlertTitle>Your password should have,</AlertTitle>
                                                                                                <Typography className="mt-1" >Minimum 8 characters</Typography>
                                                                                                <Typography className="mt-1">At least one upper case English letter</Typography>
                                                                                                <Typography className="mt-1">At least one lower case English letter</Typography>
                                                                                                <Typography className="mt-1">At least one digit</Typography>





                                                                                            </Alert>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Button
                                                                                                className=" w-full"
                                                                                                progress={this.state.index_search_progress}
                                                                                                type="submit"
                                                                                                size="Large"
                                                                                                disabled={!this.state.formData.email}
                                                                                            // endIcon={<Icon>forward</Icon>}

                                                                                            // scrollToTop={true}
                                                                                            >
                                                                                                <span className="capitalize">Next : Login to Aptitude test portal</span>
                                                                                            </Button>
                                                                                        </Grid>


                                                                                    </Grid>

                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                                    <Alert severity='warning'>
                                                                                        This email will be used as your username and password will be added as your password.<br></br>
                                                                                        Remember your email and password when you login next time via <a href='https://ems.vpa.ac.lk/'>https://ems.vpa.ac.lk/</a>
                                                                                    </Alert>
                                                                                </Grid>

                                                                            </Grid>
                                                                        )}
                                                                    </Fragment>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </ValidatorForm>
                                            </Grid>
                                        </Grid>

                                    </div>
                                }



                            </Grid>
                            :
                            <Grid className="justify-center text-center w-full pt-12">
                                <CircularProgress size={30} />
                            </Grid>
                    }

                    <Dialog open={this.state.dialogBox}>
                        <DialogTitle id="form-dialog-title">
                            <div className="flex w-full justify-center items-center my-3">
                                <Avatar
                                    className={[
                                        classes.large,
                                        'bg-light-primary ',
                                    ]}
                                >
                                    <Icon className="text-primary">timer</Icon>
                                </Avatar>
                            </div>
                            <div className="flex w-full justify-center items-center my-2">
                                <h4 className="text-primary font-medium">
                                    Please Hold a Moment!
                                </h4>
                            </div>
                            <Divider className="my-2" />
                        </DialogTitle>

                        <DialogContent>
                            <DialogContent>
                                <div className="flex w-full justify-center items-center mt--5 mb-4">
                                    <h4 className="text-green font-normal text-center">
                                        Once your account created, You will
                                        redirected to the Portal{' '}
                                    </h4>
                                </div>
                                {this.state.CircularProgress && (
                                    <Grid className="justify-center text-center w-full pt-5">
                                        <CircularProgress size={30} />
                                    </Grid>
                                )}
                            </DialogContent>
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        fullWidth={true}
                        open={false}
                        maxWidth={"md"}
                    >
                        {/* <DialogTitle id="form-dialog-title">

                            <h4 className="text-primary font-medium">Make Payment before applying to the Aptitude Test</h4>
                        </DialogTitle> */}

                        <DialogContent>
                            <Alert
                                icon={false}
                                severity="info"
                                className="mt-1"
                            >
                                <AlertTitle className="text-24 font-bold">
                                    Payment Instructions
                                </AlertTitle>
                                <Typography className="mt-4 font-bold">
                                    Instructions to do the Payment are as below.
                                </Typography>

                                <Typography className="mt-1 ">
                                    Use your{' '}
                                    <strong>
                                        {' '}
                                        National Identity Card Number ( NIC
                                        Number )
                                    </strong>{' '}
                                    as your payment Reference Code.
                                </Typography>

                                <Typography className="my-3">
                                    <Alert
                                        severity="error"
                                        icon={<Icon>error</Icon>}
                                    >
                                        <AlertTitle>Special Note</AlertTitle>
                                        If you have registered for three
                                        aptitude tests, you should make three
                                        payments, if have registered for two
                                        aptitude tests, you should make, two
                                        payments, and if you have registered for
                                        one aptitude test should make a single
                                        payment. Please remember to state your
                                        admission number/s in each payment as
                                        the reference.
                                    </Alert>
                                </Typography>

                                <Typography className="mt-4 text-15 text-primary font-bold cursor-pointer"></Typography>

                                <Typography className="mt-4 text-15 font-bold">
                                    Please use one of the following 2 methods to
                                    make the payment.
                                </Typography>

                                <div>
                                    {/* <Typography className='mt-2'>
                                            <strong>Method 1) Online Payment Gateway</strong>
                                        </Typography>

                                        <Typography className='mt-2'>
                                            If you are doing via online payment gateway of VPA University, please state the admission number in the reason/purpose section
                                            Please only state the <strong> Admission Number</strong> and <span className="text-error font-bold">do not write anything else.</span>
                                        </Typography>

                                        <Typography className='mt-2'>
                                            To make online payments <span>👉 <a style={{ color: 'blue' }} href='https://vpa.ac.lk/pay/product/2022-aptitude-test-fee/'>click here. </a></span>
                                        </Typography> */}

                                    <Typography className="mt-4">
                                        <strong>
                                            Method 1) Online Bank Transfer
                                        </strong>
                                    </Typography>
                                    <Typography className="mt-2">
                                        If you are using online/mobile banking
                                        please state the admission number in the
                                        reason/purpose section of your transfer.
                                        Please only write the{' '}
                                        <strong>
                                            {' '}
                                            National Identity Card (NIC) Number
                                        </strong>{' '}
                                        and{' '}
                                        <span className="text-error font-bold">
                                            do not write anything else.
                                        </span>
                                    </Typography>

                                    <Typography className="mt-2">
                                        Your payments should be done to the
                                        following account of
                                        <strong>
                                            {' '}
                                            "University of the Visual Performing
                                            Arts"
                                        </strong>{' '}
                                        with the account number{' '}
                                        <strong>
                                            {' '}
                                            0000084072227, BOC Bank, Torrington
                                            branch.
                                        </strong>
                                    </Typography>
                                    <Typography className="mt-4">
                                        <strong>
                                            Method 2) Bank Deposit (Not
                                            recommended method of payment)
                                        </strong>
                                    </Typography>
                                    <Typography className="mt-2">
                                        Payment should be made to the following
                                        account of
                                        <strong>
                                            {' '}
                                            "University of the Visual Performing
                                            Arts"
                                        </strong>{' '}
                                        with the account number{' '}
                                        <strong>
                                            {' '}
                                            0000084072227, BOC Bank, Torrington
                                            branch.
                                        </strong>
                                    </Typography>
                                    <Typography className="mt-2">
                                        If you have registered to multiple
                                        aptitude tests you have to write
                                        multiple payment slips separately for
                                        each exam. Remember to write your
                                        admission number in the "Purpose of the
                                        deposit" section on the slip when you
                                        make payments through bank counters.
                                        Please only state the above number, do
                                        not write anything else.
                                    </Typography>
                                    <Typography
                                        className="mt-2"
                                        style={{ fontStyle: 'italic' }}
                                    >
                                        <strong>
                                            {' '}
                                            While making the payment remind your
                                            banking officer to enter your
                                            admission number into the banking
                                            system.
                                        </strong>
                                    </Typography>
                                    <Typography className="mt-2">
                                        Please note{' '}
                                        <span className="text-error font-bold">
                                            {' '}
                                            payments through CDM (Cash Deposit
                                            Machines) are NOT Allowed.
                                        </span>
                                        Your registration will not be completed
                                        until you make the payment. Please make
                                        sure to make your payment before the
                                        deadline.
                                    </Typography>

                                    {/* <Typography className='mt-2' >
                                     Your aptitude test admission and the paper will be available only after the results verification and payment verification. So, make sure to make the payment as soon as possible.
                                 </Typography> */}

                                    <Typography className="mt-2">
                                        Payment verification is a manual process
                                        so it might take a maximum of around 3
                                        working days to approve the payment.
                                    </Typography>
                                </div>
                            </Alert>
                            <div className="p-1">
                                <Typography className="mt-5">
                                    <strong>
                                        Confirm the Payment and continue the
                                        application
                                    </strong>
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                this.state
                                                    .payment_confirmation_checkbox
                                            }
                                            onChange={() => {
                                                var checkbox =
                                                    this.state
                                                        .payment_confirmation_checkbox
                                                this.setState({
                                                    payment_confirmation_checkbox:
                                                        !checkbox,
                                                })
                                            }}
                                            name="chkbox_confirm"
                                            color="primary"
                                        />
                                    }
                                    label={<p>I have done the payment.</p>}
                                />
                            </div>
                        </DialogContent>
                        {this.state.payment_confirmation_checkbox && (
                            <DialogActions>
                                <div className="flex w-full  justify-center ">
                                    <Button
                                        className="text-18 w-full"
                                        size="large"
                                        onClick={() => {
                                            this.setState({
                                                msgDialog: false,
                                            })
                                        }}
                                        color="primary"
                                        autoFocus
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </DialogActions>
                        )}
                    </Dialog>

                    {/* Snackbar */}
                    <LoonsSnackbar
                        open={this.state.snackbar}
                        onClose={() => {
                            this.setState({
                                snackbar: false,
                            })
                        }}
                        message={this.state.snackbar_message}
                        autoHideDuration={3000}
                        severity={this.state.snackbar_severity}
                        elevation={2}
                        variant="filled"
                    ></LoonsSnackbar>
                </Fragment>
            </div>
        )
    }
}

export default withStyles(styleSheet)(ApplicantVerifyAccount)
