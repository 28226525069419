import React from 'react'
import { authRoles } from '../../auth/authRoles'

const intakeRoutes = [


    {
        path: '/intake/create',
        component: React.lazy(() => import('./CreateIntake')),
        auth: authRoles.SetUpNewAcademicYear,
    },
    {
        path: '/intake/all',
        component: React.lazy(() => import('./AllIntakes')),
        auth: authRoles.AllStudents,
    },
    {
        path: '/intake/addstudents/:id',
        component: React.lazy(() => import('./ImportStudents')),
        auth: authRoles.AddNewStudnetManuaklly,
    },
    {
        path: '/intake/edit/:id',
        component: React.lazy(() => import('./EditIntake')),
        auth: authRoles.AddNewStudnetManuaklly,
    },
    {
        path: '/intake/studentlist/:id',
        component: React.lazy(() => import('./MAViewIntake')),
        auth: authRoles.ViewIntake,
    },
    {
        path: '/intake/students/status',
        component: React.lazy(() => import('./AR_AllStudentStatus')),
        auth: authRoles.ViewDocumentStatus
    },

    {
        path: '/itunit/Intakes',
        component: React.lazy(() => import('./ItUnitAllIntakes')),
        auth: authRoles.ITViewIntake,
    },
    {
        path: '/itunit/viewIntake/:id',
        component: React.lazy(() => import('./ITViewIntake')),
        auth: authRoles.ITViewIntake,
    },
    {
        path: '/ma/viewIntake/:id',
        component: React.lazy(() => import('./ITViewIntake')),
        auth: authRoles.ViewIntake,
    },


]

export default intakeRoutes;
