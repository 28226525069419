import React from 'react'
import { Redirect } from 'react-router-dom'

import dashboardRoutes from './views/dashboard/DashboardRoutes'
import AptitudeRoutes from './views/AptituteTest/AptitudeRoutes'
import ExaminationRoutes from './views/Examinations/ExaminationRoutes'
import TestExamRoutes from './views/ExamTests/TestExamRoutes'
import tempRoutes from './views/Temp/TempRoutes'

import dataSetupRoutes from './views/DataSetup/dataSetupRoutes'
import StatusRoutes from './views/Status/StatusRoutes'
import intakeRoutes from './views/Intake/intakeRoutes'
import renewalsRoutes from './views/YearRenewals/renewalsRoutes'
import duplicateIdRoutes from './views/DuplicateID/duplicateIdRoutes'
import DegreesAndCoursesRoutes from './views/DegreesAndCourses/DegreesAndCoursesRoutes'
import batchRoutes from './views/Batch/BatchRoutes'
import curriculumRoutes from './views/Curriculum/CurriculumRoutes'
import applicantRoutes from './views/Applicant/ApplicantRoutes'
import AdminStudentProfileRoute from './views/AdminStudentProfile/AdminStudentProfileRoutes'
import HrRoutes from './views/HumanResource/hrRoutes'
import MedicalUnitRoutes from './views/MedicalUnit/MedicalUnitRoutes'
import FinanceRoutes from './views/Finance/FinanceRoutes'
import DocumentManagementRoutes from './views/DocumentManagement/documentManagementRoutes'
import CourseManagementRoutes from './views/CourseManagement/CourseManagementRoutes'
import CourseRoutes from './views/CourseManagement/CourseRoutes'
import HRWorkFlowsRoutes from './views/HRworkflows/HRWorkFlowsRoutes'
import LeaveRoutes from './views/Leave/LeaveRoutes'
import AuditRoutes from './views/Audit/AuditRoutes'
import ResearchGrantRoutes from './views/ResearchGrant/ResearchGrantRoutes'
import VehicleRegisterRoutes from './views/Vehicle/VehicleRegisterRoutes'
import MahapolaRoutes from './views/Mahapola/MahapolaRoutes'
import WelfareRoutes from './views/Welfare/WelfareRoutes'
import VapaTVRoutes from './views/VapaTV/VapaTVRoutes'
import VapaPressRoutes from './views/VAPAPress/VapaPressRoutes'
import JobVacancyRoutes from './views/JobVancy/JobVacancyRoute'
import ResourceReservationRoutes from './views/ResourceReservation/ResourceReservationRoutes'
import VouchersRoutes from './views/Finances/Vouchers/VouchersRoutes'
import RetirementRoutes from './views/Retirements/RetirementRoutes'
import ConfigureTotalCreditRoutes from './views/Examinations/ConfigureTotalCredit/ConfigureTotalCreditRoutes'

import ConfigurationsGradesRoutes from './views/Examinations/Configurations/ConfigurationsGradesRoutes'
import SetupGradesRoutes from './views/Examinations/SetupGrades/SetupGradesRoutes'
import SemesterExaminationRoutes from './views/Examinations/SemesterExams/SemesterExaminationRoutes'
import EnterMarksRoutes from './views/Examinations/EnterMarks/EnterMarksRoutes'
import TimetableRoutes from './views/Timetable/TimetableRoutes'
import BatchWiseAllStudentsResultsRoutes from './views/Examinations/BatchWiseAllStudentsResults/BatchWiseAllStudentsResultsRoutes'
import AdmissionRoutes from './views/Admission/AdmissionRoutes'
import ConvocationsRoutes from './views/Examinations/Convocations/ConvocationsRoutes'
import GraduateListsRoutes from './views/GraduateLists/GraduateListsRoutes'
import LectureDayRoutes from './views/QRBaseAttendance/LectureDayRoutes'
import SubjectDepartmentRegistrationRoutes from './views/CourseManagement/SubjectDepartmentRegistration/SubjectDepartmentRegistrationRoutes'

const redirectRoute = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/dashboard" />,
    },
]

const errorRoute = [
    {
        component: () => <Redirect to="/session/404" />,
    },
]

const routes = [
    ...dashboardRoutes,
    ...AptitudeRoutes,
    ...ExaminationRoutes,
    ...ConvocationsRoutes,
    ...ConfigureTotalCreditRoutes,
    ...TimetableRoutes,
    ...AdmissionRoutes,
    ...GraduateListsRoutes,
    ...ConfigurationsGradesRoutes,
    ...SetupGradesRoutes,
    ...BatchWiseAllStudentsResultsRoutes,
    ...SemesterExaminationRoutes,
    ...EnterMarksRoutes,
    ...TestExamRoutes,
    ...intakeRoutes,
    ...renewalsRoutes,
    ...duplicateIdRoutes,
    ...batchRoutes,
    ...curriculumRoutes,
    ...applicantRoutes,
   
    ...redirectRoute,
    ...dataSetupRoutes,
    ...DegreesAndCoursesRoutes,
    ...AdminStudentProfileRoute,
    ...tempRoutes,
    ...HrRoutes,
    ...MedicalUnitRoutes,
    ...FinanceRoutes,
    ...CourseRoutes,
    ...HRWorkFlowsRoutes,
    ...LeaveRoutes,
    ...StatusRoutes,
    ...DocumentManagementRoutes,
    ...CourseManagementRoutes,
    ...AuditRoutes,
    ...ResearchGrantRoutes,
    ...VapaTVRoutes,
    ...VapaPressRoutes,
    ...RetirementRoutes,
    ...WelfareRoutes,
    ...VehicleRegisterRoutes,
    ...MahapolaRoutes,
    ...JobVacancyRoutes,
    ...ResourceReservationRoutes,
    ...VouchersRoutes,
    ...LectureDayRoutes,
    ...SubjectDepartmentRegistrationRoutes,
    ...errorRoute,
]

export default routes
