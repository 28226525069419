import React from 'react'
import { authRoles } from '../../auth/authRoles'

const VapaTVRoutes = [
    {
        path: '/vapa_tv/program_managemet',
        component: React.lazy(() =>
            import('./ProgramManagement')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_tv/lineup_managemet',
        component: React.lazy(() =>
            import('./LineUpManagementNew')
        ),
        auth: authRoles.dataSetup,
    },
    // {
    //     path: '/vapa_tv/lineup_managemet',
    //     component: React.lazy(() =>
    //         import('./LineUpManagementExternal')
    //     ),
    //     auth: authRoles.dataSetup,
    // },
    /* {
        path: '/vapa_tv/lineup_managemet2',
        component: React.lazy(() =>
            import('./LineUpManagementInitial')
        ),
        auth: authRoles.dataSetup,
    }, */
    {
        path: '/vapa_tv/all_reviews',
        component: React.lazy(() =>
            import('./AllReviews')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_tv/review_programs',
        component: React.lazy(() =>
            import('./ReviewPrograms')
        ),
        auth: authRoles.dataSetup,
    },
   
]

export default VapaTVRoutes
