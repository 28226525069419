import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    MainContainer,
} from 'app/components/LoonsLabComponents'
import { Icon, MenuItem } from '@material-ui/core'
import { MatxMenu } from 'app/components'
import { NotificationImportant } from '@material-ui/icons'

const styles = (theme) => ({})

class Notification extends Component {

    constructor(props) {
        super(props)

        this.state = {
            all_notifications: [
                {
                    text: "ABC",
                    active: true,
                    url: "/document_management/committees/create_meeting/",
                },
                {
                    text: "ED",
                    active: false,
                    url: "",
                },
                {
                    text: "AFD",
                    active: true,
                    url: "",
                }
            ]
        }

    }

    async goToNotification(url) {
        window.location.href = url
    }

    render() {

        let { theme } = this.props
        const { classes } = this.props

        return (
            <MatxMenu
                menuButton={
                    <div className={classes.userMenu}>
                        <NotificationImportant
                            className="cursor-pointer"
                            style={{ color: "#c1c1c1" }}
                        />
                    </div>
                }

            >
                {this.state.all_notifications
                    .filter((item) => item.active)
                    .map((item, index) => (
                        <MenuItem
                            key={index}
                            onClick={()=>this.goToNotification(item.url)}
                            className={classes.menuItem}
                            style={{ width: 500 }}
                        >
                            <Icon> assistant_photo </Icon>
                            <span className="pl-4">{item.text}</span>
                        </MenuItem>
                    ))}
            </MatxMenu>
        )
    }
}

export default withStyles(styles)(Notification)

