import React from 'react'
import { authRoles } from 'app/auth/authRoles'

const VouchersRoutes = [
    {
        path: '/vouchers/all_voucher_requests',
        component: React.lazy(() => import('./AllVouchersRequester')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/EditVoucher/:id',
        component: React.lazy(() => import('./EditVoucher')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/all_voucher_requests_for_cra',
        component: React.lazy(() => import('./AllVouchersForCRA')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/all_voucher_requests_for_fma',
        component: React.lazy(() => import('./AllVouchersRequesterforFMA')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/new_request',
        component: React.lazy(() => import('./NewVoucherRequest')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/vote_number',
        component: React.lazy(() => import('./VoteNumber')),
        //auth: authRoles.vouchers,
        auth: authRoles.vouchers.filter(role => ['Bursar','Assistant Bursar','Senior Assistant Bursar','Deputy Bursar',].includes(role)),
    },
    {
        path: '/vouchers/payee_registration',
        component: React.lazy(() => import('./PayeeRegistration')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/payee_view/:id',
        component: React.lazy(() => import('./PayeeView')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/confirm_recommend_approve/:id',
        component: React.lazy(() => import('./ConfirmRecommendApprove')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/finance_MA/:id',
        component: React.lazy(() => import('./FinanceMA')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/issues_identified_by_MA',
        component: React.lazy(() => import('./IssuesIdentifiedByMAView')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/issues_identified_by_MA_View/:id',
        component: React.lazy(() => import('./IssuesIdentifiedByMA')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/request_edit',
        component: React.lazy(() => import('./RequestEditTableView')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/request_edit_view/:id',
        component: React.lazy(() => import('./RequestEdit')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/confirm_issue_view',
        component: React.lazy(() => import('./ConfirmIssueView')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/confirm_issue/:id',
        component: React.lazy(() => import('./ConfirmIssue')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/resubmit_request',
        component: React.lazy(() => import('./ResubmitRequest')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/resubmit_confirm_recommend_approve',
        component: React.lazy(() => import('./ResubmitConfirmRecommendApprove')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/view_request/:id',
        component: React.lazy(() => import('./ViewRequest')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/all_vouchers_finance',
        component: React.lazy(() => import('./AllVouchersFinance')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/checkedbyFinanceMA',
        component: React.lazy(() => import('./AllVouchersForBookKeeper')),
        //auth: authRoles.vouchers,
        auth: authRoles.vouchers.filter(role => ['Book Keeper'].includes(role)),
    },
    {
        path: '/vouchers/bookkeeper/:id',
        component: React.lazy(() => import('./BookKeeper')),
        //auth: authRoles.vouchers,
        //auth: authRoles.vouchers.filter(role => ['Book Keeper'].includes(role)),
    },
    {
        path: '/vouchers/checkedbyFinanceMAforBursar',
        component: React.lazy(() => import('./AllVouchersForBursar')),
       // auth: authRoles.vouchers,
        auth: authRoles.vouchers.filter(role => ['Bursar','Assistant Bursar','Senior Assistant Bursar','Deputy Bursar',].includes(role)),
    },
    {
        path: '/vouchers/bursarcertify/:id',
        component: React.lazy(() => import('./BursarCertify')),
        //auth: authRoles.vouchers,
        auth: authRoles.vouchers.filter(role => ['Bursar','Assistant Bursar','Senior Assistant Bursar','Deputy Bursar',].includes(role)),
    },
    {
        path: '/vouchers/all_vouchers_finance_view',
        component: React.lazy(() => import('./AllVoucherFinanceView')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/finance_certified',
        component: React.lazy(() => import('./FinanceCertified')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/finance/:id',
        component: React.lazy(() => import('./Finance')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/finance_paid',
        component: React.lazy(() => import('./FinancePaid')),
        auth: authRoles.vouchers,
    },
    {
        path: '/vouchers/finance_paid_view/:id',
        component: React.lazy(() => import('./FinancePaidView')),
        auth: authRoles.vouchers,
    },
]

export default VouchersRoutes