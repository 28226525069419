import React from 'react';
import { authRoles } from '../../auth/authRoles';

const ResourceReservationRoutes = [
    // {
    //     path: '/resource_reservation/all_resoures_table',
    //     component: React.lazy(() =>
    //         import('./AllResourcesTable')
    //     ),
    //     auth: authRoles.JobApplicationView,
    // },
    {
        path: '/resource_reservation/all_resoures',
        component: React.lazy(() =>
        import('./AllResources')
        ),
        // auth: authRoles.dataSetup, //
        // auth: authRoles.JobApplicationView,
        // auth: authRoles.vehicleView,
        auth: authRoles.ResourceReservationViews,

    },
    {
        path: '/resource_reservation/bookings',
        component: React.lazy(() =>
            import('./BookingRequests')
        ),
        // auth: authRoles.dataSetup, //
        // auth: authRoles.JobApplicationView,
        auth: authRoles.ResourceReservationViews,
    },
    {
        path: '/resource_reservation/available',
        component: React.lazy(() =>
            import('./University/AvailableResources')
        ),
        // auth: authRoles.dataSetup,
        // auth: authRoles.DataSetupDocumentSetup,
        auth: authRoles.vehicle,
    },
    {
        path: '/resource_reservation/new_resource',
        component: React.lazy(() =>
            import('./University/CreateResource')
        ),
        // auth: authRoles.dataSetup,
        auth: authRoles.ResourceReservationViews,

    },
    {
        path: '/resource_reservation/all_requests',
        component: React.lazy(() =>
            import('./University/AllRequestsTabHandeling')
        ),
        // auth: authRoles.dataSetup,
        auth: authRoles.vehicle
    },
    {
        path: '/resource_reservation/resource_type',
        component: React.lazy(() =>
            import('./University/ResourceTypeConfig')
        ),
        // auth: authRoles.dataSetup,
        // auth: authRoles.DataSetupDocumentSetup,
        auth: authRoles.vehicle,
    },
    {
        path: '/resource_reservation/view_booking',
        component: React.lazy(() =>
            import('./University/SingleBooking')
        ),
        // auth: authRoles.dataSetup,
        auth: authRoles.vehicle,

    },
    {
        path: '/resource_reservation/single_reservation/:id',
        component: React.lazy(() =>
            import('./University/SingleRequestsTabHandeling')
        ),
        // auth: authRoles.dataSetup,
        auth: authRoles.vehicle,

    },
    {
        path: '/resource_reservation/reserved_reservation/:id',
        component: React.lazy(() =>
            import('./ReservedResourceTabHandeling')
        ),
        // auth: authRoles.dataSetup,
        auth: authRoles.vehicle,

    },
]

export default ResourceReservationRoutes;