import React from 'react'
import { authRoles } from '../../auth/authRoles'

const ExaminationRoutes = [

    {
        path: '/examinations/all',
        component: React.lazy(() => import('./AllExaminations')),
        auth: authRoles.ExaminationView,
    },

]

export default ExaminationRoutes
