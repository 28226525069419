import React from 'react'
import { authRoles } from '../../auth/authRoles'

const HrRoutes = [
    {
        path: '/hr/designation/:id',
        component: React.lazy(() => import('./Designation/SingleView')),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/create_circular',
        component: React.lazy(() => import('./DataSetup/CreateCircular')),
        auth: authRoles.dashboard,
    },

    {
        path: '/hr/confirmation',
        component: React.lazy(() => import('./DataSetup/Confirmation')),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/leave_setup',
        component: React.lazy(() => import('./DataSetup/LeaveSetup')),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/leave',
        component: React.lazy(() => import('./DataSetup/Leave')),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/all_designations',
        component: React.lazy(() => import('./DataSetup/AllDesignations')),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/add_circular_type',
        component: React.lazy(() => import('./DataSetup/AddCircularType')),
        auth: authRoles.dashboard,
    },
    // Paths Added by shammika -------------
    {
        path: '/hod/request_recruitment/:id',
        component: React.lazy(() =>
            import('./Recruitment/HOD_RequestRecruitment')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hod/create_special_notice',
        component: React.lazy(() =>
            import('./Recruitment/HOD_CreateNoticeForRecruitment')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/ar/view_special_notice',
        component: React.lazy(() =>
            import('./Recruitment/AR_ViewCreatedNoticeForRecruitment')
        ),
        auth: authRoles.dataSetup,
    },

    {
        path: '/applicant/view_vacancies',
        component: React.lazy(() => import('./Recruitment/ViewVacancies')),
        auth: authRoles.dashboard,
    },

    {
        path: '/hod/send_appointment_letters',
        component: React.lazy(() =>
            import(
                './Recruitment/PracticalExamination/HOD_SendAppointLettersof_PE'
            )
        ),
        auth: authRoles.dashboard,
    },

    {
        path: '/hod/requesting_practical_examination',
        component: React.lazy(() =>
            import(
                './Recruitment/PracticalExamination/HOD_RequestPracticalExamination'
            )
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hod/view_panel_member_marks',
        component: React.lazy(() =>
            import(
                './Recruitment/PracticalExamination/HOD_ViewPanelMembersMarks'
            )
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/vc/appoint_practical_examination_panel',
        component: React.lazy(() =>
            import(
                './Recruitment/PracticalExamination/VC_AppointPracticalExaminationPanel'
            )
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hod/set_basic_details_of_practical_examination',
        component: React.lazy(() =>
            import(
                './Recruitment/PracticalExamination/HOD_SetBasicDetailsofPracticalExamination'
            )
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hod/send_practical_examination_details_to_candicate',
        component: React.lazy(() =>
            import(
                './Recruitment/PracticalExamination/HOD_SendPracticalExamDetailsToCandicate'
            )
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/panel_member/view_appointments',
        component: React.lazy(() =>
            import('./Recruitment/PracticalExamination/PM_ViewAllAppointments')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/panel_member/accept_appointments',
        component: React.lazy(() =>
            import(
                './Recruitment/PracticalExamination/PM_AcceptanceofAppointment'
            )
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/panel_member/view_appointment',
        component: React.lazy(() =>
            import(
                './Recruitment/PracticalExamination/PM_ViewSingleAppointmentDetails'
            )
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/panel_member/view_applicants',
        component: React.lazy(() =>
            import('./Recruitment/PracticalExamination/PM_ViewApplicants')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/panel_member/view_marks',
        component: React.lazy(() =>
            import('./Recruitment/PracticalExamination/PM_ViewMarkSheet')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/panel_member/view_payment_details',
        component: React.lazy(() =>
            import('./Recruitment/PracticalExamination/PM_ViewPaymentDetails')
        ),
        auth: authRoles.dashboard,
    },

    // Recruitment --General
    {
        path: '/ar/view_all_recruitment_details',
        component: React.lazy(() =>
            import('./Recruitment/General/AR_ViewAllRecruitmentDetails')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/ar/view_single_recruitment_details',
        component: React.lazy(() =>
            import('./Recruitment/General/AR_ViewSingleRecruitmentDetails')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/ar/view_duty_acceptance_status',
        component: React.lazy(() =>
            import('./Recruitment/General/AR_ViewDutyAcceptanceStatus')
        ),
        auth: authRoles.dashboard,
    },

    // Written Examination
    {
        path: '/ar/request_written_examination',
        component: React.lazy(() =>
            import(
                './Recruitment/WrittenExamination/AR_RequestWrittenExamination'
            )
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/vc/appoint_written_examination_staff',
        component: React.lazy(() =>
            import(
                './Recruitment/WrittenExamination/VC_AppointWrittenExamStaff'
            )
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/ar_ed/view_all_exam_requests',
        component: React.lazy(() =>
            import('./Recruitment/WrittenExamination/AR_ED_ViewAllExamRequests')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/ar_ed/set_basic_details_of_written_examination',
        component: React.lazy(() =>
            import(
                './Recruitment/WrittenExamination/AR_ED_SetBasicDetailsofWrittenExamination'
            )
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/ar_ed/send_appointment_letters',
        component: React.lazy(() =>
            import(
                './Recruitment/WrittenExamination/AR_ED_SendAppointLettersof_WE'
            )
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/ar_ed/send_written_examination_admissions',
        component: React.lazy(() =>
            import(
                './Recruitment/WrittenExamination/AR_ED_SendWrittenExamAdmissionToApplicants'
            )
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/ar_ed/view_all_recruitments_examinations',
        component: React.lazy(() =>
            import(
                './Recruitment/WrittenExamination/AR_ED_ViewAllRecruitmentExaminations'
            )
        ),
        auth: authRoles.dashboard,
    },

    // Paper Setup
    {
        path: '/ps/create_paper',
        component: React.lazy(() =>
            import('./Recruitment/WrittenExamination/PS_CreatePaper')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/ps/create_marking_scheme',
        component: React.lazy(() =>
            import('./Recruitment/WrittenExamination/PS_CreateMarkingScheme')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/ar_ed/add-cut_off_marks',
        component: React.lazy(() =>
            import('./Recruitment/WrittenExamination/AR_ED_AddCutOffMarks')
        ),
        auth: authRoles.dashboard,
    },

    // InterView

    {
        path: '/ar/request_interview',
        component: React.lazy(() =>
            import('./Recruitment/Interview/AR_RequestInterview')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/vc/appoint_interview_panel',
        component: React.lazy(() =>
            import('./Recruitment/Interview/VC_AppointInterviewPanel')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/ar/set_basic_details_of_interview',
        component: React.lazy(() =>
            import('./Recruitment/Interview/AR_SetBasicDetailsofInterview')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/ar/send_appointment_letters',
        component: React.lazy(() =>
            import('./Recruitment/Interview/AR_SendAppointLettersof_Interview')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/ar/send_interview_details',
        component: React.lazy(() =>
            import('./Recruitment/Interview/AR_SendInterviewDetailsToApplicant')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/ar/view_individuals_marks',
        component: React.lazy(() =>
            import('./Recruitment/Interview/AR_ViewIndividualsMarks')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/interview_panel_member/accept_appointments',
        component: React.lazy(() =>
            import('./Recruitment/Interview/PM_AcceptanceofAppointment')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/interview_panel_member/view_appointment',
        component: React.lazy(() =>
            import('./Recruitment/Interview/PM_ViewBasicDetailsofInterview')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/panel_member/view_my_applicants',
        component: React.lazy(() =>
            import('./Recruitment/Interview/PM_ViewApplicants')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/panel_head/view_panel_marks',
        component: React.lazy(() =>
            import('./Recruitment/Interview/HOIP_ViewPanelMembersMarks')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/panel_head/select_applicants',
        component: React.lazy(() =>
            import('./Recruitment/Interview/HOIP_SelectApplicants')
        ),
        auth: authRoles.dashboard,
    },

    //  ------------------------------------
    {
        path: '/hr/recruitments/temporary_employee_profile',
        component: React.lazy(() =>
            import('./Designation/Recruitment/TemporaryEmployeeProfile')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/recruitments/select_vacancies',
        component: React.lazy(() =>
            import('./Designation/Recruitment/SelectVacancies')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/recruitments/basic_information',
        component: React.lazy(() =>
            import('./Designation/Recruitment/BasicInformation')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/recruitments/graduate_details',
        component: React.lazy(() =>
            import('./Designation/Recruitment/GraduateDetails')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/recruitments/post_graduate_details',
        component: React.lazy(() =>
            import('./Designation/Recruitment/PostGraduateDetails')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/recruitments/professional_qualification',
        component: React.lazy(() =>
            import('./Designation/Recruitment/ProfessionalQualification')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/recruitments/working_experience',
        component: React.lazy(() =>
            import('./Designation/Recruitment/WorkingExperience')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/recruitments/creative_works',
        component: React.lazy(() =>
            import('./Designation/Recruitment/CreativeWorks')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/recruitments/research_and_publication',
        component: React.lazy(() =>
            import('./Designation/Recruitment/ResearchAndPublication')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/recruitments/awards_and_scholarships',
        component: React.lazy(() =>
            import('./Designation/Recruitment/AwardsAndScholarships')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/recruitments/submission',
        component: React.lazy(() =>
            import('./Designation/Recruitment/Submission')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/recruitments/recruitment_application',
        component: React.lazy(() =>
            import('./Designation/Recruitment/RecruitmentApplication')
        ),
        auth: authRoles.JobApplicationView,
    },
    {
        path: '/hr/recruitments/my_applications',
        component: React.lazy(() =>
            import('./Designation/Recruitment/MyApplications')
        ),
        auth: authRoles.JobApplicationView,
    },
    {
        path: '/hr/carders/',
        component: React.lazy(() => import('./Carders/Carders')),
        auth: authRoles.dashboard,
    },

    {
        path: '/hr/add_new_cardre',
        component: React.lazy(() => import('./Carders/AddNewCardre')),
        auth: authRoles.dashboard,
    },

    {
        path: '/hr/applicant/duty_acceptance',
        component: React.lazy(() =>
            import('./TempProfile/applicantDutyAcceptance')
        ),
        auth: authRoles.dashboard,
    },

    {
        path: '/ar/view_single_recruitment/:id',
        component: React.lazy(() =>
            import('./Recruitment/General/AR_ViewSingleRecruitmentDetails')
        ),
        auth: authRoles.dashboard,
    },

    {
        path: '/hr/add_external_experts',
        component: React.lazy(() => import('./Recruitment/AddExternalExperts')),
        auth: authRoles.dashboard,
    },

    // New Routes Added by shammika - 14/11/2022 add_service_type

    {
        path: '/hr/add_salary_code',
        component: React.lazy(() => import('./DataSetup/AddSalaryCode')),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/add_service_type',
        component: React.lazy(() => import('./DataSetup/AddServiceType')),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/setup_hierarchy',
        component: React.lazy(() => import('./Hierarchy/SetupHierarchy')),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/all_leaves',
        component: React.lazy(() => import('./DataSetup/AllLeaves')),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/all_employees',
        component: React.lazy(() => import('./Employees/Employees')),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/designation_category',
        component: React.lazy(() =>
            import('./DataSetup/AddDesignationCategory')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/employee_create/:employee_type',
        component: React.lazy(() => import('./Employees/CreateEmployee')),
        auth: authRoles.dashboard,
    },
    {
        path: '/promotion/all-promotion-requests',
        component: React.lazy(() =>
            import('./Designation/Promotion/AllPromotionRequests')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/promotion/promotion-requests-approvals',
        component: React.lazy(() =>
            import('./Designation/Promotion/PromotionApproval')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/promotion/promotion-approvals-finance',
        component: React.lazy(() =>
            import('./Designation/Promotion/PromotionFinanceView')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/increments/increment-approvals-finance',
        component: React.lazy(() =>
            import('./Increments/IncrementFinanceView')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/generate-reports/employee-report',
        component: React.lazy(() =>
            import('./GenerateReports/GenerateReports')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/employee_update/:id',
        component: React.lazy(() => import('./Employees/CreateEmployee')),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/increments/eligible-list',
        component: React.lazy(() =>
            import('./Increments/IncrementEligibleList')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/increments/eligible-approval-requests',
        component: React.lazy(() =>
            import('./Increments/IncrementRequestApprovals')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/increments/confirmed_list',
        component: React.lazy(() =>
            import('./Increments/ConfirmedList')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/increments/recommendation-list',
        component: React.lazy(() =>
            import('./Increments/IncrementRecommendationList')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/increments/create-forms',
        component: React.lazy(() =>
            import('./Increments/Academic/CreateIncrementForms')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/employee-transfers',
        component: React.lazy(() => import('./Transferes/EmployeeTransfers')),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/employee/attendance',
        component: React.lazy(() => import('./Attendance/AllAttendance')),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/employee/alljobapplications',
        component: React.lazy(() => import('./Recruitment/AllApplications/AllApplications')),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/employee/view-single-job-application/:id',
        component: React.lazy(() => import('./Recruitment/AllApplications/ViewSingleApplication/ViewSingleApplication')),
        auth: authRoles.dashboard,
    },
    {
        path: '/hr/employee/view-my-single-job-application/:id',
        component: React.lazy(() => import('./Recruitment/AllApplications/ViewSingleApplication/ViewSingleApplication')),
        auth: authRoles.JobApplicationView,
    },
]

export default HrRoutes
