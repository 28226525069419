import React from 'react'
import { authRoles } from '../../auth/authRoles'

const AptitudeRoutes = [
    {
        path: '/aptitutetest/all',
        component: React.lazy(() => import('./AllTest')),
        auth: authRoles.aptitudeTestView,
    },
    // {
    //     path: '/aptitutetest/create',
    //     component: React.lazy(() => import('./Create')),
    //     auth: authRoles.aptituteTestCreate,
    // },
    {
        path: '/aptitude_test/applicant/form',
        component: React.lazy(() =>
            import('./ApplicantAptitudeTestApplication')
        ),
        auth: authRoles.AptitudeStudent,
    },
    {
        path: '/aptitude_test/applicant/view',
        component: React.lazy(() => import('./ViewApplicantAptitudeTest')),
        auth: authRoles.AptitudeStudent,
    },
    {
        path: '/examination/aptitude_tests/:id',
        component: React.lazy(() => import('./examination/ViewAptitudeTest')),
        auth: authRoles.AptitudeStudent,
    },
    {
        path: '/examination/aptitude_test_paper/:id',
        component: React.lazy(() => import('./examination/CreateTestPaper')),
        auth: authRoles.examSetUpView,
    },
    {
        path: '/aptitude_test/appeal/appeals',
        component: React.lazy(() => import('./Appeal/Appeals')),
        auth: authRoles.AptitudeStudent,
    },
    {
        path: '/aptitude_test/appeal/appeal_details/:id',
        component: React.lazy(() => import('./Appeal/AppealDetails')),
        auth: authRoles.appealsView,
    },
    {
        path: '/aptitude_test/pass_list',
        component: React.lazy(() => import('./PassList')),
        auth: authRoles.passListView,
    },
    {
        path: '/aptitude_test/exam_basic_info',
        component: React.lazy(() => import('./examination/ExamBasicInfo')),
        auth: authRoles.basicInfoView,
    },
    {
        path: '/aptitude_test/exam_pass_list',
        component: React.lazy(() => import('./ExamPassList')),
        auth: authRoles.passListView,
    },
    {
        path: '/aptitude_test/answer_single_view_dean',
        component: React.lazy(() => import('./AnswerSingleViewDean')),
        auth: authRoles.aptituteTestCreate,
    },
    // Submit answer by student
    {
        path: '/aptitude_test/submit_answer_by_student/:id',
        component: React.lazy(() => import('./SubmitAnswerByStudent')),
        auth: authRoles.AptitudeStudent,
    },
    {
        path: '/faculty/aptitude_test/received_answers',
        component: React.lazy(() => import('./ReceivedAnswersFaculty')),
        auth: authRoles.assignPapersQuestionsView,
    },
    // Add Marks view
    {
        path: '/aptitude_test/answer/:id',
        component: React.lazy(() => import('./SingleAnswerSheet')),
        auth: authRoles.assignPapersQuestionsView,
    },
    {
        path: '/faculty/aptitude_test/answer/:id',
        component: React.lazy(() => import('./SingleAnswerSheetFaculty')),
        auth: authRoles.assignPapersQuestionsView,
    },
    {
        path: '/aptitude_test/all_examiners',
        component: React.lazy(() => import('./AllExaminers')),
        auth: authRoles.ExaminationView,
    },
    {
        path: '/aptitude_test/all_answers',
        component: React.lazy(() => import('./AllAnswersSummary')),
        auth: authRoles.allAnswerSummaryView,
    },
    {
        path: '/aptitude_test/all_interview_list',
        component: React.lazy(() => import('./AllInterviewList')),
        auth: authRoles.assignInterviewsView,
    },
    {
        path: '/aptitude_test/vc_appeals',
        component: React.lazy(() => import('./VCAppeals')),
        auth: authRoles.studentAffairsStatusView,
    },
    {
        path: '/aptitude_test/results',
        component: React.lazy(() => import('./Results')),
        auth: authRoles.studentAffairsStatusView,
    },

    {
        path: '/aptitude_test/faculty/createExaminerPanel',
        component: React.lazy(() =>
            import('./CreateExaminerPanel/Faculty_CreateExaminerPanel')
        ),
        auth: authRoles.maviewIntake,
    },
    {
        path: '/aptitude_test/faculty/assignPapers',
        component: React.lazy(() =>
            import('./Faculty_AssignPapersQuesToPanel')
        ),
        auth: authRoles.aptituteTestCreate,
    },
    {
        path: '/aptitude_test/faculty/createAptitudeExaminerList',
        component: React.lazy(() =>
            import('./Faculty_CreateAptitudeExaminersList')
        ),
        auth: authRoles.examSetUpView,
    },
    {
        path: '/aptitude_test/faculty/scheduleinterviews',
        component: React.lazy(() =>
            import('./InterviewsComponents/Faculty_ScheduleInterviews')
        ),
        auth: authRoles.examSetUpView,
    },

    {
        path: '/aptitude_test/faculty_Board/viewExaminerList',
        component: React.lazy(() => import('./FacultyBoard_ViewExaminerList')),
        auth: authRoles.examinerListView,
    },
    {
        path: '/aptitude_test//senate/approveExaminerList',
        component: React.lazy(() => import('./Senate_ApproveExaminerList')),
        auth: authRoles.examinerListView,
    },
    {
        path: '/aptitude_test/admissions/single/:id',
        component: React.lazy(() => import('./examination/ViewSingleAdmission')),
        auth: authRoles.dashboard,
    },
    {
        path: '/aptitude_test/advertisement_approval/:id',
        component: React.lazy(() => import('./Advertisement/AdvertisementApproval')),
        auth: authRoles.dashboard,
    },
    {
        path: '/aptitude_test/faculty_Board/ApproveExaminerList/:id',
        component: React.lazy(() => import('./FacultyBoard_ViewExaminerList')),
        auth: authRoles.dashboard,
    },
    {
        path: '/aptitude_test/applicant/examlinks',
        component: React.lazy(() => import('./ExamLinksApplicantViewAptitudeTest')),
        auth: authRoles.dashboard,
    },
    {
        path: '/aptitude_test/employee_singleview/:id',
        component: React.lazy(() => import('./ViewEmployee')),
        auth: authRoles.dashboard,
    },


]

export default AptitudeRoutes
