/*
Loons Lab Sub title component
Developed By Roshan
Loons Lab
*/
import React, { Fragment, useState, Component } from "react";
import {
    Divider, Typography, Grid, Card, Dialog,

} from '@material-ui/core'
import ImageView from 'app/components/LoonsLabComponents/ImageView'
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types";
import FilesService from "app/services/FilesService";
import * as apiroutes from "../../../../apiroutes";
import localStorageService from "app/services/localStorageService";
import CircularProgress from '@material-ui/core/CircularProgress'
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';


import pdf from '../DocumentLoader/pdf.png';
import doc from '../DocumentLoader/doc.png';
import jpg from '../DocumentLoader/jpg.png';
import png from '../DocumentLoader/png.png';
import ppt from '../DocumentLoader/ppt.png';
import other from '../DocumentLoader/other.png';
import audio from '../DocumentLoader/audio.png';
import video from '../DocumentLoader/video.png';
import wav from '../DocumentLoader/wav.png';


// Custome Tooltip
const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

class DocumentLoader extends Component {

    constructor(props) {
        super(props)


        this.state = {

            loading: false

        }
    }
    static propTypes = {
        files: PropTypes.array,
        short_name: PropTypes.bool,
        show_name: PropTypes.bool,
        name_type: PropTypes.string,
        slice_count: PropTypes.number,
        width: PropTypes.number,
    };

    static defaultProps = {
        files: [],
        short_name: false,
        show_name: true,
        name_type: "Original Name",
        slice_count: 25,

    };


    async clickImage(url, extention) {

        this.setState({
            loading: true
        })
        console.log("Started")

        const accessToken = await localStorageService.getItem("accessToken");
        let uri = apiroutes.FILE_VIEW + `?url=` + url + `&type=view&extension=` + extention + ``;
        console.log("file url", uri)
        fetch(uri, { headers: { Authorization: `Bearer ${accessToken}` } }) // FETCH BLOB FROM IT
            .then((response) => response.blob())
            .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
                console.log("Finish")
                this.setState({
                    loading: false
                })
                var _url = window.URL.createObjectURL(blob);
                window.open(_url, "_blank").focus();

                // window.open + focus
            }).catch((err) => {
                console.log(err);
            });


    }

    getFileimage(extension) {
        if (extension == "image/png") {
            return png;
        } else if (extension == "image/jpg" || extension == "image/jpeg") {
            return jpg;
        } else if (extension == "application/pdf") {
            return pdf;
        } else if (extension == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || extension == "application/msword") {
            return doc;
        } else if (extension == "application/vnd.openxmlformats-officedocument.presentationml.presentation" || extension == "application/vnd.ms-powerpoint") {
            return ppt;
        } else if (extension == "audio/mpeg") {
            return audio;
        } else if (extension == "audio/wav") {
            return wav;
        }
        else if (extension == "video/x-matroska" || extension == "video/mp4 ") {
            return video;
        }
        else {
            return other;
        }
    }


    getFileExtention(extension) {
        console.log("Extension ", extension)

        if (extension == "image/png") {
            return "png";
        } else if (extension == "image/jpg" || extension == "image/jpeg") {
            return "jpg";
        } else if (extension == "application/pdf") {
            return "pdf";
        } else if (extension == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || extension == "application/msword") {
            return "doc";
        } else if (extension == "application/vnd.openxmlformats-officedocument.presentationml.presentation" || extension == "application/vnd.ms-powerpoint") {
            return "ppt";
        } else if (extension == "audio/mpeg") {
            return "audio";
        } else if (extension == "video/x-matroska" || extension == "video/mp4 ") {
            return "video";
        } else if (extension == "audio/wav") {
            return "wav";
        } else {
            return "other";
        }
    }

    render() {
        const {
            files,
            short_name,
            show_name,
            name_type,
            slice_count,
            width
        } = this.props;


        return (
            <Fragment>
                <Grid container spacing={1} >
                    {
                        files.map((item, i) =>
                            <HtmlTooltip
                                title={
                                    <React.Fragment>
                                        {item.extension === "image/png" || item.extension === "image/jpg" || item.extension === "image/jpeg" ?
                                            <ImageView image_data={{ path: item.url, extension: item.extension }} preview_width={width ? width : 300} />
                                            : <span>No Preview Available</span>}

                                    </React.Fragment>
                                }
                            >

                                <Grid item key={i}>


                                    <Grid className="image_hover flex p-1" style={{ width: 'max-content' }} onClick={() => { this.clickImage(item.url, item.extension) }}>
                                        <img style={{ height: 40 }} src={this.getFileimage(item.extension)} alt="file" />


                                        {show_name ?
                                            <p className="pl-2"  >
                                                {
                                                    short_name ?
                                                        item.filename.length > slice_count ?
                                                            name_type == "Original Name" ?
                                                                item.filename.slice(0, slice_count) + '..' + this.getFileExtention(item.extension)
                                                                :
                                                                item.filename.slice(0, slice_count) + '..'
                                                            : item.filename
                                                        : item.filename
                                                }
                                            </p>
                                            :
                                            null
                                        }
                                    </Grid>
                                </Grid>
                            </HtmlTooltip>
                        )

                    }
                </Grid>



                <Dialog fullWidth maxWidth="sm"
                    open={this.state.loading}>

                    <DialogContent>
                        <Grid container spacing={2} className="justify-center text-center w-full py-6">
                            <Grid item xs={12}>
                                <Typography variant="h6">Please Wait..Your File is Downloading...</Typography>
                            </Grid>
                            <Grid item xs={12} className="py-4">
                                <CircularProgress size={30} />
                            </Grid>

                        </Grid>
                    </DialogContent>
                </Dialog>

            </Fragment >
        );
    }
}

export default DocumentLoader;