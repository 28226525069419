import React from 'react'
import { authRoles } from '../../auth/authRoles'

const applicantRoutes = [
    {
        path: '/applicant/account/setupPwd',
        component: React.lazy(() => import('./Applicant_CreateAccount')),
    },

    {
        path: '/applicant/account/temp/create',
        component: React.lazy(() => import('./ApplicantTempAccount')),
        auth: authRoles.AptitudeStudent,
    },
    {
        path: '/applicant/account/temp/view',
        component: React.lazy(() => import('./ViewApplicantTempAccount')),
        auth: authRoles.AptitudeStudent,
    },
    {
        path: '/applicant/account/temp/full_view',
        component: React.lazy(() => import('./APTFullApplication')),
        auth: authRoles.AptitudeStudent,
    },
    // {
    //     path: '/applicant/account/reset',
    //     component: React.lazy(() => import('')),
    // },
    {
        path: '/aptitude_test/applicant/paymentinfo',
        component: React.lazy(() => import('./ViewPaymentInfo')),
        auth: authRoles.dashboard,
    },

]

export default applicantRoutes
