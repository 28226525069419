import React from 'react'
import { authRoles } from '../../auth/authRoles'
import { merge } from 'lodash'

const dataSetupRoutes = [
    {
        path: '/faculty/create',
        component: React.lazy(() => import('./Faculty/CreateFaculty')),
        auth: authRoles.maviewIntake,
    },
    {
        path: '/academicYears/create',
        component: React.lazy(() => import('./AcademicYear/AcademicYearSetup')),
        auth: authRoles.maviewIntake,
    },

    {
        path: '/degree/create',
        component: React.lazy(() => import('./Degree/CreateDegree')),
        auth: authRoles.maviewIntake,
    },
    {
        path: '/course/create',
        component: React.lazy(() => import('./Course/CreateCourse')),
        auth: authRoles.maviewIntake,
    },
    {
        path: '/subject/create',
        component: React.lazy(() => import('./subject/CreateSubject')),
        auth: authRoles.maviewIntake,
    },

    {
        path: '/Documents/Student',
        component: React.lazy(() =>
            import('./Documents/SetupStudentDocuments')
        ),
        auth: authRoles.SetUpUndergrdRegDocuments,
    },
    {
        path: '/AptitdeAppeal/type',
        component: React.lazy(() =>
            import('./AptitudeAppeal/AptitudeAppealType')
        ),
        auth: authRoles.maviewIntake,
    },
    {
        path: '/AptitdeAppeal/category',
        component: React.lazy(() =>
            import('./AptitudeAppeal/AptitudeAppealCategory')
        ),
        auth: authRoles.maviewIntake,
    },

    // {
    //     path: '/AptitdeTest/createExaminerPanel',
    //     component: React.lazy(() =>
    //         import('../Faculty/Faculty_CreateExaminerPanel')
    //     ),
    //     auth: authRoles.maviewIntake,
    // },
    {
        path: '/DegreeProgram/create',
        component: React.lazy(() =>
            import('./DegreeProgram/CreateDegreeProgram')
        ),
        auth: authRoles.maviewIntake,
    },
    {
        path: '/faculty_department/faculty/create',
        component: React.lazy(() =>
            import('./FacultyDepartment/Faculty/CreateFaculty')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/faculty_department/faculty/single/view/:id',
        component: React.lazy(() =>
            import('./FacultyDepartment/Faculty/SingleFaculty')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/faculty_department/department/create',
        component: React.lazy(() =>
            import('./FacultyDepartment/Department/CreateDepartment')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/faculty_department/department/view',
        component: React.lazy(() =>
            import('./FacultyDepartment/Department/ViewDepartment')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/faculty_department/department/single/view/:id',
        component: React.lazy(() =>
            import('./FacultyDepartment/Department/SingleDepartment')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/faculty_department/unit/create',
        component: React.lazy(() =>
            import('./FacultyDepartment/Unit/CreateUnit')
        ),
        auth: authRoles.humanResoursesViews,
    },
    {
        path: '/faculty_department/unit/single/view/:id',
        component: React.lazy(() =>
            import('./FacultyDepartment/Unit/SingleUnit')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/division/create',
        component: React.lazy(() =>
            import('./Divisions/CreateDivision')
        ),
        auth: authRoles.humanResoursesViews,
    },
]

export default dataSetupRoutes
