import axios from 'axios'
import * as apiroutes from '../../apiroutes'
import localStorageService from './localStorageService'

class EmployeeService {

    createTempEmployeePostGraduateDetails = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_TEMP_PSG_DETAILS, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "multipart/form-data"
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    createTempEmployeeProfessionalQualifications = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_TEMP_PROFESSIONAL_QUALIFICATIONS, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "multipart/form-data"
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    updateTempEmployeeProfessionalQualifications = async (id,data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.CREATE_TEMP_PROFESSIONAL_QUALIFICATIONS + "/" + id, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "multipart/form-data"
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    createTempEmployeeWorkingExperience = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_TEMP_WORKING_EXPERIENCE, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "multipart/form-data"
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    createTempEmployeesCreativeWorks = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_TEMP_CREATIVE_WORKS, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "multipart/form-data"
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    updateTempEmployeesCreativeWorks = async (id,data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.CREATE_TEMP_CREATIVE_WORKS + "/" + id, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "multipart/form-data"
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    createResearchAndPublicationData = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_TEMP_RESEARCH_PUBLICATIONS, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "multipart/form-data"
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    updateResearchAndPublicationData = async (id,data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.CREATE_TEMP_RESEARCH_PUBLICATIONS + '/' +id, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "multipart/form-data"
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    createAwardsOrScholarships = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_TEMP_AWARD_SCHOLARSHIP, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "multipart/form-data"
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    updateAwardsOrScholarships = async (id,data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.CREATE_TEMP_AWARD_SCHOLARSHIP + "/" + id, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "multipart/form-data"
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    createTempEmployeeGraduateDetails = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_TEMP_EMPLOYEE_GRADUATE_DETAILS, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    createTempEmployeeNewDegreeType = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_TEMP_EMPLOYEE_NEW_DEGREE_TYPE, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    updateBasicInformation = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.UPDATE_BASIC_INFORMATION + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    updateEmployeeStatus = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .put(apiroutes.UPDATE_EMPLOYEE_STATUS + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    getAllEmployees = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_ALL_EMPLOYEES, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    getAllTempEmployees = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_TEMP_EMPLOYEE, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    getAllVacancies = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_ALL_VACANCIES, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    getSelectedVacanciesById = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_ALL_VACANCIES_BY_ID, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    getTempEmployeesById = async (id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_TEMP_EMPLOYEE + "/" + id, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    getTempEmployeesPSGDetailsById = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_TEMP_EMPLOYEE_PSG, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    getTempEmployeesGraduateDetailsById = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_TEMP_EMPLOYEE_GRADUATE_DETAILS, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    getPSGTypes = async () => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_PSG_TYPES, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    getTempEmployeesProfessionalQualificationsById = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_TEMP_PROFESSIONAL_QUALIFICATIONS, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    getTempEmployeeWorkingExperienceById = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_TEMP_WORKING_EXPERIENCE, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    getTempEmployeesCreativeWorksById = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_TEMP_CREATIVE_WORKS, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    getResearchAndPublicationDataByID = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_TEMP_RESEARCH_PUBLICATIONS, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    getAwardsOrScholarshipsByID = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_TEMP_AWARD_SCHOLARSHIP, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    createEmployee = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_EMPLOYEE, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        // "Content-Type": "multipart/form-data"
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }


    getSingleEmployeeById = async (id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_SINGLE_EMPLOYEE + id, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    AddEmployeeQualifications = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.CREATE_EMPLOYEE_EDUCATIONAL_QUALIFICATIONS, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }


    GetEmployeeQualification = async (id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.CREATE_EMPLOYEE_EDUCATIONAL_QUALIFICATIONS + "/" + id, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    AddEmployeePastWorks = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.CREATE_EMPLOYEE_PAST_WORK_EXPERIENCE, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    updateEmployee = async (id, data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.UPDATE_EMPLOYEE + id, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        // "Content-Type": "multipart/form-data"
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    updateEmployeeQualifications = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.UPDATE_EMPLOYEE_EDUCATIONAL_QUALIFICATIONS + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    updateEmployeePastWorks = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.UPDATE_EMPLOYEE_PAST_WORK_EXPERIENCE + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }


    EmployeeBulkUpload = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.EMPLOYEE_BULK_UPLOAD, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    getmployeeQualifications = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.CREATE_EMPLOYEE_EDUCATIONAL_QUALIFICATIONS, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }


    deleteEmployeeQualification = async (id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .delete(apiroutes.CREATE_EMPLOYEE_EDUCATIONAL_QUALIFICATIONS + "/" + id, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    deleteEmployeeQualificationfile = async (id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .delete(apiroutes.DELETE_QULAIFICATION_FILE_UPLOAD + "/" + id, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }


    deleteEmployeePastWorks = async (id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .delete(apiroutes.UPDATE_EMPLOYEE_PAST_WORK_EXPERIENCE + id, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    addSingleEmployeePastWork = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.UPDATE_EMPLOYEE_PAST_WORK_EXPERIENCE, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    getEmployeePastWorks = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.CREATE_EMPLOYEE_PAST_WORK_EXPERIENCE, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }
    EmployeeUploads = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.EMPLOYEE_UPLOADS, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }
    EmployeeUploadsUpadte = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.EMPLOYEE_UPLOADS + "/" + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }
    EmployeeUploadsDelete = async (id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .delete(apiroutes.EMPLOYEE_UPLOADS + "/" + id, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }
    GetEmployeeUploads = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.EMPLOYEE_UPLOADS, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }
    CreateEmployeeResignation = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.CREATE_EMPLOYEE_RESIGNATION, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    UpdateEmployeeResignation = async (id ,data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.CREATE_EMPLOYEE_RESIGNATION + "/" + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    getAllResignationRequests = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_ALL_RESIGNATION_REQUESTS, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    setupRoles = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.SETUP_ROLES, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    createBasicInformation = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.CREATE_TEMP_EMP_BASIC_INFORMATION, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    updateTempEmployeeGraduateDetails = async (id, data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.CREATE_TEMP_EMPLOYEE_GRADUATE_DETAILS + "/" + id, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    createRefree = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.CREATE_TEMP_EMP_REFREES, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    getRefrees = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.CREATE_TEMP_EMP_REFREES, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }
    updatedRefree = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.CREATE_TEMP_EMP_REFREES + '/' + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }
    tempEmployeeUploads = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.CREATE_TEMP_EMP_UPLOADS, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    getTempEmployeeUploads = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.CREATE_TEMP_EMP_UPLOADS, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    deleteEmployeeUploads = async (id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .delete(apiroutes.CREATE_TEMP_EMP_UPLOADS + '/' + id, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }
    updateTempEmployeeWorkingExperience = async (id, data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.CREATE_TEMP_WORKING_EXPERIENCE + '/' + id, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "multipart/form-data"
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    getAllTempEmployees = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_TEMP_EMPLOYEE, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    //Function to create new employee retirement record
    createEmployeeRetirement = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.CREATE_RETIREMENT, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    //Function to get all employee retirement record
    getAllEmployeeRetirements = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.CREATE_RETIREMENT, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    //Function to update employee retirement record
    updateEmployeeRetirement = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.CREATE_RETIREMENT + '/' + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    updateApplicationStatus = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .put(apiroutes.GET_ALL_VACANCIES_BY_ID + "/status/" + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

}
export default new EmployeeService()
