import axios from 'axios';
import * as apiroutes from '../../apiroutes';
import localStorageService from "./localStorageService";


class StudentService {

    getAllStudents = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_ALL_STUDENTS,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getAllStudentsReport = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.GET_ALL_STUDENTS + "reports/download", {}, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    getStudentByStudentID = async (id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_SINGLE_STUDENT + `${id}`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }


    getPostgraduateStudentByStudentID = async (id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_SINGLE_POSTGRADUATE_STUDENT + `${id}`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                    return resolve(error);
                })
        });
        return await a;
    }
    /**
     * @param {{
     *  postgraduate_applicant_id : number
     * }} data 
     */
    getPostgraduateReviewers = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.POSTGRADUATE_APPLICATION_REVIEWERS, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                    return resolve(error);
                })
        });
        return await a;
    }
    /**
     * @param {number} reviewer_id 
     * @param {{
     *  marks: number,
     *  notes: string,
     *  status: string
     * }} data 
     */
    updatePostgraduateReviewer = async (reviewer_id, data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.POSTGRADUATE_APPLICATION_REVIEWERS + reviewer_id, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                    return resolve(error);
                })
        });
        return await a;
    }

    changeStudentsStatus = async (id, data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.put(apiroutes.CHANGE_STUDENT_STATUS + id, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                }).catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    changeStudentIntake = async (id, data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.put(apiroutes.GET_ALL_STUDENTS + "intake/" + id, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                }).catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getStudentIDStatus = async (id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_STUDENT_ID_STATUS + `${id}`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    createBulkStudent = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_BULK_STUDENT, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error.response);
                    console.log("error", error);
                })
        });
        return await a;
    }

    updateStudentIDStatus = async (id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.put(apiroutes.UPDATE_STUDENT_ID_STATUS + `${id}`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    elevateStudentBulk = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.put(apiroutes.ELEVATE_STUDENT_BULK, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    createStudentManual = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.SINGLE_STUDENT_CREATE_MANUAL, data, {
                headers: { Authorization: `Bearer ${accessToken}` }
            }).then(res => {
                return resolve(res);
            })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    editStudentDetails = async (data, id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.EDIT_STUDENT_DETAILS + `${id}`, data, {
                headers: { Authorization: `Bearer ${accessToken}` }
            }).then(res => {
                return resolve(res);
            })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }


    // Year Renewal

    getAllYearRenewals = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_ALL_YEAR_RENEWALS,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }
    getSingleYearRenewal = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_YEAR_RENEWALS,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    approveOrRejectOneYearRenewal = async (data, id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.put(apiroutes.APPROVE_REJECT_ONE_YEAR_RENEWAL + `${id}`, data, {
                headers: { Authorization: `Bearer ${accessToken}` }
            }).then(res => {
                return resolve(res);
            })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    approveOrRejectBulkYearRenewal = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.put(apiroutes.APPROVE_REJECT_BULK_YEAR_RENEWALS, data, {
                headers: { Authorization: `Bearer ${accessToken}` }
            }).then(res => {
                return resolve(res);
            })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    //duplicate id

    requestDuplicateID = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.REQUEST_DUPLICATE_ID, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "multipart/form-data"
                    }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    //request year renewal
    requestYearRenewal = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.REQUEST_YEAR_RENEWAL, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    //setup year renewal
    setupYearRenewal = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.SETUP_YEAR_RENEWAL, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }

    //generate student report
    generateAllStudentsReport = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.GENERATE_ALL_STUDENT_REPORT, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    generateAllStudentsReportWithFilters = async (data, params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.GENERATE_ALL_STUDENT_REPORT, data,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }


    uploadStudentWithPassword = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.ADD_STUDENT_PASSWORD_LIST, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error.response)
                })
        })
        return await a
    }

    uploadStudentDocument = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.STUDENT_DOCUMENTS_UPLOAD, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error.response)
                })
        })
        return await a
    }


    editStudentDocument = async (data, id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.STUDENT_DOCUMENTS_UPLOAD + `/${id}`, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error.response)
                })
        })
        return await a
    }

    getStudentsForSendPasswordLink = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.STUDENT_FOR_PASSWORD_LINK,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                    return resolve(error);
                })
        });
        return await a;
    }

    getEmailExist = async (email) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.STUDENT_FOR_PASSWORD_LINK + "select/email/" + email,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }


    reSendPasswordLink = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.STUDENT_PASSWORD_LINK_SEND, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error.response)
                })
        })
        return await a
    }

    //Update year renewal
    UpdateYearRenewal = async (id, data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.UPDATE_YEAR_RENEWAL + id, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }

    getSingleRenewal = async (id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_SINGLE_YEAR_RENEWALS + id,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }

    getSingleYearRenewalRequestById = async (id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_ALL_YEAR_RENEWALS + '/' + id,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }

    //Update year renewal
    changeStatusYearRenewal = async (id, data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.put(apiroutes.UPDATE_YEAR_RENEWAL + "status/" + id, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }

    updateBulkStudentYear = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.UPDATE_BULK_STUDENTS, data, {
                headers: { Authorization: `Bearer ${accessToken}` }
            }).then(res => {
                return resolve(res);
            })
                .catch((error) => {
                    return resolve(error);
                })
        });
        return await a;
    }

    getAllstudentsSubjectRegistration = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.STUDENT_SUBJECT_REGISTRATION,  {
                    params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error.response)
                })
        })
        return await a
    }
    studentSubjectRegistration = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.STUDENT_SUBJECT_REGISTRATION, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error.response)
                })
        })
        return await a
    }
}

export default new StudentService()