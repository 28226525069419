import React from 'react'
import { authRoles } from '../../auth/authRoles'

const RetirementRoutes = [
    {
        path: '/hr/retirements/',
        component: React.lazy(() =>
            import('./RetirementList.jsx')
        ),
        auth: authRoles.humanResoursesViews,
    },
    {
        path: '/hr/academic_retirements/',
        component: React.lazy(() =>
            import('./AcademicRetirementAlert.jsx')
        ),
        auth: authRoles.hrRetirementViews,
    },
    {
        path: '/hr/non_academic_retirements/',
        component: React.lazy(() =>
            import('./NonAcademicRetirementAlert.jsx')
        ),
        auth: authRoles.hrNARetirementViews,
    },
]

export default RetirementRoutes
