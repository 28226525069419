import React, { Component } from 'react'
import { Card, Typography } from '@material-ui/core'

import { Fragment } from 'react'

import SelectVacancies from '../HumanResource/Designation/Recruitment/SelectVacancies'
export class JobVacancy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentDate: '',
            currentMonth: '',
        };
    }

    componentDidMount() {
        // Create a new Date object to get the current month
        const currentDate = new Date();
        const currentDay = currentDate.getDate();
        // Define an array of month names
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        // Get the current month index (0 - 11)
        const currentMonthIndex = currentDate.getMonth();
        // Use the month index to get the corresponding month name
        const currentMonth = monthNames[currentMonthIndex];
        // Set the current month in the component's state
        this.setState({currentDate:currentDay, currentMonth:currentMonth });
    }

    render() {
        return (
            <Fragment>

                <Card >
                    <div className="bg-light-primary py-5 text-center" >
                        {/* check_circle_outline */}
                        <div className="flex justify-center text-center" >
                            <img alt="uni_logo" src="/assets/images/logo_vpa.png" width="100px" height="100px" />
                        </div>
                        <div className="flex justify-center text-center" >

                            <Typography variant="h4" component="h4" className="mt-5 text-primary capitalize font-bold" style={{ letterSpacing: 3 }}>
                                University of the Visual and Performing Arts
                            </Typography>


                        </div>
                        <div className="flex justify-center text-center" >
                            <div className=' max-w-770'>
                                <Typography variant="h4" component="h4" className="my-2 text-green font-bold  uppercase" style={{ letterSpacing: 1 }} >
                                    vacancies
                                </Typography>
                            </div>



                        </div>
                        <div className="flex justify-center text-center" >

                            <div className=' max-w-770'>
                                <Typography className="my-2 text-gray font-medium text-14 " style={{ letterSpacing: 1 }} >
                                    The University of Visual and Performing Arts will entertain online applications for the under mentioned posts up to {this.state.currentDate} {this.state.currentMonth} 2023
                                </Typography>
                            </div>


                        </div>
                    </div>
                    <div className="px-25 py-5">
                        <SelectVacancies />
                    </div>


                </Card>

            </Fragment>
        )
    }
}

export default JobVacancy