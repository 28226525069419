
import React, { Fragment, useState, Component } from "react";
import { Grid } from '@material-ui/core'
import { any } from "prop-types";

class CustomGrid extends Component {
    static propTypes = {
        title: any,
        bodyText: any,
        children: Node,
        cutomClass: any

    };

    static defaultProps = {
        title: null,
        bodyText: null,
    };



    render() {
        const {
            title,
            bodyText,
            children,

        } = this.props;


        return (
            <Fragment>
                <Grid container item xs={12} className="items-center">
                    <small className={` text-primary bg-light-primary  px-5 py-2 font-semibold w-full m-0 text-center border-radius-8 text-14`}>
                        {title}
                    </small>
                </Grid>
                <Grid item container xs={12}>
                    <p className="ml-2 text-muted">{bodyText}</p>
                    <div>
                        {children}
                    </div>
                </Grid>
            </Fragment>

        );
    }
}

export default CustomGrid;