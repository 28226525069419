import React from 'react'
import { authRoles } from '../../auth/authRoles'

const StatusRoutes = [


    {
        path: '/status',
        component: React.lazy(() => import('./Status')),
        auth: authRoles.statusView,
    },

]

export default StatusRoutes;
