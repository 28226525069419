import React from 'react'
import {authRoles} from '../../../auth/authRoles'

const BatchWiseAllStudentsResultsRoutes = [
    {
        path: '/examinations/all_intakes',
        component: React.lazy (() => import('../AllIntakes/AllIntakes')),
        authRoles: authRoles.ExaminationView,
    },
    {
        path: '/examinations/batch_wise_all_students_results/:id',
        component: React.lazy (() => import('./BatchWiseAllStudentsResults')),
        authRoles: authRoles.ExaminationView,
    },

 
    {
        path: '/examinations/result-sheet-view/:id',
        component: React.lazy (() => import('./ResultSheetView')),
        auth: authRoles.ExaminationView,
    },
]

export default BatchWiseAllStudentsResultsRoutes