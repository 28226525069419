import React from 'react'
import ReactEcharts from 'echarts-for-react'
import { useTheme } from '@material-ui/core/styles'

const ComparisonChart2 = ({ height, dataset = []}) => {
    const { palette } = useTheme()
    
    const option = {
        grid: {
            left: '6%',
            bottom: '10%',
            right: '5%',
        },
        legend: {
            itemGap: 50,
            icon: 'roundRect',
            textStyle: {
                color: palette.text.secondary,
                fontSize: 13,
                fontFamily: 'roboto',
            },
        },
        color: [
            palette.primary.dark,
            palette.secondary.light,
            palette.error.light,
        ],
        barMaxWidth: '15px',
        tooltip: {},
        dataset: {source :dataset},
        xAxis: {
            type: 'category',
            axisLine: {
                show: false,
            },
            splitLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                interval: 0,
                color: palette.text.secondary,
                fontSize: 15,
                fontFamily: 'roboto',
            },
        },
        yAxis: {
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            splitLine: {
                // show: false
                lineStyle: {
                    color: palette.text.secondary,
                    opacity: 0.15,
                },
            },
            axisLabel: {
                color: palette.text.secondary,
                fontSize: 13,
                fontFamily: 'roboto',
            },
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
            {
                type: 'bar',
                itemStyle: {
                    barBorderRadius: [10, 10, 0, 0],
                },
                label:{
                    show:true,
                    position :'top',
                    fontWeight :'bold',
                    rotate:40
                },
                barWidth:60,
                barGap: '80%',
                barCategoryGap : '-20%'
            },
            {
                type: 'bar',
                itemStyle: {
                    barBorderRadius: [10, 10, 0, 0],
                },
                label:{
                    show:true,
                    position :'top',
                    fontWeight :'bolder',
                    rotate:40
                },
                barGap: '80%',
                barCategoryGap : '-100%'
            },
            {
                type: 'bar',
                itemStyle: {
                    barBorderRadius: [10, 10, 0, 0],
                },
                label:{
                    show:true,
                    position :'top',
                    fontWeight :'bold',
                    rotate:40
                },
                barGap: '80%', 
                barCategoryGap : '-20%'
            },
            // {
            //     type: 'bar',
            //     itemStyle: {
            //         barBorderRadius: [10, 10, 0, 0],
            //     },
            // },
        ],
    }
    console.log(option.dataset.source,"dataset123")
    return <ReactEcharts style={{ height: height }} option={option} />
}

export default ComparisonChart2
