import React from 'react'
import { authRoles } from '../../auth/authRoles'

const HRWorkFlowsRoutes = [
    {
        path: '/HRWorkFlowsRoutes/HOD',
        component: React.lazy(() => import('./HOD')),
        auth: authRoles.aptitudeTestView,
    },
    // {
    //     path: '/HRWorkFlowsRoutes/AddInstrument',
    //     component: React.lazy(() => import('./Instrument/Instruments')),
    //     auth: authRoles.aptitudeTestView,
    // },
    // {
    //     path: '/HRWorkFlowsRoutes/AllInstrument',
    //     component: React.lazy(() => import('./Instrument/All_Instruments')),
    //     auth: authRoles.aptitudeTestView,
    // },
]

export default HRWorkFlowsRoutes;