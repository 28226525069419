import React from 'react'
import {authRoles} from '../../../auth/authRoles'

const SemesterExaminationRoutes = [

    {
        path: '/examinations/semester_wise/exam_details/:id',
        component: React.lazy (() => import('./SingleSemesterExamView')),
        authRoles: authRoles.ExaminationView,
    },
    {
        path: '/examinations/semester_wise',
        component: React.lazy (() => import('./SemesterExaminations')),
        authRoles: authRoles.ExaminationView,
    },
]

export default SemesterExaminationRoutes