import React from 'react'
import { authRoles } from '../../auth/authRoles'

const curriculumRoutes = [
    // Batch
    {
        path: '/ma/curriculums/all',
        component: React.lazy(() => import('../Curriculum/MA_View_All_Curriculums')),
    },
    {
        path: '/ma/curriculums/create',
        component: React.lazy(() => import('./MA_Create_Curriculum')),
    },
    {
        path: '/ma/curriculums/view/5',
        component: React.lazy(() => import('../Curriculum/MA_View_Single_Curriculum')),
    },
    
]

export default curriculumRoutes
