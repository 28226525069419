/*
Developed By Shiny
Loons Lab
*/
import React from 'react'
import { authRoles } from '../../auth/authRoles'

const MahapolaRoutes = [
    {
        path: '/mahapola/recipients',
        component: React.lazy(() => import('./MahapolaRecipientDetails')),
        auth: authRoles.dashboard,
    },
    {
        path: '/mahapola/recipients_attendance',
        // component: React.lazy(() => import('./MahapolaRecipientsAttendance')),
        component: React.lazy(() => import('./MahapolaAttendanceTabHandling')),
        auth: authRoles.dashboard,
    },
    {
        path: '/mahapola/monthly_installments',
        component: React.lazy(() => import('./MahapolaMonthlyInstallments')),
        auth: authRoles.dashboard,
    },
    {
        path: '/mahapola/recipients_create',
        component: React.lazy(() => import('./NewMahapolaRecipients')),
        auth: authRoles.dashboard,
    },
    {
        path: '/mahapola/installment_create',
        component: React.lazy(() => import('./NewMahapolaRecipients')),
        auth: authRoles.dashboard,
    },
    {
        path: '/mahapola/installment/value_setup',
        component: React.lazy(() => import('./InstallmentValueSetup')),
        auth: authRoles.dashboard,
    },
    {
        path: '/mahapola/annexures',
        component: React.lazy(() => import('./MahapolaAnnexureTabHandling')),
        auth: authRoles.dashboard,
    },
    {
        path: '/mahapola/approval_recommend',
        component: React.lazy(() => import('./MahapolaApprovalRecommend')),
        auth: authRoles.dashboard,
    },
    {
        path: '/mahapola/student/payment_details',
        component: React.lazy(() => import('./MahapolaStudentView')),
        auth: authRoles.dashboard,
    },
]

export default MahapolaRoutes
