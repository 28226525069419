import React from 'react'
import { authRoles } from '../../auth/authRoles'

const DocumentManagementRoutes = [
    {
        path: '/document_management/committees/setup',
        component: React.lazy(() => import('./Committees/CommitteesSetup')),
        auth: authRoles.dashboard,
    },
    {
        path: '/document_management/committees/create/:id',
        component: React.lazy(() => import('./Committees/CreateCommittees')),
        auth: authRoles.dashboard,
    },
    {
        path: '/document_management/committees/view_council/:id',
        component: React.lazy(() => import('./Committees/ViewCouncil')),
        auth: authRoles.dashboard,
    },
    {
        path: '/document_management/committees/create_meeting',
        component: React.lazy(() => import('./Committees/CreateMeeting')),
        auth: authRoles.dashboard,
    },    
    {
        path: '/document_management/committees/all_meeting',
        component: React.lazy(() => import('./Committees/AllMeeting')),
        auth: authRoles.dashboard,
    },     
    {
        path: '/document_management/committees/council_meeting/:id/:tabValue',
        component: React.lazy(() => import('./Committees/CouncilMeeting')),
        auth: authRoles.dashboard,
    }, 
    {
        path: '/document_management/committees/all_memo',
        component: React.lazy(() => import('./Committees/AllMemo')),
        auth: authRoles.dashboard,
    },         
    {
        path: '/document_management/committees/memo_recommendation/:id',
        component: React.lazy(() => import('./Committees/MemoRecommendation')),
        auth: authRoles.dashboard,
    },
    {
        path: '/document_management/committees/all_committes',
        component: React.lazy(() => import('./Committees/AllCommittee')),
        auth: authRoles.dashboard,
    },
    {
        path: '/document_management/commitees/all_memo_recommendations',
        component: React.lazy(() => import('./Committees/MemoRecommendationList')),
        auth: authRoles.dashboard,
    },
    
    {
        path: '/document_management/commitees/memos/:id/:commitee_id',
        component: React.lazy(() => import('./Committees/Memos')),
        auth: authRoles.dashboard,
    },
    {
        path: '/document_management/council_meeting/qr_code_scaned_attended/:id',
        component: React.lazy(() => import('./Committees/QRCodeRead')),
        auth: authRoles.dashboard,
    },    
]

export default DocumentManagementRoutes
