import axios from 'axios'
import * as apiroutes from '../../apiroutes'
import localStorageService from './localStorageService'

class CourseService {
    getAllCourses = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_ALL_COURSES, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    createCourse = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.CREATE_COURSE, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    getSingleCourses = async (id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_ALL_COURSES + '/' + id, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    changeCourseStatus = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .put(apiroutes.CHANGE_COURSE_STATUS + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    editCourseDetails = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.EDIT_COURSE_DETAILS + `${id}`, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    deleteCourse = async (id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .delete(apiroutes.CREATE_COURSE + '/' + id, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    getCourseTypes = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.FGS_COURSE_TYPE, {
                    params: data,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    createCourseTypes = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.FGS_COURSE_TYPE, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    editCourseType = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.FGS_COURSE_TYPE + `${id}`, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }
    getPGStudentsTypes = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.FGS_STUDENT_TYPE, {
                    params: data,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    createPGStudentTypes = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.FGS_STUDENT_TYPE, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    editPGStudentType = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.FGS_STUDENT_TYPE + `${id}`, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    getPGStudentsPaymentPlans = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.FGS_PAYMENT_PLAN, {
                    params: data,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    createPGStudentPaymentPlan = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.FGS_PAYMENT_PLAN, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    editPGStudentPaymentPlan = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.FGS_PAYMENT_PLAN + `/${id}`, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    //timetable setup

    //get all timetable data
    /**
     * @param {{
     *   limit: number,
     *   academic_year_id : number|string,
     *   semester : number|string,
     *   dept_id : number|string,
     *   level : number | string,
     *   intake_id : number|string,
     *   'order[0]': Array<string>
     * }} [params] - Optional parameters for filtering documents.
     * - 'order[0]': ['id', 'DESC']
     */
    getAllTimetableData = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.TIMETABLE_SETUP, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    params,
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }
    //get all timetable data
    /**
     * @param {string| number} id
     */
    getSingleTimetableDataById = async (id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.TIMETABLE_SETUP + id, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }
    //create new timetable data
    /**
     * @param {{
     *   dept_id : number | string,
     *   semester : number | string,
     *   level : number | string,
     *   academic_year_id : number | string,
     *   intake_id : number | string,
     *   status : 'active' | string
     * }} data
     * @example
     * createNewTimetableData({
     *   dept_id: 2,
     *   semester: 1,
     *   level: 2,
     *   intake_id: 29,
     *   academic_year_id: 29,
     *   status: 'active'
     * });
     */
    createNewTimetableData = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.TIMETABLE_SETUP, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    /**
     * @param {number} id
     * @param {{
     *   dept_id : number | string,
     *   semester : number | string,
     *   level : number | string,
     *   academic_year_id : number | string,
     *   intake_id : number | string,
     *   status : 'active' | string
     * }} body
     */

    editTimetable = async (id, body) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.TIMETABLE_SETUP + id, body, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }
    /**
     * @param {number} id
     */

    deleteTimetable = async (id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .delete(apiroutes.TIMETABLE_SETUP + id, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    //get all lectures data
    /**
     * @param {{
     *   limit: number,
     *   start_date : Date,
     *   end_date : Date,
     *   start_time : number | string,
     *   end_time : string
     *   timetable_id : number | string,
     *   subject_id : number | string,
     *   hall_id : number | string
     *   lecturer_id : number | string
     *   lecture_type : 'Theory' | 'Practical'
     *   status : 'active' | string
     *   'order[0]': Array<string>
     * }} [params] - Optional parameters for filtering documents.
     * - 'order[0]': ['id', 'DESC']
     */
    getAllLectures = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.LECTURE_SCHEDULE, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    params,
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

    /**
     * @param {{
     *   start_date : Date,
     *   end_date : Date,
     *   start_time : number | string,
     *   end_time : string
     *   timetable_id : number | string,
     *   subject_id : number | string,
     *   hall_id : number | string
     *   lecturer_id : number | string
     *   lecture_type : 'Theory' | 'Practical'
     *   status : 'active' | string
     * }} data
     * @example
     * createNewLecture({
     *   start_date : new Date(),
     *   end_date : new Date(),
     *   start_time : 10:00,
     *   end_time : 12:00,
     *   timetable_id : 2,
     *   subject_id : 12,
     *   hall_id : 1,
     *   lecturer_id : 3,
     *   lecture_type : 'Theory',
     *   status : 'active'
     * });
     */

    createNewLecture = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.LECTURE_SCHEDULE, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }
    /**
     * @param {{
     *   timetable_id:number,
     *   start_date : Date,
     *   end_date : Date,
     *   start_time : number | string,
     *   end_time : string
     *   timetable_id : number | string,
     *   subject_id : number | string,
     *   hall_id : number | string
     *   lecturer_id : number | string
     *   lecture_type : 'Theory' | 'Practical'
     *   status : 'active' | string
     * }} data
     */

    updateLecture = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.LECTURE_SCHEDULE, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }
    /**
     * @param {number} id
     *
     */

    deleteLecture = async (id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .delete(apiroutes.LECTURE_SCHEDULE + id, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }
}
export default new CourseService()
