import axios from 'axios';
import * as apiroutes from '../../apiroutes';
import localStorageService from "./localStorageService";

class CommitteesService {

    getCommittees = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_COMMITTEES,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getCommitteesTable = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_COMMITTEES+"/select/table",
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getMeetings = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_ALL_MEETING,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getSingleMeeting = async (id, params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_ALL_MEETING + "/" + id,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getCommitteesById = async (id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_COMMITTEES + "/" + id, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }

    getCommitteeMembersSimple = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_ALL_COMMITTEE_MEMBERS+"/select/simple",
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                .then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getCommitteeMembers = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_ALL_COMMITTEE_MEMBERS,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                .then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getPreviousComitteeMembers = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_PREVIOUS_COMMITTEE_MEMBERS,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                .then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getCommiteeComposition = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_COMMITTEES_COMPOSITION,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getCommitteeBoardCategories = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_ALL_COMMITTEE_BOARD_CATEGORIES,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                .then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error)
                })
        });
        return await a;
    }

    getMemos = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_ALL_MEMO,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                .then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error)
                })
        });
        return await a;
    }

    getSingleMemo = async (id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_ALL_MEMO + "/" + id,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                .then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error)
                })
        });
        return await a;
    }

    getAttendance = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_COMMITTEE_MEETING_ATTENDANCE,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                .then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error)
                })
        });
        return await a;
    }

    getSingleAttendance = async (id, params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_COMMITTEE_MEETING_ATTENDANCE + "/" + id,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                .then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error)
                })
        });
        return await a;
    }

    updateCommitteesStatus = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .put(apiroutes.UPDATE_COMMITTEE_STATUS + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    updateMemoStatus = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .put(apiroutes.UPDATE_MEMO_STATUS + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    informAttendance = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.UPDATE_COMMITTEE_MEETING_ATTENDANCE_INFORM + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    markAttendance = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .put(apiroutes.MARK_COMMITTEE_MEETING_ATTENDANCE, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    updateMemo = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.UPDATE_MEMO + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }


    updateAbsentNote = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.UPDATE_ABSENT_NOTE + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    updateMeeting = async (data, id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.UPDATE_MEETING + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    updateCommitteeMembers = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.UPDATE_COMMITTEE_MEMBERS, data, {
                headers: { Authorization: `Bearer ${accessToken}` },
            })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    updateCategories = async (data, id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.UPDATE_COMMITTEE_BOARD_CATEGORIES + id, data, {
                headers: { Authorization: `Bearer ${accessToken}` },
            })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    updateCategoryStatus = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios.put(apiroutes.UPDATE_COMMITTEE_BOARD_CATEGORIES_STATUS + id, data, {
                headers: { Authorization: `Bearer ${accessToken}` },
            })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    getQR = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.GET_COMMITTEE_MEETING_QR + id, data, {
                headers: { Authorization: `Bearer ${accessToken}` },
            })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    createCommitee = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_COMMITTEES, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    createMeeting = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_MEETING, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    createCommiteeBoardCategories = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_COMMITTEE_BOARD_CATEGORIES, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    createCommiteeComposition = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_COMMITTEES_COMPOSITION, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    createMemo = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_MEMO, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    createMemoAmmendments = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.COMMITTEE_BOARD_MEMO_AMMENDMENTS, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    getMemoAmmendments = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.COMMITTEE_BOARD_MEMO_AMMENDMENTS,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                .then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error)
                })
        });
        return await a;
    }

    sendFlyerMemo = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.SEND_COMMITTEE_FLYER_MEMO, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }

                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    deleteComittee = async (id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.delete(apiroutes.DELETE_COMMITTEES + id,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    deleteComitteeCategory = async (id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.delete(apiroutes.DELETE_COMMITTEE_BOARD_CATEGORIES + id,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    //edit committee
    updateCommittee = async (data, id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.EDIT_COMMITTEE + id, data, {
                headers: { Authorization: `Bearer ${accessToken}` },
            })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a;
    }

    //edit compositions
    updateComposition = async (data, id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.EDIT_COMMITTEES_COMPOSITION + id, data, {
                headers: { Authorization: `Bearer ${accessToken}` },
            })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a;
    }

    getAttendanceDetails = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_ALL_ATTENDANCE_DESIGNATION_DETAILS,{
                params: params,
                headers: { Authorization: `Bearer ${accessToken}` },
            })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await a
    }

    getSingleAttendance = async (id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_ALL_ATTENDANCE_DESIGNATION_DETAILS + "/" + id,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                .then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error)
                })
        });
        return await a;
    }

    postAttendanceDetails = async (params) => {
        try {
            const accessToken = await localStorageService.getItem('accessToken');
    
            // Validate params to ensure they are correctly formatted
            console.log('Posting data:', params);
    
            const response = await axios.post(
                apiroutes.GET_ALL_ATTENDANCE_DESIGNATION_DETAILS,
                params,  // Send params directly as the request body
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            );
    
            return response;
        } catch (error) {
            console.error('Error:', error);
            return error;
        }
    };
    
    patchAttendanceDetails = async (id, data) => {
        try {
            const accessToken = await localStorageService.getItem('accessToken');
            console.log('Patching data:', data);
            const response = await axios.patch(
                `${apiroutes.GET_ALL_ATTENDANCE_DESIGNATION_DETAILS}${id}`,
                data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
            );
            return response;
        } catch (error) {
            console.error('Error updating attendance details:', error);
            return error.response || error; // return the error response if available
        }
    };
    

    

    

}
export default new CommitteesService()