/*
For store user privileges to pages
Edited by Roshan
Loons lab
*/

export const authRoles = {
    dashboard: ['SA', 'dashboardAccess'], // Only Super Admin has access
    aptituteTest: ['SA', 'ADMIN'],
    aptituteTestCreate: ['SA', 'ADMIN'],
    aptitudeTestView: ['SA', 'ADMIN'],
    TestPaperCreate: ['SA', 'ADMIN'],
    studentManagement: ['SA', 'ADMIN'],
    studentAffairsviewIntake: ['SA', 'ADMIN'],
    maviewIntake: ['SA', 'ADMIN', 'Exam AR', 'Exam MA', 'SSW AR', 'SSW MA'],
    itviewIntake: ['SA', 'ADMIN'],
    studentAffairsStatusView: ['SA', 'ADMIN'],
    dataSetup: [
        'SA',
        'ADMIN',
        'SSW AR',
        'SSW MA',
        'Faculty AR',
        'HOD',
        'HR AR',
        'AE AR',
        'NAE AR',
        'HR MA',
        'AE MA',
        'NAE MA',
        'Registrar Office MA',
        'Registrar',
        'Registrar Office AR',
    ],
    vehicle: [
        'SA',
        'ADMIN',
        'SSW AR',
        'SSW MA',
        'Faculty AR',
        'HOD', // head of department
        'HR AR', // assistant registrar
        'AE AR',
        'NAE AR',
        'HR MA',
        'AE MA', // academic establishment
        'NAE MA', // non academic establishment
        'Registrar Office MA',
        'Registrar',
        'Registrar Office AR',
        'Finance MA',
        'AE MA',
        'Bursar'
    ],

    vehicleView: ['ADMIN', 'SA', 'Faculty AR', 'Registrar','AE MA','Finance MA'],

    reservationHODApproval: ['HOD', 'ADMIN', 'SA','AE MA'],
    reservationMAApproval: ['SSW MA', 'AE MA', 'ADMIN', 'SA'],
    reservationARApproval: [
        'Faculty AR',
        'Registrar',
        'ADMIN',
        'SA',
    ],
    reservationRegistrarApprove: ['ADMIN', 'SA', 'Registrar'],
    reservationVCApprove: ['ADMIN', 'SA', 'Vice Chancellor'],

    employeeCreate: ['SA', 'ADMIN'],
    employeeView: ['SA', 'ADMIN'],
    intakes: ['SA', 'ADMIN'],
    test: ['SA', 'ADMIN'],
    mayearrenewal: ['SA', 'ADMIN'],
    profile: ['SA', 'ADMIN'],
    studemtProfile: ['SA', 'ADMIN'],
    cancellationRequest: ['SA', 'ADMIN'],
    allCancellationRequestView: ['SA', 'ADMIN'],
    studentAffallCancellationRequestView: ['SA', 'ADMIN'],
    batchView: ['SA', 'ADMIN'],
    postgraduateCourseCreate: ['SA', 'ADMIN', 'FGS', 'Bursar', 'Faculty AR', 'Faculty MA', 'Postgraduate MA', 'Postgraduate AR'],
    postgraduateStudentView: ['SA', 'ADMIN', 'Post Graduate Student'],
    PostgraduateMenu: ['SA', 'ADMIN'],
    postgraduateCoursesView: ['Faculty AR', 'SA', 'ADMIN'],
    humanResoursesViews: [
        'SA',
        'ADMIN',
        'HR AR',
        'AE AR',
        'NAE AR',
        'HR MA',
        'AE MA',
        'NAE MA',
        'HOD',
        'NAE Head',
        'Registrar',
        // 'Division Head',
    ],
    hrRetirementViews: ['AE MA', 'AE AR', 'HR AR', 'HR MA'],
    hrNARetirementViews: ['NAE AR', 'NAE MA', 'NAE Head', 'HR AR', 'HR MA'],

    //ResearchGrant
    researchGrantOverview: [
        'Faculty AR', 
        'HR AR',
        'AE AR',
        'NAE AR',
        'Registrar Office AR',
        'AE MA', 
        'Registrar Office MA',
        'Research Grant MA',
        'Research Grant AR',
        'Registrar',
        'Lecturer',
        'SA', 
        'ADMIN'
    ],
    researchGrant: [
        'Research Grant MA',
        'Research Grant AR',
        'SA', 
        'ADMIN'
    ],
    //Finance- Vouchers
    vouchers: [
        'SA',
        'ADMIN',
        'HOD',
        'Faculty MA',
        'SSW AR',
        'SSW MA',
        'Faculty AR',
        'HOD',
        'HR AR',
        'AE AR',
        'NAE AR',
        'HR MA',
        'AE MA',
        'NAE MA',
        'Registrar Office MA',
        'Registrar',
        'Registrar Office AR',
        'Bursar',
        'Finance Store Keeper',
        'Assistant Bursar',
        'Senior Assistant Bursar',
        'Deputy Bursar',
        'Management Assistant',
        'Finance Store Keeper',
        'Book Keeper',
        'Lecturer',
        'Vice Chancellor',
        'VC'


    ],

    //Final user Roles
    appicationView: ['SA', 'ADMIN', 'Temporary Student'],
    SetUpNewAcademicYear: ['SA', 'ADMIN', 'SSW MA', 'SSW AR'],
    SetUpUndergrdRegDocuments: ['SA', 'ADMIN', 'SSW MA', 'SSW AR'],
    CreateNewIntake: ['SA', 'SSW MA', 'SSW AR'],
    SetUpYearRenwalCreate: [
        'SA',
        'ADMIN',
        'Faculty MA',
        'Faculty AR',
        'Dean',
        'HOD',
        'SSW MA',
        'SSW AR',
    ],
    SetUpYearRenwalView: [
        'SA',
        'ADMIN',
        'Faculty MA',
        'Faculty AR',
        'Dean',
        'HOD',
        'SSW MA',
        'SSW AR',
    ],
    AddStudentsForIntake: ['SA', 'ADMIN', 'SSW AR'],
    ViewIntake: ['SA', 'ADMIN', 'SSW AR', 'SSW MA'],
    AddNewStudnetManuaklly: ['SA', 'ADMIN', 'SSW AR'],
    AllStudents: ['SA', 'ADMIN', 'SSW AR', 'SSW MA', 'Faculty MA'],
    AllStudentsStatusesView: ['SA', 'ADMIN', 'SSW AR', 'SSW MA'],
    ListOfIDCards: ['SA', 'ADMIN', 'SSW AR', 'SSW AR'],
    ListOfDuplicateIDCardRequests: ['SA', 'ADMIN', 'SSW AR'],
    SingleDuplicateCardRequest: ['SA', 'ADMIN', 'SSW AR'],
    BasicDetails: [
        'SA',
        'ADMIN',
        'Undergraduate Student',
        'Temporary Student',
        'Temporary Aptitude Student',
        'dashboardAccess',
    ],
    AptitudeStudent: ['SA', 'ADMIN', 'Temporary Aptitude Student'],
    PGStudent: ['SA', 'ADMIN', 'Post Graduate Student'],

    PayRegistrationFee: [
        'SA',
        'ADMIN',
        'Temporary Student',
        'Temporary Aptitude Student',
    ],
    Doccuments: [
        'SA',
        'ADMIN',
        'Undergraduate Student',
        'Temporary Student',
        'Temporary Aptitude Student',
    ],
    DigitalID: ['SA', 'ADMIN', 'Undergraduate Student'],
    YearRenewal: ['SA', 'ADMIN', 'Undergraduate Student', 'SSW MA', 'SSW AR'],
    Appeal: [
        'SA',
        'ADMIN',
        'Undergraduate Student',
        // 'Temporary Student',
        // 'Temporary Aptitude Student',
    ],
    AppealSingleView: [
        'SA',
        'ADMIN',
        'Undergraduate Student',
        'Temporary Student',
        'Temporary Aptitude Student',
    ],
    CancellationRequest: ['SA', 'ADMIN', 'Undergraduate Student'],
    ListOfYearRenewalRequests: ['SA', 'ADMIN', 'HOD', 'SSW MA', 'SSW AR'],
    ListOfYearRenewalRequestsApproved: ['SA', 'SSW AR', 'SSW MA'],
    ListOfAppeals: ['SA', 'ADMIN', 'SSW AR', 'HOD'],
    ListOfCancellationRequests: ['SA', 'HOD', 'Dean'],
    ListOfCancellationRequestsApproved: ['SA', 'SSW AR', 'SSW MA'],
    CancellationLetterGeenrateCreate: ['SA', 'SSW AR', 'SSW MA'],
    StudentProfileConfirmToBe: ['SA', 'ADMIN'],
    DataSetupDocumentSetup: ['SA', 'SSW AR', 'SSW MA'],

    // PGStudent: ['SA', 'ADMIN'],
    statusView: [
        'SA',
        'ADMIN',
        'Authorized Council Member',
        'Unit Head',
        'Vice Chancellor',
        'SSW AR',
        'SSW MA',
        'Exam AR',
        'Dean',
        'Faculty AR',
        'Faculty MA',
        'HOD',
        'Finance MA',
        'Lecturer',
        'Vice Chancellor',
        'Faculty Board Member',
        'Senate Member',
        'Bursar',
        'AE AR',
        'AE MA',
        'NAE AR',
        'NAE MA',
        'Division Head',
        'Tempory Employee',
    ],
    //Aptitude Test tab access
    basicInfoView: [
        'SA',
        'ADMIN',
        'SSW AR',
        'SSW MA',
        'Exam AR',
        'Dean',
        'Faculty AR',
        'Faculty MA',
        'HOD',
        'Finance MA',
        'Bursar',
        'Vice Chancellor',
        'Lecturer',
    ],
    advertisementView: [
        'SA',
        'ADMIN',
        'Vice Chancellor',
        'SSW AR',
        'SSW MA',
        'Exam AR',
        'Exam MA',
        'Dean',
        'Faculty AR',
        'Faculty MA',
        'HOD',
    ],
    examinerListView: [
        'SA',
        'ADMIN',
        'Vice Chancellor',
        'Exam MA',
        'Exam AR',
        'Faculty AR',
    ],
    examinerPanelsView: [
        'SA',
        'ADMIN',
        'Dean',
        'Faculty AR',
        'Faculty MA',
        'HOD',
        'Lecturer',
    ],
    answersView: ['SA', 'ADMIN', 'Exam AR', 'Lecturer'],
    examSetUpView: [
        'SA',
        'ADMIN',
        'Vice Chancellor',
        'Dean',
        'Faculty AR',
        'Faculty MA',
        'HOD',
        'Exam AR',
    ],
    cutoffSetUpView: ['SA', 'ADMIN', 'Vice Chancellor', 'Exam AR', 'Exam MA'],
    admissionsView: ['SA', 'ADMIN', 'Exam AR', 'Exam MA'],
    appealsView: [
        'SA',
        'ADMIN',
        'Vice Chancellor',
        'SSW AR',
        'SSW MA',
        'Exam AR',
        'Exam MA',
        'Faculty MA',
    ],
    passListView: ['SA', 'ADMIN', 'Vice Chancellor', 'Exam AR', 'Exam MA'],
    assignPapersQuestionsView: ['SA', 'ADMIN', 'Faculty AR'],
    assignInterviewsView: ['SA', 'ADMIN', 'Dean', 'HOD'],
    AllApplication: ['SA', 'ADMIN', 'Dean', 'HOD'],
    allAnswerSummaryView: ['SA', 'ADMIN', 'Exam AR'],
    FinanceView: ['SA', 'ADMIN', 'Bursar', 'Assistant Bursar', 'Finance MA'],
    ITViewIntake: ['SA', 'ADMIN', 'Head of IT'],

    ViewDocumentStatus: [
        'SA',
        'ADMIN',
        'SSW AR',
        'SSW MA',
        'Physical Unit AR',
        'Physical Unit MA',
    ],

    MedicalForDoctor: ['SA', 'ADMIN', 'Doctor'],
    MedicalForNurse: ['SA', 'ADMIN', 'Nurse'],
    MedicalForDoctorNurse: ['SA', 'ADMIN', 'Doctor', 'Nurse'],
    // Navigation Examination tab
    ExaminationView: [
        'SA',
        'ADMIN',
        'Vice Chancellor',
        'SSW MA',
        'SSW AR',
        'Exam AR',
        'Exam MA',
        'HOD',
        'Dean',
        'Faculty AR',
        'Faculty MA',
        'Lecturer',
        'Bursar',
        'Assistant Bursar',
    ],
    createTest: ['SA', 'ADMIN', 'SSW MA', 'SSW AR'],
    WelfareView: ['SA', 'ADMIN'],
    AuditView: ['SA', 'ADMIN', 'Assistant Internal Auditor', 'Audit Assistant (Grade I)', 'Audit Assistant (Grade II)', 'Deputy Internal Auditor', 'Senior Assistant Internal Auditor', 'Senior Internal Audit Assistant'],
    CourseManagementView: [
        'SA',
        'ADMIN',
        'Dean',
        'HOD',
        'Faculty AR',
        'Faculty MA',
        'Exam MA',
    ],
    DocumentManagementView: [
        'SA',
        'ADMIN',
        'Faculty MA',
        'Faculty AR',
        'SSW MA',
        'SSW AR',
        'Physical Unit AR',
        'Division MA',
    ],
    EmployeeProfileView: [
        'SA',
        'ADMIN',
        'Lecturer',
        'Authorized Council Member',
        'HR AR',
        'AE AR',
        'AE MA',
        'NAE MA',
        'NAE AR',
        'HR MA',
        'Vice Chancellor',
        'SSW MA',
        'SSW AR',
        'Exam AR',
        'Exam MA',
        'HOD',
        'Dean',
        'Faculty AR',
        'Faculty MA',
        'Bursar',
        'Assistant Bursar',
        'Exam AR',
        'Exam MA',
        'Doctor',
        'Nurse',
        'Press MA',
        'Division Head',
        'Registrar',
        'Tempory Employee',
        'Division Head',
    ],
    IDPrint: ['SA', 'ADMIN', 'SSW AR', 'SSW MA', 'Press MA'],
    JobApplicationView: ['Temporary Employee'],

    //bursary
    BursaryApplication: [
        'Undergraduate Student',
        'Temporary Student',
        'ADMIN',
        'SA',
    ],
    ViewApplication: [
        'Undergraduate Student',
        'Temporary Student',
        'SA',
        'ADMIN',
    ],
    AllNewApplication: [
        'SA',
        'ADMIN',
        'Faculty AR',
        'Faculty MA',
        'Vice Chancellor',
    ],
    EligibilityList: [
        'Registrar',
        'Faculty AR',
        'HR AR',
        'AE AR',
        'NAE AR',
        'Vice Chancellor',
        'SSW AR',
        'Exam AR',
        'SA',
        'ADMIN',
    ],
    AllBursaryRecipientList: ['SA', 'ADMIN'],
    CheckApplicant: ['SSW MA', 'SSW AR', 'SA', 'ADMIN'],
    CheckRejectedApplication: ['SA', 'SSW AR', 'ADMIN'],
    Agreement: ['Undergraduate Student', 'Temporary Student', 'SA', 'ADMIN'],
    CheckRecipient: ['SA', 'ADMIN'],
    EditRecipient: ['SA', 'ADMIN'],
    AllMonthlyInstallments: ['SA', 'ADMIN'],
    AllPaymentCriteria: ['Dean', 'Faculty AR', 'Faculty MA', 'SA', 'ADMIN'],
    PaymentCriteria: ['Faculty MA', 'SA', 'ADMIN'],
    AllSingedRecipients: ['Dean', 'Faculty AR', 'Faculty MA', 'SA', 'ADMIN'],
    DocumentSetup: ['SA', 'ADMIN'],
    BankDetails: ['Finance MA', 'SA', 'ADMIN'],
    InstallmentValueSetup: ['SA', 'ADMIN'],

    //Leave
    HR: ['HR AR', 'HR MA', 'ADMIN', 'SA'],

    Timetable: [
        'Registrar',
        'Faculty AR',
        'HR AR',
        'AE AR',
        'NAE AR',
        'Vice Chancellor',
        'Lecturer',
        'SSW AR',
        'Exam AR',
        'SA',
        'Undergraduate Student',
        'Temporary Student',
        'SA',
        'ADMIN',
    ],

   

    StudentAdmission: [
        'Undergraduate Student',
        'Temporary Student',
        'ADMIN',
        'SA'
    ],
    GraduatesList: ['HOD', 'Bursar', 'Assistant Bursar', 'Finance MA', 'SA', 'ADMIN'],

    LectureDay: ['Lecturer','Faculty AR','Temporary Student',],
    ResourceReservationViews: [
        'SA',
        'ADMIN',
        'SSW AR',
        'SSW MA',
        'Faculty AR',
        'HOD',
        'HR AR',
        'AE AR',
        'NAE AR',
        'HR MA',
        'AE MA',
        'NAE MA',
        'Registrar Office MA',
        'Registrar',
        'Registrar Office AR',
        'Temporary Employee',
        'Undergraduate Student',
        // // 'Temporary Student',
        // // 'Temporary Aptitude Student',
        // 'dashboardAccess',
        'Post Graduate Student',
        'Exam AR',
        'Dean',
        'Faculty MA',
        'Finance MA',
        'Bursar',
        'Vice Chancellor',
        'Lecturer',
        'Authorized Council Member',
        'Exam MA',
        'Assistant Bursar',
        'Doctor',
        'Nurse',
        'Press MA',
        'Division Head'
    ]
    
}

// Check out app/views/dashboard/DashboardRoutes.js
// Only SA & Admin has dashboard access

// const dashboardRoutes = [
//   {
//     path: "/dashboard/analytics",
//     component: Analytics,
//     auth: authRoles.admin <===============
//   }
// ];

// Check navigaitons.js

// {
//   name: "Dashboard",
//   path: "/dashboard/analytics",
//   icon: "dashboard",
//   auth: authRoles.admin <=================
// }
