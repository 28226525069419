import React, { useState } from 'react'
import {
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    Button,
    CircularProgress,
    InputAdornment,
    IconButton
} from '@material-ui/core'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import history from 'history.js'
import clsx from 'clsx'
import useAuth from 'app/hooks/useAuth'
import Avatar from '@material-ui/core/Avatar'
import EyeOn from '@material-ui/icons/Visibility';
import EyeOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    cardHolder: {
        // background: '#1A2038',
    },
    card: {
        maxWidth: 800,
        borderRadius: 12,
        margin: '1rem',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    // mobhide:{

    //     display:"none"
    // }
}))

const JwtLogin = () => {
    const [loading, setLoading] = useState(false)
    const [userInfo, setUserInfo] = useState({
        email: '',
        password: '',
    })
    const [message, setMessage] = useState('')
    const [textType, setTextType] = useState('password')

    const { login } = useAuth()

    const classes = useStyles()

    const handleChange = ({ target: { name, value } }) => {
        let temp = { ...userInfo }
        //console.log("pass2", value)
        temp[name] = value
        setUserInfo(temp)
    }

    const handleFormSubmit = async (event) => {
        setLoading(true)
        try {
            await login(userInfo.email, userInfo.password)
            // After displaying the success message, reload the page
            setTimeout(() => {
                window.location.reload();
            });
            history.push('/')
        } catch (e) {
            console.log('error', e)
            setMessage(e.error_description)
            setLoading(false)
        }
    }

    return (
        <div
            className={clsx(
                ' bg-white justify-center items-center',
                classes.cardHolder
            )}
        >
            <Grid
                container
                className="bg-white items-center justify-center h-full-screen"
            >
                {/* Images Container */}
                <Grid
                    item
                    md={8}
                    xs={12}
                    className={['h-full-screen hide-on-mobile']}
                    style={{ backgroundColor: '#afcdfb' }}
                >
                    <div className="p-8 flex justify-center  items-end h-full ">
                        <img
                            className="w-full"
                            src="/assets/images/uni.png"
                            alt=""
                        />
                    </div>
                </Grid>
                {/* Form Container */}
                <Grid container item md={4} xs={12} className="p-8 w-full">
                    <div className="w-full text-center ">
                        <img
                            className="mb-3 w-100"
                            alt="Logo"
                            src="/assets/images/logo_vpa.png"
                        />
                    </div>
                    <div className="w-full text-center mb-8">
                        <span className="text-30 justify-center text-secondary font-bold w-full">
                            University of VAPA
                        </span>
                    </div>

                    <ValidatorForm
                        onSubmit={handleFormSubmit}
                        className="w-full"
                    >
                        <TextValidator
                            className="mb-6 w-full"
                            variant="outlined"
                            size="small"
                            label="Username"
                            onChange={handleChange}
                            //type="email"
                            name="email"
                            value={userInfo.email}
                            validators={['required' /* , 'isEmail'*/]}
                            errorMessages={[
                                'This field is required',
                                'email is not valid',
                            ]}
                        />
                        <TextValidator
                            className="mb-3 w-full"
                            label="Password"
                            variant="outlined"
                            size="small"
                            onChange={(e) => {
                                handleChange(e)
                                // console.log("pass", e.target.value)
                            }
                            }
                            name="password"
                            type={textType}
                            value={userInfo.password}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            disableRipple={true}
                                            type="button"
                                            onClick={() => {
                                                textType == "password" ? setTextType("text") : setTextType("password")
                                            }}
                                        >
                                            {textType == "password"
                                                ? <EyeOff />
                                                : <EyeOn />
                                            }

                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {/* <div className="items-center w-full mb-3">
                            <FormControlLabel
                                className="mb-3 min-w-288"
                                name="agreement"
                                onChange={handleChange}
                                control={
                                    <Checkbox
                                        color="primary"
                                        size="small"
                                        onChange={({ target: { checked } }) =>
                                            handleChange({
                                                target: {
                                                    name: 'agreement',
                                                    value: checked,
                                                },
                                            })
                                        }
                                        checked={userInfo.agreement || true}
                                    />
                                }
                                label="Remeber me"
                            />
                        </div> */}

                        {message && <p className="text-error">{message}</p>}

                        <div className="w-full mb-3">
                            <Button
                                className="w-full"
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                type="submit"
                            >
                                Login to EMS
                            </Button>
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    className={classes.buttonProgress}
                                />
                            )}
                        </div>
                        <div className="w-full mb-3">
                            <a
                                className="text-primary"
                                href="/session/forgot-password"
                            >
                                Forgot password?
                            </a>
                        </div>

                        {/* <div className="flex flex-wrap items-center">
                            <span className="mr-1 ">
                                Don't have an Account?
                            </span>
                            <a
                                className="capitalize text-primary"
                                href="/session/signup"
                            >
                                Create an Account
                            </a>
                        </div> */}
                    </ValidatorForm>
                </Grid>
            </Grid>
        </div>
    )
}

export default JwtLogin

// <div className="flex flex-wrap items-center mb-4">
//                             <div className="relative full-width">
//                                 <Button
//                                     variant="contained"
//                                     color="primary"
//                                     disabled={loading}
//                                     type="submit"
//                                 >
//                                     Sign in
//                                 </Button>
//                                 {loading && (
//                                     <CircularProgress
//                                         size={24}
//                                         className={classes.buttonProgress}
//                                     />
//                                 )}
//                             </div>
//                             <span className="mr-2 ml-5">or</span>
//                             <Button
//                                 className="capitalize"
//                                 onClick={() => history.push('/session/signup')}
//                             >
//                                 Sign up
//                             </Button>
//                         </div>
