/*
Developed By Shiny
Loons Lab
*/
import React, { Component, Fragment } from 'react'
import ImageView from 'app/components/LoonsLabComponents/ImageView'

import {
    Dialog,
    Icon,
    IconButton,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core'

import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'

import CloseIcon from '@material-ui/icons/Close'

import { withStyles, makeStyles } from '@material-ui/styles'

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
})

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose } = props
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
})

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions)

class ViewResource extends Component {
    constructor(props) {
        super(props)
    }

    render(){
        let viewFormData = this.props.data
        return (
            <Fragment>

                {/* Dialog box -> View Resource*/}
                <Dialog
                    open={this.props.open}
                    aria-labelledby="form-dialog-title"
                    maxWidth="sm"
                    className="border-radius-8"
                >
                    <DialogTitle
                        className="text-center"
                        id="alert-dialog-title"
                        onClose={()=>{
                            this.props.handleClose()
                        }}
                    >
                        <Typography
                            className="font-semibold mt-5"
                            variant="h5"
                        >
                            {'View Resource'}
                        </Typography>
                    </DialogTitle>

                    <DialogContent>
                        <Grid container className="" spacing={2}>

                            {/* image */}
                            <Grid item lg={5} md={5}>
                         <Typography variant="subtitle2">Image</Typography>
                    </Grid>
                    <Grid item>
                                                    <ImageView
                                                        image_data={
                                                            viewFormData.ResourceUploads
                                                            
                                                                ? viewFormData
                                                                      .ResourceUploads.extension
                                                                : null
                                                        }
                                                        preview_width="150px"
                                                        preview_height="150px"
                                                    />
                                                </Grid>
                            {/* Resource ID */}
                            <Grid item lg={5} md={5}>
                                <Typography variant="subtitle2">Resource Reference ID </Typography>
                            </Grid> 
                            <Grid item lg={7} md={7} >
                                : {viewFormData.resource_ref_id ? viewFormData.resource_ref_id : " N/A "}
                            </Grid>

                            {/* Resource Name */}
                            <Grid item lg={5} md={5}>
                                <Typography variant="subtitle2">Resource Name </Typography>
                            </Grid> 
                            <Grid item lg={7} md={7} >
                                : {viewFormData.name ? viewFormData.name : " N/A "}
                            </Grid>

                            {/* Resource Type */}
                            <Grid item lg={5} md={5}>
                                <Typography variant="subtitle2">Resource Type </Typography>
                            </Grid> 
                            <Grid item lg={7} md={7} >
                               : {viewFormData.type ? viewFormData.type : "N/A "}
                            </Grid>

                            {/* Ownership Faculty */}
                            <Grid item lg={5} md={5}>
                                <Typography variant="subtitle2">Managed by</Typography>
                            </Grid> 
                            <Grid item lg={7} md={7} >
                               : {viewFormData.Ownership_Faculty ? viewFormData.Ownership_Faculty : "N/A "}
                            </Grid>

                            {/* Division Managed by */}
                            {/* <Grid item lg={5} md={5}>
                                <Typography variant="subtitle2">Division Managed by </Typography>
                            </Grid> 
                            <Grid item lg={7} md={7} >
                               : {viewFormData.Ownership_Division ? viewFormData.Ownership_Division : " N/A "}
                            </Grid> */}

                            {/* Address */}
                            <Grid item lg={5} md={5}>
                                <Typography variant="subtitle2">Address </Typography>
                            </Grid> 
                            <Grid item lg={7} md={7} >
                               : {viewFormData.location ? viewFormData.location : " N/A "}
                            </Grid>

                            {/* Resource Approved by */}
                            <Grid item lg={5} md={5}>
                                <Typography variant="subtitle2">Reservation approved by </Typography>
                            </Grid> 
                            <Grid item lg={7} md={7} >
                               : {viewFormData.resourceApprovalManger ? viewFormData.resourceApprovalManger : " N/A "}
                            </Grid>

                            {/* Resource Recommended by */}
                            <Grid item lg={5} md={5}>
                                <Typography variant="subtitle2">Reservation recommended by </Typography>
                            </Grid> 
                            <Grid item lg={7} md={7} >
                               : {viewFormData.resourceRecommendedBy ? viewFormData.resourceRecommendedBy : " N/A "}
                            </Grid>

                            {/* Seating Capacity */}
                            <Grid item lg={5} md={5}>
                                <Typography variant="subtitle2">Seating Capacity </Typography>
                            </Grid> 
                            <Grid item lg={7} md={7} >
                               : {viewFormData.seating_capacity ? viewFormData.seating_capacity : " N/A "}
                            </Grid>
                            
                            {/* Theory Lec Capacity */}
                            <Grid item lg={5} md={5}>
                                <Typography variant="subtitle2">Theory Lectures Capacity (Seats) </Typography>
                            </Grid> 
                            <Grid item lg={7} md={7} >
                               : {viewFormData.theory_lec_capacity ? viewFormData.theory_lec_capacity : " N/A "}
                            </Grid>

                            {/* Practical Lec Capacity */}
                            <Grid item lg={5} md={5}>
                                <Typography variant="subtitle2">Practical Lectures Capacity (Seats) </Typography>
                            </Grid> 
                            <Grid item lg={7} md={7} >
                               : {viewFormData.practical_lec_capacity ? viewFormData.practical_lec_capacity : " N/A "}
                            </Grid>

                            {/* Theory Exam Capacity */}
                            <Grid item lg={5} md={5}>
                                <Typography variant="subtitle2">Theory Exam Capacity (Seats) </Typography>
                            </Grid> 
                            <Grid item lg={7} md={7} >
                               : {viewFormData.theory_exam_capacity ? viewFormData.theory_exam_capacity : " N/A "}
                            </Grid>

                            {/* Practical Exam Capacity */}
                            <Grid item lg={5} md={5}>
                                <Typography variant="subtitle2">Practical Exam Capacity (Seats) </Typography>
                            </Grid> 
                            <Grid item lg={7} md={7} >
                               : {viewFormData.practical_exam_capacity ? viewFormData.practical_exam_capacity : " N/A "}
                            </Grid>

                            {/* Internal or External */}
                            <Grid item lg={5} md={5}>
                                <Typography variant="subtitle2">Usage-Internal/External </Typography>
                            </Grid> 
                            <Grid item lg={7} md={7} >
                               : {viewFormData.internal_use && viewFormData.external_use ? "Internal & External"
                                        : viewFormData.internal_use ? "Internal" 
                                            : viewFormData.external_use ? "External" : " N/A "}
                            </Grid>

                            {/* Currently or Past Associated */}
                            <Grid item lg={5} md={5}>
                                <Typography variant="subtitle2">Usage-Association </Typography>
                            </Grid> 
                            <Grid item lg={7} md={7} >
                               : {viewFormData.currently_associated ? "Currently Associated"
                                        : viewFormData.past_associated ? "Past Associated": " N/A "}
                            </Grid>

                            {/* If Only Internal Usage */}
                            {viewFormData.internal_use && !viewFormData.external_use && (
                                <>
                                    {/* Reservations Required or Not */}
                                    <Grid item lg={5} md={5}>
                                        <Typography variant="subtitle2">Internal Reservation Requirement </Typography>
                                    </Grid> 
                                    <Grid item lg={7} md={7} >
                                    : {viewFormData.reservation_required || viewFormData.past_reservation_required ? "Reservations Required" : "No Reservations Required"}
                                    </Grid>

                                    {/* Cost Applicable or Not */}
                                    <Grid item lg={5} md={5}>
                                        <Typography variant="subtitle2">Internal Cost Applicability</Typography>
                                    </Grid> 
                                    <Grid item lg={7} md={7} >
                                    : {viewFormData.cost_applicable ? "Cost Applicable" : "Cost Not Applicable"}
                                    </Grid>
                                </>
                            )}

                            {/* If Only External Usage */}
                            {!viewFormData.internal_use && viewFormData.external_use && (
                                <>
                                    {/* Reservations Required or Not */}
                                    <Grid item lg={5} md={5}>
                                        <Typography variant="subtitle2">External Reservation Requirement </Typography>
                                    </Grid> 
                                    <Grid item lg={7} md={7} >
                                    : {viewFormData.reservation_required || viewFormData.past_reservation_required ? "Reservations Required" : "No Reservations Required"}
                                    </Grid>

                                    {/* Cost Applicable or Not */}
                                    <Grid item lg={5} md={5}>
                                        <Typography variant="subtitle2">External Cost Applicability</Typography>
                                    </Grid> 
                                    <Grid item lg={7} md={7} >
                                    : {viewFormData.cost_applicable ? "Cost Applicable" : "Cost Not Applicable"}
                                    </Grid>
                                </>
                            )}
                            
                            {/* If both Internal & External usage */}
                            {viewFormData.internal_use && viewFormData.external_use && (
                                <>
                                    {/* Reservations Required or Not */}
                                    <Grid item lg={5} md={5}>
                                        <Typography variant="subtitle2">Usage-Reservation Requirement </Typography>
                                    </Grid> 
                                    <Grid item lg={7} md={7} >
                                    : {viewFormData.reservation_required || viewFormData.past_reservation_required ? "Reservations Required" : "No Reservations Required"}
                                    </Grid>

                                    {/* Cost Applicable or Not */}
                                    <Grid item lg={5} md={5}>
                                        <Typography variant="subtitle2">Usage-Cost Applicable</Typography>
                                    </Grid> 
                                    <Grid item lg={7} md={7} >
                                    : {viewFormData.cost_applicable ? "Cost Applicable" : "Cost Not Applicable"}
                                    </Grid>
                                </>
                            )}

                            

                        </Grid>
                    </DialogContent>

                    <DialogActions></DialogActions>
                </Dialog>
            </Fragment>
        )
    }
}
export default withStyles()(ViewResource)