import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/styles";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {
    Card,
    Icon,
    IconButton,
    Grid,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,

    Divider,
    Radio,
    RadioGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    Tooltip,
    Fab,
    Switch,
    Typography
} from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import 'date-fns'

import { themeColors } from "app/components/MatxTheme/themeColors";
import { LoonsTable, DatePicker, FilePicker, Button, ExcelToTable, LoonsSnackbar, LoonsDialogBox, LoonsSwitch, MainContainer, LoonsCard, CardTitle } from "app/components/LoonsLabComponents";



const styleSheet = ((theme) => ({

}));

class defaultPage extends Component {
    constructor(props) {
        super(props)
        this.state = {


        }
    }





    componentDidMount() {

    }

    render() {
        let { theme } = this.props;
        const { classes } = this.props



        return (
            <Fragment>
                <h1>public pages</h1>
            </Fragment>
        )
    }
}
export default withStyles(styleSheet)(defaultPage);