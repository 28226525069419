import defaultPage from './defaultPage'
import ApplicantVerifyAccount from './ApplicantVerifyAccount'
import ApplicationForm from './ApplicationForm'
import JobVacancy from './JobVacancy'
import SelectVacancies from '../HumanResource/Designation/Recruitment/SelectVacancies'
import AptRegLanding from './AptRegLanding'
import AllResources from './ResourceReservation'
import LineUpManagementExternal from '../VapaTV/LineUpManagementExternal'

const publicRoutes = [
    {
        path: '/public/home',
        component: defaultPage,
    },

    {
        path: '/aptitude_test_registration',
        component: AptRegLanding,
    },
    {
        path: '/applicant/account/create',
        component: ApplicationForm,
    },
    {
        path: '/jobvacancy',
        component: JobVacancy,
    },

    {
        path: '/aptitude_test_application_form',
        component: ApplicantVerifyAccount,
    },
    {
        path: '/all_resoures',
        component: AllResources,

    },
    {
        path: '/vapa_tv/lineup_management',
        component: LineUpManagementExternal,

    },
    
]

export default publicRoutes
