import { authRoles } from './auth/authRoles'
import { merge } from 'lodash'
import StudentService from 'app/services/StudentService'
// import CertificateClearanceService from 'app/services/CertificateClearanceService'


export const navigations = [
    {
        name: 'Dashboard',
        path: '/dashboard',
        icon: 'dashboard',
        auth: authRoles.dashboard,
    },
    {
        name: 'My Account',
        icon: 'supervised_user_circle',
        auth: authRoles.EmployeeProfileView,

        children: [
            {
                name: 'My Profile',
                path: '/myprofile',
                auth: authRoles.EmployeeProfileView,
            },
        ],
    },
    {
        name: 'Timetable',
        path: '/timetable',
        icon: 'grid_on',
        auth: authRoles.Timetable,
    },
    {
        name: 'Admission',
        path: '/admission',
        icon: 'school',
        auth: authRoles.StudentAdmission,
    },
    {
        name: 'Graduate Lists',
        path: '/graduate_lists',
        icon: 'school',
        auth: authRoles.GraduatesList,
    },
    {
        name: 'All Status',
        path: '/status',
        icon: 'dashboard',
        auth: authRoles.statusView,
    },
    {
        name: 'Intake',
        icon: 'school',
        // auth: merge({}, authRoles.SetUpNewAcademicYear, authRoles.AllStudents, authRoles.itviewIntake),
        auth: authRoles.dataSetup,
        children: [
            {
                name: 'Create Intake',
                path: '/intake/create',
                auth: authRoles.SetUpNewAcademicYear,
            },
            {
                name: 'All Intake',
                path: '/intake/all',
                auth: authRoles.AllStudents,
            },
            {
                name: 'Document Submission Status',
                path: '/intake/students/status',
                auth: authRoles.ViewDocumentStatus,
            },
            // Before - Student Tab
            {
                name: 'Add Student',
                path: '/student/create',
                auth: authRoles.AddNewStudnetManuaklly,
            },

            /* {
                name: 'ma View Intake', //temp
                path: '/ma/viewIntake/1',
                auth: authRoles.maviewIntake,
            }, */

            /*  {
                 name: 'IT View Intake', //temp
                 path: '/itunit/viewIntake/1',
                 auth: authRoles.itviewIntake,
             }, */
        ],
    },
    {
        name: 'Year Renewals',
        icon: 'school',
        auth: authRoles.YearRenewal,
        children: [
            {
                name: 'Create New', //temp
                path: '/ma/setup-year-renewal',
                auth: authRoles.SetUpYearRenwalCreate,
            },
            {
                name: 'View Single Year Renewal',
                path: '/view-single-year-renewal',
                auth: authRoles.SetUpYearRenwalCreate,
            },

            {
                name: 'Year Renewal Requests',
                path: '/hod/year-renewals/',
                auth: authRoles.ListOfYearRenewalRequests,
            },
            // {
            //     name: 'Dean - Year Renewal Requests',
            //     path: '/dean/year-renewals/',
            //     auth: authRoles.ListOfYearRenewalRequests,
            // },
        ],
    },
    {
        name: 'Finance',
        icon: 'local_atm',
        auth: authRoles.FinanceView,
        children: [
            {
                name: 'Student Payments', //temp
                path: '/students/payments',
                auth: authRoles.FinanceView,
            },
        ],
    },
    {
        name: 'IT Unit',
        icon: 'computer',
        auth: authRoles.ITViewIntake,
        children: [
            {
                name: 'All Intakes', //temp
                path: '/itunit/Intakes',
                auth: authRoles.ITViewIntake,
            },
        ],
    },

    {
        name: 'Student',
        icon: 'school',
        // auth: merge({}, authRoles.dataSetup, authRoles.IDPrint),
        auth: authRoles.dataSetup,
        children: [
            {
                name: 'All Students',
                path: '/students/all',
                auth: authRoles.AllStudents,
            },
            {
                name: 'Students Password Set Email',
                path: '/students/sendPasswordLink',
                auth: authRoles.AllStudents,
            },

            /*  {
                 name: 'Student - View Profile',
                 path: '/student/profile',
                 auth: authRoles.appicationView,
             }, */
            {
                name: 'Duplicate ID Card Requests', //temp'
                path: '/studentaffairs/approveAllDuplicateIDRequests',
                auth: authRoles.ListOfIDCards,
            },
            {
                name: 'Student ID - Print',
                path: '/student/student_id_print',
                auth: authRoles.IDPrint,
            },
            /* {
                name: 'Stu.Aff Approve Single Dup.ID Req.', //temp'
                path: '/studentaffairs/approveSingleDuplicateIDRequests',
                auth: authRoles.studentAffairsviewIntake,
            }, */

            {
                name: 'Student - Profile',
                path: '/student/newprofile',
                auth: authRoles.appicationView,
            },
            {
                name: 'Subject Registration',
                path: '/student/subjectregistration',
                auth: authRoles.appicationView,
            },
            // {
            //     name: 'Request Year Renewal',
            //     path: '/student/renewal',
            //     auth: authRoles.appicationView,
            // },
            {
                name: 'AR/SA - Registered Students',
                path: '/student-affairs/students/registered/',
                auth: authRoles.AllStudents,
            },

            /* {
                name: 'HOD - Student Profile View',
                // path: '/hod/student-profile/:id',
                path: '/hod/student-profile/2',
                auth: authRoles.appicationView,
            }, */
            /*  {
                 name: 'Cancellation Registration',
                 path: '/student/cancellationRequest',
                 auth: authRoles.cancellationRequest,
             }, */

            {
                name: 'Student Application',
                path: '/student/application/create',
                auth: authRoles.cancellationRequest,
            },
            {
                name: 'My Applications',
                path: '/student/application/5',
                auth: authRoles.cancellationRequest,
            },
            {
                name: 'My Applications Payment',
                path: '/student/application/pay/5',
                auth: authRoles.cancellationRequest,
            },
            /*  {
                 name: 'Student Appeal',
                 path: '/appeal/create',
                 auth: authRoles.cancellationRequest,
             }, */
            /* {
                name: 'Student Documents',
                path: '/student/Documents/',
                auth: authRoles.cancellationRequest,
            }, */
        ],
    },

    {
        name: 'Appeals',
        icon: 'report',
        auth: merge(
            {},
            authRoles.allCancellationRequestView,
            authRoles.studentAffallCancellationRequestView
        ),
        children: [
            {
                name: 'All Requests',
                path: '/appeal/allAppealRequests',
                auth: authRoles.allCancellationRequestView,
            },
            {
                name: 'All Cancellation Requests',
                path: '/studentAff/allcancellationrequests',
                auth: authRoles.studentAffallCancellationRequestView,
            },
        ],
    },

    {
        name: 'Data Setup',
        icon: 'table_view',
        auth: merge({}, authRoles.dataSetup, authRoles.humanResoursesViews),
        children: [
            {
                name: 'Academic Year',
                path: '/academicYears/create',
                auth: authRoles.dataSetup,
                icon: '',
            },
            {
                name: 'Add External Experts',
                path: '/hr/add_external_experts',
                auth: authRoles.dataSetup,
            },

            {
                name: 'Student Documents',
                path: '/Documents/Student',
                auth: authRoles.SetUpUndergrdRegDocuments,
                icon: '',
            },

            {
                name: 'Course',
                path: '/course/create',
                auth: authRoles.dataSetup,
            },
            {
                name: 'Subject',
                path: '/subject/create',
                auth: authRoles.dataSetup,
            },
            {
                name: 'Degree Program',
                path: '/DegreeProgram/create',
                auth: authRoles.dataSetup,
            },
            {
                name: 'Faculty',
                path: '/faculty_department/faculty/create',
                auth: authRoles.dataSetup,
            },
            {
                name: 'Department',
                path: '/faculty_department/department/view',
                auth: authRoles.dataSetup,
            },
            // {
            // Department create is included to the Department View
            //     name: 'Department',
            //     // icon: 'supervisor_account',
            //     auth: authRoles.dataSetup,
            //     children: [
            //         {
            //             name: 'Create Department',
            //             path: '/faculty_department/department/create',
            //             auth: authRoles.dataSetup,
            //         },

            //     ],
            // },

            {
                name: 'Unit/ Center',
                // icon: 'home',
                auth: authRoles.humanResoursesViews,
                path: '/faculty_department/unit/create',
            },
            {
                name: 'Division',
                // icon: 'home',
                auth: authRoles.humanResoursesViews,
                path: '/division/create',
            },

            {
                name: 'Add Salary Code',
                path: '/hr/add_salary_code',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'Add Service Type',
                path: '/hr/add_service_type',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'Circular Type',
                path: '/hr/add_circular_type',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'Designation Category',
                path: '/hr/designation_category',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'Increment Forms',
                // icon: 'home',
                auth: authRoles.humanResoursesViews,
                path: '/hr/increments/create-forms',
            },
            {
                name: 'HR/Qualifications',
                path: '/hr/confirmation',
                // icon: 'account_box',
                auth: authRoles.humanResoursesViews,
            },

            {
                name: 'Curriculum',
                // icon: 'school',
                auth: authRoles.dataSetup,
                children: [
                    {
                        name: 'MA Create Curriculum',
                        path: '/ma/curriculums/create',
                        auth: authRoles.dataSetup,
                    },
                    {
                        name: 'MA View All Curriculums',
                        path: '/ma/curriculums/all',
                        auth: authRoles.dataSetup,
                    },
                ],
            },

            {
                name: 'Aptitude Appeal',
                icon: 'table_view',
                auth: authRoles.dataSetup,
                children: [
                    {
                        name: 'Aptitude Appeal Type',
                        path: '/AptitdeAppeal/type',
                        auth: authRoles.test,
                        icon: '',
                    },
                    {
                        name: 'Aptitude Appeal Category',
                        path: '/AptitdeAppeal/category',
                        auth: authRoles.test,
                        icon: '',
                    },
                ],
            },
        ],
    },

    /*  {
         name: 'Aptitute Test',
         icon: 'table_view',
         auth: authRoles.aptituteTest,
         children: [
             {
                 name: 'All',
                 path: '/aptitutetest/all',
                 auth: authRoles.aptitudeTestView,
             },
             {
                 name: 'Create',
                 path: '/aptitutetest/create',
                 auth: authRoles.aptituteTestCreate,
             },
             {
                 name: 'New Test Paper',
                 path: '/data-table/expandable-mui-table',
                 auth: authRoles.TestPaperCreate,
             },
             {
                 name: 'All Appications',
                 path: '/data-table/expandable-mui-table',
                 auth: authRoles.appicationView,
             },
         ],
     }, */

    // {
    //     name: 'Cancellations',
    //     icon: 'school',

    //     children: [
    //         /*  {
    //              name: 'Cancellation Requests',
    //              path: '/allcancellationrequests',
    //              auth: authRoles.allCancellationRequestView,
    //          }, */
    //         // {
    //         //     name: 'Cancellation Requests StAff',
    //         //     path: '/studentAff/allcancellationrequests',
    //         //     auth: authRoles.studentAffallCancellationRequestView,
    //         // },
    //     ],
    // },

    // {
    //     name: 'Duplicate ID',
    //     icon: 'school',
    //     children: [
    //         // {
    //         //     name: 'Stu.Aff Approve All Dup.ID Req.', //temp'
    //         //     path: '/studentaffairs/approveAllDuplicateIDRequests',
    //         //     auth: authRoles.studentAffairsviewIntake,
    //         // },
    //         // {
    //         //     name: 'Stu.Aff Approve Single Dup.ID Req.', //temp'
    //         //     path: '/studentaffairs/approveSingleDuplicateIDRequests',
    //         //     auth: authRoles.studentAffairsviewIntake,
    //         // },

    //         /*  {
    //              name: 'MA View List of Dup.ID Req.', //temp
    //              path: '/ma/viewduplicateIDCardsRequests',
    //              auth: authRoles.maviewIntake,
    //          }, */
    //         /*   {
    //               name: 'MA Status Change DuplicateID', //temp
    //               path: '/ma/changeStatusDuplicateID',
    //               auth: authRoles.maviewIntake,
    //           }, */
    //     ],
    // },
    {
        name: 'Postgraduate',
        icon: 'school',
        auth: authRoles.postgraduateCourseCreate,

        children: [
            {
                name: 'Course',
                icon: '',
                auth: authRoles.postgraduateCourseCreate,
                children: [
                    {
                        name: 'Setup Course Type',
                        path: '/postgraduate/setup_course_type',
                        auth: authRoles.postgraduateCourseCreate,
                    },
                    {
                        name: 'Setup Student Type',
                        path: '/postgraduate/setup_student_type',
                        auth: authRoles.postgraduateCourseCreate,
                    },
                    {
                        name: 'Setup Payment Plan',
                        path: '/postgraduate/setup_payment_plan',
                        auth: authRoles.postgraduateCourseCreate,
                    },
                    {
                        name: 'All Courses',
                        path: '/postgraduate/courses',
                        auth: authRoles.postgraduateCourseCreate,
                    },

                    // {

                    //     name: 'Create Course',
                    //     path: '/postgraduate/course/create',
                    //     auth: authRoles.postgraduateCourseCreate,
                    // },
                ],
            },
            {
                name: 'Batch',
                icon: '',
                auth: authRoles.postgraduateCourseCreate,
                children: [
                    {
                        name: 'MA Create Batch',
                        path: '/ma/batch/create',
                        auth: authRoles.postgraduateCourseCreate,
                    },
                    {
                        name: 'MA View All Batches',
                        path: '/ma/batch/all',
                        auth: authRoles.postgraduateCourseCreate,
                    },
                    {
                        name: 'All Students',
                        path: '/ma/student/all',
                        auth: authRoles.postgraduateCourseCreate,
                    },
                ],
            },
            {
                name: 'Applications',
                icon: '',
                auth: authRoles.postgraduateCourseCreate,
                children: [
                    {
                        name: 'AR View All Applications',
                        path: '/ar/applications/',
                        auth: authRoles.postgraduateCourseCreate,
                    },
                    {
                        name: 'Special Circumstance Applications',
                        path: '/ar/special_applications/',
                        auth: authRoles.postgraduateCourseCreate,
                    },
                    {
                        name: 'My Profile',
                        path: '/student/application/view',
                        auth: authRoles.postgraduateStudentView,
                    },
                    {
                        name: 'All Courses',
                        path: '/student/application/all',
                        auth: authRoles.postgraduateStudentView,
                    },
                    {
                        name: 'My Applications',
                        path: '/student/application/my',
                        auth: authRoles.postgraduateStudentView,
                    },
                    {
                        name: 'My Applications Payment',
                        path: '/student/application/pay',
                        auth: authRoles.postgraduateStudentView,
                    },
                ],
            },
        ],
    },

    {
        name: 'Temp',
        icon: 'table_view',
        auth: authRoles.aptituteTest,
        children: [
            // {
            //     name: 'MA View All Batches',
            //     path: '/ma/batch/all',
            //     auth: authRoles.dashboard,
            // },
            {
                name: 'Answer Single View Dean',
                path: '/aptitude_test/answer_single_view_dean',
                auth: authRoles.aptituteTest,
            },
            {
                name: 'Submit Answer By Student',
                path: '/aptitude_test/submit_answer_by_student/25985',
                auth: authRoles.aptituteTest,
            },
            {
                name: 'Results',
                path: '/aptitude_test/results',
                auth: authRoles.aptituteTest,
            },

            // {
            //     name: 'Employee Profile',
            //     path: '/employee/profile',
            //     auth: authRoles.EmployeeProfileView,
            // },
        ],
    },
    // {
    //     name: 'Full Application',
    //     path: '/applicant/account/temp/full_view',
    //     icon: 'account_box',
    //     auth: authRoles.AptitudeStudent,
    // },
    // {
    //     name: 'Application',
    //     path: '/applicant/account/temp/view',
    //     icon: 'account_box',
    //     auth: authRoles.AptitudeStudent,
    // },
    // {
    //     name: 'Create Temp Account',
    //     path: '/applicant/account/temp/create',/applicant/account/temp/full_view
    //     icon: 'manage_accounts',
    //     auth: authRoles.AptitudeStudent,
    // },
    // {
    //     name: 'Payment Information ',
    //     path: '/aptitude_test/applicant/paymentinfo',
    //     icon: 'payment',
    //     auth: authRoles.AptitudeStudent,
    // },
    // {
    //     name: 'All Admissions ',
    //     path: '/aptitude_test/applicant/view',
    //     icon: 'class',
    //     auth: authRoles.AptitudeStudent,
    // },

    // {
    //     name: 'Paper & Submissions ',
    //     path: '/aptitude_test/applicant/examlinks',
    //     icon: 'turned_in',
    //     auth: authRoles.AptitudeStudent,
    // },
    {
        name: 'Help & Support',
        path: '/aptitude_test/applicant/form',
        icon: 'contact_support',
        auth: authRoles.AptitudeStudent,
    },

    // {
    //     name: 'Applicant Aptitude',
    //     icon: 'school',
    //     auth: authRoles.AptitudeStudent,
    //     children: [
    //         /*  {
    //              name: 'Applicant Verify Account',
    //              path: '/aptitude_test_registration?id=11',
    //              auth: authRoles.dashboard,
    //          }, */
    //         {
    //             name: 'Cerate Applicant Temp Account',
    //             path: '/applicant/account/temp/create',
    //             auth: authRoles.AptitudeStudent,
    //         },

    //         //         /* {
    //         //             name: 'View Applicant Temp Account',
    //         //             path: '/applicant/account/temp/view/1',
    //         //             auth: authRoles.PayRegistrationFee,
    //         //         }, */
    //         {
    //             name: 'Applicant Aptitude Test Admission View',
    //             path: '/aptitude_test/applicant/view',
    //             auth: authRoles.AptitudeStudent,
    //         },
    //         {
    //             name: 'Applicant Aptitude Test Appeal Form',
    //             path: '/aptitude_test/applicant/form',
    //             auth: authRoles.AptitudeStudent,
    //         },
    //     ],
    // },
    {
        name: 'PG Applicant',
        icon: 'school',
        auth: authRoles.PGStudent,
        children: [
            {
                name: 'PG - Applicant Create Account',
                path: '/applicant/account/setupPwd',
                auth: authRoles.PGStudent,
            },
            {
                name: 'PG - Application Form',
                path: '/applicant/account/create',
                auth: authRoles.PGStudent,
            },
        ],
    },
    // {
    //     name: 'Aptitude Test',
    //     icon: 'school',
    //     auth: authRoles.dataSetup,
    //     children: [

    //         // {
    //         //     name: 'Fac. Create Examiner Panel',
    //         //     path: '/aptitude_test/faculty/createExaminerPanel',
    //         //     auth: authRoles.dashboard,
    //         // },
    //         // {
    //         //     name: 'Fac. Assign Papers',
    //         //     path: '/aptitude_test/faculty/assignPapers',
    //         //     auth: authRoles.dashboard,
    //         // },
    //         /*  {
    //              name: 'Fac. Create Aptitude Examiner List',
    //              path: '/aptitude_test/faculty/createAptitudeExaminerList',
    //              auth: authRoles.dashboard,
    //          }, */
    //         // {
    //         //     name: 'Fac. Schedule Interviews',
    //         //     path: '/aptitude_test/faculty/scheduleinterviews',
    //         //     auth: authRoles.dashboard,
    //         // },
    //         {
    //             name: 'Fac Board. View Examiner List',
    //             path: '/aptitude_test/faculty_Board/viewExaminerList',
    //             auth: authRoles.examinerListView,
    //         },
    //         {
    //             name: 'Senate. Approve Examiner List',
    //             path: '/aptitude_test/senate/approveExaminerList',
    //             auth: authRoles.examinerListView,
    //         },
    //     ],
    // },

    {
        name: 'Examinations',
        icon: 'account_balance',
        auth: authRoles.ExaminationView,

        children: [
            {
                name: 'All Examinations',
                path: '/examinations/all',
                auth: authRoles.ExaminationView,
            },
            {
                name: 'Semester Exams',
                path: '/examinations/semester_wise',
                auth: authRoles.ExaminationView,
            },
            {
                name: 'All Interview List',
                path: '/aptitude_test/all_interview_list',
                auth: authRoles.assignInterviewsView,
            },
            {
                name: 'Setup Grades',
                path: '/examinations/setup_grades',
                auth: authRoles.ExaminationView,
            },
            {
                name: 'Configure Grades',
                path: '/examinations/configurations_grades',
                auth: authRoles.ExaminationView,
            },
            {
                name: 'Configure Class',
                path: '/examinations/configurations_class',
                auth: authRoles.ExaminationView,
            },
            {
                name: 'Configure Total Credit',
                path: '/examinations/configure_total_credit',
                auth: authRoles.ExaminationView,
            },
            
            // {
            //     name: 'Enter Results',
            //     path: '/examinations/enter_marks',
            //     auth: authRoles.ExaminationView,
            // },
            // {
            //     name: 'Batch Wise All Student Results',
            //     path: '/examinations/batch_wise_all_students_results',
            //     auth: authRoles.ExaminationView,
            // },
            // {
            //     name: 'Intake wise Results',
            //     path: '/examinations/all_intakes',
            //     auth: authRoles.ExaminationView,
            // },

        ],
    },
    {
        name: 'Employee',
        icon: 'people_alt',
        auth: authRoles.humanResoursesViews,
        path: '/hr/all_employees',
    },
    {
        name: 'Reports',
        icon: 'style',
        auth: authRoles.humanResoursesViews,
        path: '/generate-reports/employee-report',
    },

    {
        name: 'Circulars',
        icon: 'assignment',
        auth: authRoles.humanResoursesViews,
        path: '/hr/create_circular',
    },
    {
        name: 'Designation',
        icon: 'account_box',
        auth: authRoles.humanResoursesViews,
        path: '/hr/all_designations',
    },
    {
        name: 'Leave',
        icon: 'exit_to_app',
        auth: authRoles.statusView,

        children: [
            {
                name: 'Setup New Leave',
                path: '/hr/leave_setup',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'All Leave Requests',
                path: '/Leave/EmployeeLeaveRequests',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'Leave Cancellation Requests',
                path: '/Leave/leave-cancellations',
                auth: authRoles.statusView,
            },
            {
                name: 'Substitute Leave Requests',
                path: '/Leave/leave-substitutes/',
                auth: authRoles.statusView,
            },
            {
                name: 'Leave Requests Approvals',
                path: '/Leave/leave-approval-requests/',
                auth: authRoles.statusView,
            },
            {
                name: 'My Leaves',
                path: '/Leave/my-leaves/',
                auth: authRoles.statusView,
            },
            {
                name: 'Overtime Details',
                path: '/Leave/overtime-details/',
                auth: authRoles.FinanceView,
            },
            {
                name: 'Attendance Details',
                path: '/Leave/attendance-details/',
                auth: authRoles.HR,
            },
            {
                name: 'Leave Summary',
                path: '/Leave/leave-summary/',
                auth: authRoles.HR,
            },
            {
                name: 'Other Leave Setup',
                path: '/Leave/other-leave-setup/',
                auth: authRoles.HR,
            },
            {
                name: 'HR Calender',
                path: '/Leave/add-calender-events/',
                auth: authRoles.HR,
            },
        ],
    },
    {
        name: 'Resignation Requests',
        icon: 'exit_to_app',
        auth: authRoles.statusView,
        children: [
            {
                name: 'All Resignation Requests',
                path: '/employee/all_resignations',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'Resignation Approvals',
                path: '/employee/resignation_hod',
                auth: authRoles.statusView,
            },
        ],
    },
    {
        name: 'Retirement',
        icon: 'exit_to_app',
        path: '/hr/academic_retirements/',
        auth: authRoles.hrRetirementViews,
    },
    {
        name: 'Retirement',
        icon: 'exit_to_app',
        path: '/hr/non_academic_retirements/',
        auth: authRoles.hrNARetirementViews,
    },
    // {
    //     name: 'Attendance',
    //     icon: 'account_box',
    //     auth: authRoles.humanResoursesViews,
    //     path: '/hr/employee/attendance',
    // },
    {
        name: 'Increments',
        icon: 'plus_one',
        auth: authRoles.statusView,

        children: [
            {
                name: 'Increment Eligible List',
                path: '/hr/increments/eligible-list',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'Increment Approval Requests',
                path: '/hr/increments/eligible-approval-requests',
                auth: authRoles.statusView,
            },
            // {
            //     name: 'Confirmed List',
            //     path: '/hr/increments/confirmed_list',
            //     auth: authRoles.humanResoursesViews,
            // },
            // {
            //     name: 'Recommendation List',
            //     path: '/hr/increments/recommendation-list',
            //     auth: authRoles.humanResoursesViews,
            // },
        ],
    },

    {
        name: 'Promotions',
        icon: 'trending_up',
        auth: authRoles.statusView,

        children: [
            {
                name: 'Setup Hierarchy',

                path: '/hr/setup_hierarchy',
                auth: authRoles.humanResoursesViews,
            },

            {
                name: 'All Promotion Requests',
                path: '/promotion/all-promotion-requests',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'Promotion Requests Approvals',
                path: '/promotion/promotion-requests-approvals',
                auth: authRoles.statusView,
            },
        ],
    },
    {
        name: 'HR',
        icon: 'people_alt',
        auth: authRoles.FinanceView,
        // auth: authRoles.statusView,
        children: [
            {
                name: 'Promotions',
                path: '/promotion/promotion-approvals-finance',
                auth: authRoles.FinanceView,
            },
            {
                name: 'Increments',
                path: '/increments/increment-approvals-finance',
                auth: authRoles.FinanceView,
                // auth: authRoles.statusView,
            },
        ],
    },

    // {
    //     name: 'Transfers',
    //     icon: 'account_box',
    //     auth: authRoles.humanResoursesViews,
    //     path: '/hr/employee-transfers',
    // },
    // {
    //     name: 'Payments',
    //     icon: 'format_list_numbered',
    //     path: '/hr/payments/year-renewal',
    //     auth: authRoles.humanResoursesViews,
    // },
    // {
    //     name: 'Temp Proile',
    //     icon: '',
    //     auth: authRoles.humanResoursesViews,

    //     children: [
    //         {
    //             name: 'Applicant Duty Acceptance',
    //             path: '/hr/applicant/duty_acceptance',
    //             auth: authRoles.humanResoursesViews,
    //         },
    //     ],
    // },
    {
        name: 'Cardre',
        icon: 'supervised_user_circle',
        auth: authRoles.humanResoursesViews,
        path: '/hr/add_new_cardre',
    },

    {
        name: 'Recruitment',
        icon: '',
        auth: authRoles.humanResoursesViews,

        children: [
            // {
            //     name: 'Add External Experts',
            //     path: '/hr/add_external_experts',
            //     auth: authRoles.humanResoursesViews,
            // },

            // {
            //     name: 'HOD - Request Recruitment',
            //     path: '/hod/request_recruitment',
            //     auth: authRoles.dataSetup,
            // },
            {
                name: 'HOD - Create Special Notice',
                path: '/hod/create_special_notice',
                auth: authRoles.humanResoursesViews,
            },

            {
                // name: 'AR - View Special Notice',
                name: 'AR - Advertisements',
                path: '/ar/view_special_notice',
                auth: authRoles.humanResoursesViews,
            },
            // {
            //     name: 'Applicant - View Vacancies',
            //     path: '/applicant/view_vacancies',
            //     auth: authRoles.dashboard,
            // },
            {
                name: 'AR - View All Recrutment Details',
                path: '/ar/view_all_recruitment_details',
                auth: authRoles.humanResoursesViews,
            },
            // {
            //     name: 'AR - View Single Recrutment Details',
            //     path: '/ar/view_single_recruitment_details',
            //     auth: authRoles.dataSetup,
            // },
            {
                name: 'AR - View Duty Acceptance Status',
                path: '/ar/view_duty_acceptance_status',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'View All Job Applications',
                path: '/hr/employee/alljobapplications',
                auth: authRoles.humanResoursesViews,
            },
        ],
    },
    {
        name: 'Practical Examination',
        icon: '',
        auth: authRoles.humanResoursesViews,

        children: [
            {
                name: 'HOD - Requesting Practical Examination',
                path: '/hod/requesting_practical_examination',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'VC - Appoint Practical Examination Panel',
                path: '/vc/appoint_practical_examination_panel',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'HOD - Set Basic Detail PE',
                path: '/hod/set_basic_details_of_practical_examination',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'HOD - Send Appointment Letters',
                path: '/hod/send_appointment_letters',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'HOD - Send PE Details to Candicate',
                path: '/hod/send_practical_examination_details_to_candicate',
                auth: authRoles.humanResoursesViews,
            },

            {
                name: 'PM - View All Appointments',
                path: '/panel_member/view_appointments',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'PM - Accept Appointments',
                path: '/panel_member/accept_appointments',
                auth: authRoles.humanResoursesViews,
            },
            // {
            //     name: 'PM - View Appointment Details',
            //     path: '/panel_member/view_appointment',
            //     auth: authRoles.dashboard,
            // },

            {
                name: 'PM - View Applicants',
                path: '/panel_member/view_applicants',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'HOD - View Panel Member Marks',
                path: '/hod/view_panel_member_marks',
                auth: authRoles.humanResoursesViews,
            },

            // {
            //     name: 'PM - View Marksheet',
            //     path: '/panel_member/view_marks',
            //     auth: authRoles.humanResoursesViews,
            // },
            {
                name: 'PM - View Payment Details',
                path: '/panel_member/view_payment_details',
                auth: authRoles.humanResoursesViews,
            },
        ],
    },
    {
        name: 'Written Examination',
        icon: '',
        auth: authRoles.humanResoursesViews,
        children: [
            {
                name: 'AR - Request Written Exam',
                path: '/ar/request_written_examination',
                auth: authRoles.humanResoursesViews,
            },

            {
                name: 'VC - Appoint Staff for Written Exam',
                path: '/vc/appoint_written_examination_staff',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'AR (ED) - View All Exam Requests',
                path: '/ar_ed/view_all_exam_requests',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'AR (ED) - Set Basic Details of WE',
                path: '/ar_ed/set_basic_details_of_written_examination',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'AR (ED) - Send Appointment Letters',
                path: '/ar_ed/send_appointment_letters',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'AR (ED) - Send Admission to Applicants',
                path: '/ar_ed/send_written_examination_admissions',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'AR (ED) - View All Recruitment Examinations',
                path: '/ar_ed/view_all_recruitments_examinations',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'PS - Create Paper',
                path: '/ps/create_paper',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'PS - Create Marking Scheme',
                path: '/ps/create_marking_scheme',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'AR (ED) - Add Cutoff Marks',
                path: '/ar_ed/add-cut_off_marks',
                auth: authRoles.humanResoursesViews,
            },
        ],
    },

    {
        name: 'Interview',
        icon: '',
        auth: authRoles.humanResoursesViews,

        children: [
            {
                name: 'AR - Interview',
                path: '/ar/request_interview',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'VC - Appoint Panel',
                path: '/vc/appoint_interview_panel',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'AR - Set Basic Details of Interview',
                path: '/ar/set_basic_details_of_interview',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'AR - Send Appointment Letters',
                path: '/ar/send_appointment_letters',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'AR - Send Interview Details',
                path: '/ar/send_interview_details',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'AR - View Individuals Marks',
                path: '/ar/view_individuals_marks',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'PM- Accept Appointments',
                path: '/interview_panel_member/accept_appointments',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'PM- View Basic Details of an Interview',
                path: '/interview_panel_member/view_appointment',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'PM- View Applicants',
                path: '/panel_member/view_my_applicants',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'PM- View Panel Marks',
                path: '/panel_head/view_panel_marks',
                auth: authRoles.humanResoursesViews,
            },
            {
                name: 'PM- Select Applicants',
                path: '/panel_head/select_applicants',
                auth: authRoles.humanResoursesViews,
            },
        ],
    },
    // Recruitment Application
    {
        name: 'Application',
        icon: '',
        auth: authRoles.JobApplicationView,
        path: '/hr/recruitments/recruitment_application',
        show: localStorage?.userInfo
            ? JSON.parse(localStorage?.userInfo ? localStorage.userInfo : '')
                ?.roles?.length > 5
                ? false
                : true
            : true,
    },
    {
        name: 'My Applications',
        icon: '',
        auth: authRoles.JobApplicationView,
        path: '/hr/recruitments/my_applications',
        // show: localStorage?.userInfo
        //     ? JSON.parse(localStorage?.userInfo ? localStorage.userInfo : '')
        //         ?.roles?.length > 5
        //         ? false
        //         : true
        //     : true,
    },
    {
        name: 'Medical Unit',
        icon: 'local_hospital',
        auth: authRoles.MedicalForDoctorNurse,
        children: [
            // {
            //     name: 'Student List',
            //     path: '/medical_unit/student/student_list',AccountBalanceWallet
            //     auth: authRoles.dataSetup,
            // },
            {
                name: 'Todays Prescription',
                path: '/medical_unit/todays_prescription',
                icon: 'account_balance_wallet',
                auth: authRoles.MedicalForDoctorNurse,
            },
            {
                name: 'Scan Patient',
                icon: 'search',
                auth: authRoles.MedicalForDoctorNurse,
                path: '/medicalunit/scanpatient',
            },
            {
                name: 'View Issued Drug Details',
                icon: 'assignment',
                auth: authRoles.MedicalForDoctorNurse,
                path: '/medicalunit/viewissueddrugdetails',
            },
            {
                name: 'Overall Checkup Details',
                icon: 'ballot',
                auth: authRoles.MedicalForDoctorNurse,
                path: '/medicalunit/overallmedicalcheckupdetails',
            },
            {
                name: 'Overall Medical Details',
                icon: 'class',
                auth: authRoles.MedicalForDoctorNurse,
                path: '/medicalunit/overallmedicaldetails',
            },
            {
                name: 'Family - Medical Details',
                icon: 'perm_contact_calendar',
                auth: authRoles.MedicalForDoctorNurse,
                path: '/medicalunit/overallmedicalcheckupdetailsoffamily',
            },
            {
                name: 'Medical Items',
                icon: 'post_add',
                auth: authRoles.MedicalForDoctorNurse,
                path: '/medical_unit/medical_items',
            },
        ],
    },
    {
        name: 'Document Management',
        icon: 'library_books',
        auth: authRoles.DocumentManagementView,
        children: [
            {
                name: 'Committees',
                icon: '',

                children: [
                    {
                        name: 'Commitee Set up',
                        path: '/document_management/committees/setup',
                        auth: authRoles.DocumentManagementView,
                    },
                    {
                        name: 'View All Committees',
                        path: '/document_management/committees/all_committes',
                        auth: authRoles.DocumentManagementView,
                    },
                ],
            },
            {
                name: 'View All Meetings',
                path: '/document_management/committees/all_meeting',
                auth: authRoles.DocumentManagementView,
            },
            {
                name: 'View All Memos',
                path: '/document_management/committees/all_memo',
                auth: authRoles.DocumentManagementView,
            },
            {
                name: 'Memo Recommendations',
                path: '/document_management/commitees/all_memo_recommendations',
                auth: authRoles.DocumentManagementView,
            },
        ],
    },
    {
        name: 'Course Management',
        icon: 'library_books',
        auth: authRoles.CourseManagementView,
        children: [
            {
                name: 'Assessments',
                icon: '',

                children: [
                    {
                        name: 'Assessment Setup',
                        path: '/course_management/assesment/setup',
                        auth: authRoles.CourseManagementView,
                    },
                    {
                        name: 'Learning Outcome Setup',
                        path: '/course_management/learning_outcome_alignment/setup',
                        auth: authRoles.CourseManagementView,
                    },
                ],
            },
            {
                name: 'Module',
                icon: '',

                children: [
                    // {
                    //     name: 'Course Setup',
                    //     path: '/course_management/course/setup',
                    //     auth: authRoles.dashboard,
                    // },
                    {
                        name: 'Module Setup',
                        path: '/course_management/course/subject_setup',
                        auth: authRoles.CourseManagementView,
                    },
                ],
            },
            {
                name: 'Resource Allocation',
                icon: '',

                children: [
                    {
                        name: 'Add New Resource',
                        path: '/course_management/resource_allocation/add_new_resource',
                        auth: authRoles.CourseManagementView,
                    },
                ],
            },
            {
                name: 'Academic Calendar',
                icon: '',

                children: [
                    {
                        name: 'Create Academic Calendar',
                        path: '/course_management/academic_calendar/create_academic_calendar',
                        auth: authRoles.CourseManagementView,
                    },
                    {
                        name: 'View Academic Calendar',
                        path: '/course_management/academic_calendar/view_academic_calendar',
                        auth: authRoles.CourseManagementView,
                    },
                ],
            },
            {
                name: 'Temporary Lectures',
                icon: '',

                children: [
                    {
                        name: 'Recruit Temporary Lectures',
                        path: '/course_management/temporary_lectures/recruit',
                        auth: authRoles.CourseManagementView,
                    },
                ],
            },
            {
                name: 'Visiting Lectures',
                icon: '',

                children: [
                    {
                        name: 'Approval Process',
                        path: '/course_management/visiting_lectureres/approval_process',
                        auth: authRoles.CourseManagementView,
                    },
                    {
                        name: 'Registration',
                        path: '/course_management/visiting_lectureres/registration',
                        auth: authRoles.CourseManagementView,
                    },
                    {
                        name: 'View',
                        path: '/course_management/visiting_lectureres/view',
                        auth: authRoles.CourseManagementView,
                    },
                ],
            },
            {
                name: 'Syllabus',
                icon: '',

                children: [
                    // {
                    //     name: 'Create Syllabus',
                    //     path: '/course_management/syllabus/create',
                    //     auth: authRoles.CourseManagementView,
                    // },
                    {
                        name: 'All Syllabuses',
                        path: '/course_management/all_syllabus',
                        auth: authRoles.CourseManagementView,
                    },
                   
                    // {
                    //     name: 'Student Department Preference',
                    //     path: '/course_management/student_department_preference',
                    //     auth: authRoles.CourseManagementView,
                    // },
                ],
            },
            {
                name: 'Work Norm',
                icon: '',

                children: [
                    {
                        name: 'Work Norm',
                        path: '/course_management/work_norm/work_norm',
                        auth: authRoles.CourseManagementView,
                    },
                    {
                        name: 'Attendance Lecturer',
                        path: '/course_management/work_norm/attendance/lecturer',
                        auth: authRoles.CourseManagementView,
                    },
                    {
                        name: 'Attendance Students',
                        path: '/course_management/work_norm/attendance/students',
                        auth: authRoles.CourseManagementView,
                    },
                    {
                        name: 'Faculty MA',
                        path: '/course_management/work_norm/faculty_ma',
                        auth: authRoles.CourseManagementView,
                    },
                    {
                        name: 'Faculty HOD',
                        path: '/course_management/work_norm/faculty_hod',
                        auth: authRoles.CourseManagementView,
                    },
                    {
                        name: 'Faculty Dean',
                        path: '/course_management/work_norm/faculty_dean',
                        auth: authRoles.CourseManagementView,
                    },
                    {
                        name: 'Audit',
                        path: '/course_management/work_norm/audit_view',
                        auth: authRoles.CourseManagementView,
                    },
                ],
            },
            {
                name: 'Department Selection',
                icon: '',

                children: [
                    {
                        name: 'Student Information',
                        path: '/course_management/department_selection/student_information',
                        auth: authRoles.CourseManagementView,
                    },
                    {
                        name: 'Department Details',
                        path: '/course_management/department_selection/department_details',
                        auth: authRoles.CourseManagementView,
                    },
                    {
                        name: 'Marks',
                        path: '/course_management/department_selection/marks',
                        auth: authRoles.CourseManagementView,
                    },
                ],
            },
            {
                name: 'Subject Registration',
                icon: '',
                path: '/course_management/subject_registration',
                auth: authRoles.CourseManagementView,
            },
            {
                name: 'Work Details',
                icon: '',
                path: '/course_management/work_details',
                auth: authRoles.CourseManagementView,
            },
            {
                name: 'Setup Timetable',
                path: '/course_management/setup_timetable',
                auth: authRoles.CourseManagementView,
            },
            {
                name: 'Department Registration Inclide',
                path: '/course_management/department_registration',
                auth: authRoles.CourseManagementView,
            },
        ],
    },

    {
        name: 'Audit',
        icon: 'pageview',
        auth: authRoles.AuditView,
        children: [
            {
                name: 'All Audits', //temp
                path: '/audit_view/allaudits',
                auth: authRoles.AuditView,
            },
            {
                name: 'Audit - Check List Set up',
                path: '/audit_view/checklist',
                auth: authRoles.AuditView,
            },
        ],
    },
    {
        name: 'Research Grant',
        icon: 'redeem',
        auth: authRoles.researchGrant,
        children: [
            {
                name: 'Grants', //temp
                path: '/research-grant/view',
                auth: authRoles.researchGrant,
            },
            {
                name: 'Allowances',
                path: '/research-grant/view_allowance',
                auth: authRoles.researchGrant,
            },
            {
                name: 'Grants Applications Overview', //temp
                path: '/research-grant/overview',
                auth: authRoles.researchGrant,
            },
            {
                name: 'Allowances Applications Overview',
                path: '/research-grant/overview?type=allowance',
                auth: authRoles.researchGrant,
            },
            // {
            //     name: 'All Research Allowances',
            //     path: '/research-grant/all_allowances',
            //     auth: authRoles.researchGrant,
            // },
            {
                name: 'Overview',
                path: '/research-grant/employee_overview',
                auth: authRoles.researchGrantOverview,
            },
            // {
            //     name: 'EmployeeView-All Grants & Apply',
            //     path: '/research-grant/employeeview_allgrants',
            //     auth: authRoles.researchGrant,
            // },
            // {
            //     name: 'E-My Research Allowances Request',
            //     path: '/research-grant/employee_research_allowances_requests',
            //     auth: authRoles.researchGrant,
            // },
        ],
    },

    {
        name: 'Welfare',
        icon: 'school',
        auth: authRoles.WelfareView,
        children: [
            {
                name: 'Season',
                icon: 'supervisor_account',
                //auth: authRoles.WelfareView,
                auth: authRoles.dataSetup,
                children: [
                    {
                        name: 'Seasons Management', //temp
                        path: '/welfare/season/seasons-management',
                        //auth: authRoles.WelfareView,
                        auth: authRoles.dataSetup,
                    },
                ],
            },

            {
                name: 'Hostels',
                icon: '',
                auth: authRoles.dataSetup,
                children: [
                    {
                        name: 'Hostel Intakes', //temp
                        path: '/welfare/hostel/create-intake',
                        auth: authRoles.dataSetup,
                    },
                    {
                        name: 'Hostel Applications', //temp
                        path: '/welfare/hostel/hostel-applications',
                        auth: authRoles.dataSetup,
                    },
                    {
                        name: 'Student Notice', //temp
                        path: '/welfare/hostel/student-notice',
                        auth: authRoles.dataSetup,
                    },
                    {
                        name: 'Hostel Notice', //temp
                        path: '/welfare/hostel/hostel-notice',
                        auth: authRoles.dataSetup,
                    },
                    {
                        name: 'Resource Reservation', //temp
                        path: '/welfare/hostel/resource-reservation/',
                        auth: authRoles.dataSetup,
                    },
                ],
            },

            {
                name: 'Field Visits',
                icon: '',
                auth: authRoles.dataSetup,
                children: [
                    {
                        name: 'Uploading Reports', //temp
                        path: '/welfare/field-visit/uploading-reports',
                        auth: authRoles.dataSetup,
                    },
                    {
                        name: 'View All Field Visits', //temp
                        path: '/welfare/field-visit/view-all-field-visits',
                        auth: authRoles.dataSetup,
                    },
                    {
                        name: 'Field Visit Requests', //temp
                        path: '/welfare/field-visit/all-field-visit-approval-view',
                        auth: authRoles.dataSetup,
                    },
                ],
            },

            {
                name: 'Scholarship',
                icon: 'school',
                auth: authRoles.dataSetup,
                children: [
                    {
                        name: 'All Scholarships',
                        path: '/welfare/scholarships/all_scholarship',
                        auth: authRoles.dataSetup,
                    },
                ],
            },

            {
                name: 'Scholarships',
                icon: 'reserach',
                auth: authRoles.dataSetup,
                children: [
                    // {
                    //     name: 'New Scholarships PC1', //temp
                    //     path: '/welfare/scholarships/new_cholarships_pc1',
                    //     auth: authRoles.dataSetup,
                    // },
                    {
                        name: 'New Scholarships Selections', //temp
                        path: '/welfare/scholarships/new_scholarships_selections',
                        auth: authRoles.dataSetup,
                    },
                    {
                        name: 'All Awards', //temp
                        path: '/welfare/scholarships/all_awarded_scholarships',
                        auth: authRoles.dataSetup,
                    },
                    // {
                    //     name: 'Student Selections EAY', //temp
                    //     path: '/welfare/scholarships/student_selections_EAY',
                    //     auth: authRoles.dataSetup,
                    // },
                    // {
                    //     name: 'Student Selections PC', //temp
                    //     path: '/welfare/scholarships/student_selections_PC',
                    //     auth: authRoles.dataSetup,
                    // },
                    // {
                    //     name: 'Student Selections Other', //temp
                    //     path: '/welfare/scholarships/student_selections_Other',
                    //     auth: authRoles.dataSetup,
                    // },
                    // {
                    //     name: 'Approval EAY', //temp
                    //     path: '/welfare/scholarships/approval_EAY',
                    //     auth: authRoles.dataSetup,
                    // },
                    // {
                    //     name: 'Approval PC', //temp
                    //     path: '/welfare/scholarships/approval_PC',
                    //     auth: authRoles.dataSetup,
                    // },
                    // {
                    //     name: 'Approval Other', //temp
                    //     path: '/welfare/scholarships/approval_Other',
                    //     auth: authRoles.dataSetup,
                    // },
                    // {
                    //     name: 'Awarding EAY', //temp
                    //     path: '/welfare/scholarships/awarding_EAY',
                    //     auth: authRoles.dataSetup,
                    // },
                    // {
                    //     name: 'Awarding PC', //temp
                    //     path: '/welfare/scholarships/awarding_PC',
                    //     auth: authRoles.dataSetup,
                    // },
                    // {
                    //     name: 'Awarding Other', //temp
                    //     path: '/welfare/scholarships/awarding_Other',
                    //     auth: authRoles.dataSetup,
                    // },
                ],
            },
            {
                name: 'Bursary',
                icon: '',
                auth: authRoles.dataSetup,
                children: [
                    {
                        name: 'All New Applicants',
                        path: '/welfare/bursary/all-new-applicants',
                        auth: authRoles.AllNewApplication,
                    },
                    {
                        name: 'Eligibility List',
                        path: '/welfare/bursary/eligibility-list',
                        auth: authRoles.EligibilityList,
                    },
                    {
                        name: 'All Bursary Recipient List',
                        path: '/welfare/bursary/all-bursary-recipient-list',
                        auth: authRoles.AllBursaryRecipientList,
                    },
                    {
                        name: 'All Monthly Installments',
                        path: '/welfare/bursary/all-monthly-installments',
                        auth: authRoles.AllMonthlyInstallments,
                    },
                    {
                        name: 'All Payment Criteria',
                        path: '/welfare/bursary/all-payment-criteria',
                        auth: authRoles.AllPaymentCriteria,
                    },
                    {
                        name: 'All Singed Recipients',
                        path: '/welfare/bursary/all-singed-recipients',
                        auth: authRoles.AllSingedRecipients,
                    },
                    {
                        name: 'Document Setup',
                        path: '/welfare/bursary/DocumentSetup',
                        auth: authRoles.DocumentSetup,
                    },
                    {
                        name: 'Bank Details',
                        path: '/welfare/bursary/bank-details',
                        auth: authRoles.BankDetails,
                    },
                    {
                        name: 'Installment Value Setups',
                        path: '/welfare/bursary/installment-value-setup',
                        auth: authRoles.InstallmentValueSetup,
                    },
                ],
            },
        ],
    },
    {
        name: 'Welfare',
        icon: 'school',
        auth: authRoles.appicationView,
        children: [

            {
                name: 'Season',
                icon: '',
                auth: authRoles.appicationView,
                children: [
                    {
                        name: 'Student Season', //temp
                        path: '/welfare/season/student-seasons',
                        auth: authRoles.appicationView,
                    },
                ],
            },
            {
                name: 'Hostel',
                icon: '',
                auth: authRoles.appicationView,
                children: [
                    {
                        name: 'Student Hostel', //temp
                        path: '/welfare/hostel/student-hostel',
                        auth: authRoles.appicationView,
                    },
                ],
            },
            {
                name: 'Bursary',
                icon: '',
                auth: authRoles.appicationView,
                children: [
                    {
                        name: 'Bursary Application',
                        path: '/welfare/bursary/bursary-application',
                        auth: authRoles.BursaryApplication,
                    },
                    // {
                    //     name: 'View Application',
                    //     path: '/welfare/bursary/view-application',
                    //     auth: authRoles.ViewApplication,
                    // },
                    {
                        name: 'Agreement',
                        path: '/welfare/bursary/Agreement',
                        auth: authRoles.Agreement,
                    },
                ],
            },
        ],
    },
    {
        name: 'VAPA Press',
        icon: 'live_tv',
        auth: authRoles.vouchers,
        children: [

            {
                name: 'Author Registration',
                path: '/vapa_press/author_registration',
                auth: authRoles.vouchers,
            },
            {
                name: 'Application Submission',
                path: '/vapa_press/application_submission',
                auth: authRoles.vouchers,
            },
            // {
            //     name: 'Application Approval',
            //     path: '/vapa_press/application_approval',
            //     auth: authRoles.vouchers,
            // },
            {
                name: 'My Book Applications',
                path: '/vapa_press/all_view_author',
                auth: authRoles.vouchers,
            },
            {
                name: 'All Application ',
                path: '/vapa_press/all_applications',
                auth: authRoles.vouchers,
            },
            {
                name: 'Agreement Setup ',
                path: '/vapa_press/agreement_setup',
                auth: authRoles.vouchers,
            },
            {
                name: 'Agreement Create ',
                path: '/vapa_press/agreement_create',
                auth: authRoles.vouchers,
            },
            {
                name: 'Manuscript Resubmit Author ',
                path: '/vapa_press/manuscript_resubmit_author',
                auth: authRoles.vouchers,
            },
            {
                name: 'Manuscript Upload Manuscripts By MA   ',
                path: '/vapa_press/manuscript_upload_manuscripts_by_ma',
                auth: authRoles.vouchers,
            },
            {
                name: 'Manuscript Send For Checking By MA   ',
                path: '/vapa_press/manuscript_send_for_checking_by_ma',
                auth: authRoles.vouchers,
            },
            // {
            //     name: 'View   ',
            //     path: '/vapa_press/manuscript_accept_by_ma',
            //     auth: authRoles.vouchers,
            // },
            {
                name: 'Trans Book Legal', //temp
                path: '/vapa_press/trans_book_legal',
                auth: authRoles.vouchers,
            },
            {
                name: 'Trans Book Approve', //temp
                path: '/vapa_press/trans_book_approve',
                auth: authRoles.vouchers,
            },

            {
                name: 'All Book Reviews', //temp
                path: '/vapa_press/all_book_reviews',
                auth: authRoles.vouchers,
            },
            {
                name: 'Manuscript Accepted by MA', //temp
                path: '/vapa_press/manuscript_accept_by_ma',
                auth: authRoles.vouchers,
            },
            {
                name: 'View Accepted Manuscript', //temp
                path: '/vapa_press/view_accepted_manuscript',
                auth: authRoles.vouchers,
            },
            {
                name: 'Own Books BOM', //temp
                path: '/vapa_press/own_books',
                auth: authRoles.vouchers,
            },
            {
                name: 'Trans Books BOM', //temp
                path: '/vapa_press/trans_books',
                auth: authRoles.vouchers,
            },
            {
                name: 'Sign by Director', //temp
                path: '/vapa_press/agreement_sign_director',
                auth: authRoles.vouchers,
            },
            {
                name: 'Sign by Author', //temp
                path: '/vapa_press/sign_by_author',
                auth: authRoles.vouchers,
            },
            {
                name: 'View by Author', //temp
                path: '/vapa_press/view_by_author',
                auth: authRoles.vouchers,
            },
            {
                name: 'View by Press', //temp
                path: '/vapa_press/view_by_press',
                auth: authRoles.vouchers,
            },
            {
                name: 'Sign by Author Two', //temp
                path: '/vapa_press/sign_by_author_two',
                auth: authRoles.vouchers,
            },
            {
                name: 'Sign by Registrar VC', //temp
                path: '/vapa_press/sign_registrar_vc',
                auth: authRoles.vouchers,
            },
            {
                name: 'View by Author Two', //temp
                path: '/vapa_press/view_by_author_two',
                auth: authRoles.vouchers,
            },
            {
                name: 'View by Press Two', //temp
                path: '/vapa_press/view_by_press_two',
                auth: authRoles.vouchers,
            },
            {
                name: 'Book Details',
                path: '/vapapress/book_details_own',
                auth: authRoles.dataSetup,
            },

            {
                name: 'Translations Details',
                path: '/vapapress/book_details_trans',
                auth: authRoles.dataSetup,
            },

            {
                name: 'Editions Details',
                path: '/vapapress/book_details_editions',
                auth: authRoles.dataSetup,
            },

            {
                name: 'View Cover Design',
                path: '/vapapress/view_cover_design',
                auth: authRoles.dataSetup,
            },

            {
                name: 'Budget Press',
                path: '/vapapress/budget_press',
                auth: authRoles.dataSetup,
            },

            {
                name: 'Budget Author',
                path: '/vapapress/budget_author',
                auth: authRoles.dataSetup,
            },

            {
                name: 'Discount Setup for Budget',
                path: '/vapapress/discount_setup_for_budget',
                auth: authRoles.dataSetup,
            },

            {
                name: 'Book Timeline',
                path: '/vapapress/book_timeline',
                auth: authRoles.dataSetup,
            },


            {
                name: 'Setup Staff',
                path: '/vapapress/setup_staff',
                auth: authRoles.dataSetup,
            },

            {
                name: 'All Published Books',
                path: '/vapapress/all_published_books',
                auth: authRoles.dataSetup,
            },

            {
                name: 'Booklet Application',
                path: '/vapapress/booklet_application',
                auth: authRoles.dataSetup,
            },

            {
                name: 'Invoices',
                path: '/vapapress/invoice_upload',
                auth: authRoles.dataSetup,
            },
            {
                name: 'Viewing Books',
                path: '/vapapress/view_books',
                auth: authRoles.dataSetup,
            },

            {
                name: 'VAPA Books',
                path: '/vapapress/vapa_books',
                auth: authRoles.dataSetup,
            },

            {
                name: 'Manuscript Sent',
                path: '/vapapress/manuscrit_sent',
                auth: authRoles.dataSetup,
            },

        ],
    },

    {
        name: 'VAPA TV',
        icon: 'live_tv',
        auth: authRoles.WelfareView,
        children: [
            {
                name: 'Program Management',
                path: '/vapa_tv/program_managemet',
                auth: authRoles.WelfareView,
            },
            {
                name: 'Line up Management',
                path: '/vapa_tv/lineup_managemet',
                auth: authRoles.WelfareView,
            },
            // {
            //     name: 'Lineup Management External',
            //     path: '/lineup_managemet',
            //     auth: authRoles.WelfareView,
            // },
            // {
            //     name: 'Lineup Management Initial',
            //     path: '/vapa_tv/lineup_managemet2',
            //     auth: authRoles.WelfareView,
            // },
            {
                name: 'All Reviews',
                path: '/vapa_tv/all_reviews',
                auth: authRoles.WelfareView,
            },
            {
                name: 'Review Programs',
                path: '/vapa_tv/review_programs',
                auth: authRoles.WelfareView,
            },
        ],
    },
    {
        name: 'Resource Reservation',
        icon: 'event_seat',
        auth: authRoles.dataSetup,
        children: [
            // {
            //     name: 'All Resources Table',
            //     path: '/resource_reservation/all_resoures_table',
            //     auth: authRoles.JobApplicationView,
            // },
            {
                name: 'All Resources',
                path: '/resource_reservation/all_resoures',
                // auth: authRoles.dataSetup, //
                // auth: authRoles.JobApplicationView,
                // auth: authRoles.vehicleView,
                auth: authRoles.ResourceReservationViews
            },
            {
                name: 'Manage Resources', //Available Resources
                path: '/resource_reservation/available',
                // auth: authRoles.dataSetup,
                // auth: authRoles.DataSetupDocumentSetup,
                auth: authRoles.vehicle

            },
            {
                name: 'My Booking Requests',
                path: '/resource_reservation/bookings',
                // auth: authRoles.WelfareView, /'/
                // auth: authRoles.JobApplicationView,
                // auth: authRoles.dataSetup, //
                auth: authRoles.ResourceReservationViews

            },
            // {
            //     name: 'Add New Resource',
            //     path: '/resource_reservation/new_resource',
            //     auth: authRoles.WelfareView,
            // },
            {
                name: 'Resource Type Configuration',
                path: '/resource_reservation/resource_type',
                // auth: authRoles.dataSetup,
                // auth: authRoles.DataSetupDocumentSetup,
                auth: authRoles.vehicle

            },
            {
                name: 'All Requests',
                path: '/resource_reservation/all_requests',
                // auth: authRoles.dataSetup,
                auth: authRoles.vehicle
                

            },
        ],
    },
    // {
    //     name: 'Resignations-MA',
    //     icon: 'format_list_numbered',
    //     path: '/employee/resignation_ma',
    //     auth: authRoles.humanResoursesViews,
    // },
    // {
    //     name: 'Resignations-DEAN',
    //     icon: 'format_list_numbered',
    //     path: '/employee/resignation_dean',
    //     auth: authRoles.humanResoursesViews,
    // },
    // {
    //     name: 'Resignations-VC',
    //     icon: 'format_list_numbered',
    //     path: '/employee/resignation_vc',
    //     auth: authRoles.humanResoursesViews,
    // },

    {
        name: 'Vehicles',
        icon: '',
        auth: authRoles.vehicle,

        children: [
            {
                name: 'All vehicle details',
                path: '/vehicle/view',
                auth: authRoles.vehicleView,
            },
            {
                name: 'Renew Licence',
                path: '/vehicle/renew_licence',
                auth: authRoles.vehicleView,
            },
            {
                name: 'Fueling',
                path: '/vehicle/fueling',
                auth: authRoles.vehicleView,
            },
            {
                name: 'Find Vehicle For Reservation',
                path: '/vehicle/find_vehicle_for_reservation',
                auth: authRoles.vehicle,
            },
            {
                name: 'Reservation',
                path: '/vehicle/my_reservations',
                auth: authRoles.vehicle,
            },
            {
                name: 'Reservation Head Approval',
                path: '/vehicle/hod/view_reservations',
                auth: authRoles.reservationHODApproval,
            },
            {
                name: 'Reservation MA Approval',
                path: '/vehicle/th/view_reservations',
                auth: authRoles.reservationMAApproval,
            },
            {
                name: 'Reservation AR Approval',
                path: '/vehicle/ga/view_reservations',
                auth: authRoles.reservationARApproval,
            },
            {
                name: 'Reservation Registrar Approval',
                path: '/vehicle/ra/view_reservations',
                auth: authRoles.reservationRegistrarApprove,
            },
            {
                name: 'Reservation VC Approval',
                path: '/vehicle/vc/view_reservations',
                auth: authRoles.reservationVCApprove,
            },
            {
                name: 'Service',
                path: '/vehicle/service',
                auth: authRoles.vehicleView,
            },
            {
                name: 'Maintenance',
                path: '/vehicle/maintenance',
                auth: authRoles.vehicleView,
            },
            {
                name: 'Accident',
                path: '/vehicle/accident',
                auth: authRoles.vehicleView,
            },
        ],
    },
    {
        name:'Agreements',
        icon:'',
        auth: authRoles.dashboard,

        children:[
            {
                name:'All Agreements',
                path:'/all_agreements',
                auth:authRoles.vehicle,
            },
            {
                name:'Setup Agreement Types',
                path:'/setup_agreement_type',
                auth:authRoles.vehicle,
            }
        ]
    },

    {
        name: 'Mahapola',
        icon: '',
        auth: authRoles.dataSetup,

        children: [
            {
                name: 'Mahapola Recipients',
                path: '/mahapola/recipients',
                auth: authRoles.dataSetup,
            },
            {
                name: 'Recipients Attendance',
                path: '/mahapola/recipients_attendance',
                auth: authRoles.dataSetup,
            },
            {
                name: 'Monthly Installments',
                path: '/mahapola/monthly_installments',
                auth: authRoles.dataSetup,
            },
            {
                name: 'Installment Value Setup',
                path: '/mahapola/installment/value_setup',
                auth: authRoles.dataSetup,
            },
            {
                name: 'Approval/Recommend',
                path: '/mahapola/approval_recommend',
                auth: authRoles.dataSetup,
            },
        ],
    },
    //Finance - Vouchers
    {
        name: 'Vouchers',
        icon: 'money',
        auth: authRoles.vouchers,
        children: [
            {
                name: 'All Vouchers', //temp
                path: '/vouchers/all_voucher_requests',
                auth: authRoles.vouchers,
            },
            {
                name: 'Vote Number', //temp
                path: '/vouchers/vote_number',
                auth: authRoles.vouchers,
            },
            {
                name: 'Payee Registration', //temp
                path: '/vouchers/payee_registration',
                auth: authRoles.vouchers,
            },
            {
                name: 'Confirm Recommend Approve', //temp
                path: '/vouchers/all_voucher_requests_for_cra',
                auth: authRoles.vouchers,
            },
            {
                name: 'Finance MA vouchers view', //temp
                path: '/vouchers/all_voucher_requests_for_fma',
                auth: authRoles.vouchers,
            },
            {
                name: 'Issues Identified by MA', //temp
                path: '/vouchers/issues_identified_by_MA',
                auth: authRoles.vouchers,
            },
            {
                name: 'Request Edit', //temp
                path: '/vouchers/request_edit',
                auth: authRoles.vouchers,
            },
            {
                name: 'Confirm Issue', //temp
                path: '/vouchers/confirm_issue_view',
                auth: authRoles.vouchers,
            },
            {
                name: 'Resubmit Request', //temp
                path: '/vouchers/resubmit_request',
                auth: authRoles.vouchers,
            },
            // {
            //     name: 'Resubmit CRA', //temp
            //     path: '/vouchers/resubmit_confirm_recommend_approve',
            //     auth: authRoles.vouchers,
            // },
            {
                name: 'All Vouchers Finance', //temp
                path: '/vouchers/all_vouchers_finance',
                auth: authRoles.vouchers,
            },
            {
                name: 'BookKeeper', //temp
                path: '/vouchers/checkedbyFinanceMA',
                auth: authRoles.vouchers,
            },
            {
                name: 'Bursar Certify', //temp
                path: '/vouchers/checkedbyFinanceMAforBursar',
                auth: authRoles.vouchers,
            },
            {
                name: 'Finance-certified', //temp
                path: '/vouchers/finance_certified',
                auth: authRoles.vouchers,
            },
            // {
            //     name: 'Finance-paid', //temp
            //     path: '/vouchers/finance_paid',
            //     auth: authRoles.vouchers,
            // },
        ],
    },




    {
        name: 'Convocation',
        icon: 'school',
        auth: authRoles.vouchers,
        path: '/convocations/all_convocations',
    },

    {
        name: 'QR Base Attendance',
        icon: 'school',
        auth: authRoles.LectureDay,
        path: '/QRBaseAttendance/MarkMyAttendance',
    },
    //convocation student view
    {
        name: 'Convocation',
        path: '/convocation',
        icon: 'school',
        auth: authRoles.Appeal,
        show: (async () => {
            try {
                const { id: studentId, roles } = JSON.parse(localStorage.userInfo)
                //check gratulate list 
                // 
                if (!studentId) return true
                if (!roles.some(role => role.toLowerCase().endsWith('student'))) return false
                const res = await StudentService.getStudentByStudentID(studentId)
                // CertificateClearanceService.getAllGraduates()
                // console.clear()
                // console.log('navigations.js =>>>>>>', res)
                // console.log('navigations.js return val =>>>>>>', res.data.view.year.endsWith('4'))
                return res.data.view.year.endsWith('4')
            } catch (error) {
                console.error(error)
                return false
            }
        })(),
    },
]


