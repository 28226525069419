import React from 'react'
import { authRoles } from '../../auth/authRoles'

const ResearchGrantRoutes = [

    {
        path: '/research-grant/view',
        component: React.lazy(() => import('./GrantView')),
        auth: authRoles.researchGrant,
    },
    {
        path: '/research-grant/views/:id',
        component: React.lazy(() => import('./GrantView')),
        auth: authRoles.researchGrant,
    },
    {
        path: '/research-grant-new/create',
        component: React.lazy(() => import('./CreateNewResearchGrant')),
        auth: authRoles.researchGrant,
    },
    {
        path: '/research-grant/review/:id',
        component: React.lazy(() => import('./ViewResearchGrant')),
        auth: authRoles.researchGrant,
    },
    {
        path: '/research-grant/view_allowance',
        component: React.lazy(() => import('./ViewAllowance')),
        auth: authRoles.researchGrant,
    },
    // {
    //     path: '/research-grant/create_allowance',
    //     component: React.lazy(() => import('./CreateNewAllowance')),
    //     auth: authRoles.researchGrant,
    // },
    {
        path: '/research-grant/review_allowance/:id',
        component: React.lazy(() => import('./ReviewAllowance')),
        auth: authRoles.researchGrant,
    },
    {
        path: '/research-grant/all_allowances',
        component: React.lazy(() => import('./AllAllowances')),
        auth: authRoles.researchGrant,
    },
    {
        path: '/research-grant/employee_overview',
        component: React.lazy(() => import('./EmployeeOverview')),
        auth: authRoles.researchGrantOverview,
    },
    {
        path: '/research-grant/employeeview_allgrants',
        component: React.lazy(() => import('./EAllGrantsAndApply')),
        auth: authRoles.researchGrantOverview,
    },
    {
        path: '/research-grant/employee_review/:id',
        component: React.lazy(() => import('./EReviewAllGrants')),
        auth: authRoles.researchGrantOverview,
    },
    {
        path: '/research-grant/employee_research_allowances_requests',
        component: React.lazy(() => import('./EMyResearchAllowancesRequest')),
        auth: authRoles.researchGrant,
    },
    {
        path: '/research-grant/employee_research_allowances_requests_review',
        component: React.lazy(() => import('./EMyResearchAllowancesReview')),
        auth: authRoles.researchGrant,
    },
    {
        path: '/research-grant/employee_allowance_request_creation/:id',
        component: React.lazy(() => import('./AllowanceRequestCreation')),
        auth: authRoles.researchGrant,
    },
    {
        path: '/research-grant/overview',
        component: React.lazy(() => import('./Overview')),
        auth: authRoles.researchGrant,
    },


]


export default ResearchGrantRoutes
