import React from 'react'
import { authRoles } from '../../auth/authRoles'

const TestExamRoutes = [

    {
        path: '/test/create',
        component: React.lazy(() => import('./CreateTest')),
        auth: authRoles.createTest,
    },

]

export default TestExamRoutes
