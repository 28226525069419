export const SET_USER_NAVIGATION = 'SET_USER_NAVIGATION'

const getfilteredNavigations = (navList = [], roles) => {
    return navList.reduce((array, nav) => {
        if (!nav.children) {
            /*  if (nav.auth.includes(roles)) {
                 array.push(nav)
             } */

            roles.forEach(menurole => {
                if (nav.auth.includes(menurole)) {
                    array.push(nav)
                }
            });




        } else {
            if (nav.children) {
                nav.children = getfilteredNavigations(nav.children, roles)

                if (nav.children.length != 0) {
                    array.push(nav)
                }

            } else {
                array.push(nav)
            }
        }
        return array
    }, [])
}


export function getNavigationByUser() {
    return (dispatch, getState) => {
        let { user, navigations = [] } = getState()

        let filteredNavigations = getfilteredNavigations(navigations, user)
        console.log("sasas", filteredNavigations)
        dispatch({
            type: SET_USER_NAVIGATION,
            payload: [...filteredNavigations],
        })
    }
}
