import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Grid, Paper, Typography } from '@mui/material'
import { SubTitle, Button } from 'app/components/LoonsLabComponents'
import portalGuide from 'app/views/PublicPages/Portal_guide.pdf'
import aptitudeGuide from 'app/views/PublicPages/Registering_aptitude_test.pdf'
import * as appconst from '../../../appconst'
import { Card } from 'react-bootstrap'
import ApplicationServices from 'app/services/ApplicationServices'
import moment from 'moment'

const styleSheet = (palette, ...theme) => ({
    paper: {
        height: 150,
        width: 250,
    },
})
class AptRegLanding extends Component {
    constructor(props) {
        super(props)
        this.state = {
            regClose: false,
            showImage: true,
            testID: null,
        }
    }

    async getTestDetails(test_id) {
        await ApplicationServices.getStudentDegreePrograms(test_id)
            .then((res) => {
                if (res.status === 200) {
                    let data = res.data.view
                    this.setState(
                        {
                            testData: data,
                        },
                        () => {
                            this.registrationClose(data)
                        }
                    )
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    registrationClose(data) {
        let today = moment(new Date()).format('YYYY-MM-DD')
        // let endDate = moment(data.end_date).format('YYYY-MM-DD')

        let startDate = moment(data?.start_date).subtract(1,'day').format('YYYY-MM-DD')
        let endDate = moment(data?.end_date).add(1,'day').format('YYYY-MM-DD')
        
        console.log("SSSSS", 
            moment(new Date()).format('YYYY-MM-DD'),
            moment(today).isBetween(data?.start_date,data?.end_date),
            moment(today).isBetween(startDate,endDate)
        )
        if (!moment(today).isBetween(startDate,endDate)) {
            this.setState({
                regClose: true,
            })
        }
    }

    componentDidMount() {
        var id = new URLSearchParams(this.props.location.search).get('id')

        this.setState(
            {
                testID: id,
            },
            () => {
                this.getTestDetails(id)
            }
        )

        window.addEventListener('resize', () => {
            if (window.innerWidth <= 600) {
                this.setState({
                    showImage: false,
                })
            } else {
                this.setState({
                    showImage: true,
                })
            }
        })
    }

    render() {
        const { classes } = this.props
        return (
            <div>
                <div style={{ background: '#ffe1b357' }}>
                    <div className="flex justify-center text-center p-5">
                        <img
                            // className="w-full"
                            src="/assets/images/logo_vpa.png"
                            alt="profile"
                            width={'100px'}
                        />
                    </div>
                    <div className="flex justify-center text-center">
                        <Typography
                            className="mt-3 capitalize font-bold text-dynamic"
                            style={{
                                letterSpacing: 3,
                                color: '#c00010',
                            }}
                        >
                            WELCOME TO APTITUDE TEST REGISTRATION PORTAL
                        </Typography>
                    </div>
                    <div className="flex justify-center text-center">
                        <Typography
                            className="mt-2 mb-5  font-medium text-dynamic2"
                            style={{ letterSpacing: 2, color: '#ff7400' }}
                        >
                            University of the Visual and Performing Arts
                        </Typography>
                    </div>
                </div>

                <div
                    id="body"
                    className="pt-5 h-screen relative"
                    style={{
                        backgroundImage: this.state.showImage
                            ? `url(/assets/images/landBack.png)`
                            : '',
                        backgroundSize: 'cover',
                        height: '80vh',
                    }}
                >
                    {/* <div style={{
                        position: 'absolute',
                        background: '#000000',
                        width: '100%',
                        height: '80vh',
                        zIndex: '0',
                        top: '0px',
                        opacity: '0.2'

                    }}></div> */}
                    <Grid
                        justifyContent="center"
                        container
                        spacing={2}
                        style={{
                            position: 'absolute',
                            zIndex: '0',
                            top: '5%',
                        }}
                    >
                        {/* <Grid item xs={12} >
                            <Typography className='text-center text-28 text-black font-bold'>Aptitude test registration portal will be open from 18th September 2023</Typography>
                        </Grid> */}

                        <Grid item xs={12}>
                            <Typography className="text-center text-28 text-black font-bold">
                                Please follow one of the following Aptitude Test
                                Registration guides
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="center" spacing={2}>
                                {/* <Grid item >
                                    <a href={portalGuide} target='_blank' rel='noopener noreferrer' >
                                        <Paper className={[classes.paper, "w-full flex justify-center items-center"]}
                                        >
                                            <img
                                                // className="w-full"
                                                src="/assets/images/pdf.jpg"
                                                alt="profile"
                                                width={"70px"}
                                            />
                                        </Paper>
                                    </a>
                                </Grid> */}
                                <Grid item>
                                    <a
                                        href={aptitudeGuide}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Paper
                                            className={[
                                                classes.paper,
                                                'w-full flex justify-center items-center',
                                            ]}
                                        >
                                            <img
                                                // className="w-full"
                                                src="/assets/images/pdf.jpg"
                                                alt="profile"
                                                width={'70px'}
                                            />
                                        </Paper>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a
                                        href={
                                            appconst.APT_YT_Registration_Guide
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Paper
                                            className={[
                                                classes.paper,
                                                'w-full flex justify-center items-center',
                                            ]}
                                        >
                                            <img
                                                // className="w-full"
                                                src="/assets/images/yt.png"
                                                alt="profile"
                                                width={'100px'}
                                            />
                                        </Paper>
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            className="flex justify-center mt-24"
                        >
                            {this.state.regClose ||
                                new URLSearchParams(this.props.location.search).get(
                                    'id'
                                ) == null ? (
                                <Button
                                    className="border-radius-8 text-center text-24 px-5 py-4 button-secondary"
                                    size="large"
                                    style={{ border: '2px solid white' }}
                                    onClick={() => { }}
                                >
                                    Registration Closed
                                </Button>
                            ) : (
                                <Button
                                    className="border-radius-8 text-center text-24 px-5 py-4 button-secondary"
                                    size="large"
                                    style={{ border: '2px solid white' }}
                                    onClick={() => {
                                        window.location.href =
                                            '/aptitude_test_application_form?id=' +
                                            this.state.testID
                                    }}
                                >
                                    Apply for Aptitude Test
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default withStyles(styleSheet)(AptRegLanding)
