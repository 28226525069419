import React, { useState, useEffect } from 'react'
import {
    Card,
    Icon,
    IconButton,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    Tooltip,
    Fab,
    Switch,
    Typography,
    InputAdornment,
    CircularProgress,
    Divider,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
} from '@material-ui/core'
import {
    LoonsTable,
    DatePicker,
    FilePicker,
    ExcelToTable,
    LoonsSnackbar,
    LoonsSwitch,
    MainContainer,
    LoonsCard,
    CardTitle,
    SubTitle,
    Button,
    LoonsDialogBox,
    DocumentLoader,
} from 'app/components/LoonsLabComponents'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import AuthService from 'app/services/AuthService'
import EyeOn from '@material-ui/icons/Visibility';
import EyeOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    cardHolder: {
        background: '#1A2038',
    },
    card: {
        maxWidth: 800,
        borderRadius: 12,
        margin: '1rem',
    },
}))

const ForgotPassword = () => {
    const [state, setState] = useState({ snackbar: false })

    const [textType, setTextType] = useState("password")
    const [textType2, setTextType2] = useState("password")
    const classes = useStyles()

    const handleChange = ({ target: { name, value } }) => {
        setState({
            ...state,
            [name]: value,
        })
    }
    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== state.password) {
                return false;
            }
            return true;
        });
        ValidatorForm.removeValidationRule('isPasswordMatch');
    })

    const handleFormSubmit = async (event) => {

        let user = {
            username: state.email,
            password: state.password
        }
        console.log("USER", user)
        await AuthService.ResetPassword(user).then(
            res => {
                if (res.status === 201) {
                    setTimeout(() => {
                        setState({
                            snackbar: true,
                            snackbar_severity: 'success',
                            snackbar_message: 'Password Reset Successfull!',
                        }, () => {
                            window.location.href = "/session/signin"
                        })
                    }, 2000)



                }
            }
        )
    }


    let { email } = state

    return (
        <div
            className={clsx(
                'bg-white justify-center items-center  h-full-screen'
                // classes.cardHolder
            )}
        >
            <Grid
                spacing={2}
                container
                className=" bg-white items-center h-full-screen "
            >
                <Grid
                    className="px-10 w-full bg-light-gray h-full-screen items-center justify-center hide-on-mobile"
                    item
                    sm={8}
                    xs={12}
                >
                    <img
                        className="w-full min-w-750"
                        src="/assets/images/forgotPassword.png"
                        alt="forget password"
                    />
                </Grid>

                <Grid className="p-8 w-full  " item sm={4} xs={12}>
                    <div className="w-full text-center ">
                        <img
                            className="mb-3 w-100"
                            alt="Logo"
                            src="/assets/images/logo_vpa.png"
                        />
                    </div>
                    <div className="w-full text-center mb-8">
                        <span className="text-30 justify-center text-secondary font-bold w-full">
                            University of VAPA
                        </span>
                    </div>

                    <div className="w-full mb-2">
                        {' '}
                        <span className="text-22 text-gray font-bold">
                            Forgot your password?
                        </span>
                    </div>
                    <div className="w-full mb-3">
                        {' '}
                        <span className="text-14 text-hint ">
                            Enter your registered email below to recieve
                            password reset instruction
                        </span>
                    </div>

                    <ValidatorForm onSubmit={handleFormSubmit}>
                        <TextValidator
                            className="mb-6 w-full"
                            variant="outlined"
                            label="Email"
                            onChange={handleChange}
                            type="email"
                            name="email"
                            size="small"
                            value={state.email || ''}
                            validators={['required', 'isEmail']}
                            errorMessages={[
                                'This field is required',
                                'email is not valid',
                            ]}
                        />
                        <TextValidator
                            className="mb-3 w-full"
                            label="New Password"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}

                            name="password"
                            type={textType}
                            value={state.password}
                            validators={[`matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$`, 'required']}
                            errorMessages={["Please enter a valid password (at least 8 characters, containing at least one uppercase letter, one lowercase letter, and one digit)", 'This field is required']}
                            InputProps={{

                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            disableRipple={true}
                                            type="button"
                                            onClick={() => {
                                                textType == "password" ? setTextType("text") : setTextType("password")
                                            }}
                                        >
                                            {textType == "password"
                                                ? <EyeOff />
                                                : <EyeOn />
                                            }

                                        </IconButton>
                                    </InputAdornment>
                                )
                            }} />
                        {/* <TextValidator
                            className="mb-3 w-full"
                            label="Confirm Password"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}

                            name="confirmPassword"
                            type={textType2}
                            value={state.confirmPassword}
                            validators={['isPasswordMatch', 'required']}
                            errorMessages={['Password Mismatch', 'This field is required']}
                            InputProps={{

                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            disableRipple={true}
                                            type="button"
                                            onClick={() => {
                                                textType2 == "password" ? setTextType2("text") : setTextType2("password")
                                            }}
                                        >
                                            {textType2 == "password"
                                                ? <EyeOff />
                                                : <EyeOn />
                                            }

                                        </IconButton>
                                    </InputAdornment>
                                )
                            }} /> */}
                        <div className="items-center w-full mb-3">
                            <Button
                                className="w-full"
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Reset Password
                            </Button>
                        </div>

                        <a className="text-primary" href="/session/signin">
                            Back to Sign in
                        </a>
                    </ValidatorForm>
                </Grid>
            </Grid>
            {/* Snackbar */}
            <LoonsSnackbar
                open={state.snackbar}
                onClose={() => {
                    setState({
                        snackbar: false,
                    })
                }}
                message={state.snackbar_message}
                autoHideDuration={3000}
                severity={state.snackbar_severity}
                elevation={2}
                variant="filled"
            ></LoonsSnackbar>
        </div>
    )
}

export default ForgotPassword

{
    /* <Card className={classes.card}>
                <Grid container>
                    <Grid item lg={5} md={5} sm={5} xs={12}>
                        <div className="p-8 flex justify-center items-center h-full">
                            <img
                                className="w-full"
                                src="/assets/images/illustrations/dreamer.svg"
                                alt=""
                            />
                        </div>
                    </Grid>
                    <Grid item lg={7} md={7} sm={7} xs={12}>
                        <div className="p-8 h-full bg-light-gray relative">
                            <ValidatorForm onSubmit={handleFormSubmit}>
                                <TextValidator
                                    className="mb-6 w-full"
                                    variant="outlined"
                                    label="Email"
                                    onChange={handleChange}
                                    type="email"
                                    name="email"
                                    size="small"
                                    value={email || ''}
                                    validators={['required', 'isEmail']}
                                    errorMessages={[
                                        'This field is required',
                                        'email is not valid',
                                    ]}
                                />
                                <div className="flex items-center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        Reset Password
                                    </Button>
                                    <span className="ml-4 mr-2">or</span>
                                    <Link to="/session/signin">
                                        <Button className="capitalize">
                                            Sign in
                                        </Button>
                                    </Link>
                                </div>
                            </ValidatorForm>
                        </div>
                    </Grid>
                </Grid>
            </Card> */
}
