/*
Loons Lab Date picker component
Developed By Roshan
Loons Lab
*/
import React, { Fragment, useState, Component } from "react";
import { Card } from '@material-ui/core'
import { any } from "prop-types";
import { withStyles } from '@material-ui/styles'
import {
    Divider,
    Typography,
} from '@material-ui/core'

const styleSheet = ((theme) => ({
    productCard: {
        '& .profile-status': {
            position: 'absolute',
            right: 0,
            top: 24,
            borderTopLeftRadius: 26,
            borderBottomLeftRadius: 26,
            zIndex: 4,
        },
        // '& .image-box-overlay': {
        //     position: 'absolute',
        //     top: 0,
        //     bottom: 0,
        //     left: 0,
        //     right: 0,
        //     background: 'rgba(0, 0, 0, 0.74)',
        //     zIndex: 2,
        //     opacity: 0,
        //     transition: 'all 250ms ease-in-out',
        // },
        // '&:hover': {
        //     '& .image-box-overlay': {
        //         opacity: 1,
        //     },
        // },
    },


}));

class ProfileCard extends Component {
    static propTypes = {
        title: any,
        children: Node,
    };

    static defaultProps = {
        title: null,

    };



    render() {

        const {
            title,
            children,
            classes
        } = this.props;


        return (

            <Card className={["border-radius-8 bg-paper pb-5", classes.productCard]} elevation={2} >

                <Typography className="p-4 my-2 text-16 font-medium">{title}</Typography>
                <Divider />
                {children}
            </Card>


        );
    }
}

export default withStyles(styleSheet)(ProfileCard);