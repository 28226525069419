import axios from 'axios'
import * as apiroutes from '../../apiroutes'
import localStorageService from './localStorageService'

class ResourceReservationServices {
    // create resource type
    createResourceType = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.CREATE_RESOURCE_TYPE, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error.response)
                    return resolve(error.response)
                })
        })
        return await a
    }

    //Get all resource types
    getAllResourceTypes = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_ALL_RESOURCE_TYPES, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    params: params,
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }

    //change resource type status
    changeResourceTypeStatus = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .put(apiroutes.CHANGE_STATUS_RESOURCE_TYPE + '/' + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    //update resource type
    updateResourceType = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.UPDATE_RESOURCE_TYPE + `${id}`, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await promise
    }

    //Get all resources
    getAllResources = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_ALL_RESOURCES, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    params: params,
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }

    // Get single Resource
    getSingleResource = async (id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_SINGLE_RESOURCE + id, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }

    //Get all booking requests
    getAllBookingRequests = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_ALL_BOOIKNG_REQUESTS, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    params: params,
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }

    // Get single Request
    getSingleRequest = async (id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_SINGLE_BOOIKNG_REQUEST + id, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }

    //change resource booking status
    changeResourceBookingStatus = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .put(apiroutes.CHANGE_STATUS_BOOIKNG_REQUEST + '/' + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    // create cancellation request
    createCancellationRequest = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.CREATE_CANCELLATION_REQUEST, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error.response)
                    return resolve(error.response)
                })
        })
        return await a
    }

    // Get single cancellation Request
    getAllCancellationRequest = async () => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_ALL_BOOIKNG_CANCELLATION_REQUEST, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }

    //update cancellation request --> Accept cancellation
    acceptCancellationRequest = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.UPDATE_CANCELLATION_REQUEST + `${id}`, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await promise
    }

    // create post reservation report
    createPostReservation = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.CREATE_POST_RESERVATION, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error.response)
                    return resolve(error.response)
                })
        })
        return await a
    }

    // create a new booking
    createBooking = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.CREATE_BOOKING, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error.response)
                    return resolve(error.response)
                })
        })
        return await a
    }

    updateBooking = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.CREATE_BOOKING + '/' + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error.response)
                    return resolve(error.response)
                })
        })
        return await a
    }

    // create resource
    createResource = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.CREATE_RESOURCE, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error.response)
                    return resolve(error.response)
                })
        })
        return await a
    }

    // update resource
    updateResource = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.CREATE_RESOURCE + '/' + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error.response)
                    return resolve(error.response)
                })
        })
        return await a
    }

    //change resource  status
    changeResourceStatus = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .put(apiroutes.CREATE_RESOURCE + '/status/' + id, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await a
    }

    // get amenity/resource item details by id
    getSingleResourceItem = async (id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_SINGLE_RESOURCE_ITEM + id, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }
    // get reservations data
    /**
     *
     * @param {{resource_id:number|string}} params
     */

    getReservationsByResourceId = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.RESERVATIONS, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    params,
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }

    // PUBLIC APIS

    PUBLIC_getResources = async (params) => {
        const promise = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.PUBLIC_RESOURCES, {
                    params,
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }

    PUBLIC_getResourceTypes = async (params) => {
        const promise = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.PUBLIC_RESOURCE_TYPES, {
                    params,
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }
}

export default new ResourceReservationServices()
