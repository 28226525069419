/*
Developed By Shiny
Loons Lab
*/
import React from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

const bookingsList = [
  {
    id: 1,
    start_date: "2023-09-11",
    end_date: "2023-09-11",
    start_time: "08:00:00",
    end_time: "12:00:00"
  },
  {
    id: 2,
    start_date: "2023-09-11",
    end_date: "2023-09-11",
    start_time: "13:00:00",
    end_time: "15:00:00"
  },
  {
    id: 3,
    start_date: "2023-09-27",
    end_date: "2023-09-27",
    start_time: "18:00:00",
    end_time: "20:00:00"
  }
];

class ReservationDateTimePicker extends React.Component {
  constructor(props) {
    super(props);

    console.log(props)

    this.state = {
      value: null,
      // bookings: bookingsList,
      bookings: props.data,
    };
  }

  componentDidUpdate(prevProps) {
    // Check if the 'data' prop has changed
    if (prevProps.data !== this.props.data) {
      this.setState({ bookings: this.props.data });
    }
  }

  generateDisabledTimeSlots(bookingsList) {
    const disabledTimeSlots = {};

    for (const booking of bookingsList) {
      const startDate = dayjs(booking.start_date).format("YYYY-MM-DD");

      const startTimeComponents = booking.start_time.split(":");
      const startTimeInMinutes =
        parseInt(startTimeComponents[0]) * 60 + parseInt(startTimeComponents[1]);

      const endTimeComponents = booking.end_time.split(":");
      const endTimeInMinutes =
        parseInt(endTimeComponents[0]) * 60 + parseInt(endTimeComponents[1]);

      if (!disabledTimeSlots[startDate]) {
        disabledTimeSlots[startDate] = [];
      }

      disabledTimeSlots[startDate].push({
        start: startTimeInMinutes,
        end: endTimeInMinutes
      });
    }

    // Convert the object into an array of date and time ranges
    const result = Object.keys(disabledTimeSlots).map((date) => ({
      date,
      ranges: disabledTimeSlots[date]
    }));

    // console.log("result: ", result);
    return result;
  }

  shouldDisableDate = (date) => {
    const { value } = this.state;
    const {label, selectedFromDate} = this.props;

    // console.log("date: ", date)
    // console.log("value: ", value)
    // console.log("selectedFromDate: ", selectedFromDate)
  
    // If no FROM date is selected, allow any TO date
    if (!value) {
      return false;
    }

    // Disable dates before the selected FROM date
    if(selectedFromDate) {
       return date.isBefore(selectedFromDate, "day");
    }
  };

  shouldDisableTime = (time, clockType) => {
    const { value, bookings } = this.state;
    const { selectedFromValue, selectedFromDate } = this.props;
    // console.log(selectedFromValue)
    // console.log(selectedFromDate)

    let selectedTimeInMinutes = null;

    // Check if the current time is within any of the disabled time slots
    if (value) {
      const selectedDate = value.format("YYYY-MM-DD");
      const selectedHour = value.hour();
      const selectedMinute = value.minute();
      selectedTimeInMinutes = selectedHour * 60 + selectedMinute;

      for (const slot of this.generateDisabledTimeSlots(bookings)) {
        if (slot.date === selectedDate) {
          for (const range of slot.ranges) {
            if (
              selectedTimeInMinutes >= range.start &&
              selectedTimeInMinutes <= range.end
            ) {
              return true; // Disable the time if it falls within a disabled slot
            }
          }
        }
      }
    } 

    if (selectedFromDate && selectedTimeInMinutes) {
      const selectedFromHour = selectedFromDate.hour();
      const selectedFromMinute = selectedFromDate.minute();
      const selectedFromTimeInMinutes = selectedFromHour * 60 + selectedFromMinute;

      if (value.format("YYYY-MM-DD") == selectedFromDate.format("YYYY-MM-DD") && selectedTimeInMinutes < selectedFromTimeInMinutes) {
        // return true; // Disable time slots earlier than the selected "FROM" time
        return time.isBefore(selectedFromDate, "minute");
      }
    }

    return false; // Enable the time if no conditions match
  };

  render() {
    const { value, bookings } = this.state;
    const minDateTime = value ? dayjs() : dayjs();

    console.log(bookings)

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label={`${this.props.label} *`}
            value={this.props.selectedFromValue ? this.props.selectedFromValue : this.props.selectedToValue ? this.props.selectedToValue : value}
            onChange={(newValue) => {
              this.setState({ value: newValue });
              console.log("newValue: ", newValue)
              this.props.setDateTime(newValue)
            }}
            disablePast
            shouldDisableTime={this.shouldDisableTime}
            shouldDisableDate={this.shouldDisableDate}
            minDateTime={minDateTime}
            viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
            }}
            isRequired
            // validators={['required']}
            // errorMessages={['This field is required']}
        />
        
      </LocalizationProvider>
    );
  }
}

export default ReservationDateTimePicker;
