/*
Loons Lab Button component
Developed By Roshan
Loons Lab
*/
import React, { Component } from "react";
import { Button } from '@material-ui/core'
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from "prop-types";
import { scrollToTop } from "utils";

class LoonsButton extends Component {
    static propTypes = {
        onClick: PropTypes.func,
        children: PropTypes.node,
        variant: PropTypes.string,
        className: PropTypes.any,
        label: PropTypes.string,
        size: PropTypes.string,
        disabledClassName: PropTypes.string,
        disabled: PropTypes.bool,
        color: PropTypes.string,
        type: PropTypes.string,
        scrollToTop: PropTypes.bool,
        endIcon: PropTypes.string,
        startIcon: PropTypes.string,
        style: PropTypes.object
    };

    static defaultProps = {
        className: "",
        color: "primary",
        label: "",
        progress: false,
        size: "medium",
        variant: "contained",
        disabled: false,
        disabledClassName: "",
        type: 'button',
        scrollToTop: false
    };

    scrollToTop() {
        scrollToTop();
    }
    handleButtonClick = event => {
        const { onClick, disabled, scrollToTop } = this.props;

        if (disabled) return;

        onClick &&
            onClick({
                event
            });
        if (scrollToTop) {
            this.scrollToTop()
        }
    };

    renderChildren = (label, children) => {

        if (label) {
            return label;
        }

        if (children) {
            return children;
        }
    };

    render() {
        const {
            children,
            label,
            className,
            color,
            size,
            type,
            variant,
            disabled,
            progress,
            startIcon,
            endIcon,
            style,
            disabledClassName,
            // icon
        } = this.props;


        return (

            <Button
                className={className}
                size={size}
                endIcon={endIcon == null ? null : <Icon>{endIcon}</Icon>}
                variant={variant}
                disabled={progress ? true : disabled}
                color={color}
                type={type}
                style={style}
                onClick={this.handleButtonClick}
                startIcon={progress ? <CircularProgress size={24} /> : (startIcon == null ? null : <Icon>{startIcon}</Icon>)}
            >

                {this.renderChildren(label, children)}

            </Button>


        );
    }
}

export default LoonsButton;