import React from 'react'
import { authRoles } from '../../auth/authRoles'

const TimetableRoutes = [
    {
        path: '/timetable',
        component: React.lazy(() => import('./Timetable')),
        auth: authRoles.Timetable,
    },

   
]

export default TimetableRoutes
