import React from 'react'
import { authRoles } from '../../auth/authRoles'

const AdminStudentProfileRoutes = [
    {
        path: '/admin/postgraduatestudent/profile/:id',
        component: React.lazy(() => import('./PostGraduateProfile')),
        auth: authRoles.postgraduateCourseCreate,
    },
    //student application
    {
        path: '/ar/applications/',
        component: React.lazy(() =>
            import('./AR_AllApplications')
        ),
        auth: authRoles.postgraduateCourseCreate,
    },
    //student application
    {
        path: '/ar/special_applications/',
        component: React.lazy(() =>
            import('./AR_AllApplicationsSpecial')
        ),
        auth: authRoles.postgraduateCourseCreate,
    },
    {
        path: '/student/application/my',
        // path: '/student/application/:id',
        component: React.lazy(() =>
            import('../AdminStudentProfile/application/MyApplications')
        ),
        auth: authRoles.postgraduateStudentView,
    },
    {
        path: '/student/application/view/:id',
        component: React.lazy(() =>
            import('../AdminStudentProfile/application/StudentApplication')
        ),
        auth: authRoles.postgraduateStudentView,
    },
    {
        path: '/student/application/pay',
        // path: '/student/application/pay/:id',
        component: React.lazy(() =>
            import('../AdminStudentProfile/application/MyApplicationPayments')
        ),
        auth: authRoles.postgraduateStudentView,
    },
    {
        path: '/student/application/all',
        // path: '/student/application/:id',
        component: React.lazy(() =>
            import('../AdminStudentProfile/application/AllApplications')
        ),
        auth: authRoles.postgraduateStudentView,
    },
    {
        path: '/student/single/application/:id',
        component: React.lazy(() =>
            import('../AdminStudentProfile/application/FGSStudentApplciation')
        ),
        auth: authRoles.postgraduateStudentView,
    },


]

export default AdminStudentProfileRoutes
