import axios from 'axios';
import * as apiroutes from '../../apiroutes';
import localStorageService from "./localStorageService";
import * as appconst from '../../appconst';

class AuthService {

    userLogin = async (username, password) => {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.USER_LOGIN, `grant_type=password&client_id=client1&username=${username}&password=${password}$client_secret=${appconst.keycloak_client_secret}`, {
                headers: headers
            }
            ).then(res => {
                return resolve(res);
            })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;

    }

    updateUserPassword = async (data) => {
        this.userdata = data;
        const a = new Promise((resolve, reject) => {
            this.getUserByUsername(data.email).then((userdata) => {
                this.sendFirstLoginEmail(userdata).then((respond) => {
                    return resolve(respond);
                })
            });


        });
        return await a;
    }

    getUserByUsername = async (username) => {
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.USER_GET_KEYCLOAK,
                {
                    params: {
                        username: username
                    }
                })
                .then(res => {
                    console.log("user by username", res)
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    sendFirstLoginEmail = async (data) => {

        this.userid = data.data[0].id;
        console.log("id", data.data[0].id)
        const a = new Promise((resolve, reject) => {
            var d = ["UPDATE_PASSWORD"];

            axios.put(apiroutes.USER_GET_KEYCLOAK + "/" + data.data[0].id + "/execute-actions-email",
                ["UPDATE_PASSWORD"]
            )
                .then(res => {

                    if (res.status == 204) {

                        return resolve(res);
                    }
                })
                .catch((error) => {
                    return resolve(error);
                    console.log("error", error);
                })
        });
        return await a;
    }

    ResetPassword = async (data) => {
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.ResetPassword, data,
            ).then(res => {
                return resolve(res);
            })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    getClientRoles = async () => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios
                .get(`${apiroutes.KEYCLOAK}admin/realms/VPARealm/roles`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        // 'Client-Id': appconst.keycloak_client_id
                    }
                })
                .then(res => {
                    return resolve(res);
                })
                .catch(error => {
                    console.error(error);
                });
        });
        return await a;
    }

    getUserRoles = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_SINGLE_USER_ROLES, {
                    headers: {
                        params: params,
                        Authorization: `Bearer ${accessToken}`,
                        // 'Client-Id': appconst.keycloak_client_id
                    }
                })
                .then(res => {
                    return resolve(res);
                })
                .catch(error => {
                    console.error(error);
                });
        });
        return await a;
    }

}

export default new AuthService()