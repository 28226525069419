class localStorageService {
    ls = window.localStorage

    setItem(key, value) {
        value = JSON.stringify(value)
        this.ls.setItem(key, value)
        return true
    }

    getItem(key) {
        let value = this.ls.getItem(key)
        return JSON.parse(value)

    }

}

export default new localStorageService()
