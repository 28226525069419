import React, { Component, Fragment } from 'react';
import { 
    MainContainer, 
    LoonsCard, 
    CardTitle, 
    DatePicker, 
    TimePicker,
    Button, 
    FilePicker, 
    LoonsSnackbar
} from 'app/components/LoonsLabComponents';
import Rating from '@mui/material/Rating';
import { 
    Dialog, 
    DialogContent, 
    DialogTitle, 
    DialogActions, 
    Grid, 
    TextField, 
    IconButton, 
    Radio, 
    RadioGroup, 
    FormControlLabel,
    FormControl,
    FormLabel,
    CircularProgress, 
    Typography,
    Paper,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
} from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { Autocomplete} from '@material-ui/lab';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import VapaTvServices from '../../services/VapaTvServices';
import AirplayIcon from '@mui/icons-material/Airplay';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import Checkbox from '@mui/material/Checkbox';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import { program_repeat } from '../../../appconst';
import localStorageService from 'app/services/localStorageService';

import moment, { duration } from 'moment'
import ImageView from 'app/components/LoonsLabComponents/ImageView';


class LineUpManagementExternal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            duration:'',
            genreSingle:'',
            image:null,
            programName:'',
            programs: [],  
            genres: [], 
            files: { fileList: [] },
            dialogBox: false,
            newGenre: false,
            editMode: false,
            newProgramDialogBox: false,
            eventViewDialogBox: false,
            clickedDate: null,
            clickedTime: null,
            clickedEvent: null,
            newReviewData: {
                tv_program_id: null,
                // program_name: '',
                rater: '',
                comment: '',
                rating: 0,
            }, 
            filterData: {
                id:'',
                page: 0,
                genre: '',
                search: null,
                limit: 20,
                'order[0]': [ 'DESC'],
            },  
            data: [],
            lineupEvents: [],
            loaded: false,
            viewData: {
                tv_program_id: null,
                title: '',
                start: null,
                end: null,
                startTime: null,
                start_time: null,
                endTime: null,
                end_time: null,
                startRecur: null,
                endRecur: null,
                is_repeat: false,
                no_of_repeats: 0,
                repeat_every: '',
                repeating_days: '',
                TVLineupRepeatingDays: [],
                is_end: false,
                end_date: null,
                end_occurance: null,
                is_public: false,
            },
            formData: {
                tv_program_id: null,
                program_name: '',
                rater: '',
                comment: '',
                rating: 0,
            },
            // selectedProgramName: '',
            reviews: [],
            filteredReviews: [],
            newProgramFormData: {
                live_or_recorded: 'recorded',
                tv_genre_id: null,
                genre_name: '',
            }, 
            lineupData : {
                tv_program_id: null,
                title: '',
                start: null,
                end: null,
                startTime: null,
                endTime: null,
                startRecur: null,
                endRecur: null,
                is_repeat: false,
                no_of_repeats: 0,
                repeat_every: '',
                repeating_days: '',
                repeating_day_items: [],
                is_end: false,
                end_date: null,
                end_occurance: null,
                is_public: false,
            },
            //snackbar
            snackbar: false,
            snackbar_severity: 'success',
            snackbar_message: '',   
        }
    }

    componentDidMount() {
        this.loadAllPrograms();
        // this.LoadGenres();
        this.loadAllLineupPrograms();
        // this.LoadPrograms();
        // this.loadReviews();
        this.getUserData();
    }

    //Function to load all the programs
    async loadAllPrograms() { 
        let res = await VapaTvServices.getAllProgramsExternal(this.state.filterData);
        console.log('all programs', res.data.view)
        if (res.status == 200) {
            console.log('res -> all programs1', res.data)
            this.setState(
                {
                    programs: res.data.view.data,
                }
            )
            console.log('res -> state.programs', this.state.programs)
        }
    }
    
    closeDialog = () => {
        this.setState({
            eventViewDialogBox: false,
        })
    }
    //Setting data to the calendar readable format
    setEventData(data) {
        let event = {};
    
        if (data.is_repeat) {
            let days = [];
            
            event.title = data.lineup_title;
            event.startTime = data.start_time;
            event.endTime = data.end_time;
            event.startRecur = data.start_date + 'T' + data.start_time;
            event.endRecur = data.end_date + 'T' + data.end_time;
    
            console.log('Event Title:', event.title);
            console.log('Start Time:', event.startTime);
            console.log('End Time:', event.endTime);
            console.log('Start Recurrence:', event.startRecur);
            console.log('End Recurrence:', event.endRecur);
    
            for (let i = 0; data.TVLineupRepeatingDays.length > i; i++) {
                days.push(data.TVLineupRepeatingDays[i].day);
                console.log('Repeating Days:', days);
            }
    
            event.daysOfWeek = days;
            event.extendedProps = { "id": data.id };
    
        } else {
            event.title = data.lineup_title;
            event.start = data.start_date + 'T' + data.start_time;
            event.end = data.end_date + 'T' + data.end_time;
            event.extendedProps = { "id": data.id };
    
            console.log('Event Title:', event.title);
            console.log('Start:', event.start);
            console.log('End:', event.end);
        }
    
        console.log('Event Data:', event);
    
        return event;
    }
    

    //Function to load all lineup programs
    async loadAllLineupPrograms() { 
        this.setState({
            loaded: false,
        });

        let lineupData = [];
        let res = await VapaTvServices.getAllLineupProgramsExternal(this.state.filterData);
        if (res.status == 200) {
            console.log('res -> all lineup programs', res.data)
            let data = res.data.view.data;

            let dataArr = [];
            data.map((event) => {
                dataArr.push(this.setEventData(event));
            })
            
            for(let i=0; i < dataArr.length; i++) {
                var arrData = {}
                arrData['title'] = dataArr[i].title;
                arrData['startTime'] = dataArr[i].startTime;
                arrData['endTime'] = dataArr[i].endTime;
                arrData['start'] = dataArr[i].start;
                arrData['end'] = dataArr[i].end;
                arrData['startRecur'] = dataArr[i].startRecur;
                arrData['endRecur'] = dataArr[i].endRecur;
                arrData['daysOfWeek'] = dataArr[i].daysOfWeek;
                arrData['extendedProps'] = dataArr[i].extendedProps;

                lineupData[i] = arrData;
            }

            console.log('Incoming modified events: ', lineupData);
            this.setState({
                lineupEvents: lineupData,
                loaded: true,
            });
        }
    } 
    async dialogClose() {
        let lineupData = {
            tv_program_id: null,
            title: '',
            start: null,
            end: null,
            startTime: null,
            endTime: null,
            startRecur: null,
            endRecur: null,
            is_repeat: false,
            no_of_repeats: 0,
            repeat_every: '',
            repeating_days: '',
            repeating_day_items: [],
            is_end: false,
            end_date: null,
            end_occurance: null,
            is_public: false,
        }
        
        this.setState({
            eventId: null,
            dialogBox: false,
            eventViewDialogBox: false,
            lineupData: lineupData,
            viewData: lineupData,
        })
    }

    //Function to fetch single lineup event
    async fetchSingleEvent(id) {
        try {
            const res = await VapaTvServices.getSingleLineupExternal(id);
            console.log('Caught Lineup: ', res.data.view);
    
            let viewData = res.data.view;
            console.log('name', viewData.data[0].lineup_title);
            console.log('id', this.state.eventId);
    
            let days = [];
            // Ensure viewData.TVLineupRepeatingDays is defined before trying to access its length
            if (viewData.TVLineupRepeatingDays && viewData.TVLineupRepeatingDays.length > 0) {
                for (let i = 0; i < viewData.TVLineupRepeatingDays.length; i++) {
                    days.push(viewData.TVLineupRepeatingDays[i].day);
                }
            }
    
            // Update state for the repeating days
            days.forEach((day) => {
                switch(day) {
                    case 0:
                        this.setState({ sunday: true });
                        break;
                    case 1:
                        this.setState({ monday: true });
                        break;
                    case 2:
                        this.setState({ tuesday: true });
                        break;
                    case 3:
                        this.setState({ wednesday: true });
                        break;
                    case 4:
                        this.setState({ thursday: true });
                        break;
                    case 5:
                        this.setState({ friday: true });
                        break;
                    case 6:
                        this.setState({ saturday: true });
                        break;
                    default:
                        break;
                }
            });
    
            // Add repeat status to viewData
            viewData.is_repeatStatus = viewData.is_repeat ? 'Repeats' : 'Does not repeat';
    
            // Log the start and end times
            console.log(' Start Time:', viewData.data[0].start_time);
            console.log(' End Time:', viewData.data[0].end_time);
    
            // Format start and end times
            viewData.startTime = viewData.start_time;
            viewData.endTime = viewData.end_time;
            viewData.start_time = `${viewData.start_date}T${viewData.start_time}`;
            viewData.end_time = `${viewData.end_date}T${viewData.end_time}`;
    
            // Log the start and end times
            console.log('Formatted Start Time:', viewData.start_time);
            console.log('Formatted End Time:', viewData.end_time);
    
            // Update the state with the fetched data and load reviews
            this.setState({
                eventViewDialogBox: true,
                viewData: viewData
            }, () => {
                // Load reviews immediately after setting viewData
                this.loadReviews();
            });
    
            console.log('SELECTED EVENT: ', this.state.viewData);
            console.log('SELECTED EVENT: ', this.state.viewData.data[0].lineup_title);
    
        } catch (error) {
            console.error('Error fetching event:', error);
        }
    }
    
    async loadReviews() {
        this.setState({
            loaded: false  // Set loading state before fetching reviews
        });
    
        try {
            // Fetch reviews data
            let data = await VapaTvServices.getAllProgramReviewsForExternal();
            console.log('reviewdue', data.data.view.data[0].TVProgram.duration)
            console.log('reviewdue', data.data.view.data[0].TVProgram.TVGenre.name)


            if (data.status === 200) {
                // Extract and filter reviews based on eventId
                let reviews = data.data.view.data;
                let filtered = reviews.filter(review => review.tv_program_id === this.state.eventId);

                console.log('reviewdue', filtered);
                // Calculate overall rating
                let userRating = filtered.map(review => review.rating);
                let totalRate = userRating.reduce((acc, curr) => acc + curr, 0);
                let rateCount = userRating.length;
                let overallRate = rateCount > 0 ? totalRate / rateCount : 0;
    
                let programName = filtered[0].TVProgram.name;
                let genreSingle = filtered[0].TVProgram.TVGenre.name;
                let duration = filtered[0].TVProgram.duration;
                let image = data.data.view.data[0].TVProgram.TVProgramUploads.extension;
                // Update filteredReviews and overallRate
                this.setState({
                    reviews: reviews,
                    filteredReviews: filtered,
                    overallRate: overallRate,
                    loaded: true , // Ensure to manage loading state appropriately
                    programName : programName,
                    genreSingle: genreSingle,
                    duration: duration,
                    image:image,
                }, 
                console.log('reviewdue', this.state.filteredReviews)

            );
            } else {
                console.log('ERROR in loading reviews');
                this.setState({
                    loaded: true  // Ensure to manage loading state appropriately even on error
                });
            }
        } catch (error) {
            console.error('Error fetching reviews:', error);
            // Handle error cases if reviews loading fails
            this.setState({
                loaded: true  // Ensure to manage loading state appropriately even on error
            });
        }
    }
    
    


    async submitReview() {
        console.log('Review to submit: ', this.state.newReviewData);
        var data = this.state.newReviewData;
    
        // Set the tv_program_id to the current selected program's id from viewData
        data.tv_program_id = this.state.eventId;
    
        try {
            let res = await VapaTvServices.createProgramReviewExternal(data);
            console.log(res, 'Res in Creating new review');
            if (res.status === 201) {
                let newData = {
                    tv_program_id: null,
                    rater: '',
                    comment: '',
                    rating: 0,
                };
    
                this.setState({
                    newReviewData: newData,
                    snackbar: true,
                    snackbar_message: "Review Created Successfully",
                    snackbar_severity: "success",
                });
    
                // Optionally reload reviews after adding a new one
                this.loadReviews();
            } else {
                this.setState({
                    snackbar: true,
                    snackbar_severity: 'error',
                    snackbar_message: 'Failed to create review.',
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                snackbar: true,
                snackbar_severity: 'error',
                snackbar_message: 'Error!',
            });
        }
    }
    
    
    
   //Function to calculate overall user-rating for the selected program
   calcOverallRating(rateCount, totalRate) {
    let overall = 0.0;
    overall = totalRate / rateCount
    console.log('overall rating', overall)
    this.setState({
        overallRate: overall
    });
}
 //Function to get user data
 async getUserData() {
    var user_data = await localStorageService.getItem('userInfo');
    
    let data = this.state.newReviewData;
    data.rater = user_data.allData.preferred_username;

    this.setState({
        newReviewData: data
    });
}


render() {
    const { classes } = this.props;
    const { viewData } = this.state;

    return (
        <div style={{backrounColor:'#fff'}}>
        <Fragment>
            <MainContainer >
                {/* <LoonsCard > */}
                    <Grid container spacing={2}>
                        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                            <CardTitle title="Lineup Management" />
                        </Grid> */}
                    </Grid>
                    <div style={{display:'flex',flexDirection: 'column', alignItems:'center', justifyContent:'center'}}>
                        <h2 className='mt-5'>Line up Management for the public</h2>
                    <img
                        style={{ width: '400px', height: '400px' }}
                        src="/assets/images/vapatv.png"
                        alt="VAPA TV"
                    />
                    </div>

                    {this.state.loaded ? (
                        <Grid style={{ marginTop: 15, }} item lg={12} md={12} sm={3} xs={12}>
                            <FullCalendar
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                initialView={"timeGridWeek"}
                                title='Lineup Calendar'
                                headerToolbar={{
                                    start: 'today prev,next',
                                    center: 'title',
                                    end: "dayGridMonth,timeGridWeek,timeGridDay",
                                }}
                                selectable={true}
                                height={'80vh'}
                                buttonText={{
                                    today: 'Today',
                                    month: 'Month',
                                    week: 'Week',
                                    day: 'Day',
                                    list: 'List'
                                }}
                                events={this.state.lineupEvents}
                                eventClick={(info) => {
                                    this.setState({
                                        clickedEvent: info.event,
                                        eventId: info.event.extendedProps.id,
                                    })
                                    this.fetchSingleEvent(info.event.extendedProps.id);
                                }}
                                dateClick={(info) => {
                                    let data = this.state.lineupData;
                                    data.start = info;
                                    this.setState({
                                        dialogBox: true,
                                        clickedDate: info.date,
                                        clickedTime: info.date.getHours() + ':' + info.date.getMinutes()
                                    });
                                }}
                            />
                        </Grid>
                    ) : (
                        <Grid className="justify-center text-center w-full pt-12">
                            <CircularProgress size={30} />
                        </Grid>
                    )}

                    {/* Dialog box -> View Lineup Event */}
                    <Dialog
                        open={this.state.eventViewDialogBox}
                        onClose={this.dialogClose.bind(this)}
                        aria-labelledby="form-dialog-title"
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogContent>
                            <Grid container spacing={2} className='p-10'>
                                {/*---------- Review Form ----------*/}
                                <Grid item direction='column' lg={6} md={6} sm={12} xs={12}>
                                    <ImageView
                                        image_data={this.state.image
                                        }
                                        preview_width="180px"
                                        preview_height="180px"
                                    />
                                    <ValidatorForm
                                        onSubmit={() => this.submitReview()}
                                        onError={(error) => console.log(error)}
                                    >
                                        <Grid item container className="mt-1" spacing={2}>
                                            <Grid item lg={10} md={6} sm={12} xs={12}>
                                                <Typography className='font-semibold'>
                                                    Add your Rating
                                                </Typography>
                                                <Rating
                                                    name="half-rating"
                                                    defaultValue={2}
                                                    precision={1}
                                                    value={this.state.newReviewData?.rating}
                                                    onChange={(event, value) => {
                                                        let data = this.state?.newReviewData;
                                                        data.rating = value;

                                                        this.setState({
                                                            newReviewData: data
                                                        });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={10} md={6} sm={12} xs={12}>
                                                <Typography className='font-semibold mb-2'>
                                                    Add your Comment
                                                </Typography>
                                                <TextValidator
                                                    className="w-full"
                                                    label="Comment"
                                                    name="comment"
                                                    onChange={(event) => {
                                                        let data = this.state?.newReviewData;
                                                        data.comment = event.target.value;

                                                        this.setState({
                                                            newReviewData: data
                                                        });
                                                    }}
                                                    value={this.state.newReviewData?.comment}
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    multiline={true}
                                                    rows={5}
                                                    validators={['required']}
                                                    errorMessages={[
                                                        'This field is required',
                                                    ]}
                                                />
                                            </Grid>
                                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                                <Button
                                                    className="w-full"
                                                    type="submit"
                                                    startIcon={<SaveIcon />}
                                                >
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </ValidatorForm>
                                </Grid>

                                {/*---------- Review List ----------*/}
                                <Grid item direction='column' lg={6} md={6} sm={12} xs={12}>
                                    <Grid item container className="mt-1" spacing={2}>
                                        <Grid item>
                                            {this.state.viewData && this.state.viewData.data && (
                                                <>
                                                    <Typography className='font-semibold' style={{ fontSize: '18px' }}>
                                                        Program Name: {viewData.data[0].lineup_title}
                                                        {/* {this.state.programName} */}
                                                    </Typography>
                                                    <Typography> Genre: {this.state.genreSingle}</Typography>
                                                    <Typography> Duration: {this.state.duration} minutes </Typography>
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                    {this.state.overallRate > 0 && (
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Typography className='font-semibold mt-5'>
                                                Overall Rating 
                                                {/* for {this.state.viewData.name} */}
                                            </Typography>
                                            <Rating name="size-large" size="large" precision={0.25} value={this.state.overallRate} readOnly />
                                        </Grid>
                                    )}
                                    <Grid item lg={12} md={6} sm={12} xs={12}>
                                        <Paper style={{ maxHeight: 500, overflow: 'auto' }}>
                                            <List>
                                                {this.state.loaded ? (
                                                    this.state.filteredReviews.length > 0 ? (
                                                        this.state.filteredReviews.map((review) => (
                                                            <Fragment key={review.id}>
                                                                <ListItem alignItems='flex-start'>
                                                                    <ListItemAvatar>
                                                                        <Avatar src='' />
                                                                    </ListItemAvatar>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Fragment>
                                                                                <Typography>
                                                                                    {/* {`${review.rater} `} */}
                                                                                    External Reviewer
                                                                                </Typography>
                                                                                {/* <Typography>{`${review.TVProgram.name}`}</Typography> */}
                                                                                <Rating name="half-rating" value={review.rating} readOnly />
                                                                            </Fragment>
                                                                        }
                                                                        secondary={
                                                                            <Fragment>
                                                                                {/* <Typography
                                                                                    component="span"
                                                                                    variant="body2"
                                                                                    color="textPrimary"
                                                                                >
                                                                                    {`${review.rater} - `}
                                                                                </Typography> */}
                                                                                <Typography component="span" variant="body2" color="textPrimary" style={{color:'grey'}}>
                                                                                   Comment: {review.comment}
                                                                                </Typography>
                                                                            </Fragment>
                                                                        }
                                                                    />
                                                                </ListItem>
                                                                <Divider variant="inset" component="li" />
                                                            </Fragment>
                                                        ))
                                                    ) : (
                                                        <Typography variant="body2" className="ml-24">
                                                            No reviews found for this program.
                                                        </Typography>
                                                    )
                                                ) : (
                                                    <Grid container justify="center" alignItems="center" style={{ height: 300 }}>
                                                        <CircularProgress />
                                                    </Grid>
                                                )}
                                            </List>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                {/* </LoonsCard> */}
            </MainContainer>

            <LoonsSnackbar
                    open={this.state.snackbar}
                    onClose={() => {
                        this.setState({
                            snackbar: false,
                        })
                    }}
                    message={this.state.snackbar_message}
                    autoHideDuration={3000}
                    severity={this.state.snackbar_severity}
                    elevation={2}
                    variant="filled"
                ></LoonsSnackbar>
        </Fragment>
        </div>
    );
}

}

export default LineUpManagementExternal;