import React from 'react'
import { authRoles } from '../../auth/authRoles'

const duplicateIdRoutes = [


    {
        path: '/ma/viewduplicateIDCardsRequests',
        component: React.lazy(() => import('./ViewDuplicateIdCardsRequests')),
        auth: authRoles.ListOfDuplicateIDCardRequests,
    },
    {
        path: '/ma/changeStatusDuplicateID',
        component: React.lazy(() => import('./ChangeStatusSingleDuplicateIDRequest')),
        auth: authRoles.ListOfDuplicateIDCardRequests,
    },
    {
        path: '/studentaffairs/approveAllDuplicateIDRequests',
        component: React.lazy(() => import('./ApproveAllDuplicateIDCardsRequest')),
        auth: authRoles.ListOfDuplicateIDCardRequests,
    },
    {
        path: '/studentaffairs/approveSingleDuplicateIDRequests',
        component: React.lazy(() => import('./ApproveSingleDuplicateIDRequest')),
        auth: authRoles.ListOfDuplicateIDCardRequests,
    },


]

export default duplicateIdRoutes;
