import React from 'react'
import { authRoles } from '../../auth/authRoles'

const MedicalUnitRoutes = [
    // {
    //     path: '/medicalunit/staff/view_staff_list',
    //     component: React.lazy(() => import('./Staff/StaffList/ViewStaffList')),
    //     auth: authRoles.MedicalForDoctorNurse,
    // },

    //Initial Medical Format for a Staff
    {
        path: '/medicalunit/patientInfo/:id',
        component: React.lazy(() => import('./Patient/PatientInfo')),
        auth: authRoles.MedicalForDoctorNurse,
    },

    // Scan Patient
    {
        path: '/medicalunit/scanpatient',
        component: React.lazy(() => import('./Patient/ScanPatient')),
        auth: authRoles.MedicalForDoctorNurse,
    },
    // View Patient profile
    {
        path: '/medicalunit/viewpatientprofile',
        component: React.lazy(() => import('./Patient/PatientInfo')),
        auth: authRoles.MedicalForDoctorNurse,
    },

    //View Issued Drug Details to patients
    {
        path: '/medicalunit/viewissueddrugdetails',
        component: React.lazy(() => import('./ViewIssuedDrugTabHandeling')),
        auth: authRoles.MedicalForDoctorNurse,
    },
    //Overall Medical Checkup Details
    {
        path: '/medicalunit/overallmedicalcheckupdetails',
        component: React.lazy(() => import('./OverallMedicalCheckupDetails')),
        auth: authRoles.MedicalForDoctorNurse,
    },
    //Overall Medical Checkup Details
    {
        path: '/medicalunit/overallmedicaldetails',
        component: React.lazy(() => import('./OverallMedicalDetails')),
        auth: authRoles.MedicalForDoctorNurse,
    },

    //Overall Medical Checkup Details of Staff's Family
    {
        path: '/medicalunit/overallmedicalcheckupdetailsoffamily',
        component: React.lazy(() => import('./OverallMedicalCheckupDetails_StaffFamily')),
        auth: authRoles.MedicalForDoctorNurse,
    },
    {
        path: '/medical_unit/student/student_list',
        component: React.lazy(() => import('./Student/StudentList')),
        auth: authRoles.MedicalForDoctorNurse,
    },
    {
        path: '/medical_unit/student/inital_medical_certification/:id',
        component: React.lazy(() => import('./Student/InitialMedicalCertification')),
        auth: authRoles.MedicalForDoctorNurse,
    },
    {
        path: '/medical_unit/student/patient_info/:id',
        component: React.lazy(() => import('./Student/PatientInfo')),
        auth: authRoles.MedicalForDoctorNurse,
    },
    {
        path: '/medical_unit/medical',
        component: React.lazy(() => import('./Patient/Medical/Medical')),
        auth: authRoles.MedicalForDoctorNurse,
    },
    {
        path: '/medical_unit/family_checkup',
        component: React.lazy(() => import('./Patient/Family/FamilyCheckup')),
        auth: authRoles.MedicalForDoctorNurse,
    },
    {
        path: '/medical_unit/todays_prescription',
        component: React.lazy(() => import('./TodaysPrescription')),
        auth: authRoles.MedicalForDoctorNurse,
    },

    {
        path: '/medical_unit/medical_items',
        component: React.lazy(() => import('./MedicalItems/MedicalItemsTabHandeling')),
        auth: authRoles.MedicalForDoctorNurse,
    },
]

export default MedicalUnitRoutes
