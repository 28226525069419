//For local backend routes endpoints
// export const ENDPOINT = 'http://localhost:81/'
// export const STUDENT_MICROSERVICE = ENDPOINT + 'api/students/'
// export const ADMINISTRATIONS_MICROSERVICE = ENDPOINT + 'api/administrations/'
// export const EMPLOYEES_MICROSERVICE = ENDPOINT + 'api/employees/'
// export const EXAMINATIONS_MICROSERVICE = ENDPOINT + 'api/exams/'
// export const MEDICAL_MICROSERVICE = ENDPOINT + 'api/medicals/'
// export const FINANCE_MICROSERVICE = ENDPOINT + 'api/finance/'
// export const USERMANAGEMENT_MICROSERVICE = ENDPOINT + 'api/usermanagementapp/'
// export const CAMUNDA = 'http://localhost:81/engine-rest/'
// export const KEYCLOAK = 'http://localhost:8080/auth/'
// export const BROWSER_URL =
//     'http://localhost:3000/aptitude_test/submit_answer_by_student/'
// export const AUDIT_MICROSERVICES = ENDPOINT + 'api/audit/'

// Azure Connect -- Testing Deployment
// export const ENDPOINT = 'http://20.84.117.40/'
// export const CAMUNDA = 'http://20.84.117.40/engine-rest/'
// export const KEYCLOAK = 'http://20.84.117.40/auth/'
// export const BROWSER_URL = 'http://20.84.117.40/aptitude_test/submit_answer_by_student/'

// Testing New Connect -- Testing Deployment
// export const ENDPOINT = 'http://52.224.240.88/'
// export const CAMUNDA = 'http://52.224.240.88/engine-rest/'
// export const KEYCLOAK = 'http://52.224.240.88/auth/'
// export const BROWSER_URL ='http://52.224.240.88/aptitude_test/submit_answer_by_student/'

// Testing Deployment - New
// export const ENDPOINT = 'http://52.224.240.88/'
// export const CAMUNDA = 'http://52.224.240.88/engine-rest/'
// export const KEYCLOAK = 'http://52.224.240.88/auth/'
// export const BROWSER_URL = 'http://52.224.240.88/aptitude_test/submit_answer_by_student/'

// EMS Connect
export const ENDPOINT = 'https://ems.vpa.ac.lk/'
export const CAMUNDA = 'https://ems.vpa.ac.lk/engine-rest/'
export const KEYCLOAK = 'https://ems.vpa.ac.lk/auth/'
export const BROWSER_URL = 'https://ems.vpa.ac.lk/aptitude_test/submit_answer_by_student/'

export const STUDENT_MICROSERVICE = ENDPOINT + 'studentapp/api/'
export const ADMINISTRATIONS_MICROSERVICE = ENDPOINT + 'administrationapp/api/'
export const EMPLOYEES_MICROSERVICE = ENDPOINT + 'employeeapp/api/'
export const EXAMINATIONS_MICROSERVICE = ENDPOINT + 'examapp/api/'
export const MEDICAL_MICROSERVICE = ENDPOINT + 'medicalapp/api/'
export const FINANCE_MICROSERVICE = ENDPOINT + 'financeapp/api/'
export const USERMANAGEMENT_MICROSERVICE = ENDPOINT + 'usermanagementapp/api/'
export const AUDIT_MICROSERVICES = ENDPOINT + 'audit/api/'

//Keycloack API Routes
export const USER_LOGIN =
    KEYCLOAK + 'realms/VPARealm/protocol/openid-connect/token'
export const USER_GET_KEYCLOAK = KEYCLOAK + 'admin/realms/VPARealm/users'
export const CAMUNDA_TASK = CAMUNDA + 'task'
export const CAMUNDA_PROCESS_VAR = CAMUNDA + 'variable-instance'
export const FILE_VIEW = STUDENT_MICROSERVICE + 'uploads/'

//Student Api Routes
export const CREATE_SINGLE_STUDENT = STUDENT_MICROSERVICE + 'students/'
export const CREATE_BULK_STUDENT = STUDENT_MICROSERVICE + 'students/bulk'
export const GET_SINGLE_STUDENT = STUDENT_MICROSERVICE + 'students/'
export const CHANGE_STUDENT_STATUS = STUDENT_MICROSERVICE + 'students/status/'
export const ELEVATE_STUDENT_BULK = STUDENT_MICROSERVICE + 'students/transfer/'
export const SINGLE_STUDENT_CREATE_MANUAL = STUDENT_MICROSERVICE + 'students/'
export const GET_ALL_STUDENTS = STUDENT_MICROSERVICE + 'students/'

export const GENERATE_ALL_STUDENT_REPORT =
    STUDENT_MICROSERVICE + 'students/reports/download'

export const REQUEST_DUPLICATE_ID = STUDENT_MICROSERVICE + 'duplicate_card'

export const EDIT_STUDENT_DETAILS = STUDENT_MICROSERVICE + 'students/'
export const CANCELLATION_STUDENT_REG =
    STUDENT_MICROSERVICE + 'student_requests/'
export const ID_CARD_SUBMIT = STUDENT_MICROSERVICE + 'student_requests/'
export const STUDENT_REQUEST_ACTIONS =
    ADMINISTRATIONS_MICROSERVICE + 'student_requests/status/'

export const GET_STUDENT_ID_STATUS = ADMINISTRATIONS_MICROSERVICE + 'cards/'
export const UPDATE_STUDENT_ID_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'cards/status/'

export const GET_SINGLE_POSTGRADUATE_STUDENT =
    STUDENT_MICROSERVICE + 'postgraduate/applicant/'
export const REQUEST_APPEAL = STUDENT_MICROSERVICE + 'student_requests/'
export const UPDATE_APPEAL_STATUS = STUDENT_MICROSERVICE + 'student_requests/'

export const ADD_STUDENT_PASSWORD_LIST =
    STUDENT_MICROSERVICE + 'student_passwords/'

export const STUDENT_FOR_PASSWORD_LINK = USERMANAGEMENT_MICROSERVICE + 'users/'
export const STUDENT_PASSWORD_LINK_SEND =
    USERMANAGEMENT_MICROSERVICE + 'users/send_rest_link'

//Administrations Api Routes
export const SINGLE_APPEAL_STUDENT_ADMINI =
    ADMINISTRATIONS_MICROSERVICE + 'student_requests/'
export const SINGLE_CANCELLATION_STUDENT_ADMINI =
    ADMINISTRATIONS_MICROSERVICE + 'student_requests/'
export const GET_ALL_FACULTIES = ADMINISTRATIONS_MICROSERVICE + 'faculties/'
export const CREATE_FACULTY = ADMINISTRATIONS_MICROSERVICE + 'faculties/'
export const EDIT_FACULTY_DETAILS = ADMINISTRATIONS_MICROSERVICE + 'faculties/'
export const CHANGE_FACULTY_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'faculties/status/'

export const GET_ALL_YEAR_RENEWALS =
    STUDENT_MICROSERVICE + 'year_renewal_request'
export const APPROVE_REJECT_ONE_YEAR_RENEWAL =
    ADMINISTRATIONS_MICROSERVICE + 'year_renewal_request/status/'
export const APPROVE_REJECT_BULK_YEAR_RENEWALS =
    ADMINISTRATIONS_MICROSERVICE + 'year_renewal_request/bulk_status'
export const GET_YEAR_RENEWALS = ADMINISTRATIONS_MICROSERVICE + 'years'
export const TIMETABLE_SETUP =
    ADMINISTRATIONS_MICROSERVICE + 'time_table_setup/'
export const LECTURE_SCHEDULE =
    ADMINISTRATIONS_MICROSERVICE + 'lecture_schedule/'

export const STUDENTS_ATTENDANCE =
    ADMINISTRATIONS_MICROSERVICE + 'student_lecture_attendance/'
export const LECTURE_DATE =
    ADMINISTRATIONS_MICROSERVICE + 'student_lecture_attendance/dates/'

export const ALL_GRADUATES = ADMINISTRATIONS_MICROSERVICE + 'all_graduates/'
export const LIBRARY_CLEARANCE = ADMINISTRATIONS_MICROSERVICE + 'library_clearance/'
export const PAYMENT_CLEARANCE = ADMINISTRATIONS_MICROSERVICE + 'payment_clearance/'
export const OUTSTANDING_PAYMENTS = ADMINISTRATIONS_MICROSERVICE + 'outstanding_payments/'
export const HOSTEL_PAYMENT_CLEARANCE = ADMINISTRATIONS_MICROSERVICE + 'hostel_payment_clearance/'
export const MAIN_STORE_ITEM_CLEARANCE = ADMINISTRATIONS_MICROSERVICE + 'main_store_item_clearance/'
export const MAIN_STORE_ITEMS = ADMINISTRATIONS_MICROSERVICE + 'main_store_items/'

export const CERTIFICATE_TYPES = ADMINISTRATIONS_MICROSERVICE + 'certificate_types/'
export const COUNTRY_POSTAGE = ADMINISTRATIONS_MICROSERVICE + 'country_postage/'

// Aptitude Appeals types

export const CREATE_APTITUDE_APPEAL_TYPE =
    ADMINISTRATIONS_MICROSERVICE + 'aptitude_appeal_types'
export const CHANGE_APTITUDE_APPEAL_TYPE_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'aptitude_appeal_types/status'
export const GET_ALL_APTITUDE_APPEAL_TYPES =
    ADMINISTRATIONS_MICROSERVICE + 'aptitude_appeal_types'
export const EDIT_APTITUDE_APPEAL_TYPE =
    ADMINISTRATIONS_MICROSERVICE + 'aptitude_appeal_types'

//Aptitude Appeals  Categories
export const CREATE_APTITUDE_APPEAL_CATEGORY =
    ADMINISTRATIONS_MICROSERVICE + 'aptitude_appeal_categories'
export const CHANGE_APTITUDE_APPEAL_CATEGORY_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'aptitude_appeal_categories/status'
export const GET_ALL_APTITUDE_APPEAL_CATEGORIES =
    ADMINISTRATIONS_MICROSERVICE + 'aptitude_appeal_categories'
export const EDIT_APTITUDE_APPEAL_CATEGORY =
    ADMINISTRATIONS_MICROSERVICE + 'aptitude_appeal_categories'

//intake Api routes
export const GET_All_INTAKES = ADMINISTRATIONS_MICROSERVICE + 'intakes/'
export const CHANGE_INTAKE_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'intakes/status/'
export const CREATE_INTAKE = ADMINISTRATIONS_MICROSERVICE + 'intakes/'

//Intake Routes
export const CREATE_SINGLE_INTAKE = ADMINISTRATIONS_MICROSERVICE + 'intakes'
export const GET_ALL_INTAKES = ADMINISTRATIONS_MICROSERVICE + 'intakes'

//Course routes
export const GET_ALL_COURSES = ADMINISTRATIONS_MICROSERVICE + 'courses'
export const CREATE_COURSE = ADMINISTRATIONS_MICROSERVICE + 'courses'
export const CHANGE_COURSE_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'courses/status/'
export const EDIT_COURSE_DETAILS = ADMINISTRATIONS_MICROSERVICE + 'courses/'

// Duplicate Cards
export const GET_ALL_DUPLICATE_CARD_REQUESTS =
    ADMINISTRATIONS_MICROSERVICE + 'duplicate_card'
export const UPDATE_SINGLE_DUPLICATE_CARD_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'duplicate_card/status/'
export const UPDATE_BULK_DUPLICATE_CARD_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'duplicate_card/bulk_status'

//year renewal
export const REQUEST_YEAR_RENEWAL =
    STUDENT_MICROSERVICE + 'year_renewal_request'
export const SETUP_YEAR_RENEWAL = ADMINISTRATIONS_MICROSERVICE + 'years'

//Batch
export const CREATE_BATCH = ADMINISTRATIONS_MICROSERVICE + 'batch/'
export const VIEW_ALL_BATCHES = ADMINISTRATIONS_MICROSERVICE + 'batch/'
export const GET_SINGLE_BATCH = ADMINISTRATIONS_MICROSERVICE + 'batch/'

//Advertisements
export const CREATE_ADVERTISEMENT =
    ADMINISTRATIONS_MICROSERVICE + 'advertisements'
export const GET_ADVERTISEMENT = ADMINISTRATIONS_MICROSERVICE + 'advertisements'
export const CHANGE_ADVERTISEMENT_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'advertisements/status/'
export const UPDATE_BATCH_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'batch/status/'
export const UPDATE_ADVERTISEMENT =
    ADMINISTRATIONS_MICROSERVICE + 'advertisements/'

//Curriculum
export const VIEW_ALL_CURRICULUMS =
    ADMINISTRATIONS_MICROSERVICE + 'curriculums/'
export const CHANGE_STATUS_CURRICULUM =
    ADMINISTRATIONS_MICROSERVICE + 'curriculums/status/'
export const CREATE_CURRICULUM = ADMINISTRATIONS_MICROSERVICE + 'curriculums/'

//subject
export const CREATE_SUBJECT = ADMINISTRATIONS_MICROSERVICE + 'subjects'
export const VIEW_ALL_SUBJECTS = ADMINISTRATIONS_MICROSERVICE + 'subjects'
export const UPDATE_SUBJECT = ADMINISTRATIONS_MICROSERVICE + 'subjects/'
export const STUDENT_SUBJECT_REGISTRATION =
    ADMINISTRATIONS_MICROSERVICE + 'student_subject_registration/'

//syllabus
export const SYLLABUS = ADMINISTRATIONS_MICROSERVICE + 'syllabus/'

//yearly syllabus
export const YEARLY_SYLLABUS = ADMINISTRATIONS_MICROSERVICE + 'yearly_syllabus/'

//Application
export const CREATE_APPLICANT_ACCOUNT =
    STUDENT_MICROSERVICE + 'postgraduate/applicant'
export const GET_APPLICANT_ACCOUNT =
    STUDENT_MICROSERVICE + 'postgraduate/applicant/'
export const UPDATE_APPLICANT_ACCOUNT =
    STUDENT_MICROSERVICE + 'postgraduate/applicant/'
export const POSTGRADUATE_APPLICATION_CONFIRMATIONS = STUDENT_MICROSERVICE + 'postgraduate_application_confirmations/'
export const POSTGRADUATE_APPLICATION_REVIEWERS = STUDENT_MICROSERVICE + 'postgraduate_applicant_reviewers/'
export const POSTGRADUATE_APPLICATION_EXAMINERS = STUDENT_MICROSERVICE + 'postgraduate_applicant_examiners/'

//Academic
export const SAVE_ACADEMIC_DATA =
    STUDENT_MICROSERVICE + 'postgraduate/applicant_academic'
export const GET_ACADEMIC_DATA =
    STUDENT_MICROSERVICE + 'postgraduate/applicant_academic/find/'
export const UPDATE_SINGLE_ACADEMIC_DATA =
    STUDENT_MICROSERVICE + 'postgraduate/applicant_academic/'
export const DELETE_ACADEMIC_DEGREE =
    STUDENT_MICROSERVICE + 'postgraduate/applicant_academic/'

//Course
export const SAVE_COURSE_DATA =
    STUDENT_MICROSERVICE + 'postgraduate/applicant_course/'
export const GET_APPLICANT_SELECTED_COURSE =
    STUDENT_MICROSERVICE + 'postgraduate/applicant_course/'
export const GET_APPLICANT_COURSE =
    STUDENT_MICROSERVICE + 'postgraduate/applicant_course/'
export const APPLICANT_FORM_FINAL_SUBMIT =
    STUDENT_MICROSERVICE + 'postgraduate/applicant_course/submit/'

export const APPLICANT_GET_MY_APPLICATIONS =
    STUDENT_MICROSERVICE + 'postgraduate/applicant_course/'

// Referee
export const SAVE_REFEREE_DATA =
    STUDENT_MICROSERVICE + 'postgraduate/applicant_referee'

//supervisor
export const SAVE_SUPERVISOR_DATA =
    STUDENT_MICROSERVICE + 'postgraduate/applicant_supervisor'
export const UPDATE_SINGLE_SUPERVISOR_DATA =
    STUDENT_MICROSERVICE + 'postgraduate/applicant_supervisor/'

//applicant form submit
export const GET_ALL_APPLICATIONS =
    STUDENT_MICROSERVICE + 'postgraduate/applicant_course'

//supervisor from
export const GET_SUPERVISOR_FORM_DATA =
    STUDENT_MICROSERVICE + 'postgraduate/postgraduate_applicant_supervisor/'
export const UPDATE_SUPERVISOR_FORM_DATA =
    STUDENT_MICROSERVICE + 'postgraduate/applicant_supervisor/'

// Documents
export const STUDENT_DOCUMENTS =
    ADMINISTRATIONS_MICROSERVICE + 'required_uploads'
export const STUDENT_DOCUMENTS_UPLOAD =
    STUDENT_MICROSERVICE + 'students_uploads'
export const STUDENT_APTITUDE_UPLOAD =
    STUDENT_MICROSERVICE + 'aptitude_students_uploads'
export const STUDENT_DOCUMENTS_APPROVE =
    STUDENT_MICROSERVICE + 'students_uploads/status'

export const CHANGE_DOCUMENT_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'required_uploads/status/'

//test
export const CREATE_TEST = ADMINISTRATIONS_MICROSERVICE + 'tests'
export const GET_ALL_TEST_DATA = ADMINISTRATIONS_MICROSERVICE + 'tests'
export const GET_SINGE_TEST_DATA = ADMINISTRATIONS_MICROSERVICE + 'tests/'

//Aptitude test
export const CREATE_APPLICANT_ACCOUNT_VERIFICATION =
    STUDENT_MICROSERVICE + 'students_aptitude'
export const UPDATE_APPLICANT_ACCOUNT_DATA =
    STUDENT_MICROSERVICE + 'students_aptitude/'
export const GET_APPLICANT_ACCOUNT_DATA =
    STUDENT_MICROSERVICE + 'students_aptitude/'

export const GET_APPEAL_TYPES =
    ADMINISTRATIONS_MICROSERVICE + 'aptitude_appeal_types'
export const GET_APPEAL_CATEGORIES =
    ADMINISTRATIONS_MICROSERVICE + 'aptitude_appeal_categories'
export const CREATE_APTITUDE_APPEAL =
    STUDENT_MICROSERVICE + 'aptitude_student_requests'
export const GET_APTITUDE_APPEALS =
    ADMINISTRATIONS_MICROSERVICE + 'aptitude_student_requests'
export const GET_APTITUDE_DEGREE_PROGRAM_BY_ID =
    ADMINISTRATIONS_MICROSERVICE + 'tests/'
export const GET_STUDENT_APTITUDE_ADMISSIONS =
    ADMINISTRATIONS_MICROSERVICE + 'aptitude_student_faculty_tests'
export const GET_SINGLE_STUDENT_APTITUDE_ADMISSION_DATA =
    EXAMINATIONS_MICROSERVICE + 'aptitude_test_exams'
export const UPDATE_STUDENT_APTITUDE_ADMISSIONS_VERIFY =
    ADMINISTRATIONS_MICROSERVICE + 'aptitude_student_faculty_tests/verify'
export const STUDENT_APTITUDE_ADMISSIONS_SEND_EMAIL =
    ADMINISTRATIONS_MICROSERVICE + 'aptitude_student_faculty_tests/email'

export const GET_ALl_EXAMINERS = EXAMINATIONS_MICROSERVICE + 'test_examiners/'
export const GET_ALl_STUDENT_ANSWERS =
    EXAMINATIONS_MICROSERVICE + 'aptitude_exam_student_answers'
export const PUT_MARKS =
    EXAMINATIONS_MICROSERVICE + 'aptitude_test_panel_examiners_answer_marks/'
export const GET_ALl_PAPERS =
    EXAMINATIONS_MICROSERVICE + 'aptitude_test_exams_student/'
export const CREATE_APTITUDE_PAPER_QUESTION =
    EXAMINATIONS_MICROSERVICE + 'aptitude_test_exams/'
export const UPDATE_SINGLE_ANSWER =
    EXAMINATIONS_MICROSERVICE + 'aptitude_exam_student_answers/'
export const GET_ALL_STUDENTS_ANSWERS =
    EXAMINATIONS_MICROSERVICE + 'aptitude_exam_student_answers/select/questions'
export const GET_ALL_STUDENTS_ANSWERS_SUMMARY =
    EXAMINATIONS_MICROSERVICE + 'aptitude_test_student_papers/paper/count'

//Academic Years
export const ACADEMIC_YEARS = ADMINISTRATIONS_MICROSERVICE + 'academic_years'

//degree program
export const GET_ALL_DEGREE_PROGRAMS =
    ADMINISTRATIONS_MICROSERVICE + 'degree_programs'
export const CREATE_DEGREE_PROGRAM =
    ADMINISTRATIONS_MICROSERVICE + 'degree_programs'

// Examinations
export const CREATE_NEW_APTITUDE_TEST =
    ADMINISTRATIONS_MICROSERVICE + 'test_faculty/'
export const GET_ALL_APTITUDE_TESTS =
    ADMINISTRATIONS_MICROSERVICE + 'test_faculty/'

export const GET_ALL_EXAMINATIONS =
    ADMINISTRATIONS_MICROSERVICE + 'test_faculty'
export const GET_SINGLE_APTITUDE_TEST_EXAMINATION =
    ADMINISTRATIONS_MICROSERVICE + 'tests/'
export const CREATE_DEGREE_PROGRAMS =
    ADMINISTRATIONS_MICROSERVICE + 'degree_programs'

export const CREATE_NEW_APTITUDE_TEST_CUTOFF =
    EXAMINATIONS_MICROSERVICE + 'test_faculty/cutoff/'
export const GET_ALL_APTITUDE_TESTS_CUTOFF =
    ADMINISTRATIONS_MICROSERVICE + 'test_faculty/'

//Examinations - convocation

export const CONVOCATION = ADMINISTRATIONS_MICROSERVICE + 'convocation/'
export const CONVOCATION_REGISTRATION = ADMINISTRATIONS_MICROSERVICE + 'convocation_registration/'
export const CONVOCATION_SESSIONS = ADMINISTRATIONS_MICROSERVICE + 'convocation_sessions/'
export const CONVOCATION_FACULTIES = ADMINISTRATIONS_MICROSERVICE + 'convocation_faculties/'
// Aptitude Test -----------
// Exam Panels
export const GET_ALL_PANELS = EXAMINATIONS_MICROSERVICE + 'test_panels'
export const CREATE_PANEL = EXAMINATIONS_MICROSERVICE + 'test_panels'
export const EDIT_PANEL = EXAMINATIONS_MICROSERVICE + 'test_panels'
//Examiners
export const GET_EXAMINER_LIST = EXAMINATIONS_MICROSERVICE + 'test_examiners'
export const CREATE_EXAMINER_LIST = EXAMINATIONS_MICROSERVICE + 'test_examiners'
// Employees
export const GET_ALL_EMPLOYEES = EMPLOYEES_MICROSERVICE + 'employees'
export const GET_ALL_VACANCIES =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_vacancies'
export const GET_ALL_VACANCIES_BY_ID =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_applications'
export const GET_TEMP_EMPLOYEE = EMPLOYEES_MICROSERVICE + 'temp_employees'
export const CREATE_TEMP_PSG_DETAILS =
    EMPLOYEES_MICROSERVICE + 'temp_employees_post_graduates'
export const GET_TEMP_EMPLOYEE_PSG =
    EMPLOYEES_MICROSERVICE + 'temp_employees_post_graduates'
export const GET_PSG_TYPES = EMPLOYEES_MICROSERVICE + 'temp_employees_pgd_types'
export const UPDATE_BASIC_INFORMATION =
    EMPLOYEES_MICROSERVICE + 'temp_employees/'
export const GET_TEMP_EMPLOYEE_GRADUATE_DETAILS =
    EMPLOYEES_MICROSERVICE + 'temp_employees_graduates'
export const CREATE_TEMP_EMPLOYEE_GRADUATE_DETAILS =
    EMPLOYEES_MICROSERVICE + 'temp_employees_graduates'
export const GET_TEMP_PROFESSIONAL_QUALIFICATIONS =
    EMPLOYEES_MICROSERVICE + 'temp_employees_qualifications'
export const CREATE_TEMP_PROFESSIONAL_QUALIFICATIONS =
    EMPLOYEES_MICROSERVICE + 'temp_employees_qualifications'
export const GET_TEMP_WORKING_EXPERIENCE =
    EMPLOYEES_MICROSERVICE + 'temp_employees_works'
export const CREATE_TEMP_WORKING_EXPERIENCE =
    EMPLOYEES_MICROSERVICE + 'temp_employees_works'
export const GET_TEMP_CREATIVE_WORKS =
    EMPLOYEES_MICROSERVICE + 'temp_employees_creative_works'
export const CREATE_TEMP_CREATIVE_WORKS =
    EMPLOYEES_MICROSERVICE + 'temp_employees_creative_works'
export const GET_TEMP_RESEARCH_PUBLICATIONS =
    EMPLOYEES_MICROSERVICE + 'temp_employees_publications'
export const CREATE_TEMP_RESEARCH_PUBLICATIONS =
    EMPLOYEES_MICROSERVICE + 'temp_employees_publications'
export const GET_TEMP_AWARD_SCHOLARSHIP =
    EMPLOYEES_MICROSERVICE + 'temp_employees_awards'
export const CREATE_TEMP_AWARD_SCHOLARSHIP =
    EMPLOYEES_MICROSERVICE + 'temp_employees_awards'
export const UPDATE_EMPLOYEE_STATUS =
    EMPLOYEES_MICROSERVICE + 'temp_employees/status/'
export const CREATE_TEMP_EMPLOYEE_NEW_DEGREE_TYPE =
    EMPLOYEES_MICROSERVICE + 'temp_employees_pgd_types'

export const CREATE_BASIC_QUALIFICATIONS =
    EMPLOYEES_MICROSERVICE + 'qualifications'
export const GET_BASIC_QUALIFICATIONS =
    EMPLOYEES_MICROSERVICE + 'qualifications'
export const UPDATE_BASIC_QUALIFICATIONS =
    EMPLOYEES_MICROSERVICE + 'qualifications/status/'
export const DELETE_BASIC_QUALIFICATIONS =
    EMPLOYEES_MICROSERVICE + 'qualifications/'

export const GET_DESIGNATIONS = EMPLOYEES_MICROSERVICE + 'designations'

export const GET_DESIGNATIONS_CATEGORY =
    EMPLOYEES_MICROSERVICE + 'designation_category'

export const GET_SERVICE_TYPE = EMPLOYEES_MICROSERVICE + 'service_type'

export const CREATE_CIRCULAR_TYPE = EMPLOYEES_MICROSERVICE + 'circulars_types'
export const GET_CIRCULAR_TYPE = EMPLOYEES_MICROSERVICE + 'circulars_types'
export const UPDATE_CIRCULAR_TYPE_STATUS =
    EMPLOYEES_MICROSERVICE + 'circulars_types/status/'
export const UPDATE_CIRCULAR_TYPE = EMPLOYEES_MICROSERVICE + 'circulars_types/'

export const GET_ALL_LEAVES = EMPLOYEES_MICROSERVICE + 'leaves'
export const CREATE_LEAVE = EMPLOYEES_MICROSERVICE + 'leaves'

export const CREATE_LEAVE_DESIGNATION =
    EMPLOYEES_MICROSERVICE + 'leave_designations'
export const GET_LEAVE_DESIGNATION =
    EMPLOYEES_MICROSERVICE + 'leave_designations'

// Panel Questions
export const GET_ALL_PANEL_QUESTIONS =
    EXAMINATIONS_MICROSERVICE + 'aptitude_test_panel_questions'
export const ASSIGN_PANEL_QUESTIONS =
    EXAMINATIONS_MICROSERVICE + 'aptitude_test_panel_questions'
export const EDIT_ASSIGN_PANEL_QUESTIONS =
    EXAMINATIONS_MICROSERVICE + 'aptitude_test_panel_questions/'

export const All_QUESTIONS = EXAMINATIONS_MICROSERVICE + 'aptitude_test_exams/'

export const GET_ALL_TESTS = ADMINISTRATIONS_MICROSERVICE + 'tests'
export const GET_SINGLE_APTITUDE_TEST = ADMINISTRATIONS_MICROSERVICE + 'tests/'

// Interviews
export const GET_APTITUDE_EXAM_STUDENTS =
    EXAMINATIONS_MICROSERVICE + 'aptitude_test_exams_student'

export const ASSIGN_PANEL_INTERVIEW_TO_APPLICANT =
    EXAMINATIONS_MICROSERVICE + 'aptitude_test_exams_student/'

// Designation

// Adding Termination Details
export const ADD_TERMINATION_DETAILS = EMPLOYEES_MICROSERVICE + 'designations/'

//Circulars
export const GET_ALL_CIRCULARS = EMPLOYEES_MICROSERVICE + 'circulars'
export const CREATE_CIRCULAR = EMPLOYEES_MICROSERVICE + 'circulars'
export const DELETE_CIRCULAR = EMPLOYEES_MICROSERVICE + 'circulars/'

export const GET_CIRCULAR_TYPES = EMPLOYEES_MICROSERVICE + 'circulars_types'

// Attendance

export const SET_ATTENDANCE_DETAILS =
    EMPLOYEES_MICROSERVICE + 'attendance_designations'
// Benefits

export const ADD_BENEFITS = EMPLOYEES_MICROSERVICE + 'benefit_designations'

//recruitments
export const RECRUITMENT_SETUP = EMPLOYEES_MICROSERVICE + 'recruitments'
export const RECRUITMENT_CHECKLIST_SETUP =
    EMPLOYEES_MICROSERVICE + 'recruitment_check_lists'
export const GET_RECRUITMENT = EMPLOYEES_MICROSERVICE + 'recruitments'
export const ADD_RECRUITMENT_PANEL =
    EMPLOYEES_MICROSERVICE + 'recruitment_panels'
export const ADD_CRITERIA = EMPLOYEES_MICROSERVICE + 'recruitment_criteria'

//Promotions
export const PROMOTION_SETUP = EMPLOYEES_MICROSERVICE + 'promotions'
export const GET_PROMOTION = EMPLOYEES_MICROSERVICE + 'promotions'
export const PROMOTION_SUB_SETUP = EMPLOYEES_MICROSERVICE + 'promotions_sub'
export const GET_PROMOTION_SUB = EMPLOYEES_MICROSERVICE + 'promotions_sub'
export const ADD_PROMOTION_PANEL = EMPLOYEES_MICROSERVICE + 'promotion_panels'
export const ADD_PROMOTION_CRITERIA =
    EMPLOYEES_MICROSERVICE + 'promotion_criteria'
export const GET_ALL_DESIGNATIONS = EMPLOYEES_MICROSERVICE + 'designations'
export const GET_ALL_QUALIFICATONS = EMPLOYEES_MICROSERVICE + 'qualifications'

// Medical Services
export const GET_PATIENTS = MEDICAL_MICROSERVICE + 'patients/'
export const GET_SINGLE_PATIENT_BY_CODE = MEDICAL_MICROSERVICE + 'patients'
export const GET_ALL_CHECKUP_DETAILS = MEDICAL_MICROSERVICE + 'medical_checkup'
export const CREATE_CHECKUP = MEDICAL_MICROSERVICE + 'medical_checkup'
export const CREATE_INITIAL_MEDICAL_CERTIFICATION =
    MEDICAL_MICROSERVICE + 'initial_medical_certification'
export const GET_ALL_MEDICAL_ITEMS = MEDICAL_MICROSERVICE + 'medical_items'
export const GET_ALL_MEDICALS = MEDICAL_MICROSERVICE + 'medical_issuing'
export const GET_ALL_FAMILY_MEMBER_MEDICAL_DETAILS =
    MEDICAL_MICROSERVICE + 'family_members'
export const GET_ALL_ISSUED_DRUG_DETAILS =
    MEDICAL_MICROSERVICE + 'medical_checkup_items'
export const GET_ISSUED_DRUG_DETAILS_FOR_OTHERS =
    MEDICAL_MICROSERVICE + 'medical_item_outside_issuing'
export const CREATE_ISSUE_DRUGs_FOR_OTHERS =
    MEDICAL_MICROSERVICE + 'medical_item_outside_issuing'

//Medicals
export const GET_ALL_MEDICAL_ISSUING_DATA_BY_PATIENT =
    MEDICAL_MICROSERVICE + 'medical_issuing'
export const GET_SINGLE_MEDICAL_ISSUING_DATA =
    MEDICAL_MICROSERVICE + 'medical_issuing/'
export const CREATE_MEDICAL_ISSUING = MEDICAL_MICROSERVICE + 'medical_issuing'
export const GET_MEDICAL_CHECKUP = MEDICAL_MICROSERVICE + 'medical_checkup'
export const GET_MEDICAL_CHECKUP_BY_ID =
    MEDICAL_MICROSERVICE + 'medical_checkup/'
export const GET_MEDICAL_ALL_ITEMS = MEDICAL_MICROSERVICE + 'medical_items'
export const CREATE_FAMILY_CHECKUP = MEDICAL_MICROSERVICE + 'family_members'
export const UPDATE_MEDICAL_CHECKUP = MEDICAL_MICROSERVICE + 'medical_checkup/'
export const GET_SINGLE_PATIENT_DATA = MEDICAL_MICROSERVICE + 'patients/'
export const SEND_PATIENT_EMAIL = MEDICAL_MICROSERVICE + 'patients/email'

// Human Resources -->
export const GET_ALL_CADRES = EMPLOYEES_MICROSERVICE + 'carders'
export const RECRUIT_VACANCIES = EMPLOYEES_MICROSERVICE + 'recruitment_requests'
export const GET_ALL_SPECIAL_NOTICES =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_notes'
export const CREATE_SPECIAL_NOTICES =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_notes'
export const GET_ALL_RECRUITMENT_REQUESTS =
    EMPLOYEES_MICROSERVICE + 'recruitment_requests'
export const APPLY_FOR_VACANCIES =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_applications'

export const REQUEST_EXAMINATION =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_exam_requests'
export const CREATE_PRACTICAL_EXAMINATION_PANEL =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_exam_panels'
export const GET_ALL_PRACTICAL_EXAMINATION_PANELS =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_exam_panels'
export const EDIT_PRACTICAL_EXAMINATION_PANELS =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_exam_panels'

export const SET_BASIC_DETAILS_OF_PRACTICAL_EXAM =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_exam_basic_info'
export const SEND_APPOINTMENT_LETTER =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_exam_panels/send_letter/'

export const GET_ALL_EXAM_ROOMS = ADMINISTRATIONS_MICROSERVICE + 'exam_rooms'
export const UPDATE_ROOM_STATUS = GET_ALL_EXAM_ROOMS + '/status/'

export const GET_ALL_APPLICANT_DETAILS =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_exam_applicants'
export const UPDATE_APPLICANT_DETAILS =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_exam_applicants/'
export const SEND_MAILS_TO_APPLICANTS =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_exam_applicants/send_email'

// Departments
export const GET_DEPARTMENTS = ADMINISTRATIONS_MICROSERVICE + 'departments'

//Payments
export const ALL_STUDENT_PAYMENTS = FINANCE_MICROSERVICE + 'student_payments'
export const PAYMENT_STATUS_CHANGE_BULK =
    FINANCE_MICROSERVICE + 'student_payments/bulk_status'
export const PAYMENT_STATUS_CHANGE_SINGLE =
    FINANCE_MICROSERVICE + 'student_payments/status/'

//Committees
export const GET_COMMITTEES = ADMINISTRATIONS_MICROSERVICE + 'committee_boards'
export const UPDATE_COMMITTEE_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'committee_boards/status/'
export const CREATE_COMMITTEES =
    ADMINISTRATIONS_MICROSERVICE + 'committee_boards'
export const CREATE_COMMITTEES_COMPOSITION =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_compositions'
export const DELETE_COMMITTEES =
    ADMINISTRATIONS_MICROSERVICE + 'committee_boards/'
export const GET_COMMITTEES_COMPOSITION =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_compositions'
export const UPDATE_COMMITTEE_MEMBERS =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_members'
export const GET_ALL_COMMITTEE_BOARD_CATEGORIES =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_categories'
export const CREATE_COMMITTEE_BOARD_CATEGORIES =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_categories'
export const UPDATE_COMMITTEE_BOARD_CATEGORIES =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_categories/'
export const DELETE_COMMITTEE_BOARD_CATEGORIES =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_categories/'
export const UPDATE_COMMITTEE_BOARD_CATEGORIES_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_categories/status/'
export const GET_ALL_COMMITTEE_MEMBERS =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_members'
export const GET_PREVIOUS_COMMITTEE_MEMBERS =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_previous_members'
export const CREATE_MEETING =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_meetings'
export const GET_ALL_MEETING =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_meetings'
export const UPDATE_MEETING =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_meetings/'
export const SEND_COMMITTEE_FLYER_MEMO =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_meetings/send_flyer_memo'
export const CREATE_MEMO =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_meeting_memos'
export const GET_ALL_MEMO =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_meeting_memos'
export const UPDATE_MEMO =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_meeting_memos/'
export const UPDATE_MEMO_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_meeting_memos/status/'
export const GET_COMMITTEE_MEETING_QR =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_meetings/generate_qr/'
export const GET_COMMITTEE_MEETING_ATTENDANCE =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_meeting_attendance'
export const MARK_COMMITTEE_MEETING_ATTENDANCE =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_meeting_attendance/status'
export const UPDATE_COMMITTEE_MEETING_ATTENDANCE_INFORM =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_meeting_attendance/inform/'
export const UPDATE_ABSENT_NOTE =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_meeting_attendance/'
export const COMMITTEE_BOARD_MEMO_AMMENDMENTS =
    ADMINISTRATIONS_MICROSERVICE +
    'commitee_board_meeting_ammendments_and_matters/'

//Positions
export const GET_POSITIONS = ADMINISTRATIONS_MICROSERVICE + 'positions'

//Faculty Extra
export const CREATE_FACULTY_UPLOADS =
    ADMINISTRATIONS_MICROSERVICE + 'faculty_uploads'
export const GET_ALL_FACULTIES_DEANS =
    ADMINISTRATIONS_MICROSERVICE + 'faculty_deans'
export const CREATE_FACULTY_DEAN =
    ADMINISTRATIONS_MICROSERVICE + 'faculty_deans'

//Departments
export const GET_ALL_DEPARTMENTS = ADMINISTRATIONS_MICROSERVICE + 'departments'
export const CREATE_DEPARTMENT = ADMINISTRATIONS_MICROSERVICE + 'departments'
export const CREATE_DEPARTMENT_UPLOAD =
    ADMINISTRATIONS_MICROSERVICE + 'department_uploads'
export const UPDATE_DEPARTMENT = ADMINISTRATIONS_MICROSERVICE + 'departments/'
export const UPDATE_DEPARTMENT_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'departments/status/'
export const CREATE_DEPARTMENT_HEAD =
    ADMINISTRATIONS_MICROSERVICE + 'department_heads'
export const GET_DEPARTMENT_HEAD =
    ADMINISTRATIONS_MICROSERVICE + 'department_heads'

//Departments
export const GET_ALL_UNITS = ADMINISTRATIONS_MICROSERVICE + 'units'
export const CREATE_UNIT = ADMINISTRATIONS_MICROSERVICE + 'units'
export const CREATE_UNIT_UPLOAD = ADMINISTRATIONS_MICROSERVICE + 'unit_uploads'
export const UPDATE_UNIT = ADMINISTRATIONS_MICROSERVICE + 'units/'
export const UPDATE_UNIT_STATUS = ADMINISTRATIONS_MICROSERVICE + 'units/status/'
export const CREATE_UNIT_HEAD = ADMINISTRATIONS_MICROSERVICE + 'unit_heads'
export const GET_UNIT_HEAD = ADMINISTRATIONS_MICROSERVICE + 'unit_heads'

//Assesments
export const ASSESMENTS = ADMINISTRATIONS_MICROSERVICE + 'assessments'

//Learning Outcomes
export const LEARNING_OUTCOMES =
    ADMINISTRATIONS_MICROSERVICE + 'learning_outcomes'

//Resource Allocation
export const RESOURCES = ADMINISTRATIONS_MICROSERVICE + 'resources'

//Academic Calendar
export const ACADEMIC_CALENDAR =
    ADMINISTRATIONS_MICROSERVICE + 'academic_calendar_main'
export const ACADEMIC_CALENDAR_2 =
    ADMINISTRATIONS_MICROSERVICE + 'academic_calendar'

//Subject Assesments
export const SUBJECT_ASSESMENTS =
    ADMINISTRATIONS_MICROSERVICE + 'subject_assessments'

//Subject Readings
export const SUBJECT_READINGS =
    ADMINISTRATIONS_MICROSERVICE + 'subject_readings'

//Subject Equipments
export const SUBJECT_EQUIPMENTS =
    ADMINISTRATIONS_MICROSERVICE + 'subject_equipments'
export const CREATE_PAPER =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_exam_papers'

export const GET_QUESTIONS_OF_A_PAPER =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_exam_paper_questions'
export const ADD_MARKING_SCHEME =
    EMPLOYEES_MICROSERVICE +
    'recruitment_request_exam_paper_questions/add_marking_scheme'

export const GET_ALL_EXAMINATION_REQUESTS =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_exam_requests'
export const GET_SINGLE_EXAMINATION_REQUEST =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_exam_requests/'
export const UPDATE_CUTOFFMARKS =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_exam_requests/'

export const GET_ALL_RECRUITMNET_REQUESTS =
    EMPLOYEES_MICROSERVICE + 'recruitment_requests'

export const GET_ALL_DIVISIONS = ADMINISTRATIONS_MICROSERVICE + 'divisions'

// Create Cadre
export const CREATE_CADRE = EMPLOYEES_MICROSERVICE + 'carders'

// Recruitment Criteria
export const GET_ALL_CRITIERIAS_FOR_A_RECRUITMENT =
    EMPLOYEES_MICROSERVICE + 'recruitment_criteria/'

export const ADD_MARKS =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_exam_applicant_marks'
export const GET_MARKS =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_exam_applicant_marks/'
export const CONFIRM_MARKS =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_exam_panels/'

export const GET_BASIC_DETAILS_OF_PRACTICAL_EXAM =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_exam_requests/'
export const GET_ALL_RECRUITMENT_APPLICATIONS =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_applications'

export const SELECT_APPLICANTS =
    EMPLOYEES_MICROSERVICE + 'recruitment_request_vacancies/submit'

//Instrument Category
export const CREATE_INSTRUMENT_CATEGORY =
    ADMINISTRATIONS_MICROSERVICE + 'instrument_categories'
export const GET_ALL_INSTRUMENT_CATEGORY =
    ADMINISTRATIONS_MICROSERVICE + 'instrument_categories'
export const EDIT_INSTRUMENT =
    ADMINISTRATIONS_MICROSERVICE + 'instrument_categories/'
export const INSTRUMENT_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'instrument_categories/'
export const GET_SINGLE_CARDRE = EMPLOYEES_MICROSERVICE + 'carders/'
export const CREATE_EXTERNAL_EXPERTS =
    EMPLOYEES_MICROSERVICE + 'external_experts'
export const GET_ALL_EXTERNAL_EXPERTS =
    EMPLOYEES_MICROSERVICE + 'external_experts'

export const GET_SUBMITTED_APITITUDE_PAPAER =
    EXAMINATIONS_MICROSERVICE + 'aptitude_test_student_papers'
export const SUBMITE_APITITUDE_PAPER =
    EXAMINATIONS_MICROSERVICE + 'aptitude_test_student_papers'

// http://ec2-3-132-15-192.us-east-2.compute.amazonaws.com:32582/exam/api/aptitude_test_exams_uploads
export const TEST_QUESTION_UPLOADS =
    EXAMINATIONS_MICROSERVICE + 'aptitude_test_exams_uploads'

export const CHANGE_TEST_FACULTY_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'test_faculty/status/'

//Adding Instrument
export const CREATE_INSTRUMENT = ADMINISTRATIONS_MICROSERVICE + 'instruments'
export const GET_ALL_INSTRUMENT = ADMINISTRATIONS_MICROSERVICE + 'instruments'
export const UPLOADE_INSTRUMENT_IMG =
    ADMINISTRATIONS_MICROSERVICE + 'instrument_uploads'

//Viewing Instrument
export const VIEW_INS_STUDENT = ADMINISTRATIONS_MICROSERVICE + 'instruments/'
export const GET_SINGLE_INSTRUMENT =
    ADMINISTRATIONS_MICROSERVICE + 'instruments/'

export const INSTRUMENT_COUNT =
    ADMINISTRATIONS_MICROSERVICE + 'instrument_items'
export const GET_ALL_INSTRUMENT_CODES =
    ADMINISTRATIONS_MICROSERVICE + 'instrument_items'

export const GET_SINGLE_INSTRUMENT_ITEMS =
    ADMINISTRATIONS_MICROSERVICE + 'instrument_items'

export const CREATE_INSTRUMENT_ISSUE =
    ADMINISTRATIONS_MICROSERVICE + 'instrument_issue'
export const GET_All_INSTRUMENT_ITEMS =
    ADMINISTRATIONS_MICROSERVICE + 'instrument_issue'
export const CHANGE_EXAMINER_LIST_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'test_faculty/examiner_list_status/'
export const GET_EXAMINER_LIST_STATUS =
    ADMINISTRATIONS_MICROSERVICE + 'test_faculty/'
export const GET_ALL_APTITUDE_TEST_APPLICATIONS =
    ADMINISTRATIONS_MICROSERVICE + 'test_faculty'

export const UPDATE_EXAMINER_LIST =
    EXAMINATIONS_MICROSERVICE + 'test_examiners/'

export const VERIFY_APTITUDE_STUDENT_APPLICATION =
    ADMINISTRATIONS_MICROSERVICE + 'aptitude_student_faculty_tests/status/'

export const GET_SINGLE_EMPLOYEE_DETAILS = EMPLOYEES_MICROSERVICE + 'employees/'
export const UPDATE_SINGLE_STUDENT_APTITUDE_ADMISSIONS_VERIFY =
    ADMINISTRATIONS_MICROSERVICE + 'aptitude_student_faculty_tests/status/'
export const GET_APTITUDE_STUDENT_QUESTION_MARKS =
    EXAMINATIONS_MICROSERVICE + 'aptitude_test_panel_examiners_answer_marks'

// forget Password
export const ResetPassword =
    USERMANAGEMENT_MICROSERVICE + 'users/send_reset_password'

//welfare routes
export const CREATESEASONNOTICE =
    ADMINISTRATIONS_MICROSERVICE + 'season_notices'
export const GETSEASONNOTICE = ADMINISTRATIONS_MICROSERVICE + 'season_notices'
export const UPDATESEASONNOTICE =
    ADMINISTRATIONS_MICROSERVICE + 'season_notices/status/'
export const DELETESEASONNOTICE =
    ADMINISTRATIONS_MICROSERVICE + 'season_notices/'
export const EDITSEASONNOTICE = ADMINISTRATIONS_MICROSERVICE + 'season_notices/'
export const STUDENTSEASONREGISTRATION =
    ADMINISTRATIONS_MICROSERVICE + 'season_student_registrations'
export const GETSTUDENTSEASONREQUESTS =
    ADMINISTRATIONS_MICROSERVICE + 'season_student_registrations'
export const CHANGESEASONSTATUS =
    ADMINISTRATIONS_MICROSERVICE + 'season_student_registrations/status/'
export const GETSEASONCOUNT =
    ADMINISTRATIONS_MICROSERVICE + 'season_student_registrations/get/count'
export const GETSINGLESTUDENTSEASONREQUEST =
    ADMINISTRATIONS_MICROSERVICE + 'season_student_registrations/'
//welfare routes -> bursary
export const BURSARY_APPLICATION = STUDENT_MICROSERVICE + 'bursary_application/'
export const GET_ALL_BANK_DETAILS = STUDENT_MICROSERVICE + 'bursary_bank_setup/'
export const BURSARY_BANK_BRANCH_SETUP =
    STUDENT_MICROSERVICE + 'bursary_branch_setup/'
export const BURSARY_DOCUMENT_SETUP =
    STUDENT_MICROSERVICE + 'bursary_document_setup/'
export const BURSARY_DOCUMENT_UPLOAD =
    STUDENT_MICROSERVICE + 'bursary_document/'

// Requesting Resumbitting
export const REQUESTRESUBMISSION =
    EXAMINATIONS_MICROSERVICE + 'aptitude_exam_student_answers/status/'
export const SENDZOOMLINKMAILS =
    EXAMINATIONS_MICROSERVICE + 'aptitude_exam_student_answers/send/emails'
// Audit services
export const CREATE_NEW_AUDIT = AUDIT_MICROSERVICES + 'audits'
export const GET_ALL_AUDITS = AUDIT_MICROSERVICES + 'audits'
export const GET_SINGLE_AUDITS = AUDIT_MICROSERVICES + 'audits/'
export const CREATE_NEW_AND_GET_ALL_CHECKLIST =
    AUDIT_MICROSERVICES + 'check_lists'
export const GET_SINGLE_CHECKLIST = AUDIT_MICROSERVICES + 'check_lists/'
export const GET_ALL_AUDIT_REQUESTS = AUDIT_MICROSERVICES + 'audit_requests'
export const GET_SINGLE_AUDIT_REQUESTS = AUDIT_MICROSERVICES + 'audit_requests/'
export const SET_AUDIT_CHECKLIST = AUDIT_MICROSERVICES + 'check_lists'
export const GET_ALL_AUDIT_CHECKLIST = AUDIT_MICROSERVICES + 'audit_check_lists'
export const EDIT_AUDIT_CHECKLIST = AUDIT_MICROSERVICES + 'audit_check_lists/'

// Generatting PassList

export const GENERATEPASSLIST =
    EXAMINATIONS_MICROSERVICE + 'aptitude_test_exams_student/set/passlist'

// Generate Report - Marks sheet

export const GENERATEREPORT =
    EXAMINATIONS_MICROSERVICE + 'aptitude_exam_student_answers/select/questions'

// New APi Routes for HR
export const ADDDESIGNATION = EMPLOYEES_MICROSERVICE + 'designations'
export const ADDSALARYCODE = EMPLOYEES_MICROSERVICE + 'salary_code'
export const GETALLSALARYCODES = EMPLOYEES_MICROSERVICE + 'salary_code'
export const EDITSALARYCODE = EMPLOYEES_MICROSERVICE + 'salary_code/'

export const ADDSERVICETYPE = EMPLOYEES_MICROSERVICE + 'service_type'
export const GETSERVICETYPE = EMPLOYEES_MICROSERVICE + 'service_type'
export const EDITSERVICETYPE = EMPLOYEES_MICROSERVICE + 'service_type/'

export const CREATE_DESIGNATIONS_CATEGORY =
    EMPLOYEES_MICROSERVICE + 'designation_category'
export const EDIT_DESIGNATIONS_CATEGORY =
    EMPLOYEES_MICROSERVICE + 'designation_category/'
export const CREATE_EMPLOYEE = EMPLOYEES_MICROSERVICE + 'employees'
// Leaves
export const REQUEST_LEAVE = EMPLOYEES_MICROSERVICE + 'leave_requests'
export const GET_REQUESTED_LEAVES = EMPLOYEES_MICROSERVICE + 'leave_requests'
export const GET_SINGLE_DESIGNATION = EMPLOYEES_MICROSERVICE + 'designations/'
// Medical Unit
export const EDIT_MEDICAL_ALL_ITEM = MEDICAL_MICROSERVICE + 'medical_items/'

export const GET_SINGLE_EMPLOYEE = EMPLOYEES_MICROSERVICE + 'employees/'
export const CREATE_EMPLOYEE_EDUCATIONAL_QUALIFICATIONS =
    EMPLOYEES_MICROSERVICE + 'employee_qualifications'
export const CREATE_EMPLOYEE_PAST_WORK_EXPERIENCE =
    EMPLOYEES_MICROSERVICE + 'employee_work_history'

export const UPDATE_CIRCULAR = EMPLOYEES_MICROSERVICE + 'circulars/'

//VAPA TV
export const CREATE_TV_PROGRAM = ADMINISTRATIONS_MICROSERVICE + 'tv_program/'
export const GET_ALL_TV_PROGRAMS = ADMINISTRATIONS_MICROSERVICE + 'tv_program/'
export const GET_ALL_TV_PROGRAMS_EXTERNAL = ADMINISTRATIONS_MICROSERVICE + 'tv_program/public'
export const CREATE_TV_GENRE = ADMINISTRATIONS_MICROSERVICE + 'tv_genre/'
export const GET_ALL_TV_GENRES = ADMINISTRATIONS_MICROSERVICE + 'tv_genre/'
export const GET_ALL_TV_GENRES_EXTERNAL = ADMINISTRATIONS_MICROSERVICE + 'tv_genre/public'

export const UPDATE_TV_PROGRAM = ADMINISTRATIONS_MICROSERVICE + 'tv_program/'
export const CHANGE_STATUS_TV_PROGRAM =
    ADMINISTRATIONS_MICROSERVICE + 'tv_program/status'
export const CREATE_DIVISION = ADMINISTRATIONS_MICROSERVICE + 'divisions'
export const UPDATE_DIVISION = ADMINISTRATIONS_MICROSERVICE + 'divisions/'

//VAPA TV Lineup
export const CREATE_LINEUP = ADMINISTRATIONS_MICROSERVICE + 'tv_lineup'
export const UPDATE_LINEUP = ADMINISTRATIONS_MICROSERVICE + 'tv_lineup/'
export const GET_ALL_LINEUP = ADMINISTRATIONS_MICROSERVICE + 'tv_lineup'
export const GET_SINGLE_LINEUP = ADMINISTRATIONS_MICROSERVICE + 'tv_lineup'
export const DELETE_LINEUP = ADMINISTRATIONS_MICROSERVICE + 'tv_lineup/'
export const GET_ALL_LINEUP_EXTERNAL = ADMINISTRATIONS_MICROSERVICE + 'tv_lineup/public'


//VAPA TV Program Rating
export const CREATE_RATING = ADMINISTRATIONS_MICROSERVICE + 'tv_program_rating'
export const CREATE_RATING_EXTERNAL= ADMINISTRATIONS_MICROSERVICE + 'tv_program_rating/public_rating'

export const UPDATE_RATING = ADMINISTRATIONS_MICROSERVICE + 'tv_program_rating/'
export const GET_ALL_RATING = ADMINISTRATIONS_MICROSERVICE + 'tv_program_rating'
export const GET_ALL_RATING_EXTERNAL = ADMINISTRATIONS_MICROSERVICE + 'tv_program_rating/public'


export const EDITDESIGNATION = EMPLOYEES_MICROSERVICE + 'designations/'

export const UPDATE_EMPLOYEE = EMPLOYEES_MICROSERVICE + 'employees/'
export const UPDATE_EMPLOYEE_EDUCATIONAL_QUALIFICATIONS =
    EMPLOYEES_MICROSERVICE + 'employee_qualifications/'
export const UPDATE_EMPLOYEE_PAST_WORK_EXPERIENCE =
    EMPLOYEES_MICROSERVICE + 'employee_work_history/'

export const EMPLOYEE_BULK_UPLOAD =
    EMPLOYEES_MICROSERVICE + 'employees/insert/bulk'

export const UPDATE_YEAR_RENEWAL = ADMINISTRATIONS_MICROSERVICE + 'years/'
export const GET_SINGLE_YEAR_RENEWALS = ADMINISTRATIONS_MICROSERVICE + 'years/'

// increment ROutes
export const GET_ALL_INCREMENT_RECOMMENDATIONS =
    EMPLOYEES_MICROSERVICE + 'increment_recommendation'
export const GET_ALL_INCREMENT_ELIGIBLELIST =
    EMPLOYEES_MICROSERVICE + 'increment_request'
export const GENERATE_ELIGIBLE_LIST =
    EMPLOYEES_MICROSERVICE + 'increment_request/create/eligibility'
export const INCREMENT_DEPENDANCIES =
    EMPLOYEES_MICROSERVICE + 'increment_dependency'
export const UPDATE_BULK_STUDENTS =
    STUDENT_MICROSERVICE + 'students/bulk/update'

//employee resignation
export const CREATE_EMPLOYEE_RESIGNATION =
    EMPLOYEES_MICROSERVICE + 'resignation_request'
export const GET_ALL_RESIGNATION_REQUESTS =
    EMPLOYEES_MICROSERVICE + 'resignation_request'
export const GET_SINGLE_RESIGNATION_REQUEST =
    EMPLOYEES_MICROSERVICE + 'resignation_request/'
export const UPDATE_SINGLE_RESIGNATION_REQUEST =
    EMPLOYEES_MICROSERVICE + 'resignation_request/'
export const CHANGE_STATUS_IN_SINGLE_RESIGNATION_REQUEST =
    EMPLOYEES_MICROSERVICE + 'resignation_request/status/'
export const DELETE_SINGLE_RESIGNATION_REQUEST =
    EMPLOYEES_MICROSERVICE + 'resignation_request/'

export const PROMOTION_HIERARCHY = EMPLOYEES_MICROSERVICE + 'hierachy_paths'
export const CREATE_PROMOTION_HIERARCHY_PATH =
    EMPLOYEES_MICROSERVICE + 'hierachy_paths/designation'
export const DELETE_HIERARCHY_DESIGNATION =
    EMPLOYEES_MICROSERVICE + 'hierachy_paths/remove_designation'
export const DELETE_HIERARCHY_UPLOAD =
    EMPLOYEES_MICROSERVICE + 'hierachy_paths_uploads'

export const INCREMENT_FORMS = EMPLOYEES_MICROSERVICE + 'increment_forms'
export const INCREMENT_FORMS_DESIGNATIONS =
    EMPLOYEES_MICROSERVICE + 'increment_form_designations'
export const UPDATE_INCREMENT_REQUEST =
    EMPLOYEES_MICROSERVICE + 'increment_request/'
export const GET_INCREMENT_REQUEST =
    EMPLOYEES_MICROSERVICE + 'increment_request'

export const CREATE_INCREMENT_RECOMMENDATIONS =
    EMPLOYEES_MICROSERVICE + 'increment_recommendation'
export const DELETE_QULAIFICATION_FILE_UPLOAD =
    EMPLOYEES_MICROSERVICE + 'employee_qualification_uploads'

export const EMPLOYEE_UPLOADS = EMPLOYEES_MICROSERVICE + 'employee_uploads'
export const CIRCULAR_UPLOADS = EMPLOYEES_MICROSERVICE + 'circulars_uploads'
export const SETUP_ROLES = USERMANAGEMENT_MICROSERVICE + 'users/update/roles'
export const GET_SINGLE_USER_ROLES = USERMANAGEMENT_MICROSERVICE + 'users'

export const LEAVE_REQUESTS_CHANGE_STATUS =
    EMPLOYEES_MICROSERVICE + 'leave_requests/status/' //Research Grant
export const RESEARCH_GRANTS = EMPLOYEES_MICROSERVICE + 'research_grant'
export const RESEARCH_GRANTS_STATUS =
    EMPLOYEES_MICROSERVICE + 'research_grant/status'

export const RESEARCH_ALLOWANCE = EMPLOYEES_MICROSERVICE + 'research_allowance'
export const RESEARCH_ALLOWANCE_STATUS =
    EMPLOYEES_MICROSERVICE + 'research_allowance/status'

export const RESEARCH_ALLOWANCE_EMPLOYEE =
    EMPLOYEES_MICROSERVICE + 'research_allowance_employee'

export const RESEARCH_GRANTS_EMPLOYEE =
    EMPLOYEES_MICROSERVICE + 'research_grant_employee'
export const RESEARCH_GRANTS_UPLOAD =
    EMPLOYEES_MICROSERVICE + 'research_grant_upload'
export const RESEARCH_ALLOWANCE_UPLOAD =
    EMPLOYEES_MICROSERVICE + 'research_allowance_uploads'
export const RESEARCH_GRANTS_PROGRESS_REPORTS =
    EMPLOYEES_MICROSERVICE + 'research_grant_progress_reports'

export const RESEARCH_GRANTS_PROGRESS_UPLOAD =
    EMPLOYEES_MICROSERVICE + 'research_grant_employee_progress_report_upload'
export const RESEARCH_GRANTS_APPROVALS =
    EMPLOYEES_MICROSERVICE + 'research_grant_approvals'
    
export const RESEARCH_ALLOWANCE_EMPLOYEE_PROGRESS_REPORT_UPLOAD =
    EMPLOYEES_MICROSERVICE + 'research_allowance_employee_progress_report_upload/'
    
export const RESEARCH_ALLOWANCE_EMPLOYEE_UPLOADS =
    EMPLOYEES_MICROSERVICE + 'research_allowance_employee_uploads/'
export const RESEARCH_GRANT_EMPLOYEE_UPLOADS =
    EMPLOYEES_MICROSERVICE + 'research_grant_employee_uploads/'
//Voucher
export const VOUCHER_PAYEE = FINANCE_MICROSERVICE + 'payees'
export const VOUCHER_REQUESTS = FINANCE_MICROSERVICE + 'voucher_requests'
export const VOTE_TITLE = FINANCE_MICROSERVICE + 'titles'
export const VOTE_SECTION = FINANCE_MICROSERVICE + 'sections'
export const VOUCHER_REQUEST_UPLOAD =
    FINANCE_MICROSERVICE + 'voucher_request_uploads'
export const VOUCHER_AMENDMENTS = FINANCE_MICROSERVICE + 'voucher_amendments'
export const VOUCHER_PAYMENT = FINANCE_MICROSERVICE + 'payment_details'

// Committee edit
export const EDIT_COMMITTEE = ADMINISTRATIONS_MICROSERVICE + 'committee_boards/'

//Composotion edit
export const EDIT_COMMITTEES_COMPOSITION =
    ADMINISTRATIONS_MICROSERVICE + 'committee_board_compositions/'

export const INCREMENT_FORMS_ANSWERS =
    EMPLOYEES_MICROSERVICE + 'increment_form_question_answers'

export const REQUEST_PROMOTION = EMPLOYEES_MICROSERVICE + 'promotion_requests'

// new Temp Emplyee routes
export const CREATE_TEMP_EMP_BASIC_INFORMATION =
    EMPLOYEES_MICROSERVICE + 'temp_employees'
export const CREATE_TEMP_EMP_REFREES =
    EMPLOYEES_MICROSERVICE + 'temp_employees_referals'
export const CREATE_TEMP_EMP_UPLOADS =
    EMPLOYEES_MICROSERVICE + 'temp_employees_uploads'

//Resource Type
export const CREATE_RESOURCE_TYPE =
    ADMINISTRATIONS_MICROSERVICE + 'resource_type'
export const GET_ALL_RESOURCE_TYPES =
    ADMINISTRATIONS_MICROSERVICE + 'resource_type'
export const CHANGE_STATUS_RESOURCE_TYPE =
    ADMINISTRATIONS_MICROSERVICE + 'resource_type/status'
export const UPDATE_RESOURCE_TYPE =
    ADMINISTRATIONS_MICROSERVICE + 'resource_type/'

//Resource Reservation
export const CREATE_RESOURCE = ADMINISTRATIONS_MICROSERVICE + 'resources'
export const GET_ALL_RESOURCES = ADMINISTRATIONS_MICROSERVICE + 'resources'
export const GET_SINGLE_RESOURCE = ADMINISTRATIONS_MICROSERVICE + 'resources/'
export const RESERVATIONS = ADMINISTRATIONS_MICROSERVICE + 'resource_booking/'
export const PUBLIC_RESOURCES = ADMINISTRATIONS_MICROSERVICE + 'resources/public'
export const PUBLIC_RESOURCE_TYPES = ADMINISTRATIONS_MICROSERVICE + 'resource_type/public'

//Resource Boooking
export const GET_ALL_BOOIKNG_REQUESTS =
    ADMINISTRATIONS_MICROSERVICE + 'resource_booking'
export const GET_SINGLE_BOOIKNG_REQUEST =
    ADMINISTRATIONS_MICROSERVICE + 'resource_booking/'
export const CHANGE_STATUS_BOOIKNG_REQUEST =
    ADMINISTRATIONS_MICROSERVICE + 'resource_booking/status'
export const GET_ALL_BOOIKNG_CANCELLATION_REQUEST =
    ADMINISTRATIONS_MICROSERVICE + 'resource_booking_cancellation'
export const CREATE_CANCELLATION_REQUEST =
    ADMINISTRATIONS_MICROSERVICE + 'resource_booking_cancellation'
export const UPDATE_CANCELLATION_REQUEST =
    ADMINISTRATIONS_MICROSERVICE + 'resource_booking_cancellation/'
export const CREATE_POST_RESERVATION =
    ADMINISTRATIONS_MICROSERVICE + 'resource_booking_post_reservation'
export const CREATE_BOOKING = ADMINISTRATIONS_MICROSERVICE + 'resource_booking'
export const GET_SINGLE_RESOURCE_ITEM =
    ADMINISTRATIONS_MICROSERVICE + 'resources_items/'

export const FGS_COURSE_TYPE = ADMINISTRATIONS_MICROSERVICE + 'course_type/'
export const FGS_STUDENT_TYPE = ADMINISTRATIONS_MICROSERVICE + 'student_type/'
export const FGS_PAYMENT_PLAN = ADMINISTRATIONS_MICROSERVICE + 'payment_plan'

//Employee Retirements
export const CREATE_RETIREMENT = EMPLOYEES_MICROSERVICE + 'retirements'
// General Admin
export const ADD_BASIC_VEHICLE_DETAILS =
    ADMINISTRATIONS_MICROSERVICE + 'basic_vehicle_details'
export const BASIC_VEHICLE_DETAILS =
    ADMINISTRATIONS_MICROSERVICE + 'basic_vehicle_details'
export const ADD_VEHICLE_OWNERSHIP_DETAILS =
    ADMINISTRATIONS_MICROSERVICE + 'vehicle_ownership_details'
export const ADD_VEHICLE_LICENSE_INSURANCE_ECO_DETAILS =
    ADMINISTRATIONS_MICROSERVICE + 'license_insurance_eco_details'
export const GET_VEHICLE_EMPLOYEE_DETAILS =
    ADMINISTRATIONS_MICROSERVICE + 'vehicle_employees/'
export const VEHICLE_RESERVATIONS =
    ADMINISTRATIONS_MICROSERVICE + 'vehicle_reservations'
export const FIND_RESERVATIONS =
    ADMINISTRATIONS_MICROSERVICE + 'vehicle_reservations/findReservation'
export const GET_VEHICLE_DETAILS =
    ADMINISTRATIONS_MICROSERVICE + 'basic_vehicle_details'
export const VEHICLE_RESERVATION_DETAILS =
    ADMINISTRATIONS_MICROSERVICE + 'vehicle_reservations_details/'
export const VEHICLE_SERVICE = ADMINISTRATIONS_MICROSERVICE + 'vehicle_services'
export const VEHICLE_ACCIDENT =
    ADMINISTRATIONS_MICROSERVICE + 'vehicle_accident'
export const VEHICLE_MAINTENANCE =
    ADMINISTRATIONS_MICROSERVICE + 'vehicle_maintenance'
export const VEHICLE_MAINTENANCE_PURPOSE =
    ADMINISTRATIONS_MICROSERVICE + 'vehicle_maintenance_purpose'
export const VEHICLE_MAINTENANCE_REPAIR_TYPE =
    ADMINISTRATIONS_MICROSERVICE + 'vehicle_repair_type'
export const VEHICLE_FUEL = ADMINISTRATIONS_MICROSERVICE + 'vehicle_fuel'

// Aptitude new Route
export const GET_STUDENT_APTITUDE_TEST =
    EXAMINATIONS_MICROSERVICE + 'aptitude_student_faculty_tests/'
export const UPDATE_STUDENT_APTITUDE_TEST =
    EXAMINATIONS_MICROSERVICE + 'aptitude_student_faculty_tests/'

// Semester Exam Grade Configuration
export const EXAM_GRADES =
    EXAMINATIONS_MICROSERVICE + 'semester_exams_grade_setup'
// Semester Exam class setup
export const SEMESTER_EXAM_CLASS_SETUP =
    ADMINISTRATIONS_MICROSERVICE + 'semester_exams_class_setup/'

// Semester Exams

export const SEMESTER_EXAMS = ADMINISTRATIONS_MICROSERVICE + 'semester_exam'

export const SEMESTER_EXAM_STUDENTS = ADMINISTRATIONS_MICROSERVICE + 'semester_exam_students'
export const SEMESTER_EXAM_RESULTS = ADMINISTRATIONS_MICROSERVICE + 'student_exam_results'
export const SEMESTER_EXAM_PUBLISH_RESULTS = ADMINISTRATIONS_MICROSERVICE + 'semester_exam_publish_results'
export const SEMESTER_EXAM_MARKS_BULK = ADMINISTRATIONS_MICROSERVICE + 'semester_exam_marks/insert/bulk'
export const SEMESTER_EXAM_SUBJECTS = ADMINISTRATIONS_MICROSERVICE + 'semester_exam_subjects'
export const SEMESTER_EXAM_SUBJECTS_STATUS = ADMINISTRATIONS_MICROSERVICE + 'semester_exam_subjects/status'
export const PAPER_SETUP = ADMINISTRATIONS_MICROSERVICE + 'paper_setup'
export const PAPER_SETUP_QUESTIONS =
    ADMINISTRATIONS_MICROSERVICE + 'paper_setup_questions'

export const SEMESTER_EXAM_MARKS = ADMINISTRATIONS_MICROSERVICE + 'semester_exam_marks'
export const EXAM_SCHEDULE = ADMINISTRATIONS_MICROSERVICE + 'exam_schedule/'
export const SEMESTER_EXAM_ADMISSIONS = ADMINISTRATIONS_MICROSERVICE + 'semester_exam_admissions/'
export const BATCH_WISE_ALL_STUDENT_RESULTS = ADMINISTRATIONS_MICROSERVICE + 'batchwise_all_student_results/'


//scholarship

export const GET_ALL_SCHOLARSHIPS = STUDENT_MICROSERVICE + 'scholarships/'
export const GET_SINGLE_SCHOLARSHIP = STUDENT_MICROSERVICE + 'scholarships/'
export const ADD_NEW_SCHOLARSHIP = STUDENT_MICROSERVICE + 'scholarships/'
export const CREATE_SCHOLARSHIP = STUDENT_MICROSERVICE + 'scholarships/'
export const UPDATE_SCHOLARSHIP = STUDENT_MICROSERVICE + 'scholarships/'
export const DELETE_SCHOLARSHIP = STUDENT_MICROSERVICE + 'scholarships/'
export const CHANGE_SCHOLARSHIP_APPLICATION_STATUS =
    STUDENT_MICROSERVICE + 'scholarships/status/'
export const SCHOLARSHIP_REPORT =
    STUDENT_MICROSERVICE + 'scholarships/reports/download'

//SCHOLARSHIP TYPE
export const GET_ALL_SCHOLARSHIP_TYPES =
    STUDENT_MICROSERVICE + 'scholarship_types/'
export const GET_SINGLE_SCHOLARSHIP_TYPE =
    STUDENT_MICROSERVICE + 'scholarship_types/'
export const ADD_NEW_SCHOLARSHIP_TYPE =
    STUDENT_MICROSERVICE + 'scholarship_types/'
export const CREATE_SCHOLARSHIP_TYPE =
    STUDENT_MICROSERVICE + 'scholarship_types'
export const UPDATE_SCHOLARSHIP_TYPE =
    STUDENT_MICROSERVICE + 'scholarship_types/'
export const DELETE_SCHOLARSHIP_TYPE =
    STUDENT_MICROSERVICE + 'scholarship_types/'

//DONORS
export const GET_ALL_DONORS = STUDENT_MICROSERVICE + 'scholarship_donors/'
export const GET_SINGLE_DONOR = STUDENT_MICROSERVICE + 'scholarship_donors/'
export const ADD_NEW_DONOR = STUDENT_MICROSERVICE + 'scholarship_donors/'
export const UPDATE_DONOR = STUDENT_MICROSERVICE + 'scholarship_donors/'
export const DELETE_DONOR = STUDENT_MICROSERVICE + 'scholarship_donors/'

//Disciplinary http://52.224.240.88/studentapp/api/disciplinary_action/
export const GET_ALL_DISCIPLINARY_ACTION =
    STUDENT_MICROSERVICE + 'disciplinary_action'
export const GET_SINGLE_DISCIPLINARY_ACTION =
    STUDENT_MICROSERVICE + 'disciplinary_action'
export const ADD_NEW_DISCIPLINARY_ACTION =
    STUDENT_MICROSERVICE + 'disciplinary_action'
export const UPDATE_DISCIPLINARY_ACTION =
    STUDENT_MICROSERVICE + 'disciplinary_action'
export const DELETE_DISCIPLINARY_ACTION =
    STUDENT_MICROSERVICE + 'disciplinary_action'

//Student Union by akar

export const GET_ALL_STUDENTS_UNION = STUDENT_MICROSERVICE + 'student_unions/'
export const GET_SINGE_STUDENTS_UNION = STUDENT_MICROSERVICE + 'student_unions/'
export const GET_ALL_STUDENTS_UNION_MEETING =
    STUDENT_MICROSERVICE + 'union_meetings/'
export const CREATE_UNION_MEETING = STUDENT_MICROSERVICE + 'union_meetings'
export const CREATE_NEW_STUDENTS_UNION = STUDENT_MICROSERVICE + 'student_unions'
export const CHANGE_UNION_MEMBER =
    STUDENT_MICROSERVICE + 'union_position_histories/'
export const GET_ALL_UNION_POSITION_DETIALS =
    STUDENT_MICROSERVICE + 'union_positions/'
export const CHANGE_UNION_COMPOSITION =
    STUDENT_MICROSERVICE + 'union_positions/'
export const ADD_UNION_MEMBER =
    STUDENT_MICROSERVICE + 'union_position_histories'
export const GET_ALL_UNION_MEMBER =
    STUDENT_MICROSERVICE + 'union_position_histories/'
export const GET_SINGLE_UNION_MEMBER =
    STUDENT_MICROSERVICE + 'union_position_histories/'
export const CHANGE_UNION_STATUS =
    STUDENT_MICROSERVICE + 'student_unions/status/'
export const GET_ALL_UNION_MEETING_INFO =
    STUDENT_MICROSERVICE + 'union_meetings/'

// http://52.224.240.88/studentapp/api/awarded_scholarships/
export const GET_AWARDED_SCHOLARSHIPS =
    STUDENT_MICROSERVICE + 'awarded_scholarships'

export const CREATE_NEW_SCHOLARSHIP_SELECTION =
    STUDENT_MICROSERVICE + 'awarded_scholarships'

// Unions new Route by reezma
export const GET_ALL_STUDENTS_UNION_MEETING_MEMBERS = STUDENT_MICROSERVICE + 'union_meetings/'
export const GET_ALL_UNIONS = STUDENT_MICROSERVICE + 'student_unions/'
export const GET_UNION_MEETING = STUDENT_MICROSERVICE + 'union_meetings/'
export const CHANGE_UNION_MEETING_STATUS = STUDENT_MICROSERVICE + 'union_meetings/status/'


//work norm
export const GET_ALL_ATTENDANCE_DESIGNATION_DETAILS = EMPLOYEES_MICROSERVICE + 'attendance_designations_details'

//
export const AGREEMENTS = ADMINISTRATIONS_MICROSERVICE + 'agreements'
export const AGREEMENT_TYPES = ADMINISTRATIONS_MICROSERVICE + 'agreement_types'
export const DEPARTMENT_SUBJECT_REGITRATION = ADMINISTRATIONS_MICROSERVICE + 'department_and_subject_registrations'
export const DEPARTMENT_SUBJECT_REGITRATION_ALL = ADMINISTRATIONS_MICROSERVICE + 'department_and_subject_registrations'