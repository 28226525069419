import React from 'react';
import { Button, Grid, Typography, Box } from '@material-ui/core';
import { Link } from 'react-router-dom'; // Import Link from React Router

const BookInfoCard = ({
    bookTitle, 
    bookSubtitle, 
    topButtonLabel 
}) => {
    return (
        <Box p={6} border={1} borderRadius={6} borderColor="grey.300" style={{ padding: '24px', borderRadius: '12px', borderColor: '#ccc' }}>
            <Grid container alignItems="center" spacing={3}>
            <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                    <Typography variant="h5">Own</Typography>
                </Grid>
                <Grid item xs={5} container justifyContent="flex-end">
                    <Button variant="contained" color="primary">{topButtonLabel}</Button>
                </Grid>
            </Grid>

            <Box mt={1}>
                <Grid container alignItems="center" spacing={1}>
                <Grid item xs={1}></Grid>
                    <Grid item>
                        <Typography variant="h6">Book Title:</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">{bookTitle}</Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box mt={1}>
                <Grid container alignItems="center" spacing={1}>
                <Grid item xs={1}></Grid>
                    <Grid item>
                        <Typography variant="h6">Subtitle:</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">{bookSubtitle}</Typography>
                    </Grid>
                </Grid>
            </Box>

            <Grid container alignItems="center" spacing={3} mt={3}>
            <Grid item xs={2}></Grid>
            <Grid item xs={5}>
                    {/* Anchor tag to the PDF file */}
                    <a href="\assets\CertificateHead.pdf" target="_blank" rel="noopener noreferrer">
                        <Button variant="outlined" color="primary">View Manuscript</Button>
                    </a>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                    {/* Link to the desired page */}
                    <Button variant="outlined" color="primary" component={Link} to="/vapa_press/author_view__individual">View</Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default BookInfoCard;
