import React, { Component, Fragment, useEffect } from 'react'
import {
    CardActions,
    CardContent,
    Checkbox,
    Dialog,
    DialogContentText,
    FormControlLabel,
    FormLabel,
    Grid,
    Icon,
    IconButton,
    Radio,
    RadioGroup,
    Step,
    StepConnector,
    StepLabel,
    Stepper,
    TextField,
    Tooltip,
    Typography,
    InputAdornment,
} from '@material-ui/core'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import { Autocomplete, Alert } from '@material-ui/lab'
import { makeStyles, withStyles } from '@material-ui/styles'
import ApartmentIcon from '@mui/icons-material/Apartment'
import SchoolIcon from '@mui/icons-material/School'
import {
    Button,
    CardTitle,
    LoonsCard,
    LoonsSnackbar,
    LoonsTable,
    MainContainer,
} from 'app/components/LoonsLabComponents'
import ImageView from 'app/components/LoonsLabComponents/ImageView'
import { MatxLayoutSettings } from 'app/components/MatxLayout/settings'
import { themeColors } from 'app/components/MatxTheme/themeColors'
import CommitteesService from 'app/services/CommitteesService'
import DepartmentService from 'app/services/DepartmentService'
import DivisionServices from 'app/services/DivisionServices'
import FacultyService from 'app/services/FacultyService'
import ResourceReservationServices from 'app/services/ResourceReservationServices'
import clsx from 'clsx'
import moment from 'moment'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import * as appConst from '../../../appconst'
import ViewResource from '../ResourceReservation/ViewResource'

import dayGridPlugin from '@fullcalendar/daygrid'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import CircularProgress from '@material-ui/core/CircularProgress'

import EmployeeService from 'app/services/EmployeeService'
import StudentService from 'app/services/StudentService'

import ReservationDateTimePicker from '../ResourceReservation/ReservationDateTimePicker'
import localStorageService from 'app/services/localStorageService'
import Layout1Topbar from '../../components/MatxLayout/Layout1/Layout1Topbar'

import EyeOn from '@material-ui/icons/Visibility';
import EyeOff from '@material-ui/icons/VisibilityOff';
import AuthService from 'app/services/AuthService'

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
})

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose } = props
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
})

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions)

//get active theme
var activeTheme = MatxLayoutSettings.activeTheme

const styleSheet = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    iconBtn: {
        color: '#3765c9',
        backgroundColor: 'rgba(55,101,201,.1)',
        borderColor: '#3765c9',

        '&:hover': {
            background: `#3765c9`,
            color: '#ffffff',
        },
    },

    mainContainer: {
        marginTop: 5,
        padding: 10,
    },
    formFieldContainer: {
        marginTop: 15,
        width: '100%',
    },
    formSectionCard: {
        width: '100%',
        padding: 20,
        marginTop: 10,
    },
    form_buttons: {
        float: 'right',
        marginTop: 20,
        marginLeft: 5,
        color: '#fff',
    },
})

const stepperSteps = ['Days', 'Cost', 'Information']

//stepper line styles
const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 20,
    },
    active: {
        '& $line': {
            backgroundColor: themeColors[activeTheme].palette.primary.main,
        },
    },
    completed: {
        '& $line': {
            backgroundColor: themeColors[activeTheme].palette.primary.main,
        },
        filter: 'brightness(85%)',
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#EAEAF0',
        borderRadius: 1,
    },
})(StepConnector)

//stepper icon styles
const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundColor: themeColors[activeTheme].palette.primary.main,
        boxShadow: `0 5px 10px 0 ${themeColors[activeTheme].palette.primary.main}`,
    },
    completed: {
        backgroundColor: themeColors[activeTheme].palette.primary.main,
        filter: 'brightness(85%)',
    },
})

//assign stepper icons
function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles()
    const { active, completed } = props

    const icons = {
        1: <CalendarTodayIcon />,
        2: <MonetizationOnIcon />,
        3: <CheckCircleIcon />,
    }

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    )
}

const disabledTimeSlots = [
    // Define your disabled time slots here.
    // In this example, we disable 10:00 AM to 11:00 AM.
    { start: '10:00', end: '11:00' },
]

class AllResources extends Component {
    constructor(props) {
        super(props)

        this.state = {
            viewMode: false,
            totalItems: 0,
            committees: [],
            type: [],
            resouce_id: 0,
            filterData: {
                limit: 20,
                page: 0,
                search: '',
                'order[0]': ['id', 'DESC'],
            },
            open: false,
            showForm: false,
            bookMode: false,
            stepper_active_Step: 0,
            data: [],
            single_resource_data: [],
            selectedId: null,
            isLoading: false,
            resource_calender_data: null,
            calendarMode: false,
            displayError: false,

            formData: {
                event: '',
                event_organized_by: '',
                event_details: '',
                salutation: '',
                poc_name: '',
                poc_designation: '',
                poc_contact_no: '',
                poc_email: '',
                poc_nic: '',
                association_to_uni: '',
                associated_as: '',
                reg_no: '',
                associated_contact_no: '',
                associated_nic: '',
                associated_email: '',
                team_participants: 0,
                is_sponsors: false,
                sponsor_details: '',
                vehicles: [],

                start_date: null,
                end_date: null,
                start_time: null,
                end_time: null,

                employee_id: null,
                student_id: null,
                // quentity: null,
            },

            accountData: {
                email: null,
                first_name: null,
                sur_name: null,
                nic: null,
                mobile_no: null,
            },

            passData: {},

            vehicles: [{ reg_no: '' }],
            resource_id: 0,
            resource_booking_items: [
                {
                    resource_item_id: 0,
                    required_quantity: 0,
                    cost: 0,
                },
            ],
            amenety_data: [],
            columns: [
                {
                    name: 'type',
                    label: 'Amenities',
                    options: {
                        filter: true,
                    },
                },
                {
                    name: 'quentity',
                    label: 'Available Quantity',
                    options: {
                        filter: true,
                    },
                },
                // {
                //     name: 'price_type',
                //     label: 'Price Basis',
                //     options: {
                //         filter: true,
                //     },
                // },
                {
                    name: 'ammount',
                    label: 'Price per Unit (Rs.)',
                    options: {
                        filter: true,
                    },
                },
                {
                    name: 'required_quantity',
                    label: 'Required Quantity',
                    options: {
                        filter: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const rowIndex = tableMeta.rowIndex
                            return (
                                <Grid className="flex items-center">
                                    <TextField
                                        className="w-full"
                                        label="Required Quantity"
                                        onChange={(e) => {
                                            const bookingItems = [
                                                ...this.state
                                                    .resource_booking_items,
                                            ]
                                            const amenityItems = [
                                                ...this.state.amenety_data,
                                            ]

                                            // let req_qty = e.target.value >= 0 ? parseFloat(e.target.value) : ''
                                            let req_qty =
                                                e.target.value >= 0 &&
                                                e.target.value <=
                                                    amenityItems[rowIndex]
                                                        .quentity
                                                    ? parseFloat(e.target.value)
                                                    : ''
                                            let cost =
                                                req_qty > 0
                                                    ? req_qty *
                                                      parseFloat(
                                                          amenityItems[rowIndex]
                                                              .ammount
                                                      )
                                                    : 0

                                            bookingItems[
                                                rowIndex
                                            ].required_quantity = req_qty
                                            bookingItems[rowIndex].cost = cost

                                            this.setState({
                                                resource_booking_items:
                                                    bookingItems,
                                                total_cost:
                                                    parseFloat(
                                                        this.state
                                                            .temp_total_cost
                                                    ) +
                                                    bookingItems.reduce(
                                                        (accumulator, item) => {
                                                            return (
                                                                accumulator +
                                                                item.cost
                                                            )
                                                        },
                                                        0
                                                    ),
                                            })

                                            console.log(
                                                this.state
                                                    .resource_booking_items
                                            )
                                            console.log(bookingItems)
                                            console.log(
                                                '------after total cost: ' +
                                                    this.state.total_cost
                                            )
                                        }}
                                        value={
                                            this.state?.resource_booking_items[
                                                rowIndex
                                            ]?.required_quantity
                                        }
                                        name="required_quantity"
                                        type="number"
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        required
                                        InputLabelProps={{ shrink: true }}
                                        // validators={['required']}
                                        // errorMessages={['This field is required',]}
                                    />
                                </Grid>
                            )
                        },
                    },
                },
                {
                    name: 'total_amount',
                    label: 'Total Amount(Rs.)',
                    options: {
                        filter: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const rowIndex = tableMeta.rowIndex
                            const bookingItems = [
                                ...this.state.resource_booking_items,
                            ]
                            return bookingItems[rowIndex].cost > 0
                                ? bookingItems[rowIndex].cost + '.00'
                                : '00.00'
                        },
                    },
                },
            ],

            booking_data: null,
            //alert
            alert: false,
            severity: 'success',
            message: '',

            // ---View Form
            viewformData: {
                id: null,
                resource_ref_id: '',

                name: '',
                type: '',

                Ownership_Department: '',
                Ownership_Faculty: '',

                location: '',
                location_type: '',

                seating_capacity: '',
                // exam_seating_capacity: '',
                theory_lec_capacity: '',
                practical_lec_capacity: '',
                theory_exam_capacity: '',
                practical_exam_capacity: '',
                size: '',
                resourceApprovalManger: '',

                usage: '',
                internal_use: '',
                currently_associated: '',
                past_associated: '',
                past_reservation_required: '',
                reservation_required: '',
                external_use: '',

                cost_applicable: '',

                base_cost_basis: '',
                base_hours: '',
                base_cost: '',

                extra_cost_basis: '',
                extra_cost_number: '',
                extra_cost: '',

                refundable_deposit: '',

                contact_person: '',
                contact_no: '',

                items: [],
            },

            // Registration form
            registrationMode: false,
            confirmation_checkbox: false,

            // Calendar
            resource_bookings: [],
            days_selected: 'Please  select a date',

            // Ownership
            faculties: [], // filter dropdown
            x_faculties: [], // before combining {fac_id, name, dep_id}
            x_departments: [], // before combining {dep_id, name, fac_id}
            x_divisions: [], // before combining {dep_id, name, fac_id, division_id}
            faculties_departments_divisions: [], // create resource view dropdown

            // mui/lab date picker
            selectedDate: null,
            bookingsList: [],
            selectedFromValue: null,
            selectedToValue: null,

            // costs - in Stepper 2
            // required_qty_cost: 0, // cost for each amenity
            total_cost: 0, // final cost
            temp_total_cost: 0, // final cost

            // employees
            employees: [],
            temp_employees: [],
            all_employees: [],
            students: [],
            isEmpNoDisabled: false,
            isStudentNoDisabled: false,

            // temp account data
            tempAccountFormData: null,

            // btn disable
            isStepper1BtnDisabled: true,
        }
    }

    handleDateChange = (date) => {
        this.setState({ selectedDate: date })
    }

    // Open Dialog booking resource Dialog
    OpenDialogBox(resource_id) {
        console.log('item.id', resource_id)

        this.singleResource(resource_id)

        this.setState({
            bookMode: true,
            resource_id: resource_id,
        })
    }

    // Open View resource Dialog
    OpenViewResourceDialogBox(resource_id) {
        console.log('item.id', resource_id)

        // this.singleResource(resource_id)
        this.setViewFormData(resource_id)

        this.setState({
            viewMode: true,
        })
    }
    async OpenResourceCalendarBox(resource_id) {
        console.log('item.id', resource_id)
        this.setState({ isLoading: true, calendarMode: true })

        try {
            const { data } =
                await ResourceReservationServices.getReservationsByResourceId({
                    resource_id,
                })
            console.log(data)

            const filteredData = data.view.data.filter(
                (reservation) => reservation.payment_status !== 'Rejected'
            )

            this.setState({
                isLoading: false,
                resource_calender_data: filteredData,
            })
        } catch (error) {
            console.error('Error fetching reservations:', error)
            this.setState({ isLoading: false })
        }
    }

    // Open pop for registration after confirm booking
    openRegistrationDialogBox() {}

    // old function to set data in view form
    async singleResource(resource_id) {
        let res = await ResourceReservationServices.getSingleResource(
            resource_id
        )
        if (res.status === 200) {
            console.log('single-resource data: ', res.data)
            let total_cost = 0
            if (res.data?.view) {
                let data = res.data?.view
                // total_cost = data.base_cost + data.extra_cost + data.refundable_deposit - data.deposit
                //         ? data.base_cost + data.extra_cost + data.refundable_deposit - data.deposit
                //         : 'XXXX'
                if (data?.base_cost && data?.refundable_deposit) {
                    total_cost = data.base_cost + data.refundable_deposit
                } else {
                    total_cost = 'XXXXX'
                }

                console.log('total cost: ' + total_cost)
            }

            const resource_booking_items = res.data.view?.Items.map((item) => ({
                resource_item_id: item.id,
                required_quantity: 0,
                cost: 0,
            }))

            console.log('resource_booking_items : ')
            console.log(resource_booking_items)

            this.setState(
                {
                    single_resource_data: res.data.view,
                    amenety_data: res.data.view?.Items,
                    total_cost: total_cost,
                    temp_total_cost: total_cost,
                    resource_booking_items,
                    // resource_bookings: res.data?.view?.ResourceBookings
                },
                () => {
                    console.log(this.state.single_resource_data, '<- data here')
                    console.log(this.state.amenety_data, '<- amenety_data')
                    // console.log(this.state.resource_bookings, '<- resource_bookings')
                }
            )

            this.loadReservationsList(res.data?.view?.ResourceBookings)
        }
    }

    // Load the bookings done for a specific resource
    loadReservationsList(resourceBookings) {
        console.log('resourceBookings: ', resourceBookings)

        const bookingsList = resourceBookings.map((item) => ({
            id: item.id,
            resource_id: item.resource_id,
            start_date: item.start_date,
            end_date: item.end_date,
            start_time: item.start_time,
            end_time: item.end_time,
        }))

        console.log(bookingsList)
        this.setState({ bookingsList: bookingsList })
    }

    // format Date selected in Step 0
    formatDate(dateTime, key) {
        console.log('setting date')
        console.log('dateTime: ', dateTime)

        const dateTimeObject = new Date(dateTime)

        const year = dateTimeObject.getFullYear()
        const month = String(dateTimeObject.getMonth() + 1).padStart(2, '0') // Months are 0-indexed, so we add 1 and pad with '0'
        const day = String(dateTimeObject.getDate()).padStart(2, '0')

        const formattedDate = `${year}-${month}-${day}`
        console.log(`Formatted Date: ${formattedDate}`)

        let data = this.state.formData
        data[key] = formattedDate
        this.setState({ formData: data })
    }

    // format Time selected in Step 0
    formatTime(dateTime, key) {
        console.log('setting time')
        console.log('dateTime: ', dateTime)

        // const dateTimeObject = new Date(dateTime);

        // const hours = String(dateTimeObject.getHours()).padStart(2, '0');
        // const minutes = String(dateTimeObject.getMinutes()).padStart(2, '0');
        // const seconds = String(dateTimeObject.getSeconds()).padStart(2, '0');

        // const formattedTime = `${hours}:${minutes}:${seconds}`;
        // console.log(`Formatted Time: ${formattedTime}`);

        let data = this.state.formData
        data[key] = dateTime
        this.setState({ formData: data })
    }

    // enable "Save & Next" btn if both FROM & TO date/time are choosen
    enableStepper1Btn() {
        let { start_date, start_time, end_date, end_time } = this.state.formData;

        // Ensure times are formatted correctly using Moment.js
        start_time = moment(start_time, 'HH:mm:ss').format('HH:mm:ss');
        end_time = moment(end_time, 'HH:mm:ss').format('HH:mm:ss');

        console.log('my data', this.state.bookingsList);
        console.log('start_date', start_date);
        console.log('start_time', start_time);
        console.log('end_date', end_date);
        console.log('end_time', end_time);

        // Function to check if the time slot is valid
        const isValidTimeSlot = (startDate, startTime, endDate, endTime, bookings) => {
            const start = moment(`${startDate}T${startTime}`);
            const end = moment(`${endDate}T${endTime}`);

            for (let booking of bookings) {
                const bookingStart = moment(`${booking.start_date}T${booking.start_time}`);
                const bookingEnd = moment(`${booking.end_date}T${booking.end_time}`);

                // Check if there is an overlap
                if (start.isBefore(bookingEnd) && end.isAfter(bookingStart)) {
                    return false;
                }
            }
            return true;
        };

        // Check if all necessary date and time fields are provided
        if (
            start_date !== null &&
            start_time !== null &&
            end_date !== null &&
            end_time !== null
        ) {

            // Validate the proposed time slot
            const validTimeSlot = isValidTimeSlot(start_date, start_time, end_date, end_time, this.state.bookingsList);

            if (!validTimeSlot) {
                this.setState({
                    isStepper1BtnDisabled: true,
                    displayError: true,
                });
                console.log('my codeblock');
                return;
            }

            // Creating start and end DateTime objects using Moment.js
            const startDateTime = moment(`${start_date}T${start_time}`);
            const endDateTime = moment(`${end_date}T${end_time}`);

            // Check if the start date-time is before the end date-time
            if (startDateTime.isBefore(endDateTime)) {
                this.setState({ isStepper1BtnDisabled: false, displayError: false });
            } else {
                this.setState({ isStepper1BtnDisabled: true, displayError: true });
            }
        } else {
            this.setState({ isStepper1BtnDisabled: true, displayError: true });
        }
    }

    // new function to set data in view form
    async setViewFormData(resource_id) {
        let res = await ResourceReservationServices.PUBLIC_getResources({
            id: resource_id
        })
        if (res.status === 200) {
            console.log('single-resource data: ', res.data)
            let data = res.data.view.data[0]
            let formData = this.state.viewformData

            formData.id = data?.id
            formData.resource_ref_id = data?.resource_ref_id

            formData.name = data?.name
            formData.type = data?.ResourceType?.resource_type

            formData.Ownership_Department = data?.Ownership_Department?.name
            formData.Ownership_Division = data?.Ownership_Division?.name
            formData.Ownership_Faculty = data?.Ownership_Faculty?.name

            formData.location = data?.location
            formData.location_type = data?.loction_type

            formData.seating_capacity = data?.seating_capacity
            formData.theory_lec_capacity = data?.theory_lec_capacity
            formData.practical_lec_capacity = data?.practical_lec_capacity
            formData.theory_exam_capacity = data?.theory_exam_capacity
            formData.practical_exam_capacity = data?.practical_exam_capacity
            // formData.exam_seating_capacity = data?.exam_seating_capacity
            formData.size = data?.size

            if (data?.ApCommittee) {
                formData.resourceApprovalManger = data?.ApCommittee?.name
            } else if (data?.ApDesignation) {
                formData.resourceApprovalManger =
                    data?.ApDesignation?.designation
            } else {
                formData.resourceApprovalManger = data?.Faculty?.name
            }

            formData.resourceRecommendedBy = data?.RecDesignation?.designation
            formData.internal_use = data?.internal_use
            formData.currently_associated = data?.currently_associated
            formData.past_associated = data?.past_associated
            formData.reservation_required = data?.reservation_required
            formData.past_reservation_required = data?.past_reservation_required
            formData.cost_applicable = data?.cost_applicable
            formData.external_use = data?.external_use

            formData.base_cost_basis = data?.base_cost_basis
            formData.base_hours = data?.base_hours
            formData.base_cost = data?.base_cost

            formData.extra_cost_basis = data?.extra_cost_basis
            formData.extra_cost_number = data?.extra_cost_number
            formData.extra_cost = data?.extra_cost

            formData.refundable_deposit = data?.refundable_deposit

            formData.contact_person = data?.contact_person
            formData.contact_no = data?.contact_no

            formData.items = data?.Items

            this.setState({
                viewDialogBox: true,
                viewformData: formData,
            })
        }
    }

    //Change Step of the stepper
    changeStepper_Step = (step) => {
        this.setState({
            stepper_active_Step: step,
        })
    }

    async formSubmit() {
        if (this.state.email_validity == "This Email is Already Taken!") {
            return
        }

        this.setState({
            accountCreateLoaing: true,
            passwordBtn: true
        })

        if (this.state.passwordSetup) {
            let user = {
                username: this.state.accountData.email,
                password: this.state.passData.password
            }
            console.log("USER", user)
            await AuthService.ResetPassword(user).then(
                res => {
                    if (res.status === 201) {
                        this.setState({
                            alert: true,
                            severity: 'success',
                            message: 'Password Creation Successfull!',
                            showForm: false,
                            showSuccessMsg: true,
                            passwordBtn: false

                        })
                    }
                }
            )
        } else {
            var data = this.state.accountData;
            var newFormData = new FormData();
            if (data.name_with_initials) {
                newFormData.append('name_with_initials', data.name_with_initials);
            }
            if (data.first_name) {
                newFormData.append('first_name', data.first_name);
            }
            // if (data.middle_name) {
            //     newFormData.append('middle_name', data.middle_name);
            // }
            if (data.sur_name) {
                newFormData.append('sur_name', data.sur_name);
            }
            if (data.full_name) {
                newFormData.append('full_name', data.full_name);
            }
            if (data.mobile_no) {
                newFormData.append('mobile_no', data.mobile_no);
            }
            if (data.nic) {
                newFormData.append('nic', data.nic);
            }

            newFormData.append('email', data.email);
            await EmployeeService.createBasicInformation(newFormData)
                .then(res => {
                    console.log("Respond", res)
                    if (res.status === 200 || res.status === 201) {
                        this.setState({
                            alert: true,
                            severity: 'success',
                            message: 'Account has been created successfully!',

                        },
                            () => {
                                setInterval(() => {
                                    this.setState({
                                        passwordSetup: true,
                                        accountCreateLoaing: false,
                                        passwordBtn: false
                                    })
                                }, 3000)
                            }
                        )
                    }
                    else {
                        this.setState({
                            alert: true,
                            severity: 'error',
                            message: res.response.data.error,
                            accountCreateLoaing: false,
                            passwordBtn: false
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        alert: true,
                        severity: 'error',
                        message: 'Error!',
                        passwordBtn: false
                    })
                })
        }
    }

    handleClose = () => {
        this.setState({
            open: false,
            showForm: false,
            showSuccessMsg: false,
            passwordBtn: false
        })

    };

    dialogClose = () => {
        this.setState({
            viewMode: false,
            bookMode: false,
            registrationMode: false,
            confirmation_checkbox: false,
            calendarMode: false,
            resource_booking_items: [],
            formData: {},
            selectedFromValue: null,
            selectedToValue: null,
            isStepper1BtnDisabled: true,
            displayError: false,
        })

        this.changeStepper_Step(0)
    }

    async setFilterData(key, val) {
        //Set state filter data with user entered
        console.log(key, 'key ->')
        console.log(val, 'value ->')

        let filterData = this.state.filterData
        filterData[key] = val
        filterData.page = 0
        this.setState({ filterData })
    }

    //set input values
    handleChange = (e) => {
        var val = e.target.value
        var key = e.target.name

        console.log(val, 'handle change val')
        console.log(key, 'handle change key')

        var data = this.state.accountData
        data[key] = val

        this.setState({
            accounetData: data,
        })
    }

    async setDropdownFormData(key, val) {
        let formData = this.state.formData

        formData.salutation = val

        console.log(val, 'val here')

        console.log(formData, 'formData')
        this.setState({
            formData: formData,
        })
    }

    //Function to add new vehicle number field to the form
    async addNewVehicle() {
        var vehicles = this.state.vehicles
        console.log('Vehicle Length: ', this.state.vehicles.length)
        console.log('Vehicles', vehicles)
        let currentLastItem = vehicles[vehicles.length - 1]?.reg_no

        console.log('currentLastItem', currentLastItem)
        if (
            (currentLastItem === '' || currentLastItem === null) &&
            vehicles.length !== 0
        ) {
            console.log(
                'New Vehicle Could not Added!',
                this.state.vehicles.length
            )

            this.setState({
                alert: true,
                severity: 'error',
                message: 'Please fill the current field first.',
            })
        } else {
            console.log('New Vehicle Added!')
            var new_vehicle = { reg_no: '' }

            vehicles.push(new_vehicle)

            this.setState({
                vehicles: vehicles,
            })
        }
    }

    //Function to remove vehicle number from the array
    removeItem(index) {
        console.log('index', index)
        let newArr = this.state.vehicles
        if (index > 0) {
            newArr.splice(index, 1)

            this.setState({
                vehicles: newArr,
            })
            console.log('filterrrd Array', newArr)
        }
    }

    async checkEmail() {

        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/

        if (!regex.test(this.state.accountData.email)) {
            this.setState({
                email_validity: null
            })

            return
        }

        this.setState({
            email_validity: "Checking Validity..."
        })

        await StudentService.getEmailExist(this.state.accountData.email)
            .then(res => {
                console.log("Users", res);

                if (res.status == 200) {
                    this.setState(
                        {
                            email_validity: res.data.view,
                        },
                        () => {
                            console.log('data', this.state)
                            this.render()
                        }
                    )
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    snackbar: true,
                    snackbar_severity: 'error',
                    snackbar_message: 'Error!',
                })
            })
    }

    handleChangePassword = (e) => {
        var val = e.target.value
        var key = e.target.name

        console.log(val, key)

        var data = this.state.passData || {}
        data[key] = val

        this.setState({
            passData: data,
        })
    }

    //Function to create new booking
    async SubmitResourceBookingRequest() {
        var data = this.state.formData

        var user = localStorageService.getItem('userInfo')
        var userId = user.id
        console.log('idddd', userId)
        data.poc_name = data.salutation + data.poc_name
        data.vehicles = this.state.vehicles
        data.resource_id = this.state.resource_id
        data.resource_booking_items = this.state.resource_booking_items
        data.start_time = this.extractTime(data.start_time)
        data.end_time = this.extractTime(data.end_time)
        data.booking_status = 'Tentative Booking'
        data.payment_status = 'Invoice Requested'
        data.employee_id = userId

        console.log('booking data: ')
        console.log(data)

        let params = {
            email: data.employee?.email
                ? data.employee.email
                : data.associated_email,
            nic: data.employee?.nic ? data.employee.nic : data.associated_nic,
        }

        // if currently associated & selected an employee
        if (
            data.association_to_uni === 'current' &&
            (data.associated_as != null || data.associated_as != '') &&
            data.associated_as.label != 'Student'
        ) {
            console.log('params: ', params)

            await EmployeeService.getAllEmployees(params)
                .then(async (res1) => {
                    console.log(res1)
                    if (res1.status === 200) {
                        if (res1.data.view.data.length === 1) {
                            // employee exists in Employee table
                            console.log('employee exists in Employee table')

                            // place booking
                            this.placeBooking(data)
                        } else {
                            // no such employee, search in TempEmployee table
                            console.log(
                                'no employee, search in TempEmployee table'
                            )

                            await EmployeeService.getAllTempEmployees(params)
                                .then((res2) => {
                                    console.log(res2)
                                    if (res2.status === 200) {
                                        if (res2.data.view.data.length === 1) {
                                            // employee exists in TempEmployee table
                                            console.log(
                                                'employee exists in TempEmployee table'
                                            )

                                            // place booking
                                            this.placeBooking(data)
                                        } else {
                                            // no employee exists
                                            console.log('no such employee')

                                            // open pop with checkbox for create temp account
                                            this.setState({
                                                registrationMode: true,
                                            })

                                            // create temp account
                                            let tempAccFormData = new FormData()

                                            if (data.employee) {
                                                tempAccFormData.append(
                                                    'employee_id',
                                                    data.employee.employee_id
                                                )
                                            }
                                            if (data.associated_nic) {
                                                tempAccFormData.append(
                                                    'nic',
                                                    data.associated_nic
                                                )
                                            }
                                            if (data.associated_email) {
                                                tempAccFormData.append(
                                                    'email',
                                                    data.associated_email
                                                )
                                            }
                                            if (data.associated_contact_no) {
                                                tempAccFormData.append(
                                                    'contact_no',
                                                    data.associated_contact_no
                                                )
                                            }
                                            if (data.salutation) {
                                                tempAccFormData.append(
                                                    'salutation',
                                                    data.salutation
                                                )
                                            }
                                            if (data.poc_name) {
                                                tempAccFormData.append(
                                                    'full_name',
                                                    data.poc_name
                                                )
                                            }
                                            if (data.poc_designation) {
                                                tempAccFormData.append(
                                                    'designation',
                                                    data.poc_designation
                                                )
                                            }
                                            console.log(tempAccFormData)
                                            this.setState({
                                                tempAccountFormData:
                                                    tempAccFormData,
                                            })
                                        }
                                    }
                                })
                                .catch((error) => {
                                    console.log(error)
                                    this.setState({
                                        alert: true,
                                        severity: 'error',
                                        message: 'Error!',
                                    })
                                })
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.setState({
                        alert: true,
                        severity: 'error',
                        message: 'Error!',
                    })
                })
        }
        // if currently associated & selected a Student
        else if (
            data.association_to_uni === 'current' &&
            (data.associated_as != null || data.associated_as != '') &&
            data.associated_as.label === 'Student'
        ) {
            // TODO
        }
        // if previous OR not associated with the university
        else if (
            data.association_to_uni === 'past' ||
            data.association_to_uni === 'not_associated'
        ) {
            console.log('not associated')

            await EmployeeService.getAllTempEmployees(params)
                .then((res2) => {
                    console.log(res2)
                    if (res2.status === 200) {
                        if (res2.data.view.data.length === 1) {
                            // employee exists in TempEmployee table
                            console.log('employee exists in TempEmployee table')

                            // place booking
                            this.placeBooking(data)
                        } else {
                            // no employee exists
                            console.log('no such employee')

                            // open pop with checkbox for create temp account
                            this.setState({ registrationMode: true })

                            // create temp account
                            let tempAccFormData = new FormData()

                            if (data.employee) {
                                tempAccFormData.append(
                                    'employee_id',
                                    data.employee.employee_id
                                )
                            }
                            if (data.associated_nic) {
                                tempAccFormData.append(
                                    'nic',
                                    data.associated_nic
                                )
                            }
                            if (data.associated_email) {
                                tempAccFormData.append(
                                    'email',
                                    data.associated_email
                                )
                            }
                            if (data.associated_contact_no) {
                                tempAccFormData.append(
                                    'contact_no',
                                    data.associated_contact_no
                                )
                            }
                            if (data.salutation) {
                                tempAccFormData.append(
                                    'salutation',
                                    data.salutation
                                )
                            }
                            if (data.poc_name) {
                                tempAccFormData.append(
                                    'full_name',
                                    data.poc_name
                                )
                            }
                            if (data.poc_designation) {
                                tempAccFormData.append(
                                    'designation',
                                    data.poc_designation
                                )
                            }
                            console.log(tempAccFormData)
                            this.setState({
                                tempAccountFormData: tempAccFormData,
                            })
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.setState({
                        alert: true,
                        severity: 'error',
                        message: 'Error!',
                    })
                })
        }
    }

    async placeBooking(data) {
        // data.poc_name = data.salutation + data.poc_name
        // data.vehicles = this.state.vehicles
        // data.resource_id = this.state.resource_id
        // data.resource_booking_items = this.state.resource_booking_items
        // data.start_time = this.extractTime(data.start_time)
        // data.end_time = this.extractTime(data.end_time)

        await ResourceReservationServices.createBooking(data).then((res) => {
            console.log(res, 'res in createProgram')
            if (res.status === 201) {
                // this.setState({registrationMode:regMode})
                let data = this.state.formData
                data.start_time = null
                data.end_time = null
                data = {}
                this.setState(
                    {
                        // vehicles: [],
                        vehicles: [{ reg_no: '' }],
                        // formData: {},
                        formData: data,
                        team_participants: 0,
                        days_selected: null,
                        resource_booking_items: [],
                        bookMode: false,
                        registrationMode: false,
                        alert: true,
                        message: 'Booking Created Successfully',
                        severity: 'success',
                    },
                    () => {
                        this.loadData()
                    }
                )
                this.changeStepper_Step(0)
            } else {
                this.setState(
                    {
                        alert: true,
                        severity: 'error',
                        message: 'Error!',
                    },
                    () => {
                        this.loadData()
                    }
                )
            }
        })
        // .catch((error) => {
        //     console.log(error)
        //     this.setState({
        //         bookMode: false,
        //         alert: true,
        //         severity: 'error',
        //         message: 'Error!',
        //     })
        // })
    }

    async createTempAccount() {
        console.log(this.state.tempAccountFormData)
        let newFormData = this.state.tempAccountFormData

        let data = this.state.formData

        console.log('createTempAccount: ', data)

        // data.poc_name = data.salutation + data.poc_name
        // data.vehicles = this.state.vehicles
        // data.resource_id = this.state.resource_id
        // data.resource_booking_items = this.state.resource_booking_items
        // data.start_time = this.extractTime(data.start_time)
        // data.end_time = this.extractTime(data.end_time)

        await EmployeeService.createBasicInformation(newFormData)
            .then((res) => {
                console.log('Respond', res)
                if (res.status === 200 || res.status === 201) {
                    this.setState(
                        {
                            alert: true,
                            severity: 'success',
                            message: 'Account has been created successfully!',
                            // viewMode: false,
                            // resource_booking_items:[],
                        },
                        () => {
                            // this.loadData()

                            // place booking
                            console.log(this.state.formData)
                        }
                    )
                    this.placeBooking(this.state.formData)
                } else {
                    this.setState({
                        alert: true,
                        severity: 'error',
                        message: res.response.data.error,
                        bookMode: true,
                        registrationMode: false,
                        confirmation_checkbox: false,
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    alert: true,
                    severity: 'error',
                    message: 'Error!',
                })
            })
    }

    // extract time from date time in time picker
    extractTime(selectedTime) {
        // console.log("extracting time.....")
        // const dateObj = new Date(timestamp);

        // const hours = dateObj.getUTCHours();
        // const minutes = dateObj.getUTCMinutes();
        // const seconds = dateObj.getUTCSeconds();

        //-------
        // const dateString = "Thu Sep 07 2023 08:00:14 GMT+0530 (India Standard Time)";
        const dateObject = new Date(selectedTime)

        const hours = dateObject.getHours()
        const minutes = dateObject.getMinutes()
        const seconds = dateObject.getSeconds()
        //-------

        const timeString = `${hours}:${minutes}:${seconds}`
        console.log(timeString)
        return timeString
    }

    async loadData() {
        //For load data
        this.setState({ loaded: false })
        let filterData = this.state.filterData
        let res = await ResourceReservationServices.PUBLIC_getResources(
            this.state.filterData
        )
        console.log('ress', res.data.view.data)
        if (res.status === 200) {
            //filterData.page = res.data.view.currentPage

            // load only the Resources where status is Active & open to public (internal_use===true)
            const activeResources = res.data.view.data.filter(
                (item) => item.status === 'Active' && item.internal_use
            )
            this.setState(
                {
                    filterData,
                    // data: res.data.view.data,
                    data: activeResources,
                    totalPages: res.data.view.totalPages,
                    totalItems: res.data.view.totalItems,
                    loaded: true,
                },
                () => {
                    console.log('data', this.state)
                    // this.render()
                }
            )
        }
        console.log(this.state.data[0]?.ResourceUploads[0]?.path)
    }

    // Loading Committees
    async loadCommittees() {
        await CommitteesService.getCommitteesTable()
            .then((res) => {
                if (res.status === 200) {
                    this.setState(
                        {
                            committees: res.data.view.data,
                        },
                        () => {
                            console.log('committee data', this.state)
                            this.render()
                        }
                    )
                }
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    alert: true,
                    severity: 'error',
                    message: 'Error!',
                })
            })
    }

    // Loading Faculties
    async loadFaculties() {
        await FacultyService.getAllFaculties()
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data)
                    const newData = res.data.view.data.map((fac) => ({
                        dep_id: null,
                        name: fac.name,
                        fac_id: fac.id,
                        division_id: null,
                    }))
                    this.setState(
                        {
                            faculties: res.data.view.data,
                            x_faculties: newData,
                        },
                        () => {
                            console.log('faculty data', this.state)
                            this.render()
                        }
                    )
                    this.loadFacultiesDepartmentsDivisionsDropDown()
                }
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    alert: true,
                    severity: 'error',
                    message: 'Error!',
                })
            })
    }

    // Loading Departments
    async loadDepartments() {
        await DepartmentService.getAllDepartments()
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data)

                    const newData = res.data.view.data.map((dep) => ({
                        dep_id: dep.id,
                        name: dep.name,
                        fac_id: dep?.Faculty?.id,
                        division_id: null,
                    }))

                    this.setState(
                        {
                            x_departments: newData,
                        },
                        () => {
                            console.log('department data', this.state)
                            this.render()
                        }
                    )
                    // this.loadFacultiesDepartmentsDivisionsDropDown()
                }
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    alert: true,
                    severity: 'error',
                    message: 'Error!',
                })
            })
    }

    // Loading Divisions
    async loadDivisions() {
        await DivisionServices.getAllDivisions()
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data)

                    const newData = res.data.view.data.map((div) => ({
                        dep_id: null,
                        name: div.name,
                        fac_id: null,
                        division_id: div.id,
                    }))

                    this.setState(
                        {
                            x_divisions: newData,
                        },
                        () => {
                            console.log('division data', this.state)
                            this.render()
                        }
                    )
                    this.loadFacultiesDepartmentsDivisionsDropDown()
                }
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    alert: true,
                    severity: 'error',
                    message: 'Error!',
                })
            })
    }

    // combine the 2 arrays to create one array containing all faculties & departments
    loadFacultiesDepartmentsDivisionsDropDown() {
        let faculties = this.state.x_faculties
        let divisions = this.state.x_divisions
        // let departments = this.state.x_departments

        // const combinedArray = [...faculties, ...departments, ...divisions]
        const combinedArray = [...faculties, ...divisions]
        console.log(combinedArray)

        this.setState({
            faculties_departments_divisions: combinedArray,
        })
    }

    //Loading Resource Types
    async loadResourceTypes() {
        await ResourceReservationServices.PUBLIC_getResourceTypes()
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data.view.data, 'res.data.view.data')

                    // load only the Resource Types where status is Active
                    const activeResourceTypes = res.data.view.data.filter(
                        (item) => item.status === 'Active'
                    )

                    this.setState(
                        {
                            // type: res.data.view.data,
                            type: activeResourceTypes,
                        },
                        () => {
                            console.log('resource type data', this.state)
                            this.render()
                        }
                    )
                }
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    alert: true,
                    severity: 'error',
                    message: 'Error!',
                })
            })
    }

    renderResourceDetailsHeaderBar = (row, rowIndex) => (
        <Fragment>
            {/* Resource Name */}
            <Grid
                item
                container
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={12}
                direction="row"
                justifyContent="center"
            >
                <Grid>
                    <ApartmentIcon className="mr-3" />
                </Grid>
                <Typography>
                    {this.state.single_resource_data?.name
                        ? this.state.single_resource_data?.name
                        : ' '}
                </Typography>
            </Grid>

            {/* Ownership Faculty */}
            <Grid
                item
                container
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={12}
                direction="row"
                justifyContent="center"
            >
                <Grid>
                    <SchoolIcon className="mr-3" />
                </Grid>
                <Typography>
                    {this.state.single_resource_data?.Faculty?.name
                        ? this.state.single_resource_data?.Faculty?.name
                        : this.state.single_resource_data?.Ownership_Division
                              ?.name
                        ? this.state.single_resource_data?.Ownership_Division
                              ?.name
                        : ' '}
                </Typography>
            </Grid>

            {/* Location */}
            <Grid
                item
                container
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={12}
                direction="row"
                justifyContent="center"
            >
                <Grid>
                    <LocationOnIcon />
                </Grid>
                <Grid item>
                    <Typography>
                        {this.state.single_resource_data?.location
                            ? this.state.single_resource_data?.location
                            : ' '}
                    </Typography>
                </Grid>
            </Grid>
        </Fragment>
    )

    // Loading employee numbers
    async loadEmployees() {
        await EmployeeService.getAllEmployees()
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data)
                    const newData = res.data.view.data.filter(
                        (emp) =>
                            //    emp.employee_id != null && emp.employee_id != ''
                            emp.nic != null && emp.nic != ''
                    )

                    const employees = newData.map((emp) => ({
                        employee_id: emp.employee_id,
                        id: emp.id,
                        email: emp.email,
                        nic: emp.nic,
                        contact_no: emp.contact_no,
                    }))

                    console.log(employees)

                    this.setState(
                        {
                            // employees: employees,
                            employees: newData,
                        },
                        () => {
                            console.log('employee data', this.state)
                            this.render()
                        }
                    )
                    this.loadAllEmployees()
                }
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    alert: true,
                    severity: 'error',
                    message: 'Error!',
                })
            })
    }

    // Loading temp employees - test
    async loadTempEmployees() {
        await EmployeeService.getAllTempEmployees()
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data)
                    const newData = res.data.view.data.filter(
                        (emp) =>
                            //    emp.id != null && emp.id != ''
                            emp.nic != null && emp.nic != ''
                    )

                    const tempEmployees = newData.map((emp) => ({
                        employee_id: null,
                        id: emp.id,
                        email: emp.email,
                        nic: emp.nic,
                        contact_no: emp.contact_no,
                    }))

                    console.log(tempEmployees)

                    this.setState(
                        {
                            temp_employees: newData,
                        },
                        () => {
                            console.log('temp employee data', this.state)
                            this.render()
                        }
                    )
                    this.loadAllEmployees()
                }
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    alert: true,
                    severity: 'error',
                    message: 'Error!',
                })
            })
    }

    // combine the 2 arrays to create one array containing all employees & temp employees
    loadAllEmployees() {
        let employees = this.state.employees
        let temp_employees = this.state.temp_employees

        const combinedArray = [...employees, ...temp_employees]
        console.log(combinedArray)

        this.setState({
            all_employees: combinedArray,
        })
    }

    // Loading student numbers
    async loadStudents() {
        // let params = {}
        await StudentService.getAllStudents()
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    const newData = res.data.view.data.filter(
                        (student) => student.nic != null && student.nic != ''
                    )
                    console.log(newData)
                    this.setState(
                        {
                            students: newData,
                        },
                        () => {
                            console.log('student data', this.state)
                            this.render()
                        }
                    )
                }
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    alert: true,
                    severity: 'error',
                    message: 'Error!',
                })
            })
    }

    componentDidMount() {
        this.loadFaculties()
        this.loadResourceTypes()
        this.loadData()
        this.loadCommittees()
        // this.loadDepartments()
        this.loadDivisions()
        this.loadEmployees()
        this.loadTempEmployees()
        this.loadStudents()
    }

    render() {
        const currentDate = new Date()
        const year = currentDate.getFullYear()
        const month = String(currentDate.getMonth() + 1).padStart(2, '0')
        const day = String(currentDate.getDate()).padStart(2, '0')

        const formattedDate = `/${year}/${month}/${day}`

        const reservationId =
            this.state.single_resource_data.resource_ref_id + formattedDate // Your reservation ID
        const text = `Thank you for selecting University of Visual Performing Arts Resources for your purpose.\n\nYour Reservation ID is "${reservationId}".\n\nYour Temporary Account will be created for you to complete rest of the steps in the temporary profile. Please go to your email inbox & complete the account creation.`

        console.log(this.state.formData)

        return (
            <Fragment>
                {localStorageService.getItem('accessToken') && (
                    <Layout1Topbar disableMenu disableNotifications />
                )}
                <MainContainer>
                    <LoonsCard>
                        <Grid container spacing={2}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <CardTitle title="Resources Available for Reservation" />
                            </Grid>
                        </Grid>
                        <Fragment>
                            <ValidatorForm
                                onSubmit={() => this.loadData()}
                                onError={() => null}
                            >
                                {/* ------------ Filter fields -------------- */}
                                <Grid container className="mt-1" spacing={2}>
                                    {/* Resorce Type */}
                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        <Autocomplete
                                            className="w-full"
                                            options={this.state.type}
                                            getOptionLabel={(option) =>
                                                option.resource_type
                                            }
                                            getOptionSelected={(option) =>
                                                option.resource_type
                                            }
                                            onChange={(event, value) =>
                                                this.setFilterData(
                                                    'resource_type_id',
                                                    value === null
                                                        ? ''
                                                        : value.id
                                                )
                                            }
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    label="Type"
                                                    variant="outlined"
                                                    value={
                                                        this.state.filterData
                                                            .resource_type_id
                                                            ? this.state
                                                                  .filterData
                                                                  .resource_type_id
                                                            : ''
                                                    }
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: this.state
                                                            .filterData
                                                            .resource_type_id
                                                            ? true
                                                            : false,
                                                    }}
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Grid>

                                    {/* Ownership / Managed by*/}
                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        <Autocomplete
                                            className="w-full"
                                            options={
                                                this.state
                                                    .faculties_departments_divisions
                                            }
                                            getOptionLabel={(option) =>
                                                option.name
                                            }
                                            getOptionSelected={(option) =>
                                                option.name
                                            }
                                            onChange={(event, value) => {
                                                console.log(value)
                                                this.setFilterData(
                                                    'faculty_id',
                                                    value === null
                                                        ? ''
                                                        : value.fac_id
                                                )
                                                this.setFilterData(
                                                    'dep_id',
                                                    value === null
                                                        ? ''
                                                        : value.dep_id
                                                )
                                                this.setFilterData(
                                                    'division_id',
                                                    value === null
                                                        ? ''
                                                        : value.division_id
                                                )
                                            }}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    label="Managed by"
                                                    variant="outlined"
                                                    value={
                                                        this.state.filterData
                                                            .faculty_id
                                                    }
                                                    fullWidth
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Grid>

                                    {/* Search */}
                                    <Grid item lg={4} md={4} sm={10} xs={12}>
                                        <TextField
                                            className="w-full"
                                            InputLabelProps={{ shrink: true }}
                                            label="Search Here"
                                            onChange={(e) => {
                                                this.setFilterData(
                                                    'search',
                                                    e.target.value
                                                )
                                            }}
                                            value={this.state.filterData.search}
                                            placeholder="Resource Name"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={2} xs={12}>
                                        <Button
                                            className="w-full"
                                            type="submit"
                                            startIcon="search"
                                        >
                                            <span className="capitalize">
                                                Search
                                            </span>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                        </Fragment>

                        {/* ------------------ Cards ----------------- */}
                        {this.state.data.map((item) => (
                            <Grid container spacing={2}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <LoonsCard>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <CardTitle
                                                    title={
                                                        item.name +
                                                        ' (Resource ID: ' +
                                                        item.resource_ref_id +
                                                        ')'
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                container
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Grid item>
                                                    <ImageView
                                                        image_data={
                                                            item.ResourceUploads
                                                                .length > 0
                                                                ? item
                                                                      .ResourceUploads[
                                                                      item
                                                                          .ResourceUploads
                                                                          .length -
                                                                          1
                                                                  ]
                                                                : null
                                                        }
                                                        preview_width="150px"
                                                        preview_height="150px"
                                                    />
                                                </Grid>
                                                <Grid
                                                    container
                                                    xl={10}
                                                    lg={10}
                                                    md={9}
                                                    sm={9}
                                                    xs={12}
                                                    flexDirection="column"
                                                    justifyContent="space-between"
                                                    className="pl-4"
                                                >
                                                    <Grid
                                                        item
                                                        xl={12}
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <CardContent>
                                                            <Grid
                                                                container
                                                                xl={12}
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                                flexDirection="row"
                                                            >
                                                                <Grid
                                                                    item
                                                                    container
                                                                    xl={3}
                                                                    lg={3}
                                                                    md={3}
                                                                    sm={3}
                                                                    xs={3}
                                                                    flexDirection="row"
                                                                >
                                                                    <SchoolIcon className="mr-3" />
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="textSecondary"
                                                                        component="p"
                                                                        className={
                                                                            'mr-10'
                                                                        }
                                                                    >
                                                                        {item?.Ownership_Faculty
                                                                            ? item
                                                                                  ?.Ownership_Faculty
                                                                                  .name
                                                                            : item?.Ownership_Division
                                                                            ? item
                                                                                  ?.Ownership_Division
                                                                                  .name
                                                                            : ' ___ '}
                                                                    </Typography>
                                                                </Grid>
                                                                <LocationOnIcon className="mr-3" />
                                                                <Typography
                                                                    variant="body2"
                                                                    color="textSecondary"
                                                                    component="p"
                                                                >
                                                                    {item?.description
                                                                        ? item?.description
                                                                        : ' ___ '}
                                                                </Typography>
                                                            </Grid>
                                                            {/* <DescriptionIcon className="mr-3" /> */}
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                component="p"
                                                                className="mt-5"
                                                            >
                                                                {item?.location
                                                                    ? item?.location
                                                                    : ' ___ '}
                                                            </Typography>
                                                        </CardContent>
                                                    </Grid>
                                                    <Grid
                                                        // container
                                                        item
                                                        xl={12}
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <CardActions className="pb-0">
                                                            <Button
                                                                size="small"
                                                                color="primary"
                                                                onClick={() => {
                                                                    this.OpenViewResourceDialogBox(
                                                                        item.id
                                                                    )
                                                                }}
                                                            >
                                                                View Resource
                                                            </Button>
                                                            <Button
                                                                size="small"
                                                                color="primary"
                                                                onClick={() => {
                                                                    if (
                                                                        localStorageService.getItem(
                                                                            'accessToken'
                                                                        )
                                                                    ) {
                                                                        this.OpenDialogBox(
                                                                            item.id
                                                                        )
                                                                        return
                                                                    }
                                                                    this.setState({open: true})
                                                                }}
                                                            >
                                                                Book Resource
                                                            </Button>
                                                            <Button
                                                                size="small"
                                                                color="primary"
                                                                onClick={() => {
                                                                    this.setState(
                                                                        {
                                                                            selectedId:
                                                                                item.id,
                                                                        }
                                                                    )
                                                                    this.OpenResourceCalendarBox(
                                                                        item.id
                                                                    )
                                                                }}
                                                            >
                                                                Resource
                                                                Calendar
                                                            </Button>
                                                        </CardActions>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </LoonsCard>
                                </Grid>
                            </Grid>
                        ))}

                        {/* Dialog box -> Book Resource*/}
                        <Dialog
                            open={this.state.bookMode}
                            // onClose={this.setState({ bookMode: false })}
                            fullWidth
                            maxWidth="md"
                            onClose={this.dialogClose}
                        >
                            <DialogTitle
                                className="text-center"
                                id="alert-dialog-title"
                                onClose={this.dialogClose}
                            >
                                <Typography
                                    className="font-semibold mt-5"
                                    variant="h5"
                                >
                                    {'Book Resource'}
                                </Typography>
                            </DialogTitle>

                            <DialogContent>
                                <Stepper
                                    alternativeLabel
                                    style={{
                                        backgroundColor: 'transparent',
                                    }}
                                    activeStep={this.state.stepper_active_Step}
                                    connector={<ColorlibConnector />}
                                >
                                    {stepperSteps.map((label, key) => (
                                        <Step
                                            key={label}
                                            // className="cursor-pointer"
                                            onClick={() => {
                                                console.log(
                                                    this.state.resouce_id,
                                                    'resouce_id'
                                                )
                                                // this.changeStepper_Step(key)
                                                console.log(
                                                    'STEPPER KEY: ' + key
                                                )
                                                console.log(
                                                    'STATE STEPPER ACTIVE: ' +
                                                        this.state
                                                            .stepper_active_Step
                                                )
                                            }}
                                        >
                                            <StepLabel
                                                StepIconComponent={
                                                    ColorlibStepIcon
                                                }
                                            >
                                                {label}
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>

                                {/*------------------------- Step 0 (Days) ------------------------- */}
                                {this.state.stepper_active_Step === 0 && (
                                    <>
                                        <Grid
                                            container
                                            spacing={2}
                                            className="flex-items-center mt-1"
                                        >
                                            {/* Resource Id */}
                                            <Grid
                                                item
                                                container
                                                lg={12}
                                                xs={12}
                                                justifyContent="center"
                                            >
                                                <Typography variant="h6">
                                                    {this.state
                                                        .single_resource_data
                                                        .name +
                                                        ' (Resource ID: ' +
                                                        this.state
                                                            .single_resource_data
                                                            .resource_ref_id +
                                                        ')'}
                                                </Typography>
                                            </Grid>

                                            {this.renderResourceDetailsHeaderBar()}

                                            {/* Description */}
                                            <Grid
                                                item
                                                container
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                justifyContent="center"
                                                align="center"
                                            >
                                                <Typography variant="caption">
                                                    {this.state
                                                        .single_resource_data
                                                        ?.description
                                                        ? this.state
                                                              .single_resource_data
                                                              ?.description
                                                        : ' '}
                                                </Typography>
                                            </Grid>

                                            {/* Amenities */}
                                            {/* TODO */}

                                            {/* Picture */}
                                            <Grid
                                                container
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                className="flex-items-center mt-1"
                                            >
                                                <Grid
                                                    item
                                                    container
                                                    xl={12}
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                    justifyContent="center"
                                                >
                                                    {this.state
                                                        .single_resource_data
                                                        ?.ResourceUploads && (
                                                        // <CardMedia
                                                        //     style={{
                                                        //         width: 150,
                                                        //     }}
                                                        //     className="ml-15"
                                                        //     component="img"
                                                        //     alt="Contemplative Reptile"
                                                        //     image="/assets/images/blank-profile.png"
                                                        //     title="Live from space album cover"
                                                        // />
                                                        <ImageView
                                                            image_data={
                                                                this.state
                                                                    .single_resource_data
                                                                    ?.ResourceUploads
                                                                    .length > 0
                                                                    ? this.state
                                                                          .single_resource_data
                                                                          .ResourceUploads[
                                                                          this
                                                                              .state
                                                                              .single_resource_data
                                                                              .ResourceUploads
                                                                              .length -
                                                                              1
                                                                      ]
                                                                    : null
                                                            }
                                                            preview_width="150px"
                                                            preview_height="150px"
                                                        />
                                                    )}
                                                </Grid>

                                                {/* Date - From - To */}
                                                <Grid
                                                    item
                                                    container
                                                    spacing={1}
                                                    xl={10}
                                                    lg={10}
                                                    md={10}
                                                    sm={10}
                                                    xs={10}
                                                    className="w-full mt-10"
                                                    justifyContent="center"
                                                >
                                                    <Grid
                                                        container
                                                        xl={2}
                                                        lg={2}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                        justifyContent="flex-end"
                                                    >
                                                        <Typography className="pt-6 pr-10">
                                                            Date :
                                                        </Typography>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xl={4}
                                                        lg={4}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        {/* <DatePicker
                                                            className="w-full mt-2"
                                                            value={this.state.formData.start_date}
                                                            label="From"
                                                            format="yyyy-MM-dd"
                                                            onChange={(date) => {
                                                                console.log(date)
                                                                let data = this.state.formData
                                                                data.start_date = moment(date).format('yyyy-MM-DD')
                                                                this.setState({formData:data})
                                                            }}
                                                        /> */}
                                                        <ReservationDateTimePicker
                                                            label="From"
                                                            data={
                                                                this.state
                                                                    .bookingsList
                                                            }
                                                            selectedFromValue={
                                                                this.state
                                                                    .selectedFromValue
                                                            }
                                                            setDateTime={(
                                                                dateTime
                                                            ) => {
                                                                this.setState({
                                                                    selectedFromValue:
                                                                        dateTime,
                                                                    displayError: false,
                                                                })
                                                                this.formatDate(
                                                                    dateTime,
                                                                    'start_date'
                                                                )
                                                                this.formatTime(
                                                                    dateTime.$d,
                                                                    'start_time'
                                                                )
                                                                this.enableStepper1Btn()
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xl={4}
                                                        lg={4}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        {/* <DatePicker
                                                            className="w-full mt-2"
                                                            value={this.state.formData.end_date}
                                                            label="To"
                                                            format="yyyy-MM-dd"
                                                            onChange={(date) => {
                                                                let data = this.state.formData
                                                                data.end_date = moment(date).format('yyyy-MM-DD')
                                                                this.setState({formData:data})
                                                            }}
                                                            slotProps={{
                                                                textField: {required: true,},
                                                            }}
                                                        /> */}
                                                        <ReservationDateTimePicker
                                                            label="To"
                                                            data={
                                                                this.state
                                                                    .bookingsList
                                                            }
                                                            selectedToValue={
                                                                this.state
                                                                    .selectedToValue
                                                            }
                                                            selectedFromDate={
                                                                this.state
                                                                    .selectedFromValue
                                                            }
                                                            // minDate={this.state.selectedFromValue}
                                                            setDateTime={(
                                                                dateTime
                                                            ) => {
                                                                this.setState({
                                                                    selectedToValue:
                                                                        dateTime,
                                                                })
                                                                this.formatDate(
                                                                    dateTime,
                                                                    'end_date'
                                                                )
                                                                this.formatTime(
                                                                    dateTime.$d,
                                                                    'end_time'
                                                                )
                                                                this.enableStepper1Btn()
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {this.state.displayError && (
                                                    <Grid
                                                        item
                                                        sm={12}
                                                        xs={12}
                                                        className="flex"
                                                    >
                                                        <p className="text-center text-error p-4 bg-light-error mx-auto border-radius-8">
                                                            Time slot you
                                                            selected is already
                                                            reserved. Please
                                                            check the
                                                            reservation calendar
                                                            for available time
                                                            slots
                                                        </p>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>

                                        <ValidatorForm
                                            className="mt-15"
                                            onSubmit={() =>
                                                this.changeStepper_Step(1)
                                            }
                                            onError={(error) =>
                                                console.log(error)
                                            }
                                        >
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                align="right"
                                            >
                                                <Button
                                                    type="button"
                                                    onClick={() => {
                                                        this.changeStepper_Step(
                                                            1
                                                        )
                                                    }}
                                                    color="primary"
                                                    startIcon="save"
                                                    disabled={
                                                        this.state
                                                            .isStepper1BtnDisabled
                                                    }
                                                >
                                                    Save and Next
                                                </Button>
                                            </Grid>
                                        </ValidatorForm>
                                    </>
                                )}

                                {/*------------------------- Step 1 (Costs) ------------------------- */}
                                {this.state.stepper_active_Step === 1 && (
                                    <>
                                        <Grid
                                            container
                                            spacing={2}
                                            className="felx-items-center mb-5"
                                        >
                                            {this.renderResourceDetailsHeaderBar()}
                                        </Grid>

                                        {/* Days, Base, Extra costs, refundable depoists */}
                                        <Grid
                                            container
                                            xl={12}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className="mb-5"
                                        >
                                            <Grid
                                                item
                                                container
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                {/* Days Selected */}
                                                <Grid
                                                    container
                                                    xl={12}
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                    justifyContent="center"
                                                >
                                                    <Grid
                                                        item
                                                        container
                                                        xl={3}
                                                        lg={3}
                                                        md={3}
                                                        sm={4}
                                                        xs={4}
                                                        justifyContent="flex-end"
                                                    >
                                                        <Typography variant="subtitle1">
                                                            {'Days Selected : '}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xl={4}
                                                        lg={4}
                                                        md={4}
                                                        sm={4}
                                                        xs={4}
                                                        justifyContent="flex-end"
                                                    >
                                                        <Typography
                                                            variant="subtitle1"
                                                            className="pl-2"
                                                        >
                                                            {this.state.formData
                                                                .start_date &&
                                                            this.state.formData
                                                                .end_date
                                                                ? `${this.state.formData.start_date}  to  ${this.state.formData.end_date}`
                                                                : 'Please select the date range.'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                {/* Time Duratioin */}
                                                <Grid
                                                    container
                                                    xl={12}
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                    justifyContent="center"
                                                >
                                                    <Grid
                                                        item
                                                        container
                                                        xl={3}
                                                        lg={3}
                                                        md={3}
                                                        sm={4}
                                                        xs={4}
                                                        justifyContent="flex-end"
                                                    >
                                                        <Typography variant="subtitle1">
                                                            {'Time Duration : '}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xl={4}
                                                        lg={4}
                                                        md={4}
                                                        sm={4}
                                                        xs={4}
                                                        justifyContent="flex-end"
                                                    >
                                                        <Typography
                                                            variant="subtitle1"
                                                            className="pl-2"
                                                        >
                                                            {this.state.formData
                                                                .start_time &&
                                                            this.state.formData
                                                                .end_time
                                                                ? `${this.extractTime(
                                                                      this.state
                                                                          .formData
                                                                          .start_time
                                                                  )}  to  ${this.extractTime(
                                                                      this.state
                                                                          .formData
                                                                          .end_time
                                                                  )}`
                                                                : 'Please select the time.'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                {/* Base Cost */}
                                                <Grid
                                                    container
                                                    xl={12}
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                    justifyContent="center"
                                                >
                                                    <Grid
                                                        item
                                                        container
                                                        xl={3}
                                                        lg={3}
                                                        md={3}
                                                        sm={4}
                                                        xs={4}
                                                        justifyContent="flex-end"
                                                    >
                                                        <Typography variant="subtitle1">
                                                            {
                                                                'Base Cost (Rs) : '
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xl={4}
                                                        lg={4}
                                                        md={4}
                                                        sm={4}
                                                        xs={4}
                                                    >
                                                        <Typography
                                                            variant="subtitle1"
                                                            className="pl-2"
                                                        >
                                                            {this.state
                                                                .single_resource_data
                                                                ?.base_cost
                                                                ? this.state
                                                                      .single_resource_data
                                                                      ?.base_cost
                                                                : ' 0 '}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                {/* Extra Cost */}
                                                <Grid
                                                    container
                                                    xl={12}
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                    justifyContent="center"
                                                >
                                                    <Grid
                                                        item
                                                        container
                                                        xl={3}
                                                        lg={3}
                                                        md={3}
                                                        sm={4}
                                                        xs={4}
                                                        justifyContent="flex-end"
                                                    >
                                                        <Typography variant="subtitle1">
                                                            {
                                                                'Extra Cost (Rs) : '
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xl={4}
                                                        lg={4}
                                                        md={4}
                                                        sm={4}
                                                        xs={4}
                                                    >
                                                        <Typography
                                                            variant="subtitle1"
                                                            className="pl-2"
                                                        >
                                                            {this.state
                                                                .single_resource_data
                                                                ?.extra_cost
                                                                ? this.state
                                                                      .single_resource_data
                                                                      ?.extra_cost
                                                                : ' 0 '}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                {/* Refundable Deposit */}
                                                <Grid
                                                    container
                                                    xl={12}
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                    justifyContent="center"
                                                >
                                                    <Grid
                                                        item
                                                        container
                                                        xl={3}
                                                        lg={3}
                                                        md={3}
                                                        sm={4}
                                                        xs={4}
                                                        justifyContent="flex-end"
                                                    >
                                                        <Typography variant="subtitle1">
                                                            {
                                                                'Refundable Deposit (Rs) : '
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xl={4}
                                                        lg={4}
                                                        md={4}
                                                        sm={4}
                                                        xs={4}
                                                    >
                                                        <Typography
                                                            variant="subtitle1"
                                                            className="pl-2"
                                                        >
                                                            {this.state
                                                                .single_resource_data
                                                                ?.refundable_deposit
                                                                ? this.state
                                                                      .single_resource_data
                                                                      ?.refundable_deposit
                                                                : ' 0 '}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* Table */}
                                        <Grid className="block justify-center mt-2">
                                            <LoonsTable
                                                data={this.state.amenety_data}
                                                columns={this.state.columns}
                                                options={{
                                                    pagination: false,
                                                    serverSide: true,
                                                    download: false,
                                                    print: false,
                                                    viewColumns: false,
                                                    sort: false,
                                                    rowsPerPage: 20,
                                                    selectableRows: false,
                                                    onTableChange: (
                                                        action,
                                                        tableState
                                                    ) => {
                                                        console.log(
                                                            action,
                                                            tableState
                                                        )
                                                        switch (action) {
                                                            case 'changePage':
                                                                this.setPage(
                                                                    tableState.page
                                                                )
                                                                //this.changePage(tableState.page, tableState.sortOrder);
                                                                break
                                                            case 'sort':
                                                                //this.sort(tableState.page, tableState.sortOrder);
                                                                break
                                                            default:
                                                                console.log(
                                                                    'action not handled.'
                                                                )
                                                        }
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            container
                                            lg={12}
                                            md={12}
                                            xs={12}
                                            className="block justify-center"
                                        >
                                            <Grid item>
                                                <Typography
                                                    style={{ padding: 20 }}
                                                >
                                                    {
                                                        '*Your invoice estimate will be around '
                                                    }
                                                    <strong>
                                                        Rs.{' '}
                                                        {this.state?.total_cost}
                                                    </strong>
                                                    {
                                                        ' Please note this is an estimated cost. Actual Invoice will be sent later by the university.'
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        {/* Step 1 -> Next btn */}
                                        <ValidatorForm
                                            className="mt-15"
                                            onSubmit={() =>
                                                this.changeStepper_Step(2)
                                            }
                                            onError={(error) =>
                                                console.log(error)
                                            }
                                        >
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                align="right"
                                            >
                                                <Button
                                                    className="bg-secondary text-white mr-2"
                                                    type="button"
                                                    onClick={() => {
                                                        this.changeStepper_Step(
                                                            0
                                                        )
                                                    }}
                                                    startIcon="arrow_left"
                                                >
                                                    Back
                                                </Button>

                                                <Button
                                                    type="button"
                                                    onClick={() => {
                                                        this.changeStepper_Step(
                                                            2
                                                        )
                                                    }}
                                                    color="primary"
                                                    startIcon="save"
                                                >
                                                    Save and Next
                                                </Button>
                                            </Grid>
                                            {/* <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                align="right"
                                            >
                                                <Button
                                                    type="button"
                                                    onClick={() => {
                                                        this.changeStepper_Step(2)
                                                    }}
                                                    color="primary"
                                                    startIcon="save"
                                                >
                                                    Save and Next
                                                </Button>
                                            </Grid> */}
                                        </ValidatorForm>
                                    </>
                                )}

                                {/*------------------------- Step 2 (Information) ------------------------- */}
                                {this.state.stepper_active_Step === 2 && (
                                    <>
                                        <Grid
                                            container
                                            spacing={2}
                                            className="felx-items-center mb-5"
                                            item
                                        >
                                            {this.renderResourceDetailsHeaderBar()}
                                        </Grid>

                                        {/* Days Selected */}
                                        <Grid
                                            container
                                            xl={12}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            justifyContent="center"
                                        >
                                            <Grid
                                                item
                                                container
                                                xl={3}
                                                lg={3}
                                                md={3}
                                                sm={4}
                                                xs={4}
                                                justifyContent="flex-end"
                                            >
                                                <Typography variant="subtitle1">
                                                    {'Days Selected : '}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xl={4}
                                                lg={4}
                                                md={4}
                                                sm={4}
                                                xs={4}
                                                justifyContent="flex-end"
                                            >
                                                <Typography
                                                    variant="subtitle1"
                                                    className="pl-2"
                                                >
                                                    {this.state.formData
                                                        .start_date &&
                                                    this.state.formData.end_date
                                                        ? `${this.state.formData.start_date}  to  ${this.state.formData.end_date}`
                                                        : 'Please select the date range.'}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        {/* Time Duration */}
                                        <Grid
                                            container
                                            xl={12}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            justifyContent="center"
                                        >
                                            <Grid
                                                item
                                                container
                                                xl={3}
                                                lg={3}
                                                md={3}
                                                sm={4}
                                                xs={4}
                                                justifyContent="flex-end"
                                            >
                                                <Typography variant="subtitle1">
                                                    {'Time Duration : '}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xl={4}
                                                lg={4}
                                                md={4}
                                                sm={4}
                                                xs={4}
                                                justifyContent="flex-end"
                                            >
                                                <Typography
                                                    variant="subtitle1"
                                                    className="pl-2"
                                                >
                                                    {this.state.formData
                                                        .start_time &&
                                                    this.state.formData.end_time
                                                        ? `${this.extractTime(
                                                              this.state
                                                                  .formData
                                                                  .start_time
                                                          )}  to  ${this.extractTime(
                                                              this.state
                                                                  .formData
                                                                  .end_time
                                                          )}`
                                                        : 'Please select the time.'}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        {/* Event Details */}
                                        <Grid
                                            container
                                            lg={12}
                                            md={12}
                                            xs={12}
                                            sm={12}
                                            className="mt-5"
                                            justifyContent="center"
                                        >
                                            <Grid
                                                item
                                                container
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                justifyContent="center"
                                            >
                                                {/* Header - Event Details */}
                                                <Grid
                                                    item
                                                    container
                                                    xl={10}
                                                    lg={10}
                                                    md={10}
                                                    sm={10}
                                                    xs={10}
                                                    justifyContent="center"
                                                >
                                                    <Typography variant="h6">
                                                        {'Event Details'}
                                                    </Typography>
                                                </Grid>

                                                {/* Form */}
                                                <Grid
                                                    container
                                                    xl={12}
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                    justifyContent="center"
                                                    className="mb-6"
                                                >
                                                    <ValidatorForm
                                                        onSubmit={() => {
                                                            this.SubmitResourceBookingRequest()
                                                        }}
                                                        onError={(error) =>
                                                            console.log(error)
                                                        }
                                                    >
                                                        {/* Sub Header 1 - Event/Ceremony Details */}
                                                        <Grid
                                                            item
                                                            xl={12}
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                            className="bg-light-primary border-radius-8 mt-2 mb-4 py-3"
                                                        >
                                                            <small className=" text-primary font-medium text-15 px-2 py-6 w-full">
                                                                Event/Ceremony
                                                                Details
                                                            </small>
                                                        </Grid>

                                                        {/* Event Name */}
                                                        <Grid
                                                            item
                                                            xl={12}
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <TextValidator
                                                                className="w-full mb-2"
                                                                label="Event Name *"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    let data =
                                                                        this
                                                                            .state
                                                                            .formData
                                                                    data.event =
                                                                        e.target.value
                                                                    this.setState(
                                                                        {
                                                                            formData:
                                                                                data,
                                                                        }
                                                                    )
                                                                }}
                                                                value={
                                                                    this.state
                                                                        .formData
                                                                        .event
                                                                }
                                                                name="event"
                                                                fullWidth
                                                                variant="outlined"
                                                                size="small"
                                                                validators={[
                                                                    'required',
                                                                ]}
                                                                errorMessages={[
                                                                    'This field is required',
                                                                ]}
                                                            />
                                                        </Grid>

                                                        {/* Event Organized By */}
                                                        <Grid
                                                            item
                                                            xl={12}
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <TextValidator
                                                                className="w-full mb-2"
                                                                label="Event Organized by *"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    this.handleChange(
                                                                        e
                                                                    )
                                                                }}
                                                                value={
                                                                    this.state
                                                                        .formData
                                                                        .event_organized_by
                                                                }
                                                                name="event_organized_by"
                                                                fullWidth
                                                                variant="outlined"
                                                                size="small"
                                                                validators={[
                                                                    'required',
                                                                ]}
                                                                errorMessages={[
                                                                    'This field is required',
                                                                ]}
                                                            />
                                                        </Grid>

                                                        {/* About the Event */}
                                                        <Grid
                                                            item
                                                            xl={12}
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <TextValidator
                                                                className="w-full mb-2"
                                                                label="About the Event *"
                                                                name="event_details"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    this.handleChange(
                                                                        e
                                                                    )
                                                                }}
                                                                multiline={true}
                                                                rows={4}
                                                                value={
                                                                    this.state
                                                                        .formData
                                                                        .event_details
                                                                }
                                                                fullWidth
                                                                variant="outlined"
                                                                size="small"
                                                                validators={[
                                                                    'required',
                                                                ]}
                                                                errorMessages={[
                                                                    'This field is required',
                                                                ]}
                                                            />
                                                        </Grid>

                                                        {/* Sub Header 2 - Association */}
                                                        <Grid
                                                            item
                                                            xl={12}
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                            className="bg-light-primary border-radius-8 my-2 mb-4 py-3"
                                                        >
                                                            <small className=" text-primary font-medium text-15 px-2 py-3 w-full">
                                                                Association with
                                                                the University
                                                            </small>
                                                        </Grid>

                                                        {/* Association - Radio Buttons */}
                                                        <Grid
                                                            item
                                                            xl={12}
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <RadioGroup
                                                                row
                                                                className="mb-5"
                                                                validators={[
                                                                    'required',
                                                                ]}
                                                                errorMessages={[
                                                                    'This field is required',
                                                                ]}
                                                            >
                                                                <FormControlLabel
                                                                    value="current"
                                                                    onChange={() => {
                                                                        let formData =
                                                                            this
                                                                                .state
                                                                                .formData
                                                                        formData.association_to_uni =
                                                                            'current'
                                                                        this.setState(
                                                                            {
                                                                                formData,
                                                                            }
                                                                        )
                                                                    }}
                                                                    control={
                                                                        <Radio color="primary" />
                                                                    }
                                                                    // label="Currently working/studying in the university"
                                                                    label="Current employee/student"
                                                                    labelPlacement="current"
                                                                />

                                                                <FormControlLabel
                                                                    value="past"
                                                                    onChange={() => {
                                                                        let formData =
                                                                            this
                                                                                .state
                                                                                .formData
                                                                        formData.association_to_uni =
                                                                            'past'
                                                                        this.setState(
                                                                            {
                                                                                formData,
                                                                            }
                                                                        )
                                                                    }}
                                                                    control={
                                                                        <Radio color="primary" />
                                                                    }
                                                                    // label="Associated in the Past"
                                                                    // label="Previously worked/studied in the university"
                                                                    label="Previous employee/student"
                                                                    labelPlacement="past"
                                                                />

                                                                <FormControlLabel
                                                                    value="not_associated"
                                                                    onChange={() => {
                                                                        let formData =
                                                                            this
                                                                                .state
                                                                                .formData
                                                                        formData.association_to_uni =
                                                                            'not_associated'
                                                                        this.setState(
                                                                            {
                                                                                formData,
                                                                            }
                                                                        )
                                                                    }}
                                                                    control={
                                                                        <Radio color="primary" />
                                                                    }
                                                                    // label="Not Associated"
                                                                    label="Not a current or previous employee/student"
                                                                    labelPlacement="not_associated"
                                                                />
                                                            </RadioGroup>
                                                        </Grid>

                                                        {this.state.formData
                                                            .association_to_uni ===
                                                            'current' &&
                                                        this.state.formData
                                                            .association_to_uni !==
                                                            'not_associated' ? (
                                                            <Grid
                                                                item
                                                                container
                                                                xl={12}
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                {/* Associated as a */}
                                                                <Grid
                                                                    item
                                                                    xl={12}
                                                                    lg={12}
                                                                    md={12}
                                                                    sm={12}
                                                                    xs={12}
                                                                >
                                                                    <Autocomplete
                                                                        className="w-full mb-3"
                                                                        options={
                                                                            appConst.associated_as
                                                                        }
                                                                        getOptionLabel={(
                                                                            option
                                                                        ) =>
                                                                            option.label
                                                                        }
                                                                        getOptionSelected={(
                                                                            option,
                                                                            value
                                                                        ) =>
                                                                            option.value ===
                                                                            value.value
                                                                        }
                                                                        onChange={(
                                                                            event,
                                                                            value
                                                                        ) => {
                                                                            console.log(
                                                                                value
                                                                            )

                                                                            let formData =
                                                                                this
                                                                                    .state
                                                                                    .formData

                                                                            formData.associated_nic =
                                                                                null
                                                                            formData.employee_id =
                                                                                ''
                                                                            formData.associated_email =
                                                                                ''
                                                                            formData.associated_contact_no =
                                                                                ''

                                                                            formData.associated_as =
                                                                                value.value
                                                                            console.log(
                                                                                formData,
                                                                                'formData'
                                                                            )
                                                                            this.setState(
                                                                                {
                                                                                    formData:
                                                                                        formData,
                                                                                }
                                                                            )
                                                                        }}
                                                                        alidators={[
                                                                            'required',
                                                                        ]}
                                                                        errorMessages={[
                                                                            'This field is required',
                                                                        ]}
                                                                        renderInput={(
                                                                            params
                                                                        ) => (
                                                                            <TextValidator
                                                                                {...params}
                                                                                label="Associated as a *"
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .formData
                                                                                        .associated_as
                                                                                }
                                                                                fullWidth
                                                                                variant="outlined"
                                                                                size="small"
                                                                                validators={[
                                                                                    'required',
                                                                                ]}
                                                                                errorMessages={[
                                                                                    'This field is required',
                                                                                ]}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                {/* {this.state.formData.associated_as != null && this.state.formData.associated_as != "" && (
                                                                    <Grid
                                                                        item
                                                                        container
                                                                        spacing={1}
                                                                        xl={12}
                                                                        lg={12}
                                                                        md={12}
                                                                        sm={12}
                                                                        xs={12}
                                                                        flexDirection="row"
                                                                        justifyContent='space-between'
                                                                    >
                                                                        NIC
                                                                        <Grid
                                                                            item
                                                                            xl={3}
                                                                            lg={3}
                                                                            md={3}
                                                                            sm={12}
                                                                            xs={12}
                                                                        >
                                                                            <Autocomplete
                                                                                className="w-full mb-2"
                                                                                // options={this.state.employees}
                                                                                options={this.state.formData?.associated_as.label==='Student' ? this.state.students : this.state.all_employees}
                                                                                // getOptionLabel={(option) =>option?.employee_id ? option.employee_id : option.id}
                                                                                getOptionLabel={(option) => option.nic}
                                                                                getOptionSelected={( option, value ) => option.id === value.id}
                                                                                onChange={(event,value) => {
                                                                                    let formData = this.state.formData
                                                                                    
                                                                                    if(value) {
                                                                                        console.log(value)
                                                                                        formData.associated_nic = value.nic
                                                                                        // formData.associated_contact_no = value.contact_no ? '0' + value.contact_no : ''
                                                                                        formData.associated_contact_no = value.contact_no
                                                                                        formData.associated_email = value.email

                                                                                        if(value?.employee_id) {
                                                                                            formData.employee_id = value?.employee_id
                                                                                            this.setState({isEmpNoDisabled: false,})
                                                                                        
                                                                                        } else if (value?.reg_no) {
                                                                                            formData.student_id = value?.reg_no
                                                                                            this.setState({isStudentNoDisabled: false,})

                                                                                        } else {
                                                                                            formData.employee_id = 'N/A'
                                                                                            formData.student_id = 'N/A'
                                                                                            this.setState({isEmpNoDisabled: true,})
                                                                                            this.setState({isStudentNoDisabled: true,})
                                                                                        }
                                                                                        console.log(formData, 'formData')

                                                                                    } else {
                                                                                        console.log('deselected')
                                                                                        formData.associated_nic = ''
                                                                                        formData.employee_id = ''
                                                                                        formData.associated_email = ''
                                                                                        formData.associated_contact_no = ''
                                                                                    }
                                                                                    this.setState({formData: formData,})
                                                                                }}
                                                                                freeSolo
                                                                                renderInput={(params) => (
                                                                                    <TextValidator
                                                                                        {...params}
                                                                                        label="NIC *"
                                                                                        value={this.state.formData.associated_nic || ''}
                                                                                        // value={this.state.formData.employee?.employee_id || this.state.formData.employee?.id || ''}
                                                                                        fullWidth
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                        validators={['required', 'matchRegexp:^([0-9]{9}[x|X|v|V]|[0-9]{12})$']}
                                                                                        errorMessages={['This field is required', 'Invalid NIC']}
                                                                                        onChange={(e) => {
                                                                                            let formData = this.state.formData
                                                                                            // formData.employee = {employee_id:e.target.value}
                                                                                            // formData.reg_no = e.target.value
                                                                                            formData.associated_nic = e.target.value
                                                                                            formData.associated_contact_no = ''
                                                                                            formData.associated_email = ''
                                                                                            formData.employee_id = ''
                                                                                            console.log(formData, 'formData')
                                                                                            this.setState({
                                                                                                formData: formData,
                                                                                                isEmpNoDisabled: true,
                                                                                                isStudentNoDisabled: true,
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </Grid>

                                                                        {this.state.formData.associated_as != null && this.state.formData.associated_as.label != 'Student' ? (
                                                                            //  Employee No
                                                                        <Grid
                                                                                item
                                                                                // container
                                                                                xl={3}
                                                                                lg={3}
                                                                                md={3}
                                                                                sm={12}
                                                                                xs={12}
                                                                            >
                                                                                <TextValidator
                                                                                    className="w-full mb-2"
                                                                                    label="Employee No"
                                                                                    name="employee_no"
                                                                                    onChange={(e) => {this.handleChange(e)}}
                                                                                    value={this.state.formData.associated_nic==='' || this.state.formData.associated_nic===null ? '' : this.state.formData.employee_id}
                                                                                    fullWidth
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    // InputLabelProps={this.state.isEmpNoDisabled ? {shrink:false} : {shrink:true} }
                                                                                    InputLabelProps={this.state.formData.associated_nic==='' || this.state.formData.associated_nic===null ? {shrink:false} : {shrink:true} }
                                                                                    disabled={this.state.isEmpNoDisabled}
                                                                                    // validators={['required']}
                                                                                    // errorMessages={['This field is required']}
                                                                                />
                                                                            </Grid>
                                                                        ): this.state.formData.associated_as != null && this.state.formData.associated_as.label === 'Student' ?(
                                                                            // Student No
                                                                            <Grid
                                                                                item
                                                                                // container
                                                                                xl={3}
                                                                                lg={3}
                                                                                md={3}
                                                                                sm={12}
                                                                                xs={12}
                                                                            >
                                                                                <TextValidator
                                                                                    className="w-full mb-2"
                                                                                    label="Student No"
                                                                                    name="student_no"
                                                                                    onChange={(e) => {this.handleChange(e)}}
                                                                                    // value={this.state.formData.student_id}
                                                                                    value={this.state.formData.associated_nic==='' || this.state.formData.associated_nic===null ? '' : this.state.formData.student_id}
                                                                                    fullWidth
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    InputLabelProps={this.state.formData.associated_nic==='' || this.state.formData.associated_nic===null ? {shrink:false} : {shrink:true} }
                                                                                    disabled={this.state.isStudentNoDisabled}
                                                                                    validators={['required']}
                                                                                    errorMessages={['This field is required']}
                                                                                />
                                                                            </Grid>
                                                                        ) : null}

                                                                        Email
                                                                        <Grid
                                                                            item
                                                                            xl={3}
                                                                            lg={3}
                                                                            md={3}
                                                                            sm={12}
                                                                            xs={12}
                                                                        >
                                                                            <TextValidator
                                                                                className="w-full mb-2"
                                                                                label="Email *"
                                                                                name="associated_email"
                                                                                onChange={(e) => {this.handleChange(e)}}
                                                                                value={this.state.formData.associated_email}
                                                                                fullWidth
                                                                                variant="outlined"
                                                                                size="small"
                                                                                validators={['required', 'matchRegexp:^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$',]}
                                                                                errorMessages={['This field is required' , 'Invalid email address']}
                                                                            />
                                                                        </Grid>

                                                                        Contact No
                                                                        <Grid
                                                                            item
                                                                            xl={3}
                                                                            lg={3}
                                                                            md={3}
                                                                            sm={12}
                                                                            xs={12}
                                                                        >
                                                                            <TextValidator
                                                                                className="w-full mb-2"
                                                                                label="Contact Number *"
                                                                                name="associated_contact_no"
                                                                                onChange={(e) => {this.handleChange(e)}}
                                                                                value={this.state.formData.associated_contact_no}
                                                                                fullWidth
                                                                                variant="outlined"
                                                                                size="small"
                                                                                validators={['required', 'matchRegexp:^\\d{10}$',]}
                                                                                errorMessages={['This field is required' , 'Invalid contact number']}
                                                                            />
                                                                        </Grid>

                                                                    </Grid>
                                                                )} */}
                                                            </Grid>
                                                        ) : null}

                                                        {/* Sube Header 3 - Contact Details */}
                                                        <Grid
                                                            item
                                                            xl={12}
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                            className="bg-light-primary border-radius-8 my-2 mb-4 py-3"
                                                        >
                                                            <small className=" text-primary font-medium text-15 px-2 py-3 w-full">
                                                                Contact Details
                                                            </small>
                                                        </Grid>

                                                        {/* {this.state.formData.associated_as != null && this.state.formData.associated_as != "" && ( */}
                                                        <Grid
                                                            item
                                                            container
                                                            spacing={1}
                                                            xl={12}
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                            flexDirection="row"
                                                            justifyContent="space-between"
                                                        >
                                                            {this.state.formData
                                                                .association_to_uni ===
                                                            'current' ? (
                                                                <>
                                                                    {/* NIC */}
                                                                    <Grid
                                                                        item
                                                                        xl={3}
                                                                        lg={3}
                                                                        md={3}
                                                                        sm={12}
                                                                        xs={12}
                                                                    >
                                                                        {/* <Autocomplete
                                                                            className="w-full mb-2"
                                                                            // options={this.state.employees}
                                                                            options={
                                                                                this
                                                                                    .state
                                                                                    .formData
                                                                                    ?.associated_as
                                                                                    ?.label ===
                                                                                'Student'
                                                                                    ? this
                                                                                          .state
                                                                                          .students
                                                                                    : this
                                                                                          .state
                                                                                          .all_employees
                                                                            }
                                                                            // getOptionLabel={(option) =>option?.employee_id ? option.employee_id : option.id}
                                                                            getOptionLabel={(
                                                                                option
                                                                            ) =>
                                                                                option.nic
                                                                            }
                                                                            getOptionSelected={(
                                                                                option,
                                                                                value
                                                                            ) =>
                                                                                option.id ===
                                                                                value.id
                                                                            }
                                                                            onChange={(
                                                                                event,
                                                                                value
                                                                            ) => {
                                                                                let formData =
                                                                                    this
                                                                                        .state
                                                                                        .formData

                                                                                if (
                                                                                    value
                                                                                ) {
                                                                                    console.log(
                                                                                        value
                                                                                    )
                                                                                    formData.associated_nic =
                                                                                        value.nic
                                                                                    formData.poc_nic =
                                                                                        value.nic

                                                                                    // formData.associated_contact_no = value.contact_no ? '0' + value.contact_no : ''
                                                                                    let contact =
                                                                                        value?.contact_no
                                                                                            ? value.contact_no
                                                                                            : value.mobile_no

                                                                                    // Remove hyphens from the contact number
                                                                                    let cleanedContactNo =
                                                                                        contact
                                                                                            ? contact.replace(
                                                                                                  /-/g,
                                                                                                  ''
                                                                                              )
                                                                                            : ''

                                                                                    // Check if the number doesn't start with '0'
                                                                                    if (
                                                                                        !/^0/.test(
                                                                                            cleanedContactNo
                                                                                        )
                                                                                    ) {
                                                                                        // Add '0' to the beginning
                                                                                        cleanedContactNo =
                                                                                            '0' +
                                                                                            cleanedContactNo
                                                                                    }

                                                                                    formData.associated_contact_no =
                                                                                        cleanedContactNo
                                                                                    formData.poc_contact_no =
                                                                                        cleanedContactNo

                                                                                    formData.associated_email =
                                                                                        value.email
                                                                                    formData.poc_email =
                                                                                        value.email

                                                                                    formData.poc_name =
                                                                                        value.full_name
                                                                                    formData.poc_designation =
                                                                                        value?.Designation?.designation

                                                                                    if (
                                                                                        value?.employee_id
                                                                                    ) {
                                                                                        formData.employee_id =
                                                                                            value?.employee_id
                                                                                        this.setState(
                                                                                            {
                                                                                                isEmpNoDisabled: false,
                                                                                            }
                                                                                        )
                                                                                    } else if (
                                                                                        value?.reg_no
                                                                                    ) {
                                                                                        formData.student_id =
                                                                                            value?.reg_no
                                                                                        this.setState(
                                                                                            {
                                                                                                isStudentNoDisabled: false,
                                                                                            }
                                                                                        )
                                                                                    } else {
                                                                                        formData.employee_id =
                                                                                            'N/A'
                                                                                        formData.student_id =
                                                                                            'N/A'
                                                                                        this.setState(
                                                                                            {
                                                                                                isEmpNoDisabled: true,
                                                                                            }
                                                                                        )
                                                                                        this.setState(
                                                                                            {
                                                                                                isStudentNoDisabled: true,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                    console.log(
                                                                                        formData,
                                                                                        'formData'
                                                                                    )
                                                                                } else {
                                                                                    console.log(
                                                                                        'deselected'
                                                                                    )
                                                                                    formData.associated_nic =
                                                                                        ''
                                                                                    formData.employee_id =
                                                                                        ''
                                                                                    formData.associated_email =
                                                                                        ''
                                                                                    formData.associated_contact_no =
                                                                                        ''
                                                                                    formData.poc_name =
                                                                                        ''
                                                                                    formData.poc_designation =
                                                                                        ''
                                                                                }
                                                                                this.setState(
                                                                                    {
                                                                                        formData:
                                                                                            formData,
                                                                                    }
                                                                                )
                                                                            }}
                                                                            freeSolo
                                                                            renderInput={(
                                                                                params
                                                                            ) => ( */}
                                                                        <TextValidator
                                                                            // {...params}
                                                                            label="NIC *"
                                                                            value={
                                                                                this
                                                                                    .state
                                                                                    .formData
                                                                                    .associated_nic ||
                                                                                ''
                                                                            }
                                                                            // value={this.state.formData.employee?.employee_id || this.state.formData.employee?.id || ''}
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            size="small"
                                                                            validators={[
                                                                                'required',
                                                                                'matchRegexp:^([0-9]{9}[x|X|v|V]|[0-9]{12})$',
                                                                            ]}
                                                                            errorMessages={[
                                                                                'This field is required',
                                                                                'Invalid NIC',
                                                                            ]}
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                const nic =
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                const formData =
                                                                                    this
                                                                                        .state
                                                                                        .formData
                                                                                const people =
                                                                                    [
                                                                                        ...this
                                                                                            .state
                                                                                            .all_employees,
                                                                                        ...this
                                                                                            .state
                                                                                            .students,
                                                                                    ]
                                                                                console.log(
                                                                                    nic
                                                                                )
                                                                                console.log(
                                                                                    people
                                                                                )
                                                                                if (
                                                                                    people.some(
                                                                                        (
                                                                                            person
                                                                                        ) =>
                                                                                            person.nic ===
                                                                                            nic
                                                                                    )
                                                                                ) {
                                                                                    const value =
                                                                                        people.find(
                                                                                            (
                                                                                                person
                                                                                            ) =>
                                                                                                person.nic ===
                                                                                                nic
                                                                                        )
                                                                                    console.log(
                                                                                        value
                                                                                    )
                                                                                    formData.associated_nic =
                                                                                        value.nic
                                                                                    formData.poc_nic =
                                                                                        value.nic

                                                                                    // formData.associated_contact_no = value.contact_no ? '0' + value.contact_no : ''
                                                                                    let contact =
                                                                                        value?.contact_no
                                                                                            ? value.contact_no
                                                                                            : value.mobile_no

                                                                                    // Remove hyphens from the contact number
                                                                                    let cleanedContactNo =
                                                                                        contact
                                                                                            ? contact.replace(
                                                                                                  /-/g,
                                                                                                  ''
                                                                                              )
                                                                                            : ''

                                                                                    // Check if the number doesn't start with '0'
                                                                                    if (
                                                                                        !/^0/.test(
                                                                                            cleanedContactNo
                                                                                        )
                                                                                    ) {
                                                                                        // Add '0' to the beginning
                                                                                        cleanedContactNo =
                                                                                            '0' +
                                                                                            cleanedContactNo
                                                                                    }

                                                                                    formData.associated_contact_no =
                                                                                        cleanedContactNo
                                                                                    formData.poc_contact_no =
                                                                                        cleanedContactNo

                                                                                    formData.associated_email =
                                                                                        value.email
                                                                                    formData.poc_email =
                                                                                        value.email

                                                                                    formData.poc_name =
                                                                                        value.full_name
                                                                                    formData.poc_designation =
                                                                                        value?.Designation?.designation

                                                                                    if (
                                                                                        value?.employee_id
                                                                                    ) {
                                                                                        formData.employee_id =
                                                                                            value?.employee_id
                                                                                        this.setState(
                                                                                            {
                                                                                                isEmpNoDisabled: false,
                                                                                            }
                                                                                        )
                                                                                    } else if (
                                                                                        value?.reg_no
                                                                                    ) {
                                                                                        formData.student_id =
                                                                                            value?.reg_no
                                                                                        this.setState(
                                                                                            {
                                                                                                isStudentNoDisabled: false,
                                                                                            }
                                                                                        )
                                                                                    } else {
                                                                                        formData.employee_id =
                                                                                            'N/A'
                                                                                        formData.student_id =
                                                                                            'N/A'
                                                                                        this.setState(
                                                                                            {
                                                                                                isEmpNoDisabled: true,
                                                                                            }
                                                                                        )
                                                                                        this.setState(
                                                                                            {
                                                                                                isStudentNoDisabled: true,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                    console.log(
                                                                                        formData,
                                                                                        'formData'
                                                                                    )
                                                                                } else {
                                                                                    console.log(
                                                                                        'deselected'
                                                                                    )
                                                                                    formData.associated_nic =
                                                                                        nic
                                                                                    formData.employee_id =
                                                                                        ''
                                                                                    formData.associated_email =
                                                                                        ''
                                                                                    formData.associated_contact_no =
                                                                                        ''
                                                                                    formData.poc_name =
                                                                                        ''
                                                                                    formData.poc_designation =
                                                                                        ''
                                                                                }
                                                                                this.setState(
                                                                                    {
                                                                                        formData:
                                                                                            formData,
                                                                                    }
                                                                                )
                                                                            }}
                                                                        />
                                                                        {/* )} */}
                                                                        {/* /> */}
                                                                    </Grid>

                                                                    {this.state
                                                                        .formData
                                                                        ?.associated_as !=
                                                                    'Student' ? (
                                                                        //  Employee No
                                                                        <Grid
                                                                            item
                                                                            // container
                                                                            xl={
                                                                                3
                                                                            }
                                                                            lg={
                                                                                3
                                                                            }
                                                                            md={
                                                                                3
                                                                            }
                                                                            sm={
                                                                                12
                                                                            }
                                                                            xs={
                                                                                12
                                                                            }
                                                                        >
                                                                            <TextValidator
                                                                                className="w-full mb-2"
                                                                                label="Employee No"
                                                                                name="employee_no"
                                                                                onChange={(
                                                                                    e
                                                                                ) => {
                                                                                    this.handleChange(
                                                                                        e
                                                                                    )
                                                                                }}
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .formData
                                                                                        .associated_nic ===
                                                                                        '' ||
                                                                                    this
                                                                                        .state
                                                                                        .formData
                                                                                        .associated_nic ===
                                                                                        null
                                                                                        ? ''
                                                                                        : this
                                                                                              .state
                                                                                              .formData
                                                                                              .employee_id
                                                                                }
                                                                                fullWidth
                                                                                variant="outlined"
                                                                                size="small"
                                                                                // InputLabelProps={this.state.isEmpNoDisabled ? {shrink:false} : {shrink:true} }
                                                                                InputLabelProps={
                                                                                    this
                                                                                        .state
                                                                                        .formData
                                                                                        .associated_nic ===
                                                                                        '' ||
                                                                                    this
                                                                                        .state
                                                                                        .formData
                                                                                        .associated_nic ===
                                                                                        null
                                                                                        ? {
                                                                                              shrink: false,
                                                                                          }
                                                                                        : {
                                                                                              shrink: true,
                                                                                          }
                                                                                }
                                                                                disabled={
                                                                                    this
                                                                                        .state
                                                                                        .isEmpNoDisabled
                                                                                }
                                                                                // validators={['required']}
                                                                                // errorMessages={['This field is required']}
                                                                            />
                                                                        </Grid>
                                                                    ) : this
                                                                          .state
                                                                          .formData
                                                                          ?.associated_as ===
                                                                      'Student' ? (
                                                                        // Student No
                                                                        <Grid
                                                                            item
                                                                            // container
                                                                            xl={
                                                                                3
                                                                            }
                                                                            lg={
                                                                                3
                                                                            }
                                                                            md={
                                                                                3
                                                                            }
                                                                            sm={
                                                                                12
                                                                            }
                                                                            xs={
                                                                                12
                                                                            }
                                                                        >
                                                                            <TextValidator
                                                                                className="w-full mb-2"
                                                                                label="Student No"
                                                                                name="student_no"
                                                                                onChange={(
                                                                                    e
                                                                                ) => {
                                                                                    this.handleChange(
                                                                                        e
                                                                                    )
                                                                                }}
                                                                                // value={this.state.formData.student_id}
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .formData
                                                                                        .associated_nic ===
                                                                                        '' ||
                                                                                    this
                                                                                        .state
                                                                                        .formData
                                                                                        .associated_nic ===
                                                                                        null
                                                                                        ? ''
                                                                                        : this
                                                                                              .state
                                                                                              .formData
                                                                                              .student_id
                                                                                }
                                                                                fullWidth
                                                                                variant="outlined"
                                                                                size="small"
                                                                                InputLabelProps={
                                                                                    this
                                                                                        .state
                                                                                        .formData
                                                                                        .associated_nic ===
                                                                                        '' ||
                                                                                    this
                                                                                        .state
                                                                                        .formData
                                                                                        .associated_nic ===
                                                                                        null
                                                                                        ? {
                                                                                              shrink: false,
                                                                                          }
                                                                                        : {
                                                                                              shrink: true,
                                                                                          }
                                                                                }
                                                                                disabled={
                                                                                    this
                                                                                        .state
                                                                                        .isStudentNoDisabled
                                                                                }
                                                                                validators={[
                                                                                    'required',
                                                                                ]}
                                                                                errorMessages={[
                                                                                    'This field is required',
                                                                                ]}
                                                                            />
                                                                        </Grid>
                                                                    ) : null}

                                                                    {/* Email */}
                                                                    <Grid
                                                                        item
                                                                        xl={3}
                                                                        lg={3}
                                                                        md={3}
                                                                        sm={12}
                                                                        xs={12}
                                                                    >
                                                                        <TextValidator
                                                                            className="w-full mb-2"
                                                                            label="Email *"
                                                                            name="associated_email"
                                                                            // onChange={(e) => {this.handleChange(e)}}
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                let data =
                                                                                    this
                                                                                        .state
                                                                                        .formData
                                                                                data.associated_email =
                                                                                    e.target.value
                                                                                data.poc_email =
                                                                                    e.target.value
                                                                                this.setState(
                                                                                    {
                                                                                        formData:
                                                                                            data,
                                                                                    }
                                                                                )
                                                                            }}
                                                                            value={
                                                                                this
                                                                                    .state
                                                                                    .formData
                                                                                    .associated_email
                                                                            }
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            size="small"
                                                                            validators={[
                                                                                'required',
                                                                                'matchRegexp:^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$',
                                                                            ]}
                                                                            errorMessages={[
                                                                                'This field is required',
                                                                                'Invalid email address',
                                                                            ]}
                                                                        />
                                                                    </Grid>

                                                                    {/* Contact No */}
                                                                    <Grid
                                                                        item
                                                                        xl={3}
                                                                        lg={3}
                                                                        md={3}
                                                                        sm={12}
                                                                        xs={12}
                                                                    >
                                                                        <TextValidator
                                                                            className="w-full mb-2"
                                                                            label="Contact Number *"
                                                                            name="associated_contact_no"
                                                                            // onChange={(e) => {this.handleChange(e)}}
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                let data =
                                                                                    this
                                                                                        .state
                                                                                        .formData
                                                                                data.associated_contact_no =
                                                                                    e.target.value
                                                                                data.poc_contact_no =
                                                                                    e.target.value
                                                                                this.setState(
                                                                                    {
                                                                                        formData:
                                                                                            data,
                                                                                    }
                                                                                )
                                                                            }}
                                                                            value={
                                                                                this
                                                                                    .state
                                                                                    .formData
                                                                                    .associated_contact_no
                                                                            }
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            size="small"
                                                                            validators={[
                                                                                'required',
                                                                                'matchRegexp:^\\d{10}$',
                                                                            ]}
                                                                            errorMessages={[
                                                                                'This field is required',
                                                                                'Invalid contact number',
                                                                            ]}
                                                                        />
                                                                    </Grid>
                                                                </>
                                                            ) : (
                                                                // if past associated or not associated
                                                                <>
                                                                    {/* NIC */}
                                                                    <Grid
                                                                        item
                                                                        xl={4}
                                                                        lg={4}
                                                                        md={4}
                                                                        sm={12}
                                                                        xs={12}
                                                                    >
                                                                        <TextValidator
                                                                            className="w-full mb-2"
                                                                            label="NIC *"
                                                                            name="poc_nic"
                                                                            // name="associated_nic"
                                                                            // onChange={(e) => {this.handleChange(e)}}
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                let data =
                                                                                    this
                                                                                        .state
                                                                                        .formData
                                                                                data.poc_nic =
                                                                                    e.target.value
                                                                                data.associated_nic =
                                                                                    e.target.value
                                                                                this.setState(
                                                                                    {
                                                                                        formData:
                                                                                            data,
                                                                                    }
                                                                                )
                                                                            }}
                                                                            value={
                                                                                this
                                                                                    .state
                                                                                    .formData
                                                                                    .poc_nic
                                                                            }
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            size="small"
                                                                            validators={[
                                                                                'required',
                                                                                'matchRegexp:^([0-9]{9}[x|X|v|V]|[0-9]{12})$',
                                                                            ]}
                                                                            errorMessages={[
                                                                                'This field is required',
                                                                                'Invalid NIC',
                                                                            ]}
                                                                        />
                                                                    </Grid>

                                                                    {/* Email */}
                                                                    <Grid
                                                                        item
                                                                        xl={4}
                                                                        lg={4}
                                                                        md={4}
                                                                        sm={12}
                                                                        xs={12}
                                                                    >
                                                                        <TextValidator
                                                                            className="w-full mb-2"
                                                                            label="Email *"
                                                                            // name="associated_email"
                                                                            // onChange={(e) => {this.handleChange(e)}}
                                                                            name="poc_email"
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                let data =
                                                                                    this
                                                                                        .state
                                                                                        .formData
                                                                                data.poc_email =
                                                                                    e.target.value
                                                                                data.associated_email =
                                                                                    e.target.value
                                                                                this.setState(
                                                                                    {
                                                                                        formData:
                                                                                            data,
                                                                                    }
                                                                                )
                                                                            }}
                                                                            value={
                                                                                this
                                                                                    .state
                                                                                    .formData
                                                                                    .poc_email
                                                                            }
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            size="small"
                                                                            validators={[
                                                                                'required',
                                                                                'matchRegexp:^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$',
                                                                            ]}
                                                                            errorMessages={[
                                                                                'This field is required',
                                                                                'Invalid email address',
                                                                            ]}
                                                                        />
                                                                    </Grid>

                                                                    {/* Contact No */}
                                                                    <Grid
                                                                        item
                                                                        xl={4}
                                                                        lg={4}
                                                                        md={4}
                                                                        sm={12}
                                                                        xs={12}
                                                                    >
                                                                        <TextValidator
                                                                            className="w-full mb-2"
                                                                            label="Contact Number *"
                                                                            // name="associated_contact_no"
                                                                            // onChange={(e) => {this.handleChange(e)}}
                                                                            name="poc_contact_no"
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                let data =
                                                                                    this
                                                                                        .state
                                                                                        .formData
                                                                                data.poc_contact_no =
                                                                                    e.target.value
                                                                                data.associated_contact_no =
                                                                                    e.target.value
                                                                                this.setState(
                                                                                    {
                                                                                        formData:
                                                                                            data,
                                                                                    }
                                                                                )
                                                                            }}
                                                                            value={
                                                                                this
                                                                                    .state
                                                                                    .formData
                                                                                    .poc_contact_no
                                                                            }
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            size="small"
                                                                            validators={[
                                                                                'required',
                                                                                'matchRegexp:^\\d{10}$',
                                                                            ]}
                                                                            errorMessages={[
                                                                                'This field is required',
                                                                                'Invalid contact number',
                                                                            ]}
                                                                        />
                                                                    </Grid>
                                                                </>
                                                            )}

                                                            {/* Salutation */}
                                                            {this.state.formData
                                                                .associated_as !=
                                                                'Student' && (
                                                                <Grid
                                                                    item
                                                                    xl={2}
                                                                    lg={2}
                                                                    md={2}
                                                                    sm={2}
                                                                    xs={12}
                                                                >
                                                                    <Autocomplete
                                                                        className="w-full mb-2"
                                                                        options={
                                                                            appConst.salutation
                                                                        }
                                                                        getOptionLabel={(
                                                                            option
                                                                        ) =>
                                                                            option.label
                                                                        }
                                                                        getOptionSelected={(
                                                                            option,
                                                                            value
                                                                        ) =>
                                                                            option.value ===
                                                                            value.value
                                                                        }
                                                                        onChange={(
                                                                            event,
                                                                            value
                                                                        ) => {
                                                                            let formData =
                                                                                this
                                                                                    .state
                                                                                    .formData
                                                                            formData.salutation =
                                                                                value.value
                                                                            console.log(
                                                                                formData,
                                                                                'formData'
                                                                            )
                                                                            this.setState(
                                                                                {
                                                                                    formData:
                                                                                        formData,
                                                                                }
                                                                            )
                                                                        }}
                                                                        renderInput={(
                                                                            params
                                                                        ) => (
                                                                            <TextValidator
                                                                                {...params}
                                                                                label="Salutation *"
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .formData
                                                                                        .salutation
                                                                                }
                                                                                fullWidth
                                                                                variant="outlined"
                                                                                size="small"
                                                                                validators={[
                                                                                    'required',
                                                                                ]}
                                                                                errorMessages={[
                                                                                    'This field is required',
                                                                                ]}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            )}

                                                            {/* Point of Contact */}
                                                            <Grid
                                                                item
                                                                xl={5}
                                                                lg={5}
                                                                md={5}
                                                                sm={5}
                                                                xs={12}
                                                            >
                                                                <TextValidator
                                                                    className="w-full mb-2"
                                                                    label="Name of the point of contact *"
                                                                    name="poc_name"
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        this.handleChange(
                                                                            e
                                                                        )
                                                                    }}
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .formData
                                                                            .poc_name
                                                                    }
                                                                    // value={this.state.association_to_uni === 'current' && (this.state.formData.associated_nic != '' && this.state.formData.associated_nic != null) ? this.state.formData.poc_name : ''}
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    size="small"
                                                                    validators={[
                                                                        'required',
                                                                    ]}
                                                                    errorMessages={[
                                                                        'This field is required',
                                                                    ]}
                                                                />
                                                            </Grid>

                                                            {/* Designation of Contact */}
                                                            {this.state.formData
                                                                ?.associated_as !=
                                                                'Student' && (
                                                                <Grid
                                                                    item
                                                                    xl={5}
                                                                    lg={5}
                                                                    md={5}
                                                                    sm={12}
                                                                    xs={12}
                                                                >
                                                                    <TextValidator
                                                                        className="w-full mb-2"
                                                                        label="Designation of the contact *"
                                                                        name="poc_designation"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            this.handleChange(
                                                                                e
                                                                            )
                                                                        }}
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .formData
                                                                                .poc_designation
                                                                        }
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        size="small"
                                                                        validators={[
                                                                            'required',
                                                                        ]}
                                                                        errorMessages={[
                                                                            'This field is required',
                                                                        ]}
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                        {/* )} */}

                                                        {/* Sub Header 4 - Other Details */}
                                                        <Grid
                                                            item
                                                            xl={12}
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                            className="bg-light-primary border-radius-8 my-2 mb-4 py-3"
                                                        >
                                                            <small className=" text-primary font-medium text-15 px-2 py-3 w-full">
                                                                Other Details
                                                            </small>
                                                        </Grid>

                                                        {/* No of Participating Members */}
                                                        <Grid
                                                            item
                                                            xl={12}
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <TextValidator
                                                                className="w-full mb-2"
                                                                label="Number of Team Members Participating *"
                                                                name="team_participants"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    this.handleChange(
                                                                        e
                                                                    )
                                                                }}
                                                                value={
                                                                    this.state
                                                                        .formData
                                                                        .team_participants
                                                                }
                                                                type="number"
                                                                fullWidth
                                                                variant="outlined"
                                                                size="small"
                                                                validators={[
                                                                    'required',
                                                                    'minNumber:1',
                                                                ]}
                                                                errorMessages={[
                                                                    'This field is required',
                                                                    'Please enter an amount greater than 0',
                                                                ]}
                                                            />
                                                        </Grid>

                                                        {/* Sponsors - Radio buttons */}
                                                        <Grid
                                                            item
                                                            xl={12}
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                            className="mt-2"
                                                        >
                                                            <FormLabel
                                                                style={{
                                                                    paddingInlineEnd: 15,
                                                                }}
                                                            >
                                                                Sponsors if any
                                                            </FormLabel>

                                                            <RadioGroup
                                                                row
                                                                className="mb-2"
                                                            >
                                                                <FormControlLabel
                                                                    value="yes"
                                                                    onChange={() => {
                                                                        let formData =
                                                                            this
                                                                                .state
                                                                                .formData
                                                                        formData.is_sponsors = true
                                                                        this.setState(
                                                                            {
                                                                                formData,
                                                                            }
                                                                        )
                                                                    }}
                                                                    control={
                                                                        <Radio color="primary" />
                                                                    }
                                                                    label="Yes"
                                                                    labelPlacement="yes"
                                                                />

                                                                <FormControlLabel
                                                                    value="no"
                                                                    onChange={() => {
                                                                        let formData =
                                                                            this
                                                                                .state
                                                                                .formData
                                                                        formData.is_sponsors = false
                                                                        this.setState(
                                                                            {
                                                                                formData,
                                                                            }
                                                                        )
                                                                    }}
                                                                    control={
                                                                        <Radio color="primary" />
                                                                    }
                                                                    label="No"
                                                                    labelPlacement="no"
                                                                />
                                                            </RadioGroup>
                                                        </Grid>

                                                        {/* If has sponsors - TextArea */}
                                                        {this.state.formData
                                                            .is_sponsors ? (
                                                            <Grid
                                                                item
                                                                xl={12}
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextValidator
                                                                    className="w-full mb-2"
                                                                    label="Sponsor Details *"
                                                                    name="sponsor_details"
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        this.handleChange(
                                                                            e
                                                                        )
                                                                    }}
                                                                    multiline={
                                                                        true
                                                                    }
                                                                    rows={4}
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .formData
                                                                            .sponsor_details
                                                                    }
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    size="small"
                                                                    validators={[
                                                                        'required',
                                                                    ]}
                                                                    errorMessages={[
                                                                        'This field is required',
                                                                    ]}
                                                                />
                                                            </Grid>
                                                        ) : null}

                                                        {/* Vehicle Reg No */}
                                                        <Grid
                                                            item
                                                            xl={12}
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                            className="my-4"
                                                        >
                                                            <FormLabel
                                                                style={{
                                                                    paddingInlineEnd: 15,
                                                                }}
                                                            >
                                                                Registration
                                                                numbers of the
                                                                vehicles
                                                                required to
                                                                enter the
                                                                premises :
                                                            </FormLabel>
                                                        </Grid>

                                                        {this.state.vehicles.map(
                                                            (item, i) => (
                                                                <Grid
                                                                    key={i}
                                                                    container
                                                                    spacing={2}
                                                                    item
                                                                    xl={12}
                                                                    lg={12}
                                                                    md={12}
                                                                    sm={12}
                                                                    xs={12}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xl={8}
                                                                        lg={8}
                                                                        md={8}
                                                                        sm={8}
                                                                        xs={8}
                                                                    >
                                                                        <TextValidator
                                                                            className="w-full"
                                                                            label="Registration No"
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                let data =
                                                                                    this
                                                                                        .state
                                                                                        .vehicles
                                                                                data[
                                                                                    i
                                                                                ].reg_no =
                                                                                    e.target.value
                                                                                this.setState(
                                                                                    {
                                                                                        vehicles:
                                                                                            data,
                                                                                    }
                                                                                )
                                                                            }}
                                                                            name="vehicle_reg"
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="small"
                                                                            fullWidth
                                                                            type="text"
                                                                            value={
                                                                                this
                                                                                    .state
                                                                                    .vehicles[
                                                                                    i
                                                                                ]
                                                                                    .reg
                                                                            }
                                                                        />
                                                                    </Grid>

                                                                    {/* Add button */}
                                                                    {i ===
                                                                        this
                                                                            .state
                                                                            .vehicles
                                                                            .length -
                                                                            1 && (
                                                                        <Grid
                                                                            className="block justify-center item-start"
                                                                            item
                                                                            container
                                                                            xl={
                                                                                1
                                                                            }
                                                                            lg={
                                                                                1
                                                                            }
                                                                            md={
                                                                                1
                                                                            }
                                                                            sm={
                                                                                1
                                                                            }
                                                                            xs={
                                                                                1
                                                                            }
                                                                            flexDirection="row"
                                                                        >
                                                                            <Tooltip title="Add Vehicle">
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        this.addNewVehicle(
                                                                                            i
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    <Icon color="primary">
                                                                                        add
                                                                                    </Icon>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    )}

                                                                    {/* Delete Button */}
                                                                    {i ===
                                                                        this
                                                                            .state
                                                                            .vehicles
                                                                            .length -
                                                                            1 && (
                                                                        <Grid
                                                                            className="block justify-center item-start"
                                                                            item
                                                                            container
                                                                            xl={
                                                                                1
                                                                            }
                                                                            lg={
                                                                                1
                                                                            }
                                                                            md={
                                                                                1
                                                                            }
                                                                            sm={
                                                                                1
                                                                            }
                                                                            xs={
                                                                                1
                                                                            }
                                                                            flexDirection="row"
                                                                        >
                                                                            <Tooltip title="Remove Vehicle">
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        this.removeItem(
                                                                                            i
                                                                                        )
                                                                                        this.setState(
                                                                                            {
                                                                                                emptyVehicle: false,
                                                                                            }
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    <Icon color="error">
                                                                                        delete
                                                                                    </Icon>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            )
                                                        )}

                                                        {/* Cancel & Save buttons */}
                                                        <Grid
                                                            item
                                                            container
                                                            xl={12}
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                            align="right"
                                                            className="mt-15"
                                                            // style={{border:"2px solid red"}}
                                                        >
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                justifyContent="flex-end"
                                                            >
                                                                {/* Cancel btn */}
                                                                <Grid item>
                                                                    <Button
                                                                        onClick={this.dialogClose.bind(
                                                                            this
                                                                        )}
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        startIcon="exit_to_app"
                                                                        style={{
                                                                            paddingRight: 22,
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                </Grid>

                                                                {/* Back btn */}
                                                                <Grid item>
                                                                    <Button
                                                                        className="bg-secondary text-white"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            this.changeStepper_Step(
                                                                                1
                                                                            )
                                                                        }}
                                                                        startIcon="arrow_left"
                                                                    >
                                                                        Back
                                                                    </Button>
                                                                </Grid>

                                                                {/* Save btn */}
                                                                <Grid item>
                                                                    <Button
                                                                        color="primary"
                                                                        startIcon="save"
                                                                        style={{
                                                                            paddingInlineEnd: 50,
                                                                        }}
                                                                        type="submit"
                                                                    >
                                                                        Book
                                                                        Resource
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </ValidatorForm>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </DialogContent>
                        </Dialog>

                        {/* Dialog box -> View Resource*/}
                        <ViewResource
                            data={this.state.viewformData}
                            open={this.state.viewMode}
                            handleClose={this.dialogClose}
                        />

                        {/* Dialog Box - Registration */}
                        {this.state.registrationMode && (
                            <Dialog
                                open={this.state.registrationMode}
                                fullWidth
                                maxWidth="md"
                            >
                                <DialogTitle
                                    className="text-center"
                                    id="alert-dialog-title"
                                    onClose={this.dialogClose}
                                ></DialogTitle>

                                <DialogContent>
                                    <Grid
                                        container
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={2}
                                        xs={12}
                                        flexDirection="row"
                                    >
                                        <Typography
                                            className="font-semibold mt-5 mb-2"
                                            variant="h7"
                                        >
                                            {`Thank you for selecting University of Visual Performing Arts Resources for your purpose.`}
                                            {/* {'\nYour Temporary Account will be created for you to complete rest of the steps in the temporary profile.\n\n Please go to your email inbox & complete the account creation.'} */}
                                        </Typography>
                                        <br />
                                        <br />

                                        <Typography
                                            className="font-semibold mt-5 mb-2"
                                            variant="h7"
                                        >
                                            {`Your Reservation ID is "${reservationId}"`}
                                        </Typography>
                                        <br />
                                        <br />

                                        <Typography
                                            className="font-semibold mt-5 mb-5"
                                            variant="h7"
                                        >
                                            {` Your Temporary Account will be created for you to complete rest of the steps in the temporary profile.
                                            Please go to your email inbox & complete the account creation.`}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        this.state
                                                            .confirmation_checkbox
                                                    }
                                                    onChange={() => {
                                                        this.setState({
                                                            confirmation_checkbox:
                                                                !this.state
                                                                    .confirmation_checkbox,
                                                        })
                                                    }}
                                                    name="chekbox_confirm"
                                                    color="primary"
                                                />
                                            }
                                            label="I understand that this is a temporary account and I will do the next steps in account creation."
                                        />
                                    </Grid>

                                    {/* OK & Cancel buttons */}
                                    <Grid
                                        container
                                        lg={12}
                                        md={12}
                                        sm={2}
                                        xs={12}
                                        align="right"
                                    >
                                        <Grid
                                            container
                                            spacing={2}
                                            justifyContent="flex-end"
                                        >
                                            {/* Cancel btn */}
                                            <Grid item>
                                                <Button
                                                    onClick={this.dialogClose.bind(
                                                        this
                                                    )}
                                                    variant="outlined"
                                                    color="primary"
                                                    style={{ paddingRight: 22 }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>

                                            {/* Ok btn */}
                                            <Grid item>
                                                <Button
                                                    onClick={this.createTempAccount.bind(
                                                        this
                                                    )}
                                                    color="primary"
                                                    style={{
                                                        paddingInlineEnd: 50,
                                                    }}
                                                    disabled={
                                                        this.state
                                                            .confirmation_checkbox
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    OK
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                            </Dialog>
                        )}

                        {/* Dialog Box - resource calendar */}
                        <Dialog
                            open={this.state.calendarMode}
                            fullWidth
                            maxWidth="md"
                            onClose={this.dialogClose}
                        >
                            <DialogTitle
                                className="text-center"
                                id="alert-dialog-title"
                                onClose={this.dialogClose}
                            >
                                {
                                    this.state.data.find(
                                        (data) =>
                                            data.id === this.state.selectedId
                                    )?.name
                                }
                            </DialogTitle>
                            <DialogContent>
                                {this.state.isLoading ? (
                                    <Grid className="justify-center text-center w-full pt-12">
                                        <CircularProgress size={30} />
                                    </Grid>
                                ) : (
                                    <CalendarDialog
                                        calender_data={
                                            this.state.resource_calender_data
                                        }
                                    />
                                )}
                            </DialogContent>
                        </Dialog>
                    </LoonsCard>
                </MainContainer>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {'Do you have an account?'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            If you already have an account, please proceed to
                            login. Otherwise, kindly create a new account.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() =>
                                (window.location.href = '/session/signin')
                            }
                            color="primary"
                        >
                            Login
                        </Button>
                        <Button
                            onClick={() => {
                                this.setState({
                                    open: false,
                                    showForm: true,
                                })
                            }}
                            color="primary"
                            autoFocus
                        >
                            Create new Account
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.state.showForm && (
                    <Dialog
                        PaperProps={{
                            style: {
                                width: 600,
                            },
                        }}
                        open={this.state.showForm}
                        onClose={this.handleClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <ValidatorForm
                            onSubmit={() => {
                                this.formSubmit()
                            }}
                            onError={() => null}
                        >
                            <DialogTitle id="form-dialog-title">
                                {this.state.passwordSetup
                                    ? 'Create a Password'
                                    : 'Create an Account'}
                            </DialogTitle>
                            <DialogContent>
                                {this.state.passwordSetup ? (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography>
                                                {this.state.accountData.email}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextValidator
                                                className="mb-3 w-full"
                                                label="Create Password"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.handleChangePassword}
                                                name="password"
                                                type={this.state.textType}
                                                value={this.state.passData.password}
                                                validators={[
                                                    `matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$`,
                                                    'required',
                                                ]}
                                                errorMessages={[
                                                    'Please enter a valid password (at least 8 characters, containing at least one uppercase letter, one lowercase letter, and one digit)',
                                                    'This field is required',
                                                ]}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                size="small"
                                                                disableRipple={true}
                                                                type="button"
                                                                onClick={() => {
                                                                    this.state
                                                                        .textType ==
                                                                    'password'
                                                                        ? this.setTextType(
                                                                            'text'
                                                                        )
                                                                        : this.setTextType(
                                                                            'password'
                                                                        )
                                                                }}
                                                            >
                                                                {this.state
                                                                    .textType ==
                                                                'password' ? (
                                                                    <EyeOff />
                                                                ) : (
                                                                    <EyeOn />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <>
                                        {!this.state.accountCreateLoaing ? (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextValidator
                                                        className="w-full"
                                                        type="email"
                                                        label="Email"
                                                        variant="outlined"
                                                        size="small"
                                                        name="email"
                                                        value={
                                                            this.state.accountData
                                                                ?.email
                                                        }
                                                        onChange={(e) => {
                                                            this.handleChange(e)
                                                        }}
                                                        onBlur={(e) => {
                                                            this.checkEmail()
                                                        }}
                                                        validators={[
                                                            'required',
                                                            'isEmail',
                                                        ]}
                                                        errorMessages={[
                                                            'This field is required',
                                                            'Email is not valid',
                                                        ]}
                                                    />
                                                    {this.state.email_validity ? (
                                                        <div
                                                            className={
                                                                this.state
                                                                    .email_validity ==
                                                                'Valid Email'
                                                                    ? 'bg-green text-white'
                                                                    : this.state
                                                                        .email_validity ==
                                                                    'This Email is Already Taken!'
                                                                    ? 'bg-error text-white'
                                                                    : 'bg-default'
                                                            }
                                                            style={{
                                                                borderRadius: '5px',
                                                                padding: '5px',
                                                                paddingLeft: '15px',
                                                                marginTop: '10px',
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .email_validity
                                                            }
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Alert severity="info">
                                                        Please ensure that you enter
                                                        a valid email address.
                                                        Remember, this email will be
                                                        used within our EMS system.
                                                    </Alert>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <TextValidator
                                                        className="w-full"
                                                        type="text"
                                                        label="First Name"
                                                        variant="outlined"
                                                        size="small"
                                                        name="first_name"
                                                        value={
                                                            this.state.accountData
                                                                ?.first_name
                                                                ? this.state
                                                                    .accountData
                                                                    ?.first_name
                                                                : ''
                                                        }
                                                        onChange={(e) => {
                                                            this.handleChange(e)
                                                        }}
                                                        validators={[
                                                            'required',
                                                            'matchRegexp:^[^0-9]+$',
                                                        ]}
                                                        errorMessages={[
                                                            'This Field is required!',
                                                            'This Field Do Not Accept Numbers!',
                                                        ]}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <TextValidator
                                                        className="w-full"
                                                        type="text"
                                                        label="Surname"
                                                        variant="outlined"
                                                        size="small"
                                                        name="sur_name"
                                                        value={
                                                            this.state.accountData
                                                                ?.sur_name
                                                                ? this.state
                                                                    .accountData
                                                                    ?.sur_name
                                                                : ''
                                                        }
                                                        onChange={(e) => {
                                                            this.handleChange(e)
                                                        }}
                                                        validators={[
                                                            'matchRegexp:^[^0-9]+$',
                                                        ]}
                                                        errorMessages={[
                                                            'This Field Do Not Accept Numbers!',
                                                        ]}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextValidator
                                                        className="w-full"
                                                        type="text"
                                                        label="Full Name"
                                                        variant="outlined"
                                                        size="small"
                                                        name="full_name"
                                                        value={
                                                            this.state.accountData
                                                                ?.full_name
                                                                ? this.state
                                                                    .accountData
                                                                    ?.full_name
                                                                : ''
                                                        }
                                                        onChange={(e) => {
                                                            this.handleChange(e)
                                                        }}
                                                        validators={[
                                                            'required',
                                                            'matchRegexp:^[^0-9]+$',
                                                        ]}
                                                        errorMessages={[
                                                            'This Field is required!',
                                                            'This Field Do Not Accept Numbers!',
                                                        ]}
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <TextValidator
                                                        className="w-full"
                                                        type="text"
                                                        label="NIC *"
                                                        variant="outlined"
                                                        size="small"
                                                        name="nic"
                                                        value={
                                                            this.state.accountData?.nic
                                                                ? this.state
                                                                    .accountData?.nic
                                                                : ''
                                                        }
                                                        onChange={(e) => {
                                                            this.handleChange(e)
                                                        }}
                                                        validators={[
                                                            'required',
                                                            'matchRegexp:((^|, )([0-9]{9}(V|v)|(1|2)[0-9]{11}))+$',
                                                        ]}
                                                        errorMessages={[
                                                            'This field is required',
                                                            'Please enter a valid NIC Number(Eg:991234567V or 991234567v or 199981234567)',
                                                        ]}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <TextValidator
                                                        className="w-full"
                                                        type="text"
                                                        label="Telephone no: Mobile"
                                                        variant="outlined"
                                                        size="small"
                                                        name="mobile_no"
                                                        value={
                                                            this.state.accountData
                                                                ?.mobile_no
                                                                ? this.state
                                                                    .accountData
                                                                    ?.mobile_no
                                                                : ''
                                                        }
                                                        onChange={(e) => {
                                                            this.handleChange(e)
                                                        }}
                                                        validators={[
                                                            'required',
                                                            'matchRegexp:^[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$',
                                                        ]}
                                                        errorMessages={[
                                                            'This field is required',
                                                            'Please enter valid mobile number E.g.(0771231231)',
                                                        ]}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid className="justify-center text-center w-full pt-12">
                                                <CircularProgress size={30} />
                                            </Grid>
                                        )}
                                    </>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleClose} color="primary">
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    progress={this.state.passwordBtn}
                                    startIcon="save"
                                    color="primary"
                                >
                                    {this.state.passwordSetup
                                        ? 'Create Password'
                                        : 'Create Account'}
                                </Button>
                            </DialogActions>
                        </ValidatorForm>
                    </Dialog>
                )}
                {this.state.showSuccessMsg && (
                    <Dialog
                        PaperProps={{
                            style: {
                                width: 700,
                            },
                        }}
                        open={this.state.showSuccessMsg}
                        onClose={this.handleClose}
                    >
                        <DialogContent>
                            <div className="bg-light-primary py-3">
                                {/* check_circle_outline */}
                                <div className="flex justify-center text-center">
                                    <Icon className="text-green text-48 font-medium">
                                        check_circle_outline
                                    </Icon>
                                </div>
                                <div className="flex justify-center text-center">
                                    <Typography
                                        variant="h4"
                                        component="h4"
                                        className="mt-5 text-primary capitalize font-bold"
                                        style={{ letterSpacing: 3 }}
                                    >
                                        CONGRATULATIONS!
                                    </Typography>
                                </div>
                                <div className="block justify-center text-center px-10">
                                    <Typography
                                        className="my-2 text-green font-medium text-16"
                                        style={{ letterSpacing: 2 }}
                                    >
                                        Your Account has been Successfully Created
                                    </Typography>
                                    <Typography
                                        className="my-2 text-black font-medium text-14"
                                        style={{ letterSpacing: 2 }}
                                    >
                                        You are now ready to access the system and
                                        proceed with your job application. By
                                        clicking “Ok” button, you will be redirected
                                        to the login page. Please use the email
                                        address and password provided during the
                                        registration to login.{' '}
                                    </Typography>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() =>
                                    (window.location.href = '/session/signin')
                                }
                                color="primary"
                            >
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
                <LoonsSnackbar
                    open={this.state.alert}
                    onClose={() => {
                        this.setState({ alert: false })
                    }}
                    message={this.state.message}
                    autoHideDuration={5000}
                    severity={this.state.severity}
                    elevation={2}
                    variant="filled"
                />
            </Fragment>
        )
    }
}

// export default AllResources
export default withStyles()(AllResources)

export const CalendarDialog = ({ calender_data }) => {
    console.log('calendarDialog', calender_data)
    const events =
        calender_data?.map((data) => ({
            id: data.id,
            title: data.event,
            start: new Date(`${data.start_date}T${data.start_time}`), // Concatenate start date and time and convert to Date object
            end: new Date(`${data.end_date}T${data.end_time}`),
            backgroundColor: '#0f8be0',
        })) || []
    return (
        <>
            <FullCalendar
                // key={selectedEvent}
                plugins={[
                    googleCalendarPlugin,
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin,
                ]}
                initialView={'dayGridMonth'}
                title="My Leaves Calendar"
                headerToolbar={{
                    start: 'today prev,next',
                    center: 'title',
                    end: 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                buttonText={{
                    today: 'Today',
                    month: 'Month',
                    week: 'Week',
                    day: 'Day',
                    list: 'List',
                }}
                // selectable={true}
                height={'85vh'}
                googleCalendarApiKey={appConst.GOOGLE_CALENDAR_API_KEY}
                eventSources={[
                    {
                        googleCalendarId:
                            'en.lk#holiday@group.v.calendar.google.com',
                        backgroundColor: 'green',
                        borderColor: 'green',
                    },
                ]}
                events={events}
                eventClick={(info) => {
                    info.jsEvent.preventDefault()
                    //     if (info.event.url) return
                    //     setSelectedEvent(
                    //         events.find(
                    //             (event) =>
                    //                 event.title === info.event.title &&
                    //                 event.start ===
                    //                     moment(info.event.start).format(
                    //                         'YYYY-MM-DD'
                    //                     )
                    //         )
                    //     )
                    //     console.log(info)
                }}
                eventContent={(info) => {
                    // console.log(info)
                    const { url, title } = info.event
                    //google holiday events
                    if (url) {
                        return <div>{title}</div>
                    }
                    //other events

                    const event = calender_data.find(
                        (data) => data.id === Number(info.event.id)
                    )

                    const HtmlTooltip = withStyles((theme) => ({
                        tooltip: {
                            backgroundColor: '#f5f5f9',
                            color: 'rgba(0, 0, 0, 0.87)',
                            // maxWidth: 220,
                            border: '1px solid #dadde9',
                            fontSize: '0.8rem',
                            maxWidth: 'none',
                            width: 'auto',
                        },
                        arrow: {
                            color: '#f5f5f9',
                        },
                    }))(Tooltip)

                    return (
                        <HtmlTooltip
                            arrow
                            placement="top"
                            title={
                                <div
                                    className="custom-tooltip bg-white p-4 w-full"
                                    style={{ width: 'fit-content' }}
                                >
                                    <h4 className="capitalize text-center">
                                        {event.event}
                                    </h4>
                                    <p
                                        className="text-primary p-1"
                                        style={{ whiteSpace: 'nowrap' }}
                                    >
                                        🟦 : {event.start_date}{' '}
                                        {event.end_date === event.start_date
                                            ? ''
                                            : '- ' + event.end_date}{' '}
                                        🕖{' '}
                                        {moment(
                                            event.start_time,
                                            'HH:mm:ss'
                                        ).format('hh.mm A')}{' '}
                                        -{' '}
                                        {moment(
                                            event.end_time,
                                            'HH:mm:ss'
                                        ).format('hh.mm A')}
                                    </p>
                                    {/* <p>
                                        <span>Contact Person :</span>{' '}
                                        {event.poc_name}
                                    </p>
                                    <p>
                                        <span>Contact Number :</span>{' '}
                                        {event.poc_contact_no}
                                    </p>
                                    <p>
                                        <span>Email :</span> {event.poc_email}
                                    </p> */}
                                </div>
                            }
                        >
                            <div
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: '#0f8be0',
                                    color: 'white',
                                    borderRadius: '3px',
                                    width: '100%',
                                }}
                            >
                                {title}
                            </div>
                        </HtmlTooltip>
                    )
                }}
            />
            <style>
                {`.fc-day-sun, .fc-day-sat  { background-color: #FFAF3826; }
                        .custom-tooltip p {
                            font-weight:500;
                        }
                        .custom-tooltip span {
                            font-weight:700;
                        }
                        `}
            </style>
        </>
    )
}
