import React from "react";
import {authRoles} from '../../../auth/authRoles';

const ConfigureTotalCreditRoutes = [
    {
        path: '/examinations/configure_total_credit',
        component: React.lazy(() => import ('./ConfigureTotalCredit')),
        authRoles: authRoles.ExaminationView,
    },
]

export default ConfigureTotalCreditRoutes;