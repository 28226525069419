import React, { Component, Fragment, useState } from 'react'
import { withStyles } from '@material-ui/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {
    Card,
    Icon,
    IconButton,
    Grid,
    FormControl,
    FormLabel,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormHelperText,
    Checkbox,
    TextField,
    Fab,
    Tooltip,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'

import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import 'date-fns'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { themeColors } from 'app/components/MatxTheme/themeColors'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
    LoonsTable,
    DatePicker,
    FilePicker,
    Button,
    ExcelToTable,
    LoonsSnackbar,
    LoonsSwitch,
    CardTitle,
    SubTitle,
    LoonsCard,
    MainContainer,
    DocumentLoader
} from 'app/components/LoonsLabComponents'
import * as appConst from '../../../../../appconst'
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment'
import EmployeeService from 'app/services/EmployeeService'
import localStorageService from 'app/services/localStorageService'

const styleSheet = (theme) => ({})

class BasicInformation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            alert: false,
            message: '',
            severity: 'success',
            formData: {
                name_with_initials: ""
            },
            files1: { fileList: [] },
            files2: { fileList: [] },
            files3: { fileList: [] },
            links: [{
                active: true,
            }],
            user: {},

            helperText:"",
            error:false,
        }
    }

    // Setting up Filter Data
    async setDropdownFormData(key, val) {
        //Set state filter data with user entered

        let formData = this.state.formData
        formData[key] = val.label

        if (key == "permanent_address_province") {
            if (formData.same_as_permanant_address) {
                formData["postal_address_province"] = val.label;
            }
        }

        if (key == "permanent_address_district") {
            if (formData.same_as_permanant_address) {
                formData["postal_address_district"] = val.label;
            }
        }

        this.setState({ formData }, () => {
            console.log(this.state.formData)
        })
    }

    async saveData() {

        var data = this.state.formData;
        var links = this.state.links;
        var newLinks = [];
        var files = this.state.files1.fileList;
        var employee_id = this.state.user.id;

        var newFormData = new FormData();

        if (data.name_with_initials) {
            newFormData.append('name_with_initials', data.name_with_initials);
        }
        if (data.first_name) {
            newFormData.append('first_name', data.first_name);
        }
        if (data.middle_name) {
            newFormData.append('middle_name', data.middle_name);
        }
        if (data.sur_name) {
            newFormData.append('sur_name', data.sur_name);
        }
        if (data.other_name) {
            newFormData.append('other_name', data.other_name);
        }
        if (data.full_name) {
            newFormData.append('full_name', data.full_name);
        }
        if (data.mobile_no) {
            newFormData.append('mobile_no', data.mobile_no);
        }
        if (data.contact_no) {
            newFormData.append('contact_no', data.contact_no);
        }
        if (data.nic) {
            newFormData.append('nic', data.nic);
        }

        newFormData.append('email', data.email);

        if (data.date_of_birth) {
            newFormData.append('date_of_birth', data.date_of_birth);
        }
        if (data.gender) {
            newFormData.append('gender', data.gender);
        }
        if (data.age) {
            newFormData.append('age', data.age);
        }
        if (data.citizenship) {
            newFormData.append('citizenship', data.citizenship);
        }

        if (data.other_citizenship) {
            newFormData.append('other_citizenship', data.other_citizenship);
        }

        if (data.permanent_address_no) {
            newFormData.append('permanent_address_no', data.permanent_address_no);
        }
        if (data.permanent_address_street) {
            newFormData.append('permanent_address_street', data.permanent_address_street);
        }
        if (data.permanent_address_city) {
            newFormData.append('permanent_address_city', data.permanent_address_city);
        }
        if (data.permanent_address_town) {
            newFormData.append('permanent_address_town', data.permanent_address_town);
        }
        if (data.permanent_address_district) {
            newFormData.append('permanent_address_district', data.permanent_address_district);
        }
        if (data.permanent_address_province) {
            newFormData.append('permanent_address_province', data.permanent_address_province);
        }
        if (data.postal_address_no) {
            newFormData.append('postal_address_no', data.postal_address_no);
        }
        if (data.postal_address_street) {
            newFormData.append('postal_address_street', data.postal_address_street);
        }
        if (data.postal_address_city) {
            newFormData.append('postal_address_city', data.postal_address_city);
        }
        if (data.postal_address_town) {
            newFormData.append('postal_address_town', data.postal_address_town);
        }
        if (data.postal_address_district) {
            newFormData.append('postal_address_district', data.postal_address_district);
        }
        if (data.postal_address_province) {
            newFormData.append('postal_address_province', data.postal_address_province);
        }

        let title_idx = 0

        if (this.state.files1.fileList.length > 0) {
            newFormData.append("file_titles[" + title_idx + "]", "nic front");
            newFormData.append("file", this.state.files1.fileList[0].file);
            title_idx++
        }

        if (this.state.files2.fileList.length > 0) {
            newFormData.append("file_titles[" + title_idx + "]", "nic back");
            newFormData.append("file", this.state.files2.fileList[0].file);
            title_idx++
        }
        if (this.state.files3.fileList.length > 0) {
            newFormData.append("file_titles[" + title_idx + "]", "applicant photo");
            newFormData.append("file", this.state.files3.fileList[0].file);
            title_idx++
        }

        for (var i = 0; i < data?.TempEmployeeLinks?.length; i++) {
            if (data.TempEmployeeLinks[i].active == false) {
                if (data.TempEmployeeLinks[i].text == "facebook_link_bi_perm" || data.TempEmployeeLinks[i].text == "instagram_link_bi_perm" || data.TempEmployeeLinks[i].text == "linkedin_link_bi_perm" || data.TempEmployeeLinks[i].text == "youtube_link_bi_perm") {
                    newLinks.push(data.TempEmployeeLinks[i]);
                }
            }
        }

        for (var i = 0; i < links?.length; i++) {
            if (links[i].active == false) {
                if (links[i].text != "facebook_link_bi_perm" && links[i].text != "instagram_link_bi_perm" && links[i].text != "linkedin_link_bi_perm" && links[i].text != "youtube_link_bi_perm") {
                    newLinks.push(links[i]);
                }
            }
        }

        for (var i = 0; i < newLinks?.length; i++) {
            newFormData.append('links[' + i + '][text]', newLinks[i].text);
            newFormData.append('links[' + i + '][link]', newLinks[i].link);
        }


        await EmployeeService.updateBasicInformation(this.state.user.id, newFormData)
            .then(res => {
                if (res.status === 200) {
                    this.setState({
                        alert: true,
                        severity: 'success',
                        message: 'Basic Information Saved Successfully!',
                    },

                        // () => {
                        //     setInterval(() => {
                        //         // window.location.reload(false)
                        //     }, 3000)
                        // }
                    )
                }
                else {
                    this.setState({
                        alert: true,
                        severity: 'error',
                        message: 'Error!',
                    })
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    alert: true,
                    severity: 'error',
                    message: 'Error!',
                })
            })
    }

    async formSubmit() {
        console.log("formData-->>", this.state.formData)

        let data = this.state.formData
        if (data.gender == '' || data.gender == null) {
            this.setState({helperText: 'Please select an option.', error:true})
        } else {
            await this.saveData();
            this.props.changeStepper_Step(this.props.stepper_active_Step + 1);
        }
    }

    changeStepper_Step2 = (step) => {
        this.props.changeStepper_Step(step);
    }

    selectedFiles1(files) {
        console.log(this.state);
        this.setState({ files1: files })
    }

    selectedFiles2(files) {
        console.log(this.state);
        this.setState({ files2: files })
    }

    selectedFiles3(files) {
        console.log(this.state);
        this.setState({ files3: files })
    }

    removeNewLink = (index) => {
        var links = this.state.links;

        links.splice(index, 1);

        this.setState({
            links: links
        })

        console.log(this.state.links)

    }

    addNewLink = (index) => {
        var links = this.state.links;
        var this_link = links[index];

        if ((this_link.text != undefined && this_link.text != "") && (this_link.link != undefined && this_link.link != "")) {

            this_link.active = false;

            links[index] = this_link;

            links.push({
                active: true,
                this_id: links.length
            });

            this.setState({
                links: links
            })
        } else {
            this.setState({
                alert: true,
                message: "Please add both display text and link to proceed",
                severity: "error"
            })
        }
    }

    handleLinkChange = (e, index) => {
        var val = e.target.value
        var key = e.target.name

        var links = this.state.links;
        var this_link = links[index];

        this_link[key] = val;
        links[index] = this_link;

        this.setState({
            links: links,
        })
    }

    handleChange = (e) => {
        var val = e.target.value
        var key = e.target.name

        console.log(val, key)

        var data = this.state.formData || {}
        data[key] = val

        this.setState({
            formData: data,
        })
    }


    handlePermLinksChange = (e) => {
        var val = e.target.value
        var key = e.target.name

        console.log(val, key)

        var data = this.state.formData;
        var links = data.TempEmployeeLinks;
        var this_link = {};
        var count = 0;
        var link_has = false;

        for (var i = 0; i < links.length; i++) {
            if (links[i].text == key) {
                this_link = links[i];
                count = i;
                link_has = true;
            }
        }

        if (!link_has) {
            this_link = {
                "employee_id": this.state.user.id,
                "text": key,
                "link": val
            }
            links.push(this_link);
        } else {
            this_link["link"] = val;
            links[count] = this_link;
        }

        data.TempEmployeeLinks = links;

        this.setState({
            formData: data,
        })
    }


    setPermAddressAsCur = (e) => {
        var formData = this.state.formData;
        if (formData.same_as_permanant_address) {
            formData["postal_" + e.target.name.split("_")[1] + "_" + e.target.name.split("_")[2]] = e.target.value;
        }
        this.setState({
            formData: formData
        })
    }

    setAddress = async () => {

        let formData = this.state.formData;
        let all_equal = true;
        let same_as_permanant_address = this.state.same_as_permanant_address;

        if (formData.permanent_address_no != formData.postal_address_no) {
            all_equal = false;
        } else if (formData.permanent_address_street != formData.postal_address_street) {
            all_equal = false;
        } else if (formData.permanent_address_city != formData.postal_address_city) {
            all_equal = false;
        } else if (formData.permanent_address_town != formData.postal_address_town) {
            all_equal = false;
        } else if (formData.permanent_address_province != formData.postal_address_province) {
            all_equal = false;
        } else if (formData.permanent_address_district != formData.postal_address_district) {
            all_equal = false;
        }

        formData["same_as_permanant_address"] = all_equal;
        same_as_permanant_address = all_equal;

        this.setState({
            formData,
            same_as_permanant_address
        })

    }

    async loadData() {
        var employee_id = this.state.user.id;
        await EmployeeService.getTempEmployeesById(employee_id)
            .then(res => {
                console.log(res, "loadData");

                if (res.status == 200) {
                    this.setState(
                        {
                            formData: res.data.view,
                        },
                    )
                    this.setAddress()
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    alert: true,
                    severity: 'error',
                    message: 'Error!',
                })
            })
    }

    fileformat2(obj) {
        if (obj != undefined) {
            var file_array = []
            // obj.forEach((element, index) => {
            file_array.push({
                url: obj.path,
                extension: obj.extension,
                filename: obj.file_name,
            })
            // });

            return file_array
        }
    }

    async getUserData() {
        let user = await localStorageService.getItem("userInfo")

        this.setState({
            user: user
        })
    }

    async componentDidMount() {
        await this.getUserData();
        await this.loadData();

        var links = [];

        if (this.state.formData?.TempEmployeeLinks) {
            links = this.state.formData?.TempEmployeeLinks;
        }

        for (var i = 0; i < links.length; i++) {
            links[i].active = false;
            links[i].this_id = i;
        }

        links.push({ active: true, this_id: links.length });

        this.setState({
            links: links
        })
    }

    render() {
        let { theme } = this.props
        const { classes } = this.props

        return (
            <Fragment>
                <div>
                    <div className="pt-7">
                        <ValidatorForm
                            onSubmit={() => this.formSubmit()}
                            onError={() => null}
                        >
                            <Grid container spacing={2} className="bg-light-primary" style={{ borderRadius: "10px 10px 10px 10px" }}>
                                <Grid item lg={12} md={12} sm={12} xs={12} className="bg-primary" style={{ borderRadius: "10px 10px 0px 0px" }}></Grid>
                                <Grid container spacing={2} className="p-5">
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="text"
                                            label="Name with initials *"
                                            variant="outlined"
                                            size="small"
                                            name="name_with_initials"
                                            value={this.state.formData?.name_with_initials ? this.state.formData.name_with_initials : ""}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                            }}
                                            validators={[
                                                "required",
                                                "matchRegexp:^[^0-9]+$",
                                            ]}
                                            errorMessages={[
                                                "This Field is required!",
                                                "This Field Do Not Accept Numbers!",
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="text"
                                            label="Full Name *"
                                            variant="outlined"
                                            size="small"
                                            name="full_name"
                                            value={this.state.formData?.full_name ? this.state.formData?.full_name : ""}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                            }}
                                            validators={[
                                                "required",
                                                "matchRegexp:^[^0-9]+$",
                                            ]}
                                            errorMessages={[
                                                "This Field is required!",
                                                "This Field Do Not Accept Numbers!",
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="text"
                                            label="First Name *"
                                            variant="outlined"
                                            size="small"
                                            name="first_name"
                                            value={this.state.formData?.first_name ? this.state.formData?.first_name : ""}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                            }}
                                            validators={[
                                                "required",
                                                "matchRegexp:^[^0-9]+$",
                                            ]}
                                            errorMessages={[
                                                "This Field is required!",
                                                "This Field Do Not Accept Numbers!",
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="text"
                                            label="Middle Name"
                                            variant="outlined"
                                            size="small"
                                            name="middle_name"
                                            value={this.state.formData?.middle_name ? this.state.formData?.middle_name : ""}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                            }}
                                            validators={[
                                                "matchRegexp:^[^0-9]+$",
                                            ]}
                                            errorMessages={[
                                                "This Field Do Not Accept Numbers!",
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="text"
                                            label="Surname"
                                            variant="outlined"
                                            size="small"
                                            name="sur_name"
                                            value={this.state.formData?.sur_name ? this.state.formData?.sur_name : ""}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                            }}
                                            validators={[
                                                "matchRegexp:^[^0-9]+$",
                                            ]}
                                            errorMessages={[
                                                "This Field Do Not Accept Numbers!",
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="text"
                                            label="Other Name"
                                            variant="outlined"
                                            size="small"
                                            name="other_name"
                                            value={this.state.formData?.other_name ? this.state.formData?.other_name : ""}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                            }}
                                            validators={[
                                                "matchRegexp:^[^0-9]+$",
                                            ]}
                                            errorMessages={[
                                                "This Field Do Not Accept Numbers!",
                                            ]}
                                        />
                                    </Grid>

                                    {/* Gender */}
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <FormControl error={this.state.error} variant="standard">
                                            <FormLabel id="gender-radios">Gender * :</FormLabel>
                                            <RadioGroup row aria-labelledby="gender-radios">
                                                <FormControlLabel
                                                    label={"Male"}
                                                    name="Male"
                                                    value={"Male"}
                                                    onChange={() => {
                                                        let formData = this.state.formData;
                                                        formData.gender = "Male";
                                                        this.setState({ formData })
                                                    }}
                                                    control={
                                                        <Radio color="primary" />
                                                    }
                                                    display="inline"
                                                    checked={ this.state.formData?.gender == "Male" ? true : false }
                                                />
                                                <FormControlLabel
                                                    label={"Female"}
                                                    name="Female"
                                                    value={"Female"}
                                                    onChange={() => {
                                                        let formData = this.state.formData;
                                                        formData.gender = "Female";
                                                        this.setState({ formData })
                                                    }}
                                                    control={
                                                        <Radio color="primary" />
                                                    }
                                                    display="inline"
                                                    checked={this.state.formData?.gender == "Female" ? true : false }
                                                />
                                                <FormHelperText>{this.state.helperText}</FormHelperText>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    
                                    
                                    <Grid item lg={3} md={3} sm={3} xs={12}>
                                        <DatePicker
                                            className={classes.formFieldContainer}
                                            value={this.state.formData?.date_of_birth}
                                            label="Date of Birth"
                                            maxDate={new Date()}
                                            name="date_of_birth"
                                            onChange={date => {
                                                let data = this.state.formData;
                                                data['date_of_birth'] = moment(date).format(
                                                    'yyyy-MM-DD'
                                                );
                                                this.setState({ formData: data })

                                            }}
                                            required={true}
                                            errorMessages="This field is required"
                                        />
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={3} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="text"
                                            label="Age (As at Application Deadline) *"
                                            variant="outlined"
                                            size="small"
                                            name="age"
                                            value={this.state.formData?.age ? this.state.formData?.age : ""}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                            }}
                                            inputProps={{
                                                maxLength: 3
                                            }}
                                            validators={[
                                                "required",
                                                "matchRegexp:^[0-9]+$",
                                            ]}
                                            errorMessages={[
                                                "This Field is required!",
                                                "This Field Only Accept Numbers!",
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Autocomplete
                                            className="w-full"
                                            options={appConst.citizenship_arr}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            getOptionSelected={(option) => option.label}
                                            onChange={(event, value) =>
                                                this.setDropdownFormData(
                                                    'citizenship',
                                                    value == null
                                                        ? ''
                                                        : value
                                                )
                                            }
                                            value={{ label: this.state.formData?.citizenship }}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    label="Citizenship *"
                                                    variant="outlined"
                                                    value={
                                                        this.state.formData?.citizenship ? this.state.formData?.citizenship : ""
                                                    }
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: this.state.formData?.citizenship ? true : false
                                                    }}
                                                    size="small"
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        "This Field is required!"
                                                    ]}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {this.state.formData?.citizenship == "Citizen of Sri Lanka by Registration" ?
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextValidator
                                                className="w-full"
                                                type="text"
                                                label="Registration No *"
                                                variant="outlined"
                                                size="small"
                                                name="other_citizenship"
                                                value={this.state.formData?.other_citizenship}
                                                onChange={(e) => {
                                                    this.handleChange(e);
                                                }}
                                                validators={["required"]}
                                                errorMessages={[
                                                    "This Field is required!"
                                                ]}
                                            />
                                        </Grid>
                                        : ""}
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="text"
                                            label="NIC *"
                                            variant="outlined"
                                            size="small"
                                            name="nic"
                                            value={this.state.formData?.nic ? this.state.formData?.nic : ""}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                            }}
                                            validators={['required', "matchRegexp:((^|, )([0-9]{9}(V|v)|(1|2)[0-9]{11}))+$"]}
                                            errorMessages={[
                                                'This field is required', "Please enter a valid NIC Number(Eg:991234567V or 991234567v or 199981234567)"
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            {this.state.formData?.TempEmployeeUploads?.filter(el => el.title == "nic front" || el.title == "nic back").map((element) => (
                                                <Grid item xs={12} md={6}>
                                                    <Typography className='uppercase'>{element.title}</Typography>
                                                    <DocumentLoader
                                                        files={this.fileformat2(
                                                            element
                                                        )}
                                                    />
                                                </Grid>
                                            ))}

                                        </Grid>
                                    </Grid>

                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <FilePicker
                                            className="w-full"
                                            singleFileEnable={true}
                                            id="nic_file_front"
                                            multipleFileEnable={false}
                                            dragAndDropEnable={true}
                                            documentName={false}
                                            accept="image/png, image/gif, image/jpeg"
                                            maxFileSize={3072000}
                                            maxTotalFileSize={10240000}
                                            maxFilesCount={1}
                                            validators={['maxSize', 'maxTotalFileSize', 'maxFileCount']}
                                            errorMessages={["file size too lage", "Total file size is too lage", "Too many files added"]}
                                            label="NIC Front"
                                            singleFileButtonText="Select File"
                                            multipleFileButtonText="Select Files"
                                            selectedFileList={this.state.files1.fileList}
                                            selectedFiles={(files) => this.selectedFiles1(files)}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <FilePicker
                                            className="w-full"
                                            singleFileEnable={true}
                                            id="nic_file_back"
                                            multipleFileEnable={false}
                                            dragAndDropEnable={true}
                                            documentName={false}
                                            accept="image/png, image/gif, image/jpeg"
                                            maxFileSize={3072000}
                                            maxTotalFileSize={10240000}
                                            maxFilesCount={1}
                                            validators={['maxSize', 'maxTotalFileSize', 'maxFileCount']}
                                            errorMessages={["file size too lage", "Total file size is too lage", "Too many files added"]}
                                            label="NIC Back"
                                            singleFileButtonText="Select File"
                                            multipleFileButtonText="Select Files"
                                            selectedFileList={this.state.files2.fileList}
                                            selectedFiles={(files) => this.selectedFiles2(files)}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="text"
                                            label="Telephone no: Mobile *"
                                            variant="outlined"
                                            size="small"
                                            name="mobile_no"
                                            value={this.state.formData?.mobile_no ? this.state.formData?.mobile_no : ""}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                            }}
                                            validators={[
                                                "required",
                                                "matchRegexp:^[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$",
                                            ]}
                                            errorMessages={[
                                                'This field is required',
                                                'Please enter valid mobile number E.g.(0771231231)',
                                            ]}
                                            InputLabelProps={{
                                                shrink: this.state.formData?.mobile_no ? true : false
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="text"
                                            label="Telephone no:Home"
                                            variant="outlined"
                                            size="small"
                                            name="contact_no"
                                            value={this.state.formData?.contact_no}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                            }}
                                            validators={[

                                                "matchRegexp:^[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$",
                                            ]}
                                            errorMessages={[

                                                'Please enter valid mobile number E.g.(0771231231)',
                                            ]}
                                            InputLabelProps={{
                                                shrink: this.state.formData?.contact_no ? true : false
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="email"
                                            label="Email"
                                            variant="outlined"
                                            size="small"
                                            name="email"
                                            value={this.state.formData?.email}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                            }}
                                            InputLabelProps={{
                                                shrink: this.state.formData.email ? true : false
                                            }}
                                            validators={['required', 'isEmail']}
                                            errorMessages={['This field is required', 'Email is not valid']}
                                        />
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <SubTitle title="Permanent Address" />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="text"
                                            label="No *"
                                            variant="outlined"
                                            size="small"
                                            name="permanent_address_no"
                                            value={this.state.formData?.permanent_address_no ? this.state.formData?.permanent_address_no : ""}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                                this.setPermAddressAsCur(e);
                                            }}
                                            validators={["required"]}
                                            errorMessages={[
                                                "This Field is required!"
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="text"
                                            label="Street *"
                                            variant="outlined"
                                            size="small"
                                            name="permanent_address_street"
                                            value={this.state.formData?.permanent_address_street ? this.state.formData?.permanent_address_street : ""}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                                this.setPermAddressAsCur(e);
                                            }}
                                            validators={["required"]}
                                            errorMessages={[
                                                "This Field is required!"
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="text"
                                            label="City *"
                                            variant="outlined"
                                            size="small"
                                            name="permanent_address_city"
                                            value={this.state.formData?.permanent_address_city ? this.state.formData?.permanent_address_city : ""}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                                this.setPermAddressAsCur(e);
                                            }}
                                            validators={["required"]}
                                            errorMessages={[
                                                "This Field is required!"
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="text"
                                            label="Town *"
                                            variant="outlined"
                                            size="small"
                                            name="permanent_address_town"
                                            value={this.state.formData?.permanent_address_town ? this.state.formData?.permanent_address_town : ""}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                                this.setPermAddressAsCur(e);
                                            }}
                                            validators={["required"]}
                                            errorMessages={[
                                                "This Field is required!"
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Autocomplete
                                            className="w-full"
                                            options={appConst.provinceList}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            getOptionSelected={(option) => option.label}
                                            onChange={(event, value) => {
                                                this.setDropdownFormData(
                                                        'permanent_address_province',
                                                        value == null ? '' : value
                                                    )

                                                this.setDropdownFormData(
                                                        'permanent_address_district',
                                                        value == ''
                                                    )
                                                }
                                            }
                                            value={{ label: this.state.formData?.permanent_address_province }}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    label="Province *"
                                                    variant="outlined"
                                                    value={
                                                        this.state.formData?.permanent_address_province ? this.state.formData?.permanent_address_province : ""
                                                    }
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: this.state.formData?.permanent_address_province ? true : false,
                                                    }}
                                                    size="small"
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        "This Field is required!"
                                                    ]}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Autocomplete
                                            className="w-full"
                                            options={this.state.formData?.permanent_address_province != null ? (appConst.districtList.filter((ele) => ele.province == this.state.formData?.permanent_address_province)) : appConst.districtList}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            getOptionSelected={(option) => option.label}
                                            onChange={(event, value) =>
                                                this.setDropdownFormData(
                                                    'permanent_address_district',
                                                    value == null
                                                        ? ''
                                                        : value
                                                )
                                            }
                                            value={{ label: this.state.formData?.permanent_address_district }}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    label="District *"
                                                    variant="outlined"
                                                    value={
                                                        this.state.formData?.permanent_address_district ? this.state.formData?.permanent_address_district : ""
                                                    }
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: this.state.formData?.permanent_address_district ? true : false,
                                                    }}
                                                    size="small"
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        "This Field is required!"
                                                    ]}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <SubTitle title="Current Address" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.same_as_permanant_address ? this.state.same_as_permanant_address : this.state.formData.same_as_permanant_address ? this.state.formData.same_as_permanant_address : false}
                                                    onChange={() => {
                                                        var checkbox = this.state.same_as_permanant_address;
                                                        var formData = this.state.formData;
                                                        checkbox = !checkbox;
                                                        if (checkbox) {
                                                            formData["postal_address_no"] = formData.permanent_address_no;
                                                            formData["postal_address_street"] = formData.permanent_address_street;
                                                            formData["postal_address_city"] = formData.permanent_address_city;
                                                            formData["postal_address_town"] = formData.permanent_address_town;
                                                            formData["postal_address_province"] = formData.permanent_address_province;
                                                            formData["postal_address_district"] = formData.permanent_address_district;
                                                        } else {
                                                            formData["postal_address_no"] = "";
                                                            formData["postal_address_street"] = "";
                                                            formData["postal_address_city"] = "";
                                                            formData["postal_address_town"] = "";
                                                            formData["postal_address_province"] = null;
                                                            formData["postal_address_district"] = null;
                                                        }
                                                        formData["same_as_permanant_address"] = checkbox;
                                                        this.setState({
                                                            same_as_permanant_address: checkbox,
                                                            formData: formData,
                                                        })
                                                    }}
                                                    name="same_as_permanant_address"
                                                    color="primary"
                                                />
                                            }
                                            label="Current address is same as the permanent address"
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="text"
                                            label="No *"
                                            variant="outlined"
                                            size="small"
                                            name="postal_address_no"
                                            value={this.state.formData?.postal_address_no ? this.state.formData?.postal_address_no : ""}
                                            InputLabelProps={{ shrink: this.state.formData?.postal_address_no ? true : false }}
                                            disabled={this.state.formData?.same_as_permanant_address}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                            }}
                                            validators={["required"]}
                                            errorMessages={[
                                                "This Field is required!"
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="text"
                                            label="Street *"
                                            variant="outlined"
                                            size="small"
                                            name="postal_address_street"
                                            value={this.state.formData?.postal_address_street ? this.state.formData?.postal_address_street : ""}
                                            InputLabelProps={{ shrink: this.state.formData?.postal_address_street ? true : false }}
                                            disabled={this.state.formData?.same_as_permanant_address}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                            }}
                                            validators={["required"]}
                                            errorMessages={[
                                                "This Field is required!"
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="text"
                                            label="City *"
                                            variant="outlined"
                                            size="small"
                                            name="postal_address_city"
                                            value={this.state.formData?.postal_address_city ? this.state.formData?.postal_address_city : ""}
                                            InputLabelProps={{ shrink: this.state.formData?.postal_address_city ? true : false }}
                                            disabled={this.state.formData?.same_as_permanant_address}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                            }}
                                            validators={["required"]}
                                            errorMessages={[
                                                "This Field is required!"
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            type="text"
                                            label="Town *"
                                            variant="outlined"
                                            size="small"
                                            name="postal_address_town"
                                            value={this.state.formData?.postal_address_town ? this.state.formData?.postal_address_town : ""}
                                            InputLabelProps={{ shrink: this.state.formData?.postal_address_town ? true : false }}
                                            disabled={this.state.formData?.same_as_permanant_address}
                                            onChange={(e) => {
                                                this.handleChange(e);
                                            }}
                                            validators={["required"]}
                                            errorMessages={[
                                                "This Field is required!"
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Autocomplete
                                            className="w-full"
                                            options={appConst.provinceList}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            getOptionSelected={(option) => option.label}
                                            onChange={(event, value) =>
                                                this.setDropdownFormData(
                                                    'postal_address_province',
                                                    value == null
                                                        ? ''
                                                        : value
                                                )
                                            }
                                            value={{ label: this.state.formData?.postal_address_province }}

                                            disabled={this.state.formData?.same_as_permanant_address}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    label="Province *"
                                                    variant="outlined"
                                                    value={
                                                        this.state.formData?.postal_address_province ? this.state.formData?.postal_address_province : ""
                                                    }
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: this.state.formData?.postal_address_province ? true : false,
                                                    }}
                                                    size="small"
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        "This Field is required!"
                                                    ]}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Autocomplete
                                            className="w-full"
                                            options={this.state.formData?.postal_address_province != null ? (appConst.districtList.filter((ele) => ele.province == this.state.formData?.postal_address_province)) : appConst.districtList}
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            getOptionSelected={(option) => option.label}
                                            onChange={(event, value) =>
                                                this.setDropdownFormData(
                                                    'postal_address_district',
                                                    value == null
                                                        ? ''
                                                        : value
                                                )
                                            }
                                            value={{ label: this.state.formData?.postal_address_district }}
                                            disabled={this.state.formData?.same_as_permanant_address}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    label="District *"
                                                    variant="outlined"
                                                    value={
                                                        this.state.formData?.postal_address_district ? this.state.formData?.postal_address_district : ""
                                                    }
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: this.state.formData?.postal_address_district ? true : false,
                                                    }}
                                                    size="small"
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        "This Field is required!"
                                                    ]}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <SubTitle title="Links" />
                                    </Grid>
                                    {this.state.formData?.TempEmployeeLinks ?
                                        <Grid container spacing={2}>
                                            <Grid item lg={1} md={1} sm={1} xs={1}>
                                                <Tooltip title="Facebook">
                                                    <FacebookIcon style={{ color: "#4064AC" }} fontSize="large" />
                                                </Tooltip>
                                            </Grid>

                                            <Grid item lg={5} md={5} sm={11} xs={11}>
                                                <TextValidator
                                                    className="w-full"
                                                    type="text"
                                                    label="Link"
                                                    variant="outlined"
                                                    size="small"
                                                    name="facebook_link_bi_perm"
                                                    value={
                                                        this.state.formData?.TempEmployeeLinks.filter(ele => ele.text == "facebook_link_bi_perm").length > 0 ? this.state.formData?.TempEmployeeLinks.filter(ele => ele.text == "facebook_link_bi_perm")[0].link : ""
                                                    }
                                                    onChange={(e) => {
                                                        this.handlePermLinksChange(e);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={1} md={1} sm={1} xs={1}>
                                                <Tooltip title="LinkedIn">
                                                    <LinkedInIcon style={{ color: "#0A78B5" }} fontSize="large" />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item lg={5} md={5} sm={11} xs={11}>
                                                <TextValidator
                                                    className="w-full"
                                                    type="text"
                                                    label="Link"
                                                    variant="outlined"
                                                    size="small"
                                                    name="linkedin_link_bi_perm"
                                                    value={
                                                        this.state.formData?.TempEmployeeLinks.filter(ele => ele.text == "linkedin_link_bi_perm").length > 0 ? this.state.formData?.TempEmployeeLinks.filter(ele => ele.text == "linkedin_link_bi_perm")[0].link : ""
                                                    }
                                                    onChange={(e) => {
                                                        this.handlePermLinksChange(e);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={1} md={1} sm={1} xs={1}>
                                                <Tooltip title="Instagram">
                                                    <InstagramIcon style={{ color: "#C7426D" }} fontSize="large" />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item lg={5} md={5} sm={11} xs={11}>

                                                <TextValidator
                                                    className="w-full"
                                                    type="text"
                                                    label="Link"
                                                    variant="outlined"
                                                    size="small"
                                                    name="instagram_link_bi_perm"
                                                    // value={this.state.formData?.instagram_link}
                                                    value={
                                                        this.state.formData?.TempEmployeeLinks.filter(ele => ele.text == "instagram_link_bi_perm").length > 0 ? this.state.formData?.TempEmployeeLinks.filter(ele => ele.text == "instagram_link_bi_perm")[0].link : ""
                                                    }
                                                    onChange={(e) => {
                                                        this.handlePermLinksChange(e);
                                                    }}
                                                />

                                            </Grid>
                                            <Grid item lg={1} md={1} sm={1} xs={1}>
                                                <Tooltip title="Youtube">
                                                    <YouTubeIcon style={{ color: "#F70000" }} fontSize="large" />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item lg={5} md={5} sm={11} xs={11}>
                                                <TextValidator
                                                    className="w-full"
                                                    type="text"
                                                    label="Link"
                                                    variant="outlined"
                                                    size="small"
                                                    name="youtube_link_bi_perm"
                                                    value={
                                                        this.state.formData?.TempEmployeeLinks.filter(ele => ele.text == "youtube_link_bi_perm").length > 0 ? this.state.formData?.TempEmployeeLinks.filter(ele => ele.text == "youtube_link_bi_perm")[0].link : ""
                                                    }
                                                    onChange={(e) => {
                                                        this.handlePermLinksChange(e);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        : ""}
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <SubTitle title="Other Links" />
                                    </Grid>
                                    <Grid container className="pl-2 pr-2">
                                        <Grid container className="p-5 mt-5 mb-5 bg-light-primary" style={{ borderRadius: "10px 10px 10px 10px" }}>
                                            {this.state.links.filter(ele => ele.text != "facebook_link_bi_perm" && ele.text != "instagram_link_bi_perm" && ele.text != "linkedin_link_bi_perm" && ele.text != "youtube_link_bi_perm").map((item, index) => (
                                                <Grid container spacing={2} className="pb-2" key={index}>
                                                    <Grid item lg={4} md={4} sm={4} xs={12}>
                                                        <TextValidator
                                                            className="w-full bg-white"
                                                            type="text"
                                                            label="Display Text"
                                                            variant="outlined"
                                                            size="small"
                                                            name="text"
                                                            disabled={!item.active}
                                                            value={item.text ? item.text : ""}
                                                            onChange={(e) => {
                                                                this.handleLinkChange(e, item.this_id);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={7} md={7} sm={7} xs={12}>
                                                        <TextValidator
                                                            className="w-full bg-white"
                                                            type="text"
                                                            label="Link"
                                                            variant="outlined"
                                                            size="small"
                                                            name="link"
                                                            value={item.link ? item.link : ""}
                                                            disabled={!item.active}
                                                            onChange={(e) => {
                                                                this.handleLinkChange(e, item.this_id);
                                                            }}
                                                        />
                                                    </Grid>
                                                    {item.active ?
                                                        <Grid item lg={1} md={1} sm={1} xs={12}>
                                                            <Tooltip title="Add">
                                                                <IconButton
                                                                    onClick={() => this.addNewLink(item.this_id)}
                                                                    size="small"
                                                                    aria-label="add"
                                                                    className="bg-green text-white"
                                                                >
                                                                    <AddIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                        :
                                                        <Grid item lg={1} md={1} sm={2} xs={12}>
                                                            <Tooltip title="Remove">
                                                                <IconButton
                                                                    onClick={() => this.removeNewLink(item.this_id)}
                                                                    size="small"
                                                                    aria-label="remove"
                                                                    className="bg-error text-white"
                                                                >
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid> */}
                                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-5">
                                        {this.state.formData?.TempEmployeeUploads?.filter(el => el.title == "applicant photo").map((element) => (
                                            <div>
                                                <Typography className='uppercase'>{element.title}</Typography>
                                                <DocumentLoader
                                                    files={this.fileformat2(
                                                        element
                                                    )}
                                                />
                                            </div>
                                        ))}
                                    </Grid>
                                    {this.state.formData?.TempEmployeeUploads?.filter(el => el.title == "applicant photo").map((element) => (
                                        <Grid item lg={12} md={12} sm={12} xs={12} className="mb-5">
                                            <FilePicker
                                                className="w-full"
                                                singleFileEnable={true}
                                                id="applicant_photo"
                                                multipleFileEnable={false}
                                                dragAndDropEnable={true}
                                                documentName={false}
                                                accept="image/png, image/gif, image/jpeg"
                                                maxFileSize={3072000}
                                                maxTotalFileSize={10240000}
                                                maxFilesCount={1}
                                                validators={['maxSize', 'maxTotalFileSize', 'maxFileCount']}
                                                errorMessages={["file size too lage", "Total file size is too lage", "Too many files added"]}
                                                label="Please upload a recent photograph of the applicant (This will replace your current photograph)"
                                                singleFileButtonText="Select File"
                                                multipleFileButtonText="Select Files"
                                                selectedFileList={this.state.files3.fileList}
                                                selectedFiles={(files) => this.selectedFiles3(files)}
                                            />
                                        </Grid>
                                    ))}

                                    {this.state.formData?.TempEmployeeUploads?.length == 0 &&
                                        <Grid item lg={12} md={12} sm={12} xs={12} className="mb-5">
                                            <FilePicker
                                                className="w-full"
                                                singleFileEnable={true}
                                                id="applicant_photo"
                                                multipleFileEnable={false}
                                                dragAndDropEnable={true}
                                                documentName={false}
                                                accept="image/png, image/gif, image/jpeg"
                                                maxFileSize={3072000}
                                                maxTotalFileSize={10240000}
                                                maxFilesCount={1}
                                                validators={['maxSize', 'maxTotalFileSize', 'maxFileCount', 'required']}
                                                errorMessages={["file size too lage", "Total file size is too lage", "Too many files added", "This Field is required!"]}
                                                label="Please upload a recent photograph of the applicant *"
                                                singleFileButtonText="Select File"
                                                multipleFileButtonText="Select Files"
                                                selectedFileList={this.state.files3.fileList}
                                                selectedFiles={(files) => this.selectedFiles3(files)}
                                            />
                                        </Grid>
                                        // :
                                        // <>
                                        //     {this.state.formData?.TempEmployeeUploads?.filter(el => el.title != "applicant photo").map((element) => (
                                        //         <Grid item lg={12} md={12} sm={12} xs={12} className="mb-5">
                                        //             <FilePicker
                                        //                 className="w-full"
                                        //                 singleFileEnable={true}
                                        //                 id="applicant_photo"
                                        //                 multipleFileEnable={false}
                                        //                 dragAndDropEnable={true}
                                        //                 documentName={false}
                                        //                 accept="image/png, image/gif, image/jpeg"
                                        //                 maxFileSize={3072000}
                                        //                 maxTotalFileSize={10240000}
                                        //                 maxFilesCount={1}
                                        //                 validators={['maxSize', 'maxTotalFileSize', 'maxFileCount', 'required']}
                                        //                 errorMessages={["file size too lage", "Total file size is too lage", "Too many files added", "This Field is required!"]}
                                        //                 label="Please upload a recent photograph of the applicant *"
                                        //                 singleFileButtonText="Select File"
                                        //                 multipleFileButtonText="Select Files"
                                        //                 selectedFileList={this.state.files3.fileList}
                                        //                 selectedFiles={(files) => this.selectedFiles3(files)}
                                        //                 />
                                        //         </Grid>
                                        //     ))}
                                        // </>
                                    }

                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className="mt-5">
                                <Grid item lg={8} md={8} sm={7} xs={12}></Grid>
                                <Grid item lg={2} md={2} sm={2} xs={12}>
                                    <Button
                                        progress={false}
                                        onClick={() => this.changeStepper_Step2
                                            (
                                                this.props.stepper_active_Step - 1
                                            )
                                        }
                                        scrollToTop={true}
                                        startIcon="arrow_back_ios"
                                        className="w-full"
                                    >
                                        <span className="capitalize">
                                            Previous
                                        </span>
                                    </Button>
                                </Grid>
                                <Grid item lg={2} md={2} sm={3} xs={12}>
                                    <Button
                                        progress={false}
                                        type="submit"
                                        scrollToTop={true}
                                        endIcon="arrow_forward_ios"
                                        className="w-full"
                                    >
                                        <span className="capitalize">
                                            Save & Next
                                        </span>
                                    </Button>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </div>
                </div>
                <LoonsSnackbar
                    open={this.state.alert}
                    onClose={() => {
                        this.setState({ alert: false })
                    }}
                    message={this.state.message}
                    autoHideDuration={3000}
                    severity={this.state.severity}
                    elevation={2}
                    variant="filled"
                ></LoonsSnackbar>
            </Fragment>
        )
    }
}
export default withStyles(styleSheet)(BasicInformation)
