import React from 'react'
import { authRoles } from '../../auth/authRoles'

const VapaPressRoutes = [
    {
        path: '/vapa_press/all_view_author',
        component: React.lazy(() =>
            import('./AllViewAuthor')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/author_registration',
        component: React.lazy(() =>
            import('./AuthorRegistration')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/application_submission',
        component: React.lazy(() =>
            import('./ApplicationSubmission')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/application_submission_booklet',
        component: React.lazy(() =>
            import('./ApplicationSubmissionBooklet')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/application_submission_guideline',
        component: React.lazy(() =>
            import('./ApplicationSubmissionGuideline')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/application_approval',
        component: React.lazy(() =>
            import('./ApplicationApproval')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/all_applications',
        component: React.lazy(() =>
            import('./AllApplications')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/agreement_setup',
        component: React.lazy(() =>
            import('./AgreementSetup')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/agreement_create',
        component: React.lazy(() =>
            import('./AgreementCreate')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/manuscript_resubmit_author',
        component: React.lazy(() =>
            import('./ManuscriptResubmitAuthor')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/manuscript_upload_manuscripts_by_ma',
        component: React.lazy(() =>
            import('./ManuscriptUploadManuscriptsByMA')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/manuscript_send_for_checking_by_ma',
        component: React.lazy(() =>
            import('./ManuscriptSendForCheckingByMA')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/author_view__individual',
        component: React.lazy(() =>
            import('./AuthorViewIndividual')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/manuscript_accept_by_ma',
        component: React.lazy(() =>
            import('./ManuscriptAcceptByMA')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/all_view_author',
        component: React.lazy(() =>
            import('./AllViewAuthor')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/author_registration',
        component: React.lazy(() =>
            import('./AuthorRegistration')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/application_submission',
        component: React.lazy(() =>
            import('./ApplicationSubmission')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/application_submission_booklet',
        component: React.lazy(() =>
            import('./ApplicationSubmissionBooklet')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/application_submission_guideline',
        component: React.lazy(() =>
            import('./ApplicationSubmissionGuideline')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/application_approval',
        component: React.lazy(() =>
            import('./ApplicationApproval')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/all_applications',
        component: React.lazy(() =>
            import('./AllApplications')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/agreement_setup',
        component: React.lazy(() =>
            import('./AgreementSetup')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/agreement_create',
        component: React.lazy(() =>
            import('./AgreementCreate')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/manuscript_resubmit_author',
        component: React.lazy(() =>
            import('./ManuscriptResubmitAuthor')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/manuscript_upload_manuscripts_by_ma',
        component: React.lazy(() =>
            import('./ManuscriptUploadManuscriptsByMA')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/manuscript_send_for_checking_by_ma',
        component: React.lazy(() =>
            import('./ManuscriptSendForCheckingByMA')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/author_view__individual',
        component: React.lazy(() =>
            import('./AuthorViewIndividual')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/vapa_press/manuscript_accept_by_ma',
        component: React.lazy(() =>
            import('./ManuscriptAcceptByMA')
        ),
        auth: authRoles.dataSetup,
    },
]

export default VapaPressRoutes