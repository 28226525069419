import React from 'react'
import { authRoles } from '../../auth/authRoles'


const batchRoutes = [
    {
        path: '/batch/view/:id',
        component: React.lazy(() => import('./SingleBatch')),
        auth: authRoles.batchView,
    },
    // Batch
    {
        path: '/ma/batch/create',
        component: React.lazy(() => import('../Batch/MA_Create_Batch')),
    },
    {
        path: '/ma/batch/all',
        component: React.lazy(() => import('../Batch/MA_View_All_Batches')),
    },
    {
        path: '/batch/postgraduate/advertisement/view/:id',
        component: React.lazy(() => import('./Advertisement/AdvertisementApproval')),
        auth: authRoles.batchView,
    },
    {
        path: '/ma/student/all',
        component: React.lazy(() => import('../Batch/AllPostgraduateStudents')),
    },

]

export default batchRoutes
