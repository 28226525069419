import React, { Fragment, useState, Component } from "react";
import { Divider, Typography } from '@material-ui/core'
import { any } from "prop-types";

class BookCard extends Component {
    static propTypes = {
        title: any
    };

    static defaultProps = {
        title: null
    };
    render() {
        const {
            title
        } = this.props;


        return (
            <Fragment>
                <Typography variant="h4">{title}</Typography>
                <Divider />
            </Fragment>

        );
    }
}


export default BookCard;