import React from 'react'
import { authRoles } from 'app/auth/authRoles'

const ConvocationsRoutes = [

    {
        path: '/convocations/all_convocations',
        component: React.lazy(() => import('./AllConvocations')),
        auth: authRoles.ExaminationView,
    },
    {
        path: '/convocations/view_convocation/:id',
        component: React.lazy(() => import('./ViewConvocation')),
        auth: authRoles.ExaminationView,
    },


    //student views
    {
        path: '/convocation',
        component: React.lazy(() => import('./studentViews/ApplyConvocation')),
        auth: authRoles.Appeal,
    },


]

export default ConvocationsRoutes
