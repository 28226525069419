/*
Loons Lab Date picker component
Developed By Roshan
Loons Lab
*/
import React, { Fragment, useState, Component } from "react";
import { Card } from '@material-ui/core'
import PropTypes from "prop-types";
import { FilePicker } from "..";

class PaymentMethods extends Component {

    constructor(props) {
        super(props)
        this.state = {
            files: { fileList: [] },

        }
    }

    static propTypes = {
        type: PropTypes.any,
        methods: PropTypes.array,
        referenceNo: PropTypes.func,
        selectedFile: PropTypes.func,
    };

    static defaultProps = {
        type: null,
        methods: [],
        referenceNo: null,
    };

    async selectedFile(files) {

    }

    render() {
        const {
            title,
            children
        } = this.props;


        return (

            <div>
                Hi
            </div>


        );
    }
}

export default PaymentMethods;