import React from 'react'
import { authRoles } from '../../auth/authRoles'

const AdmissionRoutes = [
    {
        path: '/admission',
        component: React.lazy(() => import('./Admission')),
        auth: authRoles.StudentAdmission,
    },
]

export default AdmissionRoutes
