import React from 'react'
import { authRoles } from '../../auth/authRoles'

const WelfareRoutes = [
    {
        path: '/welfare/season/train_season_notice',
        component: React.lazy(() => import('./Seasons/TrainSeasonNotice')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/bus_season_notice',
        component: React.lazy(() => import('./Seasons/BusSeasonNotice')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/overview',
        component: React.lazy(() => import('./Seasons/Overview')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/train-season-requests-new',
        component: React.lazy(() => import('./Seasons/TrainSeasonRequestsNew')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/train-season-requests-checked',
        component: React.lazy(() =>
            import('./Seasons/TrainSeasonRequestsChecked')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/train-season-requests-approved',
        component: React.lazy(() =>
            import('./Seasons/TrainSeasonRequestsApproved')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/train-season-requests-printed',
        component: React.lazy(() =>
            import('./Seasons/TrainSeasonRequestsPrinted')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/train-season-requests-issued',
        component: React.lazy(() =>
            import('./Seasons/TrainSeasonRequestsIssued')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/train-season-requests-rejected',
        component: React.lazy(() =>
            import('./Seasons/TrainSeasonRequestsRejected')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/bus-season-requests-new',
        component: React.lazy(() => import('./Seasons/BusSeasonRequestsNew')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/bus-season-requests-checked',
        component: React.lazy(() =>
            import('./Seasons/BusSeasonRequestsChecked')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/bus-season-requests-approved',
        component: React.lazy(() =>
            import('./Seasons/BusSeasonRequestsApproved')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/bus-season-requests-printed',
        component: React.lazy(() =>
            import('./Seasons/BusSeasonRequestsPrinted')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/bus-season-requests-issued',
        component: React.lazy(() =>
            import('./Seasons/BusSeasonRequestsIssued')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/bus-season-requests-rejected',
        component: React.lazy(() =>
            import('./Seasons/BusSeasonRequestsRejected')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/bus-season-requests',
        component: React.lazy(() => import('./Seasons/BusSeasonRequests')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/train-season-requests',
        component: React.lazy(() => import('./Seasons/TrainSeasonRequests')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/seasons-management',
        component: React.lazy(() => import('./Seasons/SeasonsManagement')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/train-season-notice',
        component: React.lazy(() =>
            import('./Seasons/StudentTrainSeasonNotice')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/bus-season-notice',
        component: React.lazy(() => import('./Seasons/StudentBusSeasonNotice')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/train-season-registration-form',
        component: React.lazy(() =>
            import('./Seasons/TrainSeasonRegistrationForm')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/bus-season-registration-form',
        component: React.lazy(() =>
            import('./Seasons/BusSeasonRegistrationForm')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/bus-season-application',
        component: React.lazy(() => import('./Seasons/BusSeasonApplication')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/train-season-application',
        component: React.lazy(() => import('./Seasons/TrainSeasonApplication')),
        auth: authRoles.dataSetup,
    },

    {
        path: '/welfare/season/student-train-season-requests',
        component: React.lazy(() =>
            import('./Seasons/StudentTrainSeasonRequests')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/student-bus-season-requests',
        component: React.lazy(() =>
            import('./Seasons/StudentBusSeasonRequests')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/season/student-seasons',
        component: React.lazy(() => import('./Seasons/StudentsSeasons')),
        auth: authRoles.appicationView,
    },
    {
        path: '/welfare/hostel/create-intake',
        component: React.lazy(() => import('./Hostel/CreateIntake')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/hostel/hostel-applications',
        component: React.lazy(() => import('./Hostel/HostelApplications')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/hostel/hostel-registration-form',
        component: React.lazy(() => import('./Hostel/HostelRegistrationForm')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/hostel/application-notice',
        component: React.lazy(() => import('./Hostel/ApplicationNotice')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/hostel/student-notice',
        component: React.lazy(() => import('./Hostel/StudentNotice')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/hostel/eligible-list',
        component: React.lazy(() => import('./Hostel/EligibleList')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/hostel/hostel-notice',
        component: React.lazy(() => import('./Hostel/HostelNotice')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/hostel/student-hostel',
        component: React.lazy(() => import('./Hostel/StudentHostel')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/hostel/view-intake/',
        component: React.lazy(() => import('./Hostel/ViewIntake')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/hostel/resource-reservation/',
        component: React.lazy(() => import('./Hostel/ResourceReservation')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/hostel/accomodation-overview/',
        component: React.lazy(() =>
            import('./Hostel/HostelAccomodationOverview')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/field-visit/uploading-reports',
        component: React.lazy(() => import('./FieldVisits/UploadingReports')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/field-visit/view-all-field-visits',
        component: React.lazy(() => import('./FieldVisits/AllFieldVisitView')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/field-visit/all-field-visit-approval-view',
        component: React.lazy(() =>
            import('./FieldVisits/AllFieldVisitApprovalView')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/bursary/bursary-application',
        component: React.lazy(() => import('./Bursary/BursaryApplication')),
        auth: authRoles.BursaryApplication,
    },
    {
        path: '/welfare/bursary/view-application',
        component: React.lazy(() => import('./Bursary/ViewApplication')),
        auth: authRoles.ViewApplication,
    },
    {
        path: '/welfare/bursary/all-new-applicants',
        component: React.lazy(() => import('./Bursary/AllNewApplicants')),
        auth: authRoles.AllNewApplication,
    },
    {
        path: '/welfare/bursary/check-application/:bursary_application_id',
        component: React.lazy(() => import('./Bursary/CheckApplication')),
        auth: authRoles.CheckApplicant,
    },
    {
        path: '/welfare/bursary/eligibility-list',
        component: React.lazy(() => import('./Bursary/EligibilityList')),
        auth: authRoles.EligibilityList,
    },
    {
        path: '/welfare/bursary/Agreement',
        component: React.lazy(() => import('./Bursary/Agreement')),
        auth: authRoles.Agreement,
    },
    {
        path: '/welfare/bursary/all-bursary-recipient-list',
        component: React.lazy(() =>
            import('./Bursary/AllBursaryRecipientList')
        ),
        auth: authRoles.AllBursaryRecipientList,
    },
    {
        path: '/welfare/bursary/check-Recipient/:ref_no',
        component: React.lazy(() =>
            import(
                './Bursary/BursaryComponents/AllBursaryRecipientList/RecipientData'
            )
        ),
        auth: authRoles.CheckRecipient,
    },
    {
        path: '/welfare/bursary/edit-Recipient/:ref_no',
        component: React.lazy(() =>
            import(
                './Bursary/BursaryComponents/AllBursaryRecipientList/EditRecipientData'
            )
        ),
        auth: authRoles.EditRecipient,
    },
    {
        path: '/welfare/bursary/all-monthly-installments',
        component: React.lazy(() => import('./Bursary/AllMonthlyInstallments')),
        auth: authRoles.AllMonthlyInstallments,
    },
    {
        path: '/welfare/bursary/bursary-recipients/installment/:ins_no',
        component: React.lazy(() =>
            import(
                './Bursary/BursaryComponents/AllMonthlyInstallments/BursaryRecipientsInstallment'
            )
        ),
        auth: authRoles.AllMonthlyInstallments,
    },
    {
        path: '/welfare/bursary/all-payment-criteria',
        component: React.lazy(() => import('./Bursary/AllPaymentCriteria')),
        auth: authRoles.AllPaymentCriteria,
    },
    {
        path: '/welfare/bursary/payment-criteria/:ins_no',
        component: React.lazy(() => import('./Bursary/PaymentCriteria')),
        auth: authRoles.PaymentCriteria,
    },
    {
        path: '/welfare/bursary/all-singed-recipients',
        component: React.lazy(() => import('./Bursary/AllSingedRecipients')),
        auth: authRoles.AllSingedRecipients,
    },
    {
        path: '/welfare/bursary/DocumentSetup',
        component: React.lazy(() => import('./Bursary/DocumentSetup')),
        auth: authRoles.DocumentSetup,
    },
    {
        path: '/welfare/bursary/bank-details',
        component: React.lazy(() => import('./Bursary/BankDetails')),
        auth: authRoles.BankDetails,
    },
    {
        path: '/welfare/bursary/installment-value-setup',
        component: React.lazy(() => import('./Bursary/InstallmentValueSetup')),
        auth: authRoles.InstallmentValueSetup,
    },
    {
        path: '/welfare/scholarships/all_scholarship',
        component: React.lazy(() => import('./Scholarship/AllScholarships')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/scholarship/new-scholarship',
        component: React.lazy(() => import('./Scholarship/NewScholarship')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/scholarship/scholarship-view/:id',
        component: React.lazy(() =>
            import('./Scholarship/View/ScholarshipsView')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/scholarship/scholarship-edit/:id',
        component: React.lazy(() => import('./Scholarship/Edit/ScholarshipEdit')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/scholarships/new_scholarships_selections',
        component: React.lazy(() =>
            import('./Scholarships/NewScholarshipsSelections')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/scholarships/all_awarded_scholarships',
        component: React.lazy(() =>
            import('./Scholarships/AllAwardedScholarships')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/scholarships/student_selections_EAY',
        component: React.lazy(() =>
            import('./Scholarships/StudentSelectionEAY')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/scholarships/student_selections_PC',
        component: React.lazy(() =>
            import('./Scholarships/StudentSelectionPC')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/scholarships/student_selections_Other',
        component: React.lazy(() =>
            import('./Scholarships/StudentSelectionOther')
        ),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/scholarships/approval_EAY',
        component: React.lazy(() => import('./Scholarships/ApprovalEAY')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/scholarships/approval_PC',
        component: React.lazy(() => import('./Scholarships/ApprovalPC')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/scholarships/approval_Other',
        component: React.lazy(() => import('./Scholarships/ApprovalOther')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/scholarships/awarding_EAY',
        component: React.lazy(() => import('./Scholarships/AwardingEAY')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/scholarships/awarding_PC',
        component: React.lazy(() => import('./Scholarships/AwardingPC')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/scholarships/awarding_Other',
        component: React.lazy(() => import('./Scholarships/AwardingOther')),
        auth: authRoles.dataSetup,
    }, 
    {
        path: '/welfare/scholarships/scholarships-approve/:id',
        component: React.lazy(() => import('./Scholarships/Approve/ScholarshipsApprove')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/scholarships/edit_approve_scholarship/:id',
        component: React.lazy(() => import('./Scholarships/EditApproveScholarship')),
        auth: authRoles.dataSetup,
    },
    {
        path: '/welfare/scholarships/all_approval_scholarships',
        component: React.lazy(() => import('./Scholarships/AllApprovalScholarships')),
        auth: authRoles.dataSetup,
    },
    // {
    //     path: '/welfare/scholarships/new_cholarships_pc1',
    //     component: React.lazy(() => import('./Scholarships/NewScholarshipsSelectionsPC1')),
    //     auth: authRoles.dataSetup,
    // },
]

export default WelfareRoutes
