/*
Developed By Shiny
Loons Lab
*/
import React, { Fragment, useState, Component } from 'react'
import { Grid, TextField, Typography } from '@material-ui/core'

class Signature extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <div
                    style={{
                        fontSize: 15,
                        textAlign: 'center',
                        textDecorationLine: 'underline',
                        textDecorationStyle: 'dotted',
                    }}
                >
                    <img
                        src={this.props.signatureOf}
                        width="100px"
                        height="50px"
                    />
                    <div
                        style={{
                            fontSize: 15,
                            textDecorationLine: 'underline',
                            textDecorationStyle: 'dotted',
                        }}
                    >
                        &emsp; &emsp; &emsp;&emsp; &emsp; &emsp; &emsp; &emsp;
                        &emsp;&emsp; &emsp;
                    </div>
                </div>
                <div
                    style={{
                        fontSize: 15,
                        fontFamily: 'Serif',
                        textAlign: 'center',
                    }}
                >
                    {this.props.signedBy}
                    <br />
                    <small>{this.props.signedDate}</small>
                </div>
            </Grid>
        )
    }
}

export default Signature
