import React from 'react'
import { authRoles } from '../../auth/authRoles'

const DegreesAndCoursesRoutes = [
    {
        path: '/postgraduate/course/create',
        component: React.lazy(() => import('./CreatePGCourse')),
        auth: authRoles.postgraduateCourseCreate,
    },
    {
        path: '/postgraduate/courses',
        component: React.lazy(() => import('./AllPGCourses')),
        auth: authRoles.postgraduateCourseCreate,
    },
    {
        path: '/postgraduate/course/:id',
        component: React.lazy(() => import('./PGCourseSingleView')),
        auth: authRoles.postgraduateCoursesView,
    },
    {
        // path: '/postgraduate/supervisor/form',
        // path: '/postgraduate/supervisor_approval?postgraduate_applicant_id=20&applicant=12',
        // http://localhost:3000/postgraduate/supervisor_approval/20/10
        path: '/postgraduate/supervisor_approval/:postgraduate_applicant_supervisor_id/:assigned_supervisor_id',
        component: React.lazy(() => import('./SupervisorsForm')),
        auth: authRoles.postgraduateCoursesView,
    },
    {
        path: '/postgraduate/setup_payment_plan',
        component: React.lazy(() => import('./SetupPaymentPlan')),
        auth: authRoles.postgraduateCourseCreate,
    },
    {
        path: '/postgraduate/setup_course_type',
        component: React.lazy(() => import('./SetupCourseType')),
        auth: authRoles.postgraduateCourseCreate,
    },
    {
        path: '/postgraduate/setup_student_type',
        component: React.lazy(() => import('./SetupPGstudentTypes')),
        auth: authRoles.postgraduateCourseCreate,
    },
]


export default DegreesAndCoursesRoutes
