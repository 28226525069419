import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {
    Card,
    Icon,
    IconButton,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    Fab,
    Tooltip,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    InputAdornment,
} from '@material-ui/core'

import { Alert, Autocomplete, createFilterOptions } from '@material-ui/lab'
import 'date-fns'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { themeColors } from 'app/components/MatxTheme/themeColors'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
    LoonsTable,
    DatePicker,
    FilePicker,
    Button,
    ExcelToTable,
    LoonsSnackbar,
    LoonsSwitch,
    CardTitle,
    SubTitle,
    LoonsCard,
    MainContainer
} from 'app/components/LoonsLabComponents'
import * as appConst from '../../../../../appconst'
import LensIcon from '@material-ui/icons/Lens';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EmployeeService from 'app/services/EmployeeService'
import Chip from '@material-ui/core/Chip';
import localStorageService from 'app/services/localStorageService'
import WorkIcon from '@material-ui/icons/Work';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BasicInformation from './BasicInformation'
import EyeOn from '@material-ui/icons/Visibility';
import EyeOff from '@material-ui/icons/VisibilityOff';
import AuthService from 'app/services/AuthService'
import StudentService from 'app/services/StudentService'
const styleSheet = (theme) => ({})

class SelectVacancies extends Component {
    constructor(props) {
        super(props)
        this.state = {
            alert: false,
            message: '',
            severity: 'success',
            vacancy: [],
            selected_vacancies: [],
            user: {},
            btnDisable: false,
            open: false,
            showForm: false,
            formData: {

            },
            loading: true,
            passwordSetup: false,
            accountCreateLoaing: false,
            passData: {},
            textType: "password",
            showSuccessMsg: false,
            passwordBtn: false,
            email_validity: null
        }
    }

    //Loading data from filter data
    async formSubmit() {
        if (this.state.email_validity == "This Email is Already Taken!") {
            return
        }

        this.setState({
            accountCreateLoaing: true,
            passwordBtn: true
        })

        if (this.state.passwordSetup) {
            let user = {
                username: this.state.formData.email,
                password: this.state.passData.password
            }
            console.log("USER", user)
            await AuthService.ResetPassword(user).then(
                res => {
                    if (res.status === 201) {
                        this.setState({
                            alert: true,
                            severity: 'success',
                            message: 'Password Creation Successfull!',
                            showForm: false,
                            showSuccessMsg: true,
                            passwordBtn: false

                        })
                    }
                }
            )
        } else {
            var data = this.state.formData;
            var newFormData = new FormData();
            const checkedIds = this.state.vacancy
                .filter(item => item.checked)
                .map(item => item.id);
            newFormData.append('request_ids', checkedIds);
            if (data.name_with_initials) {
                newFormData.append('name_with_initials', data.name_with_initials);
            }
            if (data.first_name) {
                newFormData.append('first_name', data.first_name);
            }
            // if (data.middle_name) {
            //     newFormData.append('middle_name', data.middle_name);
            // }
            if (data.sur_name) {
                newFormData.append('sur_name', data.sur_name);
            }
            if (data.full_name) {
                newFormData.append('full_name', data.full_name);
            }
            if (data.mobile_no) {
                newFormData.append('mobile_no', data.mobile_no);
            }
            if (data.nic) {
                newFormData.append('nic', data.nic);
            }

            newFormData.append('email', data.email);
            await EmployeeService.createBasicInformation(newFormData)
                .then(res => {
                    console.log("Respond", res)
                    if (res.status === 200 || res.status === 201) {
                        this.setState({
                            alert: true,
                            severity: 'success',
                            message: 'Account has been created successfully!',

                        },
                            () => {
                                setInterval(() => {
                                    this.setState({
                                        passwordSetup: true,
                                        accountCreateLoaing: false,
                                        passwordBtn: false
                                    })
                                }, 3000)
                            }
                        )
                    }
                    else {
                        this.setState({
                            alert: true,
                            severity: 'error',
                            message: res.response.data.error,
                            accountCreateLoaing: false,
                            passwordBtn: false
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        alert: true,
                        severity: 'error',
                        message: 'Error!',
                        passwordBtn: false
                    })
                })
        }
    }

    handleClose = () => {
        this.setState({
            open: false,
            showForm: false,
            showSuccessMsg: false,
            passwordBtn: false
        })

    };

    async loadData() {
        this.setState({
            loading: true
        })

        let filterdata = {
            publish_status: true,
            status: "Active"
        }

        await EmployeeService.getAllVacancies(filterdata)
            .then(res => {
                console.log(res, "loadData");

                if (res.status == 200) {
                    this.setState(
                        {
                            vacancy: res.data.view.data,
                            loading: false
                        },
                        () => {
                            console.log('data', this.state)
                            this.render()
                        }
                    )
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    snackbar: true,
                    snackbar_severity: 'error',
                    snackbar_message: 'Error!',
                    loading: false
                })
            })
    }

    async checkEmail() {

        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/

        if (!regex.test(this.state.formData.email)) {
            this.setState({
                email_validity: null
            })

            return
        }

        this.setState({
            email_validity: "Checking Validity..."
        })

        await StudentService.getEmailExist(this.state.formData.email)
            .then(res => {
                console.log("Users", res);

                if (res.status == 200) {
                    this.setState(
                        {
                            email_validity: res.data.view,
                        },
                        () => {
                            console.log('data', this.state)
                            this.render()
                        }
                    )
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    snackbar: true,
                    snackbar_severity: 'error',
                    snackbar_message: 'Error!',
                })
            })
    }

    async loadSelectedVacancies() {

        var data = {
            employee_id: this.state.user.id
        }

        await EmployeeService.getSelectedVacanciesById(data)
            .then(res => {
                console.log(res, "loadData");

                if (res.status == 200) {
                    this.setState(
                        {
                            selected_vacancies: res.data.view.data,
                        },
                        () => {
                            console.log('data', this.state)
                            this.render()
                        }
                    )
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    snackbar: true,
                    snackbar_severity: 'error',
                    snackbar_message: 'Error!',
                })
            })
    }

    onTrigger = () => {
        this.props.getDataSelectVacancies(this.state.vacancy);
    }

    changeStepper_Step = (step) => {
        var vacancy = this.state.vacancy;
        var vacancy_selected = false;

        for (var i = 0; i < vacancy.length; i++) {
            if (vacancy[i].checked) {
                vacancy_selected = true;
            }
        }

        if (vacancy_selected) {

            this.onTrigger();
            this.props.changeStepper_Step(step);
        } else {
            this.setState({
                alert: true,
                message: "Please Select at least One Vacancy to Continue",
                severity: "warning"
            })
        }
    }

    async getUserData() {
        let user = await localStorageService.getItem("userInfo")

        this.setState({
            user: user
        })
    }

    async componentDidMount() {

        await this.getUserData()
        // await this.loadSelectedVacancies()
        await this.loadData()
    }

    handleChange = (e) => {
        var val = e.target.value
        var key = e.target.name

        console.log(val, key)

        var data = this.state.formData || {}
        data[key] = val

        this.setState({
            formData: data,
        })
    }

    handleChangePassword = (e) => {
        var val = e.target.value
        var key = e.target.name

        console.log(val, key)

        var data = this.state.passData || {}
        data[key] = val

        this.setState({
            passData: data,
        })
    }
    setTextType = (type) => {
        this.setState({
            textType: type
        })
    }

    render() {
        let { theme } = this.props
        const { classes } = this.props

        return (
            <Fragment>
                <div>
                    {!this.state.loading ?
                        <div className="pt-7">

                            <ValidatorForm
                                onSubmit={() => this.formSubmit()}
                                onError={() => null}
                            >
                                <Grid container spacing={2}>

                                    {/* <Grid item xs={12}>
                                        <CardTitle title="Select the vacancy/ vacancies that you wish to apply" />
                                    </Grid> */}
                                    <Grid container spacing={2}>
                                        <Grid item lg={10} md={10} sm={10} xs={12}></Grid>
                                        <Grid item lg={2} md={2} sm={2} xs={12}>
                                            <Button
                                                progress={false}
                                                onClick={() => {
                                                    console.log("Selected Vancy", this.state.vacancy)
                                                    // this.changeStepper_Step( 1)

                                                    let checked = false;

                                                    this.state.vacancy.forEach(element => {
                                                        if (element.checked) {
                                                            checked = true;
                                                        }
                                                    });

                                                    if (!checked) {
                                                        this.setState({
                                                            alert: true,
                                                            severity: 'warning',
                                                            message: 'Please Select at least One Vacancy to Continue'
                                                        })

                                                        return
                                                    }

                                                    this.setState({
                                                        open: true
                                                    })
                                                }
                                                }

                                                startIcon="assignment_turned_in"
                                                className="w-full"
                                                disable={this.state.btnDisable}
                                            >
                                                <span className="capitalize">
                                                    Apply
                                                </span>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4} className="my-2" >
                                        <Grid item xs={12}>
                                            <Alert severity="info"> <Typography className='text-18 font-mideum '>Please select the vacancy you want to apply. Then click <strong>Apply</strong> button. If you are applying for more than one vacancy, select them all before clicking the apply button</Typography></Alert>
                                        </Grid>
                                        {this.state.vacancy.map((item, index) => (
                                            <Grid key={index} item xs={12}  >
                                                <Grid container spacing={2} className="bg-light-primary p-5" style={{ borderRadius: "5px 5px 5px 5px" }}  >
                                                    <div className='flex w-full justify-between'>
                                                        <div className='flex items-center'>
                                                            <WorkIcon className='text-primary mr-4' />
                                                            <Typography className='text-primary' variant="h6" component="h6">
                                                                {item.CDesignation?.Designation ? item.CDesignation?.Designation?.designation : ""}
                                                            </Typography>

                                                        </div>

                                                        <Checkbox
                                                            onChange={(event) => {
                                                                let vacancy = this.state.vacancy;
                                                                if (!vacancy[index]) {
                                                                    vacancy[index] = { checked: false };
                                                                }
                                                                let this_vacancy = vacancy[index];
                                                                this_vacancy.checked = !this_vacancy.checked;
                                                                vacancy[index] = this_vacancy;
                                                                this.setState({ vacancy: vacancy });
                                                            }}
                                                            name="select_vacancy"
                                                            color="primary"
                                                            checked={
                                                                item.checked ? true
                                                                    : false
                                                            }
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="medium" />}
                                                        />


                                                    </div>
                                                    <Grid item xs={12} className='flex'>

                                                        <SubTitle title="Faculty :" />
                                                        <span className='ml-1'>{item.RecruitmentRequestsMain?.Carder?.Faculty ? item.RecruitmentRequestsMain?.Carder?.Faculty.name : "N/A"}</span>
                                                    </Grid>
                                                    <Grid item xs={12} className='flex'>

                                                        <SubTitle title="Department :" />
                                                        <span className='ml-1'> {item.RecruitmentRequestsMain?.Carder?.Department ? item.RecruitmentRequestsMain?.Carder?.Department.name : "N/A"}</span>
                                                    </Grid>

                                                    <Grid item xs={12} className='flex'>

                                                        <SubTitle title="Subject :" />
                                                        {/* <span className='ml-1'> {item.Notes?.subject ? item.Notes?.subject : "N/A"}</span> */}
                                                        <span className='ml-1'> {item.Notes?.notice_subject ? item.Notes?.notice_subject : "N/A"}</span>
                                                    </Grid>
                                                    <Grid item xs={12} className='block'>

                                                        <SubTitle title="Mandatory Qualification" />
                                                        {item.Notes?.mandatory}
                                                    </Grid>
                                                    <Grid item xs={12} className='block'>

                                                        <SubTitle title="Additional Qulifications" />
                                                        {item.Notes?.qualifications}
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>

                                    {/* <Grid container spacing={4} className="my-2" >
                               {this.state.vacancy.map((item, index) => (
                                   <Grid key={index} item xs={12} md={6} >
                                       <Grid container spacing={2} className="bg-light-primary " style={{ borderRadius: "5px 5px 5px 5px" }}  >

                                           <Grid item lg={1} md={1} sm={1} xs={2} className="pt-3" style={{ borderBottom: "3px solid white" }}>
                                               <Avatar className="bg-primary">
                                                   {index + 1}
                                               </Avatar>
                                           </Grid>
                                           <Grid item lg={10} md={10} sm={10} xs={8} className="pt-3" style={{ borderBottom: "3px solid white" }}>
                                               <Typography variant="h5" component="h5">
                                                   {item.CDesignation?.Designation ? item.CDesignation?.Designation?.designation : ""}
                                               </Typography>
                                           </Grid>
                                           <Grid item lg={1} md={1} sm={1} xs={2}>
                                               <Checkbox
                                                   onChange={(event) => {
                                                       let vacancy = this.state.vacancy;
                                                       if (!vacancy[index]) {
                                                           vacancy[index] = { checked: false };
                                                       }
                                                       let this_vacancy = vacancy[index];
                                                       this_vacancy.checked = !this_vacancy.checked;
                                                       vacancy[index] = this_vacancy;
                                                       this.setState({ vacancy: vacancy });
                                                   }}
                                                   name="select_vacancy"
                                                   color="primary"
                                                   checked={
                                                       item.checked ? true
                                                           : false
                                                   }
                                                   icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
                                                   checkedIcon={<CheckBoxIcon fontSize="medium" />}
                                               />
                                           </Grid>
                                           <Grid item lg={1} md={1} sm={1} xs={2} className="pt-2 mb-5"></Grid>
                                           <Grid item lg={5} md={5} sm={5} xs={4} className="pt-2 mb-5" style={{ borderRight: "3px solid white" }}>
                                               <Grid container spacing={2} >
                                                   <Grid item lg={3} md={3} sm={4} xs={12}>
                                                       <SubTitle title="Department :" />
                                                   </Grid>
                                                   <Grid item lg={9} md={9} sm={8} xs={12}>
                                                       {item.RecruitmentRequestsMain?.Carder?.Department ? item.RecruitmentRequestsMain?.Carder?.Department.name : "N/A"}
                                                   </Grid>
                                                   <Grid item lg={3} md={3} sm={4} xs={12}>
                                                       <SubTitle title="Faculty :" />
                                                   </Grid>
                                                   <Grid item lg={9} md={9} sm={8} xs={12}>
                                                       {item.RecruitmentRequestsMain?.Carder?.Faculty ? item.RecruitmentRequestsMain?.Carder?.Faculty.name : "N/A"}
                                                   </Grid>
                                                   <Grid item lg={3} md={3} sm={3} xs={12} style={{ borderTop: "3px solid white" }}>
                                                       {/* {item.Notes?.length > 1 ?
                                                   <SubTitle title="Subjects" />
                                                   :
                                                   <SubTitle title="Subject" />
                                               } 
                                                   </Grid>
                                                   <Grid item lg={9} md={9} sm={9} xs={12} style={{ borderTop: "3px solid white" }}>
                                                       {/* {item.Notes?.map((item2, index2) => (
                                                   <Chip
                                                       key={index2}
                                                       label={item2.subject}
                                                       style={{ backgroundColor: "white", color: "#6b6868" }}
                                                   />
                                               ))} 

                                                   </Grid>
                                               </Grid>
                                           </Grid>
                                           <Grid item lg={6} md={6} sm={6} xs={6} className="pt-2 mb-5 pl-5">
                                               <Grid container spacing={2}>
                                                   <Grid item lg={12} md={12} sm={12} xs={12}>
                                                       <SubTitle title="Mandatory" />
                                                   </Grid>

                                                   <Grid item lg={12} md={12} sm={12} xs={12} >
                                                       <Grid container spacing={2}>
                                                           <Grid item lg={1} md={1} sm={1} xs={2}>
                                                               <LensIcon fontSize='small' />
                                                           </Grid>
                                                           <Grid item lg={11} md={11} sm={11} xs={10}>
                                                               {item.Notes.mandatory}
                                                           </Grid>
                                                       </Grid>
                                                   </Grid>

                                                   <Grid item lg={12} md={12} sm={12} xs={12}>
                                                       <SubTitle title="Additional" />
                                                   </Grid>

                                                   <Grid item lg={12} md={12} sm={12} xs={12} >
                                                       <Grid container spacing={2}>
                                                           <Grid item lg={1} md={1} sm={1} xs={2}>
                                                               <LensIcon fontSize='small' />
                                                           </Grid>
                                                           <Grid item lg={11} md={11} sm={11} xs={10}>
                                                               {item.Notes.qualifications}
                                                           </Grid>
                                                       </Grid>
                                                   </Grid>

                                               </Grid>

                                           </Grid>
                                       </Grid>
                                   </Grid>
                               ))}
                           </Grid> */}

                                </Grid>

                            </ValidatorForm>

                        </div> :
                        <Grid className="justify-center text-center w-full pt-12">
                            <CircularProgress size={30} />
                        </Grid>
                    }

                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Do you have an account?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                If you already have an account, please proceed to login. Otherwise, kindly create a new account.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => window.location.href = '/session/signin'} color="primary">
                                Login
                            </Button>
                            <Button onClick={() => {
                                this.setState({
                                    open: false,
                                    showForm: true
                                })
                            }} color="primary" autoFocus>
                                Create new Account
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        PaperProps={{
                            style: {
                                width: 600
                            }
                        }}
                        open={this.state.showForm} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                        <ValidatorForm
                            onSubmit={() => {
                                this.formSubmit()
                               
                            }}
                            onError={() => null}
                        >
                            <DialogTitle id="form-dialog-title">{this.state.passwordSetup ? "Create a Password" : "Create an Account"}</DialogTitle>
                            <DialogContent>
                                {this.state.passwordSetup ?
                                    <Grid container spacing={2}>

                                        <Grid item xs={12}>
                                            <Typography>{this.state.formData.email}</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextValidator
                                                className="mb-3 w-full"
                                                label="Create Password"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.handleChangePassword}

                                                name="password"
                                                type={this.state.textType}
                                                value={this.state.passData.password}
                                                validators={[`matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$`, 'required']}
                                                errorMessages={["Please enter a valid password (at least 8 characters, containing at least one uppercase letter, one lowercase letter, and one digit)", 'This field is required']}
                                                InputProps={{

                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                size="small"
                                                                disableRipple={true}
                                                                type="button"
                                                                onClick={() => {
                                                                    this.state.textType == "password" ? this.setTextType("text") : this.setTextType("password")
                                                                }}
                                                            >
                                                                {this.state.textType == "password"
                                                                    ? <EyeOff />
                                                                    : <EyeOn />
                                                                }

                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }} />
                                        </Grid>
                                    </Grid>
                                    :
                                    <>
                                        {
                                            !this.state.accountCreateLoaing ?
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextValidator
                                                            className="w-full"
                                                            type="email"
                                                            label="Email"
                                                            variant="outlined"
                                                            size="small"
                                                            name="email"
                                                            value={this.state.formData.email}
                                                            onChange={(e) => {
                                                                this.handleChange(e);
                                                            }}
                                                            onBlur={(e) => {
                                                                this.checkEmail()
                                                            }}
                                                            validators={['required', 'isEmail']}
                                                            errorMessages={['This field is required', 'Email is not valid']}
                                                        />
                                                        {this.state.email_validity ?
                                                            <div className={this.state.email_validity == "Valid Email" ? "bg-green text-white" : this.state.email_validity == "This Email is Already Taken!" ? "bg-error text-white" : "bg-default"} style={{ borderRadius: "5px", padding: "5px", paddingLeft: "15px", marginTop: "10px" }}>
                                                                {this.state.email_validity}
                                                            </div>
                                                            : ""}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Alert severity="info">Please ensure that you enter a valid email address. Remember, this email will be used within our EMS system.</Alert>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        <TextValidator
                                                            className="w-full"
                                                            type="text"
                                                            label="First Name"
                                                            variant="outlined"
                                                            size="small"
                                                            name="first_name"
                                                            value={this.state.formData?.first_name ? this.state.formData?.first_name : ""}
                                                            onChange={(e) => {
                                                                this.handleChange(e);
                                                            }}
                                                            validators={[
                                                                "required",
                                                                "matchRegexp:^[^0-9]+$",
                                                            ]}
                                                            errorMessages={[
                                                                "This Field is required!",
                                                                "This Field Do Not Accept Numbers!",
                                                            ]}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        <TextValidator
                                                            className="w-full"
                                                            type="text"
                                                            label="Surname"
                                                            variant="outlined"
                                                            size="small"
                                                            name="sur_name"
                                                            value={this.state.formData?.sur_name ? this.state.formData?.sur_name : ""}
                                                            onChange={(e) => {
                                                                this.handleChange(e);
                                                            }}
                                                            validators={[
                                                                "matchRegexp:^[^0-9]+$",
                                                            ]}
                                                            errorMessages={[
                                                                "This Field Do Not Accept Numbers!",
                                                            ]}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextValidator
                                                            className="w-full"
                                                            type="text"
                                                            label="Full Name"
                                                            variant="outlined"
                                                            size="small"
                                                            name="full_name"
                                                            value={this.state.formData?.full_name ? this.state.formData?.full_name : ""}
                                                            onChange={(e) => {
                                                                this.handleChange(e);
                                                            }}
                                                            validators={[
                                                                "required",
                                                                "matchRegexp:^[^0-9]+$",
                                                            ]}
                                                            errorMessages={[
                                                                "This Field is required!",
                                                                "This Field Do Not Accept Numbers!",
                                                            ]}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        <TextValidator
                                                            className="w-full"
                                                            type="text"
                                                            label="NIC *"
                                                            variant="outlined"
                                                            size="small"
                                                            name="nic"
                                                            value={this.state.formData?.nic ? this.state.formData?.nic : ""}
                                                            onChange={(e) => {
                                                                this.handleChange(e);
                                                            }}
                                                            validators={['required', "matchRegexp:((^|, )([0-9]{9}(V|v)|(1|2)[0-9]{11}))+$"]}
                                                            errorMessages={[
                                                                'This field is required', "Please enter a valid NIC Number(Eg:991234567V or 991234567v or 199981234567)"
                                                            ]}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        <TextValidator
                                                            className="w-full"
                                                            type="text"
                                                            label="Telephone no: Mobile"
                                                            variant="outlined"
                                                            size="small"
                                                            name="mobile_no"
                                                            value={this.state.formData?.mobile_no ? this.state.formData?.mobile_no : ""}
                                                            onChange={(e) => {
                                                                this.handleChange(e);
                                                            }}
                                                            validators={[
                                                                "required",
                                                                "matchRegexp:^[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$",
                                                            ]}
                                                            errorMessages={[
                                                                'This field is required',
                                                                'Please enter valid mobile number E.g.(0771231231)',
                                                            ]}
                                                        />
                                                    </Grid>
                                                </Grid> :
                                                <Grid className="justify-center text-center w-full pt-12">
                                                    <CircularProgress size={30} />
                                                </Grid>

                                        }
                                    </>

                                }


                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleClose} color="primary">
                                    Cancel
                                </Button>
                                <Button type="submit"
                                    progress={this.state.passwordBtn}
                                    startIcon="save"
                                    color="primary">
                                    {this.state.passwordSetup ? "Create Password" : "Create Account"}
                                </Button>
                            </DialogActions>
                        </ValidatorForm>
                    </Dialog>

                    <Dialog
                        PaperProps={{
                            style: {
                                width: 700
                            }
                        }}
                        open={this.state.showSuccessMsg}
                        onClose={this.handleClose}

                    >

                        <DialogContent>
                            <div className="bg-light-primary py-3">
                                {/* check_circle_outline */}
                                <div className="flex justify-center text-center" >
                                    <Icon className="text-green text-48 font-medium">check_circle_outline</Icon>
                                </div>
                                <div className="flex justify-center text-center" >

                                    <Typography variant="h4" component="h4" className="mt-5 text-primary capitalize font-bold" style={{ letterSpacing: 3 }}>
                                        CONGRATULATIONS!
                                    </Typography>


                                </div>
                                <div className="block justify-center text-center px-10" >

                                    <Typography className="my-2 text-green font-medium text-16" style={{ letterSpacing: 2 }} >
                                        Your Account has been Successfully Created
                                    </Typography>
                                    <Typography className="my-2 text-black font-medium text-14" style={{ letterSpacing: 2 }} >
                                        You are now ready to access the system and proceed with your job application. By clicking “Ok” button,  you will be redirected to the login page. Please use the email address and password  provided  during the registration to login.  </Typography>

                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => window.location.href = '/session/signin'} color="primary">
                                Ok
                            </Button>

                        </DialogActions>
                    </Dialog>
                </div>


                <LoonsSnackbar
                    open={this.state.alert}
                    onClose={() => {
                        this.setState({ alert: false })
                    }}
                    message={this.state.message}
                    autoHideDuration={3000}
                    severity={this.state.severity}
                    elevation={2}
                    variant="filled"
                ></LoonsSnackbar>
            </Fragment>
        )
    }
}
export default withStyles(styleSheet)(SelectVacancies)
