import React from 'react'
import {authRoles} from '../../../auth/authRoles'

const SetupGradesRoutes = [

    {
        path: '/examinations/setup_grades',
        component: React.lazy (() => import('./SetupGrades')),
        authRoles: authRoles.ExaminationView,
    },
]

export default SetupGradesRoutes