import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import * as apiroutes from '../../apiroutes';
import * as appconst from '../../appconst';
import localStorageService from 'app/services/localStorageService';


const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const isValidToken = async (accessToken) => {
    console.log("checking")
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = (Date.now() / 1000) | 0;
    console.log("decodeToken", decodedToken)
    console.log("current time", currentTime)

    let refresh_token = await localStorageService.getItem("refresh_token")
    const decodedRefreshToken = jwtDecode(refresh_token)


    if (/\\\\\\\\\\/.test(accessToken)) {
        console.log("invalid token")
    }

    //86400
    if (/\\\\\\\\\\/.test(accessToken) || (decodedToken.exp - currentTime < appconst.refresh_befor)) {
        //console.log("checking2")
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        }

        axios.post(apiroutes.USER_LOGIN, `grant_type=refresh_token&client_id=${appconst.keycloak_client_id}&refresh_token=${refresh_token}&client_secret=${appconst.keycloak_client_secret}`, {
            headers: headers
        })
            .then((response) => {

                setSession(response.data.access_token);
                setRefreshToken(response.data.refresh_token);

            })
            .catch((error) => {

            })
    }

    if (decodedToken.exp > currentTime) {
        return true;
    } else if (decodedRefreshToken.exp < currentTime) {
        return false;
    }

}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.removeItem('accessToken')
        localStorageService.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {

        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const setRefreshToken = (token) => {
    if (token) {
        localStorage.removeItem('refresh_token')
        localStorageService.setItem('refresh_token', token)
        //axios.defaults.headers.common.Authorization = `Bearer ${token}`
    } else {
        //localStorage.removeItem('refresh_token')
        //delete axios.defaults.headers.common.Authorization
    }
}
const setUser = (user) => {

    if (user) {
        localStorageService.setItem("userInfo", user)
    } else {
        localStorage.removeItem('userInfo')
    }
}






const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (email, password) => {

        console.log("pass3", password)
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        const body = new URLSearchParams({
            grant_type: 'password',
            client_id: appconst.keycloak_client_id,
            username: email,
            password: password,
            client_secret: appconst.keycloak_client_secret

        })

        const response = await axios.post(apiroutes.USER_LOGIN,
            body
            , {
                headers: headers
            }
        )


        /*  const response = await fetch(apiroutes.USER_LOGIN, {
             method: 'POST',
             headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/x-www-form-urlencoded'
             },
             body: new URLSearchParams({
                 grant_type: 'password',
                 client_id: appconst.keycloak_client_id,
                 username: email,
                 password: password,
                 client_secret: appconst.keycloak_client_secret
 
             })
         }) */



        // const { accessToken, user } = response.data
        console.log("login data", response.data)
        setSession(response.data.access_token)
        setRefreshToken(response.data.refresh_token)

        const decodedToken = jwtDecode(response.data.access_token)
        console.log("login data decodedToken", decodedToken)

        let user = {
            id: decodedToken.userId,
            avatar: "null",
            email: decodedToken.email,
            name: decodedToken.name,
            roles: decodedToken.realm_access.roles,
            allData: decodedToken
        };

        setUser(user);


        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        })

    }

    const register = async (email, username, password) => {
        const response = await axios.post('/api/auth/register', {
            email,
            username,
            password,
        })

        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ; (async () => {
            try {
                const accessToken = localStorageService.getItem('accessToken')
                if (accessToken && await isValidToken(accessToken)) {
                    setSession(accessToken)
                    // const response = await axios.get('/api/auth/profile')
                    //const { user } = response.data
                    let user = localStorageService.getItem("userInfo");
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
