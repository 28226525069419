import React from 'react'
import { authRoles } from '../../auth/authRoles'

const renewalsRoutes = [


    {
        path: '/hod/year-renewals/',
        component: React.lazy(() => import('./HOD_AllYearRenewals')),
        auth: authRoles.SetUpYearRenwalView
    },
    {
        path: '/dean/year-renewals/',
        component: React.lazy(() => import('./Dean_AllYearRenewals')),
        auth: authRoles.SetUpYearRenwalView
    },
    {
        path: '/ma/setup-year-renewal',
        component: React.lazy(() => import('./SetupYearRenewal')),
        auth: authRoles.SetUpYearRenwalCreate
    },
    {
        path: '/ma/view-year-renewal/:id/:year_id',
        component: React.lazy(() => import('./ViewYearRenewal')),
        auth: authRoles.SetUpYearRenwalView
    },
    {
        path: '/view-single-year-renewal',
        component: React.lazy(() => import('./ViewSingleYearRenewal')),
        auth: authRoles.SetUpYearRenwalView
    },
    {
        path: '/ssw/view-all-student-list',
        component: React.lazy(() => import('./SSW_ViewAllStudents')),
        auth: authRoles.SetUpYearRenwalView
    },




]

export default renewalsRoutes;
