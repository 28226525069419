import React from 'react'
import { authRoles } from '../../auth/authRoles'

const GraduateListsRoutes = [
    {
        path: '/graduate_lists',
        component: React.lazy(() => import('./GraduateLists')),
        auth: authRoles.GraduatesList,
    },
]

export default GraduateListsRoutes
