import React from 'react'
import { authRoles } from '../../auth/authRoles'

const tempRoutes = [
    {
        path: '/studentaffairs/viewStatus/:id',
        component: React.lazy(() => import('./StudentAffairs/StatusView')),
        auth: authRoles.studentAffairsStatusView,
    },

    {
        path: '/students/all',
        component: React.lazy(() => import('./student/AllStudents')),
        auth: authRoles.dashboard,
    },
    {
        path: '/students/sendPasswordLink',
        component: React.lazy(() => import('./student/SendPasswordResetLink')),
        auth: authRoles.dashboard,
    },
    {
        path: '/student/create',
        component: React.lazy(() => import('./student/CreateStudent')),
        auth: authRoles.dashboard,
    },
    // Student
    {
        path: '/student/application/basic',
        component: React.lazy(() =>
            import('./student/StudentApplicationBasic')
        ),
        auth: authRoles.appicationView,
    },
    {
        path: '/appeal/create',
        component: React.lazy(() => import('./student/StudentAppeal')),
        auth: authRoles.dashboard,
    },
    {
        path: '/appeal/cancel',
        component: React.lazy(() => import('./student/RequestCancellation')),
        auth: authRoles.dashboard,
    },
    {
        path: '/appeal/allAppealRequests',
        component: React.lazy(() => import('../Appeal/AllStudentAppeals')),
        auth: authRoles.dashboard,
    },
    {
        path: '/viewappealrequestbyHOD/:id',
        component: React.lazy(() => import('../Appeal/ViewAppealHOD')),
        auth: authRoles.dashboard,
    },
    {
        path: '/viewappealrequestbyAppealBoard/:id',
        component: React.lazy(() => import('../Appeal/ViewAppealAppealBoard')),
        auth: authRoles.dashboard,
    },
    {
        path: '/student/renewal',
        component: React.lazy(() => import('./student/RequestYearRenewal')),
        auth: authRoles.dashboard,
    },
    {
        path: '/student/profile',
        component: React.lazy(() => import('./student/profile/StudentProfile')),
        auth: authRoles.dashboard,
    },
    {
        path: '/student/newprofile',
        component: React.lazy(() =>
            import('./student/profile/NewStudentProfile')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/student/subjectregistration',
        component: React.lazy(() =>
            import('./student/SubjectRegistration/StudentSubjectRegistration')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/student/exam_results',
        component: React.lazy(() =>
            import('./student/StudentExamResults')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/qr',
        component: React.lazy(() =>
            import('./student/Attendance/Attendance')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/da',
        component: React.lazy(() =>
            import('./student/Attendance/DailyAttendance')
        ),
        auth: authRoles.dashboard,
    },
    {
        path: '/student/duplicate-id/:id',
        component: React.lazy(() => import('./student/StudentDuplicateID')),
        auth: authRoles.dashboard,
    },
    {
        path: '/student/cancellationRequest',
        component: React.lazy(() => import('./student/CancellationRequest')),
        auth: authRoles.cancellationRequest,
    },

    {
        path: '/allcancellationrequests',
        component: React.lazy(() =>
            import('../Cancellation/AllStudentCancellations')
        ),
        auth: authRoles.cancellationRequest,
    },
    {
        path: '/viewcancellationrequest/:id',
        component: React.lazy(() => import('../Cancellation/ViewCancellation')),
        auth: authRoles.cancellationRequest,
    },
    {
        path: '/studentAff/allcancellationrequests',
        component: React.lazy(() =>
            import('../Cancellation/AllStudentCancellationsSTAff')
        ),
        auth: authRoles.cancellationRequest,
    },
    {
        path: '/studentAff/viewcancellationrequest/:id',
        component: React.lazy(() =>
            import('../Cancellation/ViewCancellationStAff')
        ),
        auth: authRoles.cancellationRequest,
    },
    {
        path: '/student/id_card',
        component: React.lazy(() =>
            import('./student/profile/StudentIDCardPrint')
        ),
        auth: authRoles.dashboard,
    },

    //profile
    {
        path: '/page-layouts/user-profile',
        component: React.lazy(() => import('./student/profile/StudentProfile')),
    },
    //admin view profile
    {
        path: '/admin/student-profile/:id',
        component: React.lazy(() =>
            import('./AdminStudentProfile/StudentProfileAdminView')
        ),
    },

    {
        path: '/hod/student-profile/:id',
        component: React.lazy(() => import('./HOD/HOD_StudentProfileView')),
    },
    //Student Affairs
    {
        path: '/student-affairs/viewStatus/:id',
        component: React.lazy(() => import('./StudentAffairs/StatusView')),
        auth: authRoles.studentAffairsStatusView,
    },
    {
        path: '/student-affairs/students/registered/',
        component: React.lazy(() =>
            import('./StudentAffairs/RegisteredStudents')
        ),
        auth: authRoles.AllStudents
    },

    {
        path: '/admin/student-profile/:id',
        component: React.lazy(() =>
            import('./AdminStudentProfile/StudentProfileAdminView')
        ),
    },

    //student application
    {
        path: '/student/application/my',
        // path: '/student/application/:id',
        component: React.lazy(() =>
            import('../AdminStudentProfile/application/MyApplications')
        ),
        auth: authRoles.cancellationRequest,
    },
    {
        path: '/student/application/view/:id',
        component: React.lazy(() =>
            import('../AdminStudentProfile/application/StudentApplication')
        ),
        auth: authRoles.cancellationRequest,
    },
    {
        path: '/student/application/pay',
        // path: '/student/application/pay/:id',
        component: React.lazy(() =>
            import('../AdminStudentProfile/application/MyApplicationPayments')
        ),
        auth: authRoles.cancellationRequest,
    },
    /*     {
            path: '/ar/applications/',
            component: React.lazy(() => import('./AR_AllApplications')),
            auth: authRoles.cancellationRequest,
        }, */

    {
        path: '/student/Documents/',
        component: React.lazy(() =>
            import('./student/Documents/StudentDocumentsUpload')
        ),
        auth: authRoles.appicationView,
    },
    {
        path: '/student/DocumentsStatus/:id',
        component: React.lazy(() =>
            import('./student/Documents/ViewStudentsDocuments')
        ),
        auth: authRoles.ViewDocumentStatus
    },
    {
        path: '/student/paymet/registration',
        component: React.lazy(() =>
            import('./student/Documents/StudentRegistrationPayment')
        ),
        auth: authRoles.appicationView,
    },
    {
        path: '/hr/view_employee_profile/:id',
        component: React.lazy(() => import('./employee/ViewEmployeeProfile')),
        auth: authRoles.humanResoursesViews,
    },
    {
        path: '/employee/resignation',
        component: React.lazy(() => import('./employee/EmployeeResignation')),
        auth: authRoles.EmployeeProfileView,
    },
    {
        path: '/student/student_id_print',
        component: React.lazy(() => import('./student/StudentIDPrint')),
        auth: authRoles.IDPrint,
    },
    {
        path: '/employee/resignation_hod',
        component: React.lazy(() => import('./employee/EmployeeResignationHod')),
        auth: authRoles.EmployeeProfileView,
    },
    {
        path: '/employee/resignation_dean',
        component: React.lazy(() => import('./employee/EmployeeResignationDean')),
        auth: authRoles.EmployeeProfileView,
    },
    {
        path: '/employee/resignation_vc',
        component: React.lazy(() => import('./employee/EmployeeResignationVc')),
        auth: authRoles.EmployeeProfileView,
    },
    {
        path: '/employee/all_resignations',
        component: React.lazy(() => import('./employee/AllResignationRequests')),
        auth: authRoles.EmployeeProfileView,
    },
    {
        path: '/myprofile',
        component: React.lazy(() => import('./employee/EmployeeProfileNew')),
        auth: authRoles.EmployeeProfileView,
    },

]

export default tempRoutes
