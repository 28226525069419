import React from 'react'
import {
    Icon,
    IconButton,
    MenuItem,
    Avatar,
    useMediaQuery,
    Hidden,
} from '@material-ui/core'
import { MatxMenu, MatxSearchBox } from 'app/components'
import NotificationBar from '../../NotificationBar/NotificationBar'
import { Link } from 'react-router-dom'
import ShoppingCart from '../../ShoppingCart/ShoppingCart'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import useAuth from 'app/hooks/useAuth'
import useSettings from 'app/hooks/useSettings'
import { NotificationProvider } from 'app/contexts/NotificationContext'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import Notification from 'app/views/Notification/Notification'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    topbar: {
        top: 0,
        zIndex: 96,
        transition: 'all 0.3s ease',

        background: `linear-gradient(90deg, ${palette.primary.main} 1%, ${palette.primary.main} 290%, rgba(255, 255, 255, 0))`,//Edit by Roshan

        '& .topbar-hold': {
            backgroundColor: palette.primary.main,// Commit by Roshan
            height: 80,
            paddingLeft: 18,
            paddingRight: 20,
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 16,
                paddingRight: 16,
            },
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 14,
                paddingRight: 16,
            },
        },
        '& .fixed': {
            boxShadow: theme.shadows[8],
            height: 64,
        },
    },
    userMenu: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: 24,
        padding: 4,
        '& span': {
            margin: '0 8px',
            // color: palette.text.secondary
        },
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 185,
    },
}))

const Layout1Topbar = ({disableMenu = false, disableNotifications = false}) => {
    const theme = useTheme()
    const classes = useStyles()
    const { settings, updateSettings } = useSettings()
    const { logout, user } = useAuth()
    const isMdScreen = useMediaQuery(theme.breakpoints.down('md'))
    const fixed = settings?.layout1Settings?.topbar?.fixed

    const updateSidebarMode = (sidebarSettings) => {
        updateSettings({
            layout1Settings: {
                leftSidebar: {
                    ...sidebarSettings,
                },
            },
        })
    }

    const handleSidebarToggle = () => {
        let { layout1Settings } = settings
        let mode

        if (isMdScreen) {
            mode =
                layout1Settings.leftSidebar.mode === 'close'
                    ? 'mobile'
                    : 'close'
        } else {
            mode =
                layout1Settings.leftSidebar.mode === 'full' ? 'close' : 'full'
        }

        updateSidebarMode({ mode })
    }

    return (
        <div className={classes.topbar}>
            <div className={clsx({ 'topbar-hold': true, fixed: fixed })}>
                <div className="flex justify-between items-center h-full">
                    <div className="flex">
                        {!disableMenu && (   
                            <IconButton
                                onClick={handleSidebarToggle}
                                className="hide-on-pc"
                            >
                                <Icon>menu</Icon>
                            </IconButton>
                        )}

                        {/*
                        <div className="hide-on-mobile">
                            <IconButton>
                                <Icon>mail_outline</Icon>
                            </IconButton>

                            <IconButton>
                                <Icon>web_asset</Icon>
                            </IconButton>

                            <IconButton>
                                <Icon>star_outline</Icon>
                            </IconButton>
                        </div>
                        */}
                    </div>
                    <div className="flex items-center">
                            {!disableNotifications && (
                                <NotificationProvider>
                                    <NotificationBar />
                                </NotificationProvider>
                            )}
                        {/*
                            <MatxSearchBox />
                            <NotificationBar2 /> 
                            <ShoppingCart />
                        */}
                        <MatxMenu
                            menuButton={
                                user ? (
                                    <div className={classes.userMenu}>
                                        
                                        <Hidden xsDown>
                                            <span>
                                                Hi <strong>{user.name}</strong>
                                            </span>
                                        </Hidden>
                                        <Avatar
                                            className="cursor-pointer"
                                            src={user.avatar}
                                        />
                                    </div>
                                ) : (
                                    <div className='flex items-center cursor-pointer gap-2 flex-col'>
                                        <Link
                                            className={classes.menuItem}
                                            to="/login"
                                        >
                                            Login
                                        </Link>
                                        <Link
                                            className={classes.menuItem}
                                            to="/signup"
                                        >
                                            Sing Up
                                        </Link>
                                    </div>
                                )
                            }
                        >
                            {/*   <MenuItem>
                                <Link className={classes.menuItem} to="/">
                                    <Icon> home </Icon>
                                    <span className="pl-4"> Home </span>
                                </Link>
                            </MenuItem>
                            <MenuItem>
                                <Link
                                    className={classes.menuItem}
                                    to="/page-layouts/user-profile"
                                >
                                    <Icon> person </Icon>
                                    <span className="pl-4"> Profile </span>
                                </Link>
                            </MenuItem>
                            <MenuItem className={classes.menuItem}>
                                <Icon> settings </Icon>
                                <span className="pl-4"> Settings </span>
                            </MenuItem> */}
                            <MenuItem
                                onClick={logout}
                                className={classes.menuItem}
                            >
                                <Icon> power_settings_new </Icon>
                                <span className="pl-4"> Logout </span>
                            </MenuItem>
                        </MatxMenu>
                        {/* <Notification /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Layout1Topbar)
