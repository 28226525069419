import axios from 'axios';
import * as apiroutes from '../../apiroutes';
import localStorageService from "./localStorageService";


class ApplicationServices {

    createApplicantAccount = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_APPLICANT_ACCOUNT, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    getApplicantData = async (id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_APPLICANT_ACCOUNT + id,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    getApplicantsData = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_APPLICANT_ACCOUNT, {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }
    

    /**
     * @param {{
     *   postgraduate_applicant_id: number,
     *   decision_for: string,
     *   meeting_no: string,
     *   confirmation_type: string,
     *   application_type: 'Normal' | 'Special Circumstance',
     *   is_bog: boolean | null,
     *   decision: string,
     *   date: string,
     *   notes: string,
     *   reviewer_details?: Array<{
     *     reviewer_no: number,
     *     reviewer_type: 'Internal' | 'External',
     *     reviewer_name: string,
     *     reviewer_designation: string
     *   }>
     * }} data - The data for postgraduate applicant decision.
     */
    updateApplicationConfirmations = async (data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.POSTGRADUATE_APPLICATION_CONFIRMATIONS, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }

    updateApplicantAccount = async (id, data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.UPDATE_APPLICANT_ACCOUNT + id, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    saveAcademicData = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.SAVE_ACADEMIC_DATA, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "multipart/form-data"
                    }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                    return resolve(error)

                })
        });
        return await a;
    }

    updateAcademicData = async (id, data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.UPDATE_SINGLE_ACADEMIC_DATA + id, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    getAcademicData = async (id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_ACADEMIC_DATA + id,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    deleteAcademicDegree = async (id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.delete(apiroutes.DELETE_ACADEMIC_DEGREE + id,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    saveCourseData = async (id, data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.SAVE_COURSE_DATA + id, data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "multipart/form-data"
                    }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                    return resolve(error)

                })
        });
        return await a;
    }

    getApplicantSelectedCourse = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_APPLICANT_SELECTED_COURSE,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    getApplicantCourseData = async (id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_APPLICANT_COURSE + id,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    saveRefereeData = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.SAVE_REFEREE_DATA, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    saveSupervisorData = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.SAVE_SUPERVISOR_DATA, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    updateSupervisorData = async (id, data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.UPDATE_SINGLE_SUPERVISOR_DATA + id, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    finalSubmit = async (id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        var data = {}
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.APPLICANT_FORM_FINAL_SUBMIT + id, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    getMyApplications = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.APPLICANT_GET_MY_APPLICATIONS,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    get_AR_AllApplications = async (params) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_ALL_APPLICATIONS,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    get_Supervisor_FormContent = async (id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_SUPERVISOR_FORM_DATA + id,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    updateSupervisorFormData = async (id1, id2, data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.UPDATE_SUPERVISOR_FORM_DATA + `${id1}/${id2}`, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }

    getStudentDegreePrograms = async (id) => {
        // const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_APTITUDE_DEGREE_PROGRAM_BY_ID + id
            ).then(res => {
                return resolve(res);
            })
                .catch((error) => {
                    return resolve(error)
                })
        });
        return await a;
    }


    /**
     * @param {number} examiner_id 
     * @param {{
     *  designation: number,
     *  name: string,
     *  type: string,
     *  exam_type: string,
     * }} data 
     */
    updatePostgraduateExaminer = async (examiner_id, data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.patch(apiroutes.POSTGRADUATE_APPLICATION_EXAMINERS + examiner_id, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                    return resolve(error);
                })
        });
        return await a;
    }
    /**
     * @param {number} examiner_id 
     */
    deletePostgraduateExaminer = async (examiner_id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.delete(apiroutes.POSTGRADUATE_APPLICATION_EXAMINERS + examiner_id,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                    return resolve(error);
                })
        });
        return await a;
    }

}

export default new ApplicationServices()