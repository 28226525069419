import React, { Component } from 'react'
import { Card, Typography } from '@material-ui/core'

import { Fragment } from 'react'
import SelectVacancies from '../HumanResource/Designation/Recruitment/SelectVacancies'
export class JobVacancy extends Component {


    render() {
        return (
            <Fragment>

                <Card >
                    <div className="bg-light-primary py-3 text-center" >
                        {/* check_circle_outline */}
                        <div className="flex justify-center text-center" >
                            <img alt="uni_logo" src="/assets/images/logo_vpa.png" width="100px" height="100px" />
                        </div>
                        <div className="flex justify-center text-center" >

                            <Typography variant="h4" component="h4" className="mt-5 text-primary capitalize font-bold" style={{ letterSpacing: 3 }}>
                                VPA Job Portal
                            </Typography>


                        </div>
                        <div className="flex justify-center text-center" >
                            <div className=' max-w-770'>
                                <Typography className="my-2 text-green font-medium text-16" style={{ letterSpacing: 1 }} >
                                    Join our community today and take the first step towards a promising future! Unlock your full potential and make your job search faster and more efficient.
                                </Typography>
                            </div>


                        </div>
                    </div>
                    <div className="px-25 py-10">
                        <SelectVacancies />
                    </div>


                </Card>

            </Fragment>
        )
    }
}

export default JobVacancy