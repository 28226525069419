import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';

import Grid from '@material-ui/core/Grid';
import SubTitle from '../SubTitle';


const tableOptions = {
    selectableRows: false,
    download: true,
    downloadOptions: { filename: 'employees.csv' },
    rowsPerPage: 100,
};

class Table extends Component {
    constructor(props) {
        super(props);
        const selectedColumns = props.columns.reduce((acc, column, index) => {
            acc[column] = index < 5; // Set true only for first 5 columns
            return acc;
        }, {});
        const visibleColumns = props.columns.filter(column => selectedColumns[column]);
        this.state = {
            visibleColumns: visibleColumns,
            selectedColumns: selectedColumns,
        };
    }
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.string).isRequired,
        data: PropTypes.arrayOf(PropTypes.object).isRequired,
    }



    handleColumnToggle = columnName => {
        const { visibleColumns, selectedColumns } = this.state;
        const updatedSelectedColumns = {
            ...selectedColumns,
            [columnName]: !selectedColumns[columnName],
        };
        const updatedVisibleColumns = Object.keys(updatedSelectedColumns).filter(
            columnName => updatedSelectedColumns[columnName],
        );
        this.setState({
            selectedColumns: updatedSelectedColumns,
            visibleColumns: updatedVisibleColumns,
        });
    };


    render() {
        const { columns, data } = this.props;
        const { visibleColumns, selectedColumns } = this.state;
        const tableData = data.map(rowData => {
            const row = [];
            for (const column of visibleColumns) {
                row.push(rowData[column]);
            }
            return row;
        });
        const tableColumns = visibleColumns.map(columnName => ({
            name: columnName,
            label: columnName.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" "),
        }));
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>

                    <Grid container spacing={2} className="bg-light-primary p-2 border-radius-8">
                        {columns.map(columnName => (
                            <Grid item xs={12} md={3}>
                                <label key={columnName}>
                                    <input
                                        type="checkbox"
                                        checked={selectedColumns[columnName]}
                                        onChange={() => this.handleColumnToggle(columnName)}
                                    />
                                    {" " + columnName.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                                </label>
                            </Grid>
                        ))}

                    </Grid>


                    {/* <button onClick={this.handleDownload}>Download</button> */}

                </Grid>
                <Grid item xs={12}>
                    <MUIDataTable
                        // title="My Table"
                        data={tableData}
                        columns={tableColumns}
                        options={tableOptions}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default Table;
