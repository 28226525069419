import React from 'react'
import {authRoles} from '../../../auth/authRoles'

const EnterMarksRoutes = [

    {
        path: '/examinations/enter_marks',
        component: React.lazy (() => import('./EnterMarks')),
        authRoles: authRoles.ExaminationView,
    },
]

export default EnterMarksRoutes