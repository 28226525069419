import axios from 'axios'
import getWeekWithOptions from 'date-fns/fp/getWeekWithOptions'
import * as apiroutes from '../../apiroutes'
import localStorageService from './localStorageService'

class VapaTvServices {

    // create program
    createProgram = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_TV_PROGRAM, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error.response);
                    return resolve(error.response);
                })
        });
        return await a;
    }
    
    // create lineup program
    createLineupProgram = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_LINEUP, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error.response);
                    return resolve(error.response);
                })
        });
        return await a;
    }
    
    // create program review
    createProgramReview = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_RATING, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error.response);
                    return resolve(error.response);
                })
        });
        return await a;
    }

    // create program review external
    createProgramReviewExternal = async (data) => {
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_RATING_EXTERNAL, data,
                {
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error.response);
                    return resolve(error.response);
                })
        });
        return await a;
    }

     //Get all PROGRAMS
     getAllPrograms = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_ALL_TV_PROGRAMS , {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    params: params
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }

    //Get all PROGRAMS
    getAllProgramsExternal = async (params) => {
        // const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_ALL_TV_PROGRAMS_EXTERNAL , {
                    // headers: { Authorization: `Bearer ${accessToken}` },
                    params: params
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }
     
    //Get all Lineup PROGRAMS
    getAllLineupPrograms = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_ALL_LINEUP , {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    params: params
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }
    //Get all Lineup PROGRAMS
    getAllLineupProgramsExternal = async (params) => {
        const promise = new Promise((resolve, reject) => {
            axios
                .post(apiroutes.GET_ALL_LINEUP_EXTERNAL , {
                    params: params
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }
    
    //Get all PROGRAMS ratings
    getAllProgramReviews = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_ALL_RATING , {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    params: params
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }
        //Get all PROGRAMS ratings
        getAllProgramReviewsForExternal = async (id) => {
            try {
                const res = await axios.post(apiroutes.GET_ALL_RATING_EXTERNAL, null, {
                    params: { id: id }
                });
                return res;
            } catch (error) {
                console.log('error', error);
                throw error;
            
        };
        }

    
    // create GENRE
    createGenre = async (data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.post(apiroutes.CREATE_TV_GENRE, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error.response);
                    return resolve(error.response);
                })
        });
        return await a;
    }

     //Get all GENRES
     getAllGenres = async (params) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .get(apiroutes.GET_ALL_TV_GENRES , {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    params: params
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        })
        return await promise
    }

    // get single tv program
    getSingleDegree = async (id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_ALL_TV_GENRES + "/" + id,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }
    
    // get single lineup event 
    getSingleLineup = async (id) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.get(apiroutes.GET_SINGLE_LINEUP + "/" + id,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }
    // get single lineup event  external
    getSingleLineupExternal = async (id) => {
        try {
            const res = await axios.post(apiroutes.GET_ALL_LINEUP_EXTERNAL, null, {
                params: { id: id }
            });
            return res;
        } catch (error) {
            console.log('error', error);
            throw error;
        }
    };
    

    //update tv program
    updateTVProgram = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.UPDATE_TV_PROGRAM + `${id}`, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await promise
    }
    
    //update lineup program
    updateLineupProgram = async (id, data) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const promise = new Promise((resolve, reject) => {
            axios
                .patch(apiroutes.UPDATE_LINEUP + `${id}`, data, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    console.log('error', error)
                    return resolve(error)
                })
        })
        return await promise
    }

    //change tv program status
    changeTVProgramStatus = async (id, data) => {
        const accessToken = await localStorageService.getItem("accessToken");
        const a = new Promise((resolve, reject) => {
            axios.put(apiroutes.CHANGE_STATUS_TV_PROGRAM + "/" + id, data,
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(res => {
                    return resolve(res);
                }).catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    //delete lineup event
    deleteLineupEvent = async (id) => {
        const accessToken = await localStorageService.getItem('accessToken')
        const a = new Promise((resolve, reject) => {
            axios
                .delete(apiroutes.DELETE_LINEUP + "/" + id, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then((res) => {
                    return resolve(res)
                })
                .catch((error) => {
                    return resolve(error)
                })
        })
        return await a
    }

}

export default new VapaTvServices()
