/*
Loons Lab Date picker component
Developed By Nishan
Loons Lab
*/
import React, { Component } from "react";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from '@material-ui/pickers'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PropTypes from "prop-types";
import { dateTimeParse } from "../../../../utils";

class LoonsTimePicker extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        children: PropTypes.node,
        variant: PropTypes.string,
        className: PropTypes.string,
        label: PropTypes.string,
        size: PropTypes.string,
        clearable: PropTypes.any,
        views:PropTypes.any,
        value: PropTypes.any,
        mask: PropTypes.any,
        placeholder: PropTypes.string,
        minDate: PropTypes.any,
        maxDate: PropTypes.any,
        required: PropTypes.bool,
        errorMessages: PropTypes.string,
        format: PropTypes.string,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        clearable: true,
        className: "mb-4 w-full",
        color: "primary",
        label: "",
        size: "small",
        variant: "outlined",
        mask: "__:__ _M",
        format: "HH:mm a",
        value: null,
        disabled: false,
        required: false,
        errorMessages: "",
        disabledClassName: "",
        placeholder: "HH/MM"
    };

    handleTimeChange = time => {
        const { onChange } = this.props;
        onChange &&
            onChange(
                dateTimeParse(time)
            );
    };

    renderChildren = (label, children) => {
        if (label) {
            return label;
        }
        if (children) {
            return children;
        }
    };

    render() {
        const {
            className,
            value,
            label,
            placeholder,
            variant,
            size,
            format,
            mask,
            required,
            disabled,
            views,
        } = this.props;

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                    clearable
                    className={className}
                    inputVariant={variant}
                    value={value}
                    label={label}
                    size={size}
                    views={views}
                    mask={mask}
                    placeholder={placeholder}
                    onChange={time => this.handleTimeChange(time)}
                    format={format}
                    autoOk={true}
                    required={required}
                    disabled={disabled}
                    KeyboardButtonProps={{
                        'aria-label': { label },
                    }}
                    keyboardIcon={<AccessTimeIcon />}
                />
            </MuiPickersUtilsProvider>
        );
    }
}

export default LoonsTimePicker;