import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {
    Card,
    Icon,
    IconButton,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    Tooltip,
    Fab,
    Switch,
    Typography,
    Divider,

} from '@material-ui/core'
import { Alert, Autocomplete, createFilterOptions } from '@material-ui/lab'
import { themeColors } from 'app/components/MatxTheme/themeColors'
import {
    LoonsTable,
    DatePicker,
    FilePicker,
    Button,
    ExcelToTable,
    LoonsSnackbar,
    LoonsSwitch,
    MainContainer,
    LoonsCard,
    CardTitle,
    SubTitle,
} from 'app/components/LoonsLabComponents'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as appConst from '../../../appconst'
import LoonsDiaLogBox from 'app/components/LoonsLabComponents/Dialogbox'
import EmailIcon from '@material-ui/icons/Email';
import CourseService from 'app/services/CourseService'
import ApplicationServices from 'app/services/ApplicationServices'
import { Link } from 'react-router-dom'
const styleSheet = (theme) => ({
    resendEmailText: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
})

class ApplicationForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            confirmation_checkbox: false,
            courseTypes: [],
            step: 0,
            all_courses: [],
            loaded: false,
            //documents
            files: { fileList: [] },
            // email confirm dialog box
            dialogBox: false,
            dialogBox_data: {},
            dialogBox_show_button: false,

            email_timeout: true,

            //snackbar
            snackbar: false,
            snackbar_message: '',
            snackbar_severity: 'success',

            selected_course: {
                // course_code: 'MU12212',
                // course_name: 'Taught Masters in Eastern Music',
                // faculty: 'Music',
                // duration: '6 Months',
                // closing_date: '2021/12/01',
            },

            formData: {
                degree: '',
                course_id: '',
                course_type: '',

                name: '',
                email: '',
                contact_number: '',

            },

            filterData: {
                type: '',
                search: '',
                limit: 20,

            },

        }

    }

    //select course
    selectCourseStep1 = (course) => {

        var data = this.state.formData

        data['course_id'] = course.id
        data['course_type'] = course.type
        data['course_type_id'] = course.course_type_id

        this.setState({
            formData: data,
            selected_course: course,
            step: 1,
        })

        console.log("Course:", this.state);

    }

    //auto search filter
    setSearchFilterValue = (key, value) => {
        console.log(key, value);

        var data = this.state.filterData
        data[key] = value

        this.setState({
            filterData: data,
        },
            async () => await this.loadData(data)
        )

    }

    //set values from selections (Autocomplete) for filters
    // setSelectedFilterValue = (key, value) => {
    //     console.log(key, value);

    //     var data = this.state.filterData
    //     data[key] = value

    //     this.setState({
    //         filterData: data,
    //     })

    // }

    //set input values for filters
    handleChangeFilterValue = (e) => {

        var val = e.target.value
        var key = e.target.name

        console.log(val, key);

        var data = this.state.filterData
        data[key] = val

        this.setState({
            filterData: data,
        })
    }

    //set input values
    handleChange = (e) => {

        var val = e.target.value
        var key = e.target.name

        console.log(val, key);

        var data = this.state.formData
        data[key] = val

        this.setState({
            formData: data,
        })
    }

    //close dialog box
    handleDialogBoxClose = () => {
        let formData = this.state.formData
        formData.name = ""
        formData.course_id = null
        formData.contact_number = ""
        formData.email = ""
        formData.degree = null

        this.setState({
            dialogBox: false,
            dialogBox_data: {},
            step: 0,
            formData

        }, () => {
            this.loadData()
        })
    }

    //submit applicant create account form
    formSubmit = async (e) => {
        var bodyformData = new FormData();
        let data = this.state.formData
        for (let i = 0; i < this.state.files.fileList.length; i++) {
            bodyformData.append(`file`, this.state.files.fileList[i].file);
        }
        bodyformData.append(`fullName`, data.name)
        bodyformData.append(`email`, data.email)
        bodyformData.append(`contact_no`, data.contact_number)
        bodyformData.append(`nic_passport`, data.nic_passport)
        bodyformData.append(`course_id`, data.course_id)
        bodyformData.append(`course_type`, data.course_type)
        bodyformData.append(`course_type_id`, data.course_type_id)
        bodyformData.append(`special`, this.state.confirmation_checkbox ? "true" : 'false')


        await ApplicationServices.createApplicantAccount(bodyformData)
            .then(res => {
                console.log("res", res);
                if (res.status == 201) {
                    this.setState({
                        snackbar: true,
                        snackbar_message: 'Account Created Successfully!',
                        snackbar_severity: 'success',
                        dialogBox: true,
                        dialogBox_show_button: true,
                    })
                }
                else {
                    this.setState({
                        snackbar: true,
                        snackbar_message: res.response.data.error,
                        snackbar_severity: 'error',
                    })
                }

            })
            .catch(error => {
                console.log(error);

                this.setState({
                    snackbar: true,
                    snackbar_message: 'Error!',
                    snackbar_severity: 'error',
                })
            })

    }

    async loadData() {

        this.setState({
            loaded: false
        })

        await CourseService.getAllCourses(this.state.filterData)
            .then(res => {
                if (res.status == 200) {
                    let filteredData = res.data.view.data.filter((element) => element.workflow_status == "Approved")
                    this.setState({
                        all_courses: filteredData,
                        loaded: true
                    })
                } else {
                    this.setState({

                        loaded: true
                    })
                }

            })
            .catch(error => {
                this.setState({

                    loaded: true
                })
                console.log(error);
            })

        // var courses = [
        //     {
        //         course_code: 'MU12212',
        //         course_name: 'Taught Masters in Eastern Music',
        //         faculty: 'Music',
        //         duration: '6 Months',
        //         closing_date: '2021/12/01',
        //     },
        //     {
        //         course_code: 'AR12212',
        //         course_name: 'Taught Masters in Visual Arts',
        //         faculty: 'Arts',
        //         duration: '6 Months',
        //         closing_date: '2021/12/01',
        //     },
        //     {
        //         course_code: 'AR12212',
        //         course_name: 'Taught Masters in Visual Arts',
        //         faculty: 'Arts',
        //         duration: '6 Months',
        //         closing_date: '2021/12/01',
        //     },
        // ];

        // //load courses
        // this.setState({
        //     all_courses: courses,
        // })



    }

    async componentDidMount() {
        await this.loadCourseTypes()
        await this.loadData()

    }

    async loadCourseTypes() {//Loading course tyoes
        let res = await CourseService.getCourseTypes();
        if (res.status == 200) {
            this.setState({ courseTypes: res.data.view.data })
        } else {
            this.loadCourseTypes()
        }
    }

    //file upload
    selectedFiles(files) {
        this.setState({
            files: files,
        })
    }

    render() {

        let { theme } = this.props
        const { classes } = this.props

        return (
            <div>
                <Fragment>
                    <MainContainer>
                        <LoonsCard>
                            {/* Application 1 */}
                            {
                                this.state.step === 0 &&
                                <Fragment>
                                    <CardTitle title="Application Form " />
                                    <div className='my-3'>
                                        <Alert severity="info">

                                            <Typography className='title' >
                                                If you are Registered at Present for Degree/ Diploma at  University of Visual Performing Arts Please Log in to the student portal and Continue the application process.

                                            </Typography>
                                            <Link to="/session/signin" >
                                                <Button className="capitalize my-4" scrollToTop={false}>
                                                    Sign In EMS
                                                </Button>
                                            </Link>
                                        </Alert>

                                    </div>


                                    <ValidatorForm
                                        className="pt-5"
                                        onSubmit={() => this.formSubmit()}
                                        onError={() => null}
                                    >
                                        <Grid container spacing={2}>

                                            {/* Degree/Course type */}
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <SubTitle title="Degree/Course Type" />
                                                <Autocomplete
                                                    className="w-full"
                                                    options={this.state.courseTypes}
                                                    getOptionLabel={(opt) => opt.name}
                                                    name="type"
                                                    size="small"
                                                    onChange={(e, v) =>
                                                        this.setSearchFilterValue(
                                                            "type",
                                                            v == null ? null : v.name
                                                        )
                                                    }
                                                    renderInput={(params) => (
                                                        <TextValidator
                                                            {...params}
                                                            label={"Select Degree/Course Type"}
                                                            variant="outlined"
                                                            value={this.state.filterData.type}
                                                        // validators={["required"]}
                                                        // errorMessages={[
                                                        //     "This field is required!"
                                                        // ]}
                                                        />
                                                    )}
                                                />

                                            </Grid>

                                            {/* Course Name */}
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <SubTitle title="Course Name" />
                                                <TextValidator
                                                    className=" w-full"
                                                    type="text"
                                                    label="Search By Course Name"
                                                    variant="outlined"
                                                    size="small"
                                                    multiline={true}
                                                    name="search"
                                                    value={this.state.filterData.search}
                                                    onChange={(e) =>
                                                        this.setSearchFilterValue(
                                                            "search",
                                                            e.target.value == null ? "" : e.target.value
                                                        )


                                                    }
                                                    InputLabelProps={{
                                                        shrink: this.state.filterData.search != ""
                                                            ? true : false,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>

                                            {/* Courses Display Area */}
                                            <Grid item className="my-10" xs={12} sm={12} md={12} lg={12}>
                                                {
                                                    this.state.loaded ?
                                                        this.state.all_courses.map((item, key) => (

                                                            <Card
                                                                className="p-5 m-2 cursor-pointer" elevation={4}
                                                                onClick={() => this.selectCourseStep1(item)}
                                                                style={{ borderLeft: "7px solid #0f90e8" }}
                                                            >

                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    alignItems="center"
                                                                    justify="center"
                                                                    key={key}
                                                                >
                                                                    <Grid className="my-1" item xs={12} sm={6} md={3} lg={3}>
                                                                        <Typography variant="body2">
                                                                            Course Code
                                                                        </Typography>
                                                                        <Typography className="font-medium" variant="body2">
                                                                            {item.code}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid className="my-1" item xs={12} sm={6} md={3} lg={3}>
                                                                        <Typography variant="body2">
                                                                            Course Name
                                                                        </Typography>
                                                                        <Typography className="font-medium" variant="body2">
                                                                            {item.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid className="my-1" item xs={12} sm={6} md={3} lg={3}>
                                                                        <Typography variant="body2">
                                                                            Faculty
                                                                        </Typography>
                                                                        <Typography className="font-medium" variant="body2">
                                                                            {item.Faculty ? item.Faculty.name : "-"}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid className="my-1" item xs={12} sm={6} md={2} lg={2}>
                                                                        <Typography variant="body2">
                                                                            Duration
                                                                        </Typography>
                                                                        <Typography className="font-medium" variant="body2">
                                                                            Months:{item.months} |
                                                                            Years:{item.years}
                                                                        </Typography>
                                                                    </Grid>
                                                                    {/* <Grid className="my-1" item xs={12} sm={6} md={1} lg={1}>
                                                                    <Typography variant="body2">
                                                                        Application End Date
                                                                    </Typography>
                                                                    <Typography className="text-error font-medium" variant="body2">
                                                                        {item.closing_date}
                                                                    </Typography>
                                                                </Grid> */}

                                                                </Grid>

                                                            </Card>

                                                        ))
                                                        :
                                                        <Grid className="justify-center text-center w-full pt-12">
                                                            <CircularProgress size={30} />
                                                        </Grid>
                                                }
                                            </Grid>
                                        </Grid>



                                    </ValidatorForm>

                                </Fragment>
                            }

                            {/* Application 2 */}
                            {
                                this.state.step === 1 &&
                                <Fragment>
                                    <CardTitle title="Application Form" />
                                    <div className='my-3'>
                                        <Alert severity="info">

                                            <Typography className='title' >
                                                If you are Registered at Present for Degree/ Diploma at  University of Visual Performing Arts Please Log in to the student portal and Continue the application process.

                                            </Typography>
                                            <Link to="/session/signin" >
                                                <Button className="capitalize my-4" scrollToTop={false}>
                                                    Sign In EMS
                                                </Button>
                                            </Link>
                                        </Alert>

                                    </div>

                                    <ValidatorForm
                                        onSubmit={() => this.formSubmit()}
                                        onError={() => null}
                                    >

                                        {/* Courses Display Area */}
                                        <Grid item className="my-5" xs={12} sm={12} md={12} lg={12}>

                                            <Card className="p-5 m-2" elevation={4}>

                                                <Grid
                                                    container
                                                    direction="row"
                                                    alignItems="center"
                                                    justify="center"
                                                >
                                                    <Grid item xs={12}>
                                                        <Typography className="text-18 font-bold">Selected Course Details</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} className='mb-4'>
                                                        <Divider />
                                                    </Grid>
                                                    <Grid className="my-1" item xs={12} sm={6} md={3} lg={3}>
                                                        <Typography variant="body2">
                                                            Course Code
                                                        </Typography>
                                                        <Typography className="font-medium" variant="body2">
                                                            {this.state.selected_course.code}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid className="my-1" item xs={12} sm={6} md={4} lg={4}>
                                                        <Typography variant="body2">
                                                            Course Name
                                                        </Typography>
                                                        <Typography className="font-medium" variant="body2">
                                                            {this.state.selected_course.name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid className="my-1" item xs={12} sm={6} md={3} lg={3}>
                                                        <Typography variant="body2">
                                                            Faculty
                                                        </Typography>
                                                        <Typography className="font-medium" variant="body2">
                                                            {this.state.selected_course.Faculty.name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid className="my-1" item xs={12} sm={6} md={2} lg={2}>
                                                        <Typography variant="body2">
                                                            Duration
                                                        </Typography>
                                                        <Typography className="font-medium" variant="body2">
                                                            Months:{this.state.selected_course.months} |
                                                            Years:{this.state.selected_course.years}
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                                <Grid item xs={12} className='my-4 mt-5'>
                                                    <Divider />
                                                </Grid>

                                                {/* Form */}
                                                <Grid item className="my-5" xs={12} sm={12} md={12} lg={12}>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                    >
                                                        {/* Full Name */}
                                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                                            <SubTitle title="Full Name" />
                                                            <TextValidator
                                                                className="mt-1 w-full"
                                                                type="text"
                                                                label="Full Name"
                                                                variant="outlined"
                                                                size="small"
                                                                name="name"
                                                                value={this.state.formData.name}
                                                                onChange={(e) => {
                                                                    this.handleChange(e);
                                                                }}
                                                                validators={["required"]}
                                                                errorMessages={[
                                                                    "This Field is required!"
                                                                ]}
                                                            />
                                                        </Grid>

                                                        {/* Contact Number */}
                                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                                            <SubTitle title="Contact Number" />
                                                            <TextValidator
                                                                className="mt-1 w-full"
                                                                type="text"
                                                                label="Contact Number"
                                                                variant="outlined"
                                                                size="small"
                                                                name="contact_number"
                                                                value={this.state.formData.contact_number}
                                                                onChange={(e) => {
                                                                    this.handleChange(e);
                                                                }}
                                                                validators={[
                                                                    "required",
                                                                    "matchRegexp:^[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$",
                                                                ]}
                                                                errorMessages={[
                                                                    "This field is required!",
                                                                    "Please enter valid phone number"
                                                                ]}
                                                            />
                                                        </Grid>

                                                        {/* Email */}
                                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                                            <SubTitle title="Email" />
                                                            <TextValidator
                                                                className="mt-1 w-full"
                                                                type="text"
                                                                label="Email"
                                                                variant="outlined"
                                                                size="small"
                                                                name="email"
                                                                value={this.state.formData.email}
                                                                onChange={(e) => {
                                                                    this.handleChange(e);
                                                                }}
                                                                validators={["required", "isEmail"]}
                                                                errorMessages={[
                                                                    "This Field is required!",
                                                                    "Invalid email address",
                                                                ]}
                                                            />
                                                        </Grid>
                                                        {/* nic / passport */}
                                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                                            <SubTitle title="Email" />
                                                            <TextValidator
                                                                className="mt-1 w-full"
                                                                type="text"
                                                                label="NIC / Passport"
                                                                variant="outlined"
                                                                size="small"
                                                                name="nic_passport"
                                                                value={this.state.formData.nic_passport}
                                                                onChange={(e) => {
                                                                    this.handleChange(e);
                                                                }}
                                                                validators={["required", 'matchRegexp:((^|, )([0-9]{9}(V|v)|(1|2)[0-9]{11}))+$']}
                                                                errorMessages={[
                                                                    "This Field is required!",
                                                                    "Invalid NIC / Passport Number",
                                                                ]}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={this.state.confirmation_checkbox}
                                                                        onChange={() => {
                                                                            var checkbox = this.state.confirmation_checkbox
                                                                            this.setState({
                                                                                confirmation_checkbox: !checkbox,
                                                                            })
                                                                        }}
                                                                        name="chkbox_confirm"
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={<p>Applying under special circumstances</p>}
                                                            />
                                                        </Grid>
                                                        {this.state.confirmation_checkbox &&
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12}>
                                                                        <Alert severity="info">

                                                                            <Typography className='title' >
                                                                                Applying under special circumstances
                                                                            </Typography>
                                                                            <Typography className='subtitle-1' >
                                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                                            </Typography>
                                                                        </Alert>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FilePicker
                                                                            className="mb-4 w-full"
                                                                            singleFileEnable={false}
                                                                            id="supporting_doc"
                                                                            multipleFileEnable={true}
                                                                            dragAndDropEnable={true}
                                                                            //tableEnable={false}
                                                                            documentName={false}
                                                                            //documentNameValidation={['required']}
                                                                            //documenterrorMessages={['This field is required']}
                                                                            //accept="image/png, image/gif, image/jpeg"
                                                                            maxFileSize={1048576}
                                                                            maxTotalFileSize={1048576}
                                                                            maxFilesCount={3}
                                                                            validators={['maxSize', 'maxTotalFileSize', 'maxFileCount']}
                                                                            errorMessages={["file size too large", "Total file size is too large", "Too many files added"]}
                                                                            label="Upload Portfolio"
                                                                            singleFileButtonText="Select File"
                                                                            multipleFileButtonText="Select Files"
                                                                            selectedFiles={(files) => this.selectedFiles(files)}
                                                                            selectedFileList={this.state.files.fileList}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                        }
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        container
                                                        className="mx-2 mt-3"
                                                        justify="flex-end"
                                                        spacing={2}
                                                    >
                                                        {/* change step */}
                                                        <Button
                                                            scrollToTop={false}
                                                            className="m-1 justify-end"
                                                            variant="outlined"
                                                            onClick={() => {
                                                                this.setState({
                                                                    step: 0,
                                                                })
                                                            }}
                                                        >
                                                            Back
                                                        </Button>
                                                        <Button
                                                            scrollToTop={false}
                                                            className="m-1 justify-end"
                                                            progress={false}
                                                            type="submit"

                                                        >
                                                            <span className="capitalize">Create Account</span>
                                                        </Button>
                                                    </Grid>
                                                </Grid>

                                            </Card>

                                        </Grid>




                                    </ValidatorForm>
                                </Fragment>
                            }

                            {/* <Button
                                className="p-1 m-2"
                                onClick={() => {
                                    this.setState({
                                        dialogBox: true,
                                        dialogBox_show_button: true,
                                    })
                                }}
                            >
                                Demo Btn Dialog Box
                            </Button> */}


                            {/* Dialog box - email confirmation */}
                            <LoonsDiaLogBox
                                open={this.state.dialogBox}
                                // open={true}
                                title={"Email Confirmation"}
                                // show_alert={false}
                                body_children={
                                    <Alert severity="info">
                                        <Typography className="text-15" >
                                            Please check your email(<b>{this.state.formData.email}</b>)
                                            for a link to verify your email address.
                                            Once verified, you'll be able to continue.
                                        </Typography>

                                        {/* Email icon */}
                                        <div className="text-center my-2" >
                                            {
                                                this.state.email_timeout ?
                                                    <EmailIcon className="display-3 bg-dark border-radius-circle p-2" />
                                                    :
                                                    <CircularProgress />
                                            }
                                        </div>

                                        {/* Email resend */}
                                        <div className="flex" >
                                            <Typography className="text-12">
                                                Didn't receive an email?&ensp;
                                            </Typography>

                                            <div
                                                className={classes.resendEmailText}
                                                onClick={() => {
                                                    console.log("Resend Email")
                                                }}
                                            >
                                                {
                                                    this.state.email_timeout ?
                                                        <Typography className="font-bold text-primary text-13">
                                                            Resend
                                                        </Typography>
                                                        :
                                                        <Typography className="font-bold text-primary text-13 ">
                                                            Please Wait...
                                                        </Typography>
                                                }
                                            </div>

                                        </div>

                                    </Alert>
                                }
                                show_button={this.state.dialogBox_show_button}
                                onClose={this.handleDialogBoxClose}
                                btn_label="OK"
                            />

                            {/* Snackbar */}
                            <LoonsSnackbar
                                open={this.state.snackbar}
                                onClose={() => {
                                    this.setState({
                                        snackbar: false,
                                    })
                                }}
                                message={this.state.snackbar_message}
                                autoHideDuration={3000}
                                severity={this.state.snackbar_severity}
                                elevation={2}
                                variant="filled"
                            ></LoonsSnackbar>

                        </LoonsCard>
                    </MainContainer >

                </Fragment >
            </div >
        )
    }
}

export default withStyles(styleSheet)(ApplicationForm)